import React, { useState } from "react";
import "./Settings.scss";
import SchoolLogo from "../../assets/logos/default-logo.png";
import { GoLocation } from "react-icons/go";
import { useAuthenticate } from "../../context/AuthContext";
import { ColorRing } from "react-loader-spinner";
import PhoneInput from "react-phone-number-input";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { API_URL } from "../../url";
import axios from "axios";
import { AiOutlineUpload } from "react-icons/ai";
import FormInput from "../../components/Forms/FormInput";
import Header from "../../components/Header";

const SchoolInfo = () => {
  const { schoolInfo } = useAuthenticate();
  const [updateData, setUpdateData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const updateSchoolInfo = async (e) => {
    e.preventDefault();

    if (!loading) {
      if (updateData.school_logo && updateData.school_logo.size > 828576) {
        toast.error(`The size of the school Logo should be less than 828KB`);
      } else {
        if (localStorage.getItem("access")) {
          setLoading(true);
          const config = {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          const formData = new FormData();
          Object.keys(updateData).forEach((key) => {
            formData.append(key, updateData[key]);
          });
          try {
            await axios
              .put(`${API_URL}accounts/school-details/`, formData, config)
              .then((response) => {
                setLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "Updated",
                  text: "School Details updated",
                }).then(() => window.location.reload());
              });
          } catch (error) {
            setLoading(false);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/";
              } else {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    }
  };

  return (
    <div className="main-container">
      <Header title="School Profile" subtitle="Information about your school" />
      <div className="school-profile-body">
        <div className="school-profile-left">
          <form onSubmit={(e) => updateSchoolInfo(e)}>
            <div className="formSection">
              <p className="formRowHeader">School Details</p>
              <div className="formRow">
                <div className="formInput">
                  <label>School Logo</label>
                  <input
                    type="file"
                    accept="image/*"
                    name="school_logo"
                    id="school_logo"
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        [e.target.name]: e.target.files[0],
                      });
                    }}
                  />
                  <label className="upload-button" htmlFor="school_logo">
                    <AiOutlineUpload style={{ fontSize: "15px" }} />
                    Upload Picture
                  </label>
                  <p style={{ fontWeight: "400", fontSize: "12px" }}>
                    {updateData.school_logo
                      ? updateData.school_logo.name
                      : "No file selected"}
                  </p>
                </div>
              </div>
              <div className="formRow">
                <div className="formInput">
                  <label>School Name</label>
                  <input
                    type="text"
                    name="school_name"
                    onChange={(e) => handleChange(e)}
                    id="school_name"
                    value={
                      updateData.school_name
                        ? updateData.school_name
                        : schoolInfo.school_name
                    }
                  />
                </div>
                <div className="formInput">
                  <label>School Short Name</label>
                  <input
                    type="text"
                    name="school_short_name"
                    onChange={(e) => handleChange(e)}
                    id="school_short_name"
                    disabled={schoolInfo.school_short_name}
                    value={
                      updateData.school_short_name
                        ? updateData.school_short_name
                        : schoolInfo.school_short_name
                    }
                  />
                </div>
              </div>
              <div className="formRow">
                <div className="formInput">
                  <label>School Initials</label>
                  <FormInput
                    type="text"
                    name="school_initials"
                    onChange={(e) => handleChange(e)}
                    id="school_initials"
                    required={true}
                    value={
                      updateData.school_initials
                        ? updateData.school_initials
                        : schoolInfo.school_initials
                    }
                    disabled={schoolInfo.school_initials}
                    pattern="^[A-Z\s&@#$%*_]{3}$"
                    infoMessage="This will be used in generating the ID of your students. It cannot be changed once set."
                    errorMessage="Should be 3 characters long with no number, or spacing and must be all uppercase"
                  />
                </div>
                <div className="formInput">
                  <label className="mb-4">School City</label>
                  <input
                    type="text"
                    name="school_city"
                    onChange={(e) => handleChange(e)}
                    id=""
                    value={
                      updateData.school_city
                        ? updateData.school_city
                        : schoolInfo.school_city
                    }
                  />
                </div>
                <div className="formInput">
                  <label>School Region</label>
                  <input
                    type="text"
                    name="school_region"
                    id=""
                    value={schoolInfo.school_region}
                    disabled
                  />
                </div>
              </div>
              
              <div className="formRow">
                {/* <div className="formInput">
                  <label>School Type</label>
                  <select name="school_type" onChange={(e)=>handleChange(e)} defaultValue={schoolInfo.school_type}>
                    <option value=""></option>
                    <option value="Private">Private</option>
                    <option value="Public">Public</option>
                  </select>
                </div> */}
                <div className="formInput">
                  <label>School Motto</label>
                  <input
                    type="text"
                    name="school_motto"
                    onChange={(e) => handleChange(e)}
                    id="school_motto"
                    value={
                      updateData.school_motto
                        ? updateData.school_motto
                        : schoolInfo.school_motto
                        ? schoolInfo.school_motto
                        : ""
                    }
                  />
                </div>
                
              </div>
              <div className="formRow">
                <div className="formInput">
                  <label htmlFor="">School Website</label>
                  <input type="text" name="school_website" id="school_website" onChange={(e)=>handleChange(e)} value={
                      updateData.school_website
                        ? updateData.school_website
                        : schoolInfo.school_website
                        ? schoolInfo.school_website
                        : ""
                    } />
                </div>
              </div>
            </div>
            {loading ? (
              <button style={{ cursor: "wait" }}>
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
                Updating...
              </button>
            ) : (
              <button>Update</button>
            )}
          </form>
        </div>
        <div className="school-profile-right">
          <h4>Your School Profile</h4>
          <div className="school-details">
            <img
              className="school-logo"
              src={schoolInfo.school_logo ? `${API_URL}${schoolInfo.school_logo}` :  SchoolLogo}
              alt="school_logo"
            />
            <h2>
              {schoolInfo.school_name}{" "}
              {schoolInfo.school_short_name && (
                <>({schoolInfo.school_short_name})</>
              )}
            </h2>
          </div>
          <div className="school-location">
            <GoLocation />
            <p>
              {schoolInfo.school_city} {schoolInfo.school_district} -{" "}
              {schoolInfo.school_region} Region
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolInfo;
