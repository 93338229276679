import React, { useState } from "react";
import { useAuthenticate } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../../url";
import { ColorRing } from "react-loader-spinner";

const MainExamMarks = () => {
  const { academicYears } = useAuthenticate();
  const [academicTerms, setAcademicTerms] = useState([]);
  const [academicYear, setAcademicYear] = useState("");
  const [academicTerm, setAcademicTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [studentResult, setStudentResult] = useState([]);

  const changeAcademicYear = (e) => {
    setAcademicYear(e.target.value);

    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  const fetchStudentResults = async (e) => {
    e.preventDefault();

    if (localStorage.getItem("access")) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      try {
        const res = await axios.get(
          `${API_URL}student/exam-marks/${academicYear}/${academicTerm}`,
          config
        );

        setStudentResult(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  return (
    <div className="main-container">
      <div className="header">
        <h1>MAIN EXAM MARKS</h1>
      </div>
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => fetchStudentResults(e)}>
            {/* ACADEMIC YEAR DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_year"
                id="academic_term"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* ACADEMIC TERM DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_term"
                onChange={(e) => setAcademicTerm(e.target.value)}
                id="academic_term"
              >
                {academicYear === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            {/* IF FILTERING DATA SHOW A LOADING BUTTON ELSE SHOW A SUBMIT BUTTON */}
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data...{" "}
              </button>
            ) : (
              <button type="submit">Filter</button>
            )}
          </form>
        </div>

        {studentResult.length > 0 && (
          <div className="income-statement">
            <div>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Subject</th>
                    <th>Marks</th>
                  </tr>
                </thead>

                <tbody>
                  {studentResult.map((mark, index) => {
                    return (
                      <tr key={index}>
                        <td>{mark?.subject}</td>
                        <td style={{ textAlign: "right" }}>
                          {mark?.marks} / {mark?.overall_marks}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainExamMarks;
