import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";



const StudentAssessmentMarks = () => {
  const { academicTerms, schoolClasses } = useAuthenticate();
  const [subjects, setSubjects] = useState([])
  const [studentMarks, setStudentMarks] = useState([])
  const [data, setData] = useState({
    class: "",
    term:"",
    subject:"",
    assessment_type:"",
  })
  const [submitData, setSubmitData] = useState([])
  const [assessmentTypes, setAssessmentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  
  
  const handleChangeData = (e, id) => {
    
  }

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // FETCH CLASS SUBJECTS
  const fetchClassTermSubjects = async (e) =>{
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(`${API_URL}academics/class-term-subjects/${data.class}/`, config);
        setSubjects(res.data)
      } catch (error) {
        
      }
    }
  }

  const fetchTermAssessmentTypes = async(e) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(`${API_URL}academics/term-assessment-type/${e.target.value}/`, config);
        setAssessmentTypes(res.data)
      } catch (error) {
        
      }
    }
  }

  const handleFetchData = async (e) => {
    e.preventDefault()
    
    if (data.class === "" || data.term === "" || data.subject === "" || data.assessment_type === "") {
        toast.error("Please Check And Select all required fields"); 
      } else {
        if (localStorage.getItem("access")) {
          setLoading(true)
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };
  
          try {
            const res = await axios.get(
              `${API_URL}academics/student-assessment-marks/${data.class}/${data.term}/${data.assessment_type}/${data.subject}/`,
              config
            );
            
            
            const studentMarksList = []
            for (let i = 0; i < res.data.length; i++) {
              studentMarksList.push({
                student_id: res.data[i].id,
                marks: res.data[i].marks
              })
            }
            
            setSubmitData(studentMarksList)
            setStudentMarks(res.data);
            setLoading(false)
            
          } catch (error) {
            if (error.response) {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
  }

  return (
    
      <div className="main-container">
        <div className="header">
          <h1>STUDENT ASSESSMENT MARKS</h1>
        </div>
        <div className="schedule-body">
          <div className="filter-columns">
            <form onSubmit={e=>handleFetchData(e)}>
              <div className="formInput">
                <select
                  required
                  name="class"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select Class</option>
                  {schoolClasses.map((schoolClass) => {
                    return (
                      <option value={schoolClass.id} key={schoolClass.id}>
                        {schoolClass.class_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="formInput">
                <select name="term" required onChange={(e) => {handleChange(e); fetchClassTermSubjects(e); fetchTermAssessmentTypes(e)}}>
                {data.class === "" ? (
                    <option>Select Class First</option>
                  ) : (
                    <>
                      <option>Academic Term</option>
                      {academicTerms.map((term) => {
                        return (
                          <option value={term.id} key={term.id}>
                            {term.term_full_name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
              <div className="formInput">
                <select name="subject" required onChange={(e) => handleChange(e)}>
                  {
                    subjects.length <= 0 ? (
                      <option>No Subject Available</option>
                    ):(
                      <>
                        <option>Select Subject</option>
                        {subjects.map((subject) => {
                          return (
                            <option value={subject.id} key={subject.id}>
                              {subject.subject_name}
                            </option>
                          );
                        })}
                      </>
                    )
                  }
                  
                </select>
              </div>
              <div className="formInput">
                <select name="assessment_type" required onChange={(e) => handleChange(e)}>
                  
                  {
                    assessmentTypes.length <= 0 ? (<option>No Assessment Type Available</option>):(
                      <>
                        <option>Select Assessment Type</option>
                        {assessmentTypes.map((assessmentType) => {
                          return (
                            <option value={assessmentType.id} key={assessmentType.id}>
                              {assessmentType.name}
                            </option>
                          );
                        })}
                      </>
                    )
                  }
                  
                </select>
              </div>
              {
                loading ? (
                  <button
                  style={{
                    backgroundColor: "gray",
                    cursor: "wait",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    color: "white",
                    padding: "10px",
                    border:"none"
                  }}
                >
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />{" "}
                  Filtering{" "}
                </button>
                ):(
                  <button type="submit">Filter</button>
                )
              }
            </form>
          </div>
          <div className="student-list">
            <div className="panel-header">
                <h4>Student List</h4>
            </div>
            <div className="list-body">
                <table>
                    <thead>
                        <tr>
                            
                            <th>Student Name</th>
                            <th>Student ID</th>
                            <th>Class</th>
                            <th>Marks</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                            {
                              studentMarks.map((studentMark) => {
                                return (
                                  <tr>
                                    <td>{studentMark.student.first_name} {studentMark.student.last_name}</td>
                                    <td>
                                      {studentMark.student.student_school_id}
                                    </td>
                                    <td>
                                      {studentMark.student.student_class}
                                    </td>
                                    <td>
                                      <input type="number" onChange={e=>handleChangeData(e, studentMark.id)} name="marks" value={studentMark.marks} id="" />
                                    </td>
                                  </tr>
                                )
                              })
                            }
                        
                    </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default StudentAssessmentMarks;
