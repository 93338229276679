import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../context/AuthContext";
import Header from "../../components/Header";

const StudentFeeAllocation = () => {
  const { academicYears, schoolClasses } = useAuthenticate();
  const [academicTerms, setAcademicTerms] = useState([]);
  const [feesGroups, setFeesGroups] = useState([]);
  const [newFeeGroups, setNewFeeGroups] = useState([]);
  const [studentFees, setStudentFees] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allocatingFees, setAllocatingFees] = useState(false)

  const [data, setData] = useState({
    student_class: "",
    term: "",
    fees: "",
  });

  // HANDLE CHECK ALL CHECK BOXES
  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === "selectAll") {
      let tempUser = studentFees.map((studentFee) => {
        return { ...studentFee, assigned: checked };
      });
      setStudentFees(tempUser);
    } else {
      let tempUser = studentFees.map((studentFee) => {
        return studentFee.student.student_id === value
          ?  { ...studentFee, assigned: checked }
          : studentFee
      });
      setStudentFees(tempUser);
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const changeAcademicYear = (e) => {
    handleChange(e);

    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  const changeAcademicTerm = (e) => {
    handleChange(e);
    let createdFeeGroups = []
    if(e.target.value !== ""){
      for(let i = 0; i < feesGroups.length; i++) {
        if(feesGroups[i].term === parseInt(e.target.value)){
          createdFeeGroups.push(feesGroups[i]);
        }
      }
      setNewFeeGroups(createdFeeGroups)
      // newFeeGroups.push(
      //   feesGroups.find((feeGroup)=> feeGroup.term === parseInt(e.target.value) )
      // )
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (data.student_class === "" || data.term === "" || data.fees === "") {
      toast.error("Please Check And Select all required fields");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}finance/assigned-student-fees/${data.student_class}/${data.term}/${data.fees}/`,
            config
          );
          const addedStudents = [];
          setStudentFees([]);
          setStudentFees(res.data);
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].assigned === true) {
              addedStudents.push(res.data[i].student.student_id);
            }
          }
          setSelectedStudents(addedStudents);
          setDataFetched(true);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error.response) {
            for (const property in error.response.data) {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  const handleAllocateFees = async (e) => {
    e.preventDefault();
    let selected_students = [];
    studentFees.map((studentFee)=>{
      studentFee.assigned === true && (
        selected_students.push(studentFee.id)
      )
    })
    
    const body = JSON.stringify({
      ...data,
      selected_students: selected_students,
    });

    
    

    if (localStorage.getItem("access")) {
      setAllocatingFees(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        await axios.post(
          `${API_URL}finance/allocate-student-fee/`,
          body,
          config
        );
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Fees Allocated Successfully",
        });
        setAllocatingFees(false)
      } catch (error) {
        setAllocatingFees(false)

        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  useEffect(() => {
    const fetchFeeGroups = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        try {
          const res = await axios.get(`${API_URL}finance/fees-group/list/`, config);
          setFeesGroups(res.data);
        } catch (error) {
          
        }
      }
    };

    fetchFeeGroups();
  }, []);

  return (
    <div className="main-container">
      <Header title="Student Fee Allocation" subtitle="Assign fees to various students" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="formInput">
              <label htmlFor="">School Class</label>
              <select
                required
                name="student_class"
                onChange={(e) => handleChange(e)}
              >
                <option>Select Class</option>
                {schoolClasses.map((schoolClass) => {
                  return (
                    <option value={schoolClass.id} key={schoolClass.id}>
                      {schoolClass.class_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="formInput">
              <label htmlFor="">Academic Year</label>
              <select
                name="academic_year"
                id="academic_year"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="formInput">
            <label htmlFor="">Academic Term</label>
              <select name="term" required onChange={(e) => changeAcademicTerm(e)}>
                <option value="">Academic Term</option>
                {academicTerms.map((academicTerm) => {
                  return (
                    <option value={academicTerm.id} key={academicTerm.id}>
                      {academicTerm.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="formInput">
            <label htmlFor="">Fee Category</label>
              <select name="fees" required onChange={(e) => handleChange(e)}>
                {
                  newFeeGroups.length === 0 && data.term !== "" && (
                    <option>No Fee Groups For This Term</option>
                  )
                }
                {
                  newFeeGroups.length > 0 && data.term !== "" && (
                    <option>Select Fee Group</option>
                  )
                }
                {newFeeGroups.map((feesGroup) => {
                  return (
                    <>
                      {
                        data.term === "" ? <option>Select Term First</option>: (
                        <>
                          <option value={feesGroup.id} key={feesGroup.id}>
                            {feesGroup.name}
                          </option>
                        </>
                        )
                      }
                    </>
                  );
                })}
              </select>
            </div>
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  color: "white",
                  padding: "15px",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Filtering...{" "}
              </button>
            ) : (
              <button type="submit">Filter</button>
            )}
          </form>
        </div>
        {dataFetched && (
          <>
            {studentFees.length > 0 ? (
              <div className="student-list">
                <header className="panel-header">
                  <h4>Student List</h4>
                </header>

                <div className="list-body">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "80px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            id=""
                            checked={
                              !studentFees.some(
                                (studentFee) => studentFee?.assigned !== true
                              )
                            }
                            onChange={(e) => handleCheckboxChange(e)}
                          />
                        </th>
                        <th>Student Name</th>
                        <th>Student ID</th>
                        <th>Class</th>
                        <th>Gender</th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentFees.map((studentFee, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: "80px" }}>
                              <input
                                type="checkbox"
                                name="student"
                                value={studentFee.student.student_id}
                                onChange={(e) => handleCheckboxChange(e)}
                                // onChange={(e) =>
                                //   e.target.checked
                                //     ? setSelectedStudents([
                                //         ...selectedStudents,
                                //         e.target.value,
                                //       ])
                                //     : selectedStudents.splice(e.target.value, 1)
                                // }
                                // defaultChecked={studentFee.assigned}
                                checked={studentFee.assigned}
                              />
                            </td>
                            <td>
                              {studentFee.student.get_full_name}{" "}
                              
                            </td>
                            <td>{studentFee.student.student_school_id}</td>
                            <td>{studentFee.student.student_class}</td>
                            <td>{studentFee.student.gender}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {
                    allocatingFees ? (
                      <button className="save-button"
                      style={{
                        backgroundColor: "gray",
                        cursor: "wait",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        color: "white",
                        padding: "10px",
                        marginTop: "15px",
                        border: "none",
                      }}
                    >
                      <ColorRing
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />{" "}
                      Saving Data...{" "}
                    </button>
                    ):(
                      <button
                        onClick={(e) => handleAllocateFees(e)}
                        className="save-button mt-4"
                      >
                        Save
                      </button>
                    )
                  }
                </div>
              </div>
            ) : (
              <div className="no-data-msg">
                THERE ARE NO STUDENTS IN THIS CLASS
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default StudentFeeAllocation;
