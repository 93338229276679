import React, { useEffect, useState } from "react";

import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Header from "../../components/Header";

const PromoteStudents = () => {
  const { schoolClasses } = useAuthenticate();
  const [classStudents, setClassStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  const [data, setData] = useState({
    current_class: "",
    destination_class: "",
  });

  const handleSubmit = async () => {
    const body = JSON.stringify({
      current_class: data.current_class,
      destination_class: data.destination_class,
      selected_students: selectedStudents,
    });

    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        await axios
          .post(`${API_URL}academics/promote-students/`, body, config)
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                icon: "success",
                title: "Students Promoted",
                text: "Students are dully promoted",
              }).then(() => window.location.reload());
            }
          });
      } catch (error) {}
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const fetchClassStudents = async (e) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/class-students/${data.current_class}/`,
          config
        );
        setClassStudents(res.data);
        setDataFetched(true);
      } catch (error) {}
    }
  };

  const handleFetchData = async (e) => {
    e.preventDefault();

    if (data.current_class === "" || data.destination_class === "") {
      toast.error("Please Check And Select all required fields");
    } else if (data.destination_class === data.current_class) {
      toast.error("Current Class and destination Class must not be the same");
    } else {
      fetchClassStudents();
    }
  };

  return (
    <div className="main-container">
      <Header title="Student Promotion" subtitle="Promote students to different classess" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => handleFetchData(e)}>
            <div className="formInput">
              <select
                required
                name="current_class"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Current Class</option>
                {schoolClasses.map((schoolClass) => {
                  return (
                    <option value={schoolClass.id} key={schoolClass.id}>
                      {schoolClass.class_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="formInput">
              <select
                required
                name="destination_class"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Destination Class</option>
                {schoolClasses.map((schoolClass) => {
                  return (
                    <option value={schoolClass.id} key={schoolClass.id}>
                      {schoolClass.class_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <button type="submit">Filter</button>
          </form>
        </div>
        {dataFetched && (
          <>
            {classStudents.length > 0 ? (
              <div className="student-list">
                <div className="panel-header">
                  <h4>Student List</h4>
                </div>

                <div className="list-body">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Student Name</th>
                        <th>Student ID</th>
                        <th>Class</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classStudents.map((classStudent, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: "80px" }}>
                              <input
                                type="checkbox"
                                value={classStudent.student_id}
                                name=""
                                id=""
                                onChange={(e) =>
                                  e.target.checked
                                    ? setSelectedStudents([
                                        ...selectedStudents,
                                        e.target.value,
                                      ])
                                    : selectedStudents.splice(e.target.value, 1)
                                }
                              />
                            </td>
                            <td>
                              {classStudent.first_name} {classStudent.last_name}
                            </td>
                            <td>{classStudent.student_school_id}</td>
                            <td>{classStudent.student_class}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <button
                    onClick={() => handleSubmit()}
                    className="save-button"
                  >
                    Promote Students
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="no-data-msg">
                  THERE ARE NO STUDENTS IN THIS CLASS
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PromoteStudents;
