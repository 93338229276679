import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SuperadminDashboard from '../pages/Dashboard/SuperadminDashboard'
import SchoolsList from '../pages/Schools/SchoolsList'
import AddSchool from '../pages/Schools/AddSchool'
import Packages from '../pages/AdminPages/Packages'

const SuperadminRoutes = () => {
  return (
    <Routes>
        <Route path='/dashboard' element={<SuperadminDashboard />} />
        <Route path="/registered-schools" element={<SchoolsList />} />
        <Route path="/add-school" element={<AddSchool />} />
        <Route path="/packages" element={<Packages />} />
    </Routes>
  )
}

export default SuperadminRoutes