import React, { useState, useEffect } from "react";
import FormInput from "../../components/Forms/FormInput";
import PhoneInput from "react-phone-number-input";
import { MdOutlineDelete } from "react-icons/md";
import { ColorRing } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const AddParent = () => {
  const { schoolClasses } = useAuthenticate();

  const ImageUpload =
    "https://epasmart.s3.eu-north-1.amazonaws.com/assets/user_image.svg";
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [students, setStudents] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);
  const [children, setChildren] = useState([]);

  const addChild = () => {
    if (!selectedChild) {
      return toast.error("Please select a child first");
    }
    const addedChildren = [...children];
    for (let i = 0; i < addedChildren.length; i++) {
      if (
        parseInt(selectedChild?.student_id) ===
        parseInt(addedChildren[i].student_id)
      ) {
        return toast.error("Already added!");
      }
    }
    const child = selectedChild;
    const childObject = {
      student_id: child?.student_id,
      first_name: child?.first_name,
      last_name: child?.last_name,
    };
    setChildren([...children, childObject]);
  };

  const selectChild = (student_id) => {
    const childSelected = students.filter(
      (student) => parseInt(student.student_id) === parseInt(student_id)
    );
    setSelectedChild(childSelected[0]);
  };

  const removeChild = (index) => {
    const childrenData = [...children];
    childrenData.splice(index, 1);
    setChildren(childrenData);
  };

  // FETCH CLASS STUDENTS
  const fetchClassStudents = async (e) => {
    if (e.target.value !== "") {
      if (localStorage.getItem("access")) {
        setLoadingStudents(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-students/${e.target.value}`,
            config
          );
          setStudents(res.data);
          setLoadingStudents(false);
        } catch (error) {
          setLoadingStudents(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    } else {
      setStudents([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (children.length < 1) {
      return toast.error("Please select at least one student");
    }

    setLoading(true)
    const formData = new FormData();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    try {
      const response = await axios.post(`${API_URL}parent/`, formData, config);
      const created_parent_id = response.data.parent_id;

      addParentChildren(created_parent_id)
    } catch (error) {
      toast.error("Something went wrong!")
      console.log(error)
      setLoading(false);
    }
  };

  const addParentChildren = async (parent_id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(children)

    try {
        await axios.post(`${API_URL}parent/${parent_id}/children/`, body, config).then((response)=>{
            console.log(response.data)
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Parent Created Successfully",
              });
            setLoading(false)
        })
    } catch (error) {
        console.log(error)
        setLoading(false);
        toast.error("An error occured while adding the children")
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="main-container">
      <div className="header">
        <h1>ADD PARENT</h1>
      </div>
      <div className="bottom">
        <div className="left">
          <img
            src={
              data?.profile_picture
                ? URL.createObjectURL(data?.profile_picture)
                : ImageUpload
            }
            alt="parent"
          />
        </div>
        <div className="right">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="formSection">
              <p className="formRowHeader">Parent Personal Info</p>
              <div className="formRow">
                <div className="formInput">
                  <label htmlFor="">Parent Image</label>
                  <input
                    type="file"
                    name="profile_picture"
                    id="profile_picture"
                    accept="image/*"
                    onChange={(e) =>
                      setData({
                        ...data,
                        [e.target.name]: e.target.files[0],
                      })
                    }
                  />
                  <label htmlFor="profile_picture" id="upload-button">
                    Upload Image
                  </label>
                  <p style={{ fontWeight: "400", fontSize: "12px" }}>
                    {data?.profile_picture
                      ? data?.profile_picture?.name
                      : "No file selected"}
                  </p>
                </div>

                <FormInput
                  label="Name"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  name="name"
                  id="name"
                  required={true}
                  // pattern="^[A-Za-z\s]{3,30}$"
                  errorMessage="Should be at least 3 characters long with no special
                    characters"
                />
                <div className="formInput">
                  <label>Gender</label>
                  <div className="radioInline">
                    <input
                      type="radio"
                      name="gender"
                      required
                      onChange={(e) => handleChange(e)}
                      value="Male"
                    />{" "}
                    Male
                  </div>
                  <div className="radioInline">
                    <input
                      type="radio"
                      name="gender"
                      onChange={(e) => handleChange(e)}
                      value="Female"
                    />{" "}
                    Female
                  </div>
                </div>
              </div>

              <div className="formRow">
                <div className="formInput">
                  <label>Phone Number</label>
                  <PhoneInput
                    defaultCountry="GH"
                    className="phone-input"
                    value={data?.phone_number}
                    onChange={(value) =>
                      setData({
                        ...data,
                        phone_number: value,
                      })
                    }
                  />
                </div>

                <FormInput
                  label="Email Address"
                  type="email"
                  onChange={(e) => handleChange(e)}
                  name="email"
                  id="email"
                  errorMessage="Please enter a valid email address"
                />
                <FormInput
                  label="Residential Address"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  name="residential_address"
                  id="residential_address"
                  required={true}
                  errorMessage="Please enter a valid residential address"
                />
              </div>

              <p className="formRowHeader">Child / Children</p>

              <div className="formRow">
                <div className="formInput">
                  <label htmlFor="">Student Class</label>
                  <select name="" id="" onChange={(e) => fetchClassStudents(e)}>
                    <option value="">Select Class</option>
                    {schoolClasses.map((school_class, index) => (
                      <option key={index} value={school_class.id}>
                        {school_class.class_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="formInput">
                  <label htmlFor="">Student</label>
                  <select
                    name="student"
                    id="student"
                    required
                    onChange={(e) => selectChild(e.target.value)}
                  >
                    {loadingStudents ? (
                      <option>Loading Students...</option>
                    ) : (
                      <>
                        {students.length < 1 ? (
                          <option>Select Class First</option>
                        ) : (
                          <>
                            <option value="">Select Student</option>
                            {students.map((student) => {
                              return (
                                <option
                                  key={student.student_id}
                                  value={student.student_id}
                                >
                                  {student.first_name} {student.last_name}
                                </option>
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </select>
                </div>
                <div className="formInput">
                  <label htmlFor=""></label>
                  <input
                    style={{
                      maxWidth: "max-content",
                      marginTop: "20px",
                      border: "none",
                    }}
                    type="button"
                    value="+"
                    className="classic-button"
                    onClick={() => addChild()}
                  />
                </div>
              </div>

              <div className="formRow">
                <div className="children-list">
                  <h4>Children List</h4>
                  <ul>
                    {children.map((child, index) => (
                      <li key={index}>
                        <span>
                          {child?.first_name} {child?.last_name}
                        </span>
                        <MdOutlineDelete onClick={() => removeChild(index)} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {loading ? (
              <button style={{ cursor: "wait" }}>
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
                Adding Parent...
              </button>
            ) : (
              <button>Add Parent</button>
            )}

            <p className="notice">
              Clicking on this button means all provided information are
              correct. An email or SMS will be sent to the parent with the
              username and password to access his/her portal
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddParent;
