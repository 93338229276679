import React from 'react'
import { useAuthenticate } from '../context/AuthContext'
import { Navigate } from 'react-router-dom';

const Guest = ({component: Component, ...rest}) => {
    const { user } = useAuthenticate();
    if (!user || user === 'undefined' || user === undefined){
        return <Component {...rest} />
    }else{
        if(user?.account_type === "Master"){
            return <Navigate to="/superadmin/dashboard" />
            
          }else if(user?.account_type === "School Administrator"){
            
            return <Navigate to="/admin/dashboard" />
          }else if(user?.account_type === "School Staff"){
            if(user?.position === "Accountant"){
              return <Navigate to="/accountant/dashboard" />
            }else{
              return <Navigate to="/staff/dashboard" />
            }
            
          }else if(user?.account_type === "Student"){
            return <Navigate to="/std/dashboard" />
            
          }else{
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            window.location.href = "/"
          }
        
    }
}

export default Guest