import React from 'react'

const Header = ({title,subtitle}) => {
  return (
    <div className='my-8 p-5 header-background text-white'>
        <h3 className="font-[400] text-[40px] leading-10">{title}</h3>
        <p>
            {subtitle}
        </p>
    </div>
  )
}

export default Header