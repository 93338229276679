import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineDelete,
  AiOutlineEye,
} from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import { API_URL, img_base_url } from "../../url";
import axios from "axios";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ExpenseDetailsModal from "./ExpenseDetailsModal";
import Header from "../../components/Header";
import { useAuthenticate } from "../../context/AuthContext";

const ExpenseList = () => {
  const expenseRef = useRef();
  const {schoolInfo} = useAuthenticate()
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expenseList, setExpenseList] = useState(null);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [expenseType, setExpenseType] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [data, setData] = useState({
    start_date: "",
    end_date: "",
  });

  // DELETE EXPENSE
  const deleteExpense = (expense_id, e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("access")) {
          setDeleting(true);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          try {
            axios.delete(
              `${API_URL}finance/school-expense/${expense_id}/`,
              config
            );

            toast.success("Expense Deleted Successfully");
            filterExpenseList(e);

            setDeleting(false);
          } catch (error) {
            setDeleting(false);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/";
              } else if (error.response.status === 404) {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        } else {
          window.location.href = "/";
        }
      }
    });
  };

  // FILTER EXPENSE LIST
  const filterExpenseList = async (e) => {
    e.preventDefault();
    if (expenseType === "" || data.start_date === "" || data.end_date === "") {
      toast.error("Select all required fields");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        setExpenseList(null);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        try {
          const res = await axios.get(
            `${API_URL}finance/school-expense/${data.start_date}/${data.end_date}/${expenseType}`,
            config
          );
          setExpenseList(res.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            toast.error("An error occurred");
          }
        }
      }
    }
  };

  // GET EXPENSE TYPES
  const getExpenseTypes = async (e) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}finance/school-expense-type/`,
          config
        );
        setExpenseTypes(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // EXPORT TO EXCEL
  const { onDownload } = useDownloadExcel({
    currentTableRef: expenseRef.current,
    filename: `Expense List`,
    sheet: "Expense List",
  });

  useEffect(() => {
    getExpenseTypes();
  }, []);

  return (
    <div className="main-container">
      <Header title="Expense List" />

      <div className="schedule-body">
        <div className="">
          <form
            onSubmit={(e) => filterExpenseList(e)}
            className="grid lg:grid-cols-4 gap-7"
          >
            <div className="flex flex-col">
              <label htmlFor="">From*:</label>
              <input
                type="date"
                name="start_date"
                onChange={(e) =>
                  setData({ ...data, start_date: e.target.value })
                }
                id=""
                className="p-[10px] border border-gray-600 rounded outline-none"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">To*: </label>
              <input
                type="date"
                name="end_date"
                onChange={(e) => setData({ ...data, end_date: e.target.value })}
                id=""
                className="p-[10px] border border-gray-600 rounded outline-none"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Expense Type*:</label>
              <select
                name="expense_type"
                onChange={(e) => setExpenseType(e.target.value)}
                className="p-[12px] border border-gray-600 rounded outline-none"
              >
                <option value="">Select Expense Type</option>
                <option value="All">All</option>
                {expenseTypes.map((expenseType) => (
                  <option key={expenseType.id} value={expenseType.id}>
                    {expenseType.name}
                  </option>
                ))}
              </select>
            </div>
            {loading ? (
              <button className="bg-gray-500 flex items-center gap-6 border-none px-[30px] py-[10px]  mt-[25px]  w-max text-white">
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data...{" "}
              </button>
            ) : (
              <button className="bg-primary border-none px-[30px] py-[10px]  mt-[25px]  w-max text-white">
                Filter
              </button>
            )}
          </form>
        </div>
        {expenseList && (
          <div className="income-statement">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <ReactToPrint
                  trigger={() => (
                    <button className="export-button">
                      <AiOutlineFilePdf />
                      Export to PDF
                    </button>
                  )}
                  content={() => expenseRef.current}
                  documentTitle="Income List"
                />
              </div>
              <div>
                <Link to="/finance/record-expense">
                  <button className="add-button">
                    <IoIosAddCircleOutline />
                    Record Expense
                  </button>
                </Link>
              </div>
            </div>
            <div>
              <h1 style={{ textAlign: "center" }}>Expense List</h1>
              <div className="scroll-table">
                <table
                  style={{
                    marginTop: "30px",
                    borderCollapse: "collapse",
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Expense Type
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Date
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Amount (GHS)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseList.expense_list.map((expense) => {
                      return (
                        <React.Fragment>
                          <tr>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                              }}
                            >
                              {expense.expense_type}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                              }}
                            >
                              {expense.date}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "right",
                              }}
                            >
                              {expense.amount}
                            </td>
                            <td>
                              <AiOutlineEye
                                onClick={() => setModal(!modal)}
                                title="View Details"
                                style={{ cursor: "pointer" }}
                              />
                              <AiOutlineDelete
                                title="Delete"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                                onClick={(e) => deleteExpense(expense.id, e)}
                              />
                            </td>
                          </tr>
                          {modal && (
                            <ExpenseDetailsModal
                              modal={modal}
                              setModal={setModal}
                              expense={expense}
                              expenseTypes={expenseTypes}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}

                    <tr>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          fontWeight: "bold",
                        }}
                      >
                        Total Expense
                      </td>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        {expenseList.total_expense}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="hidden">
              <div ref={expenseRef} className="p-3">
                <div className="flex flex-col items-center justify-center py-5 border-b-2 border-slate-500">
                  <div className="flex items-center">
                    <img
                      src={`${img_base_url}${schoolInfo?.school_logo}`}
                      alt=""
                      className="h-20"
                    />
                    <h3 className="text-[30px] font-bold">
                      {schoolInfo?.school_name}
                    </h3>
                  </div>
                  <p>
                    {schoolInfo?.school_city} - {schoolInfo?.school_region}
                  </p>
                  <p>
                    {schoolInfo?.school_phone_number} -{" "}
                    {schoolInfo?.school_email}
                  </p>
                </div>
                <h1 style={{ textAlign: "center" }} className="mt-5">Expense List</h1>
                <div className="scroll-table">
                  <table
                    style={{
                      marginTop: "30px",
                      borderCollapse: "collapse",
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Expense Type
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Date
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Amount (GHS)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {expenseList.expense_list.map((expense) => {
                        return (
                          <React.Fragment>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                }}
                              >
                                {expense.expense_type}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                }}
                              >
                                {expense.date}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "right",
                                }}
                              >
                                {expense.amount}
                              </td>
                              <td>
                                <AiOutlineEye
                                  onClick={() => setModal(!modal)}
                                  title="View Details"
                                  style={{ cursor: "pointer" }}
                                />
                                <AiOutlineDelete
                                  title="Delete"
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  onClick={(e) => deleteExpense(expense.id, e)}
                                />
                              </td>
                            </tr>
                            {modal && (
                              <ExpenseDetailsModal
                                modal={modal}
                                setModal={setModal}
                                expense={expense}
                                expenseTypes={expenseTypes}
                              />
                            )}
                          </React.Fragment>
                        );
                      })}

                      <tr>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            fontWeight: "bold",
                          }}
                        >
                          Total Expense
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          {expenseList.total_expense}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpenseList;
