import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ColorRing } from "react-loader-spinner";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import FormInput from "../../components/Forms/FormInput";
import ImageUpload from "../../assets/avatar.jpg"
import Header from "../../components/Header";

const AddStaff = () => {  
  const [loading, setLoading] = useState(false);
  const [staffImage, setStaffImage] = useState("");
  const [staffPositions, setStaffPositions] = useState([]);
  const [data, setData] = useState({
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    gender: "",
    staff_type: "",
    phone_number: "",
    joining_date: "",
    date_of_birth: "",
    marital_status: "",
    staff_department: "",
    position: "",
    residential_address: "",
    profile_picture: staffImage
  });

  // GENERATE PASSWORD
  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";

    const passwordLength = 8;

    let generatedPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      generatedPassword += characters[randomIndex];
    }

    setData({
      ...data,
      password: generatedPassword,
    });
  };

  // CHANGE DATA
  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // COMPRESS FILE
  const compressFile = (image) => {
    let file_name = image.name;
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      let image_url = event.target.result;
      let image = document.createElement("img");
      image.src = image_url;

      image.onload = (e) => {
        // creating a new canvas for the image
        const canvas = document.createElement("canvas");
        let ratio = 300 / e.target.width;
        canvas.width = 300;
        canvas.height = ratio * e.target.height;


        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        
        // converting the image quality to 50% and the file type to webp
        let new_image_url = canvas.toDataURL("image/webp", 50);
        
        // getting the image type and the data
        let arr = new_image_url.split(",");
        let image_type = arr[0].match(/:(.*?);/)[1];
        let data = arr[1];
        let dataStr = atob(data);
        let n = dataStr.length;
        let dataArr = new Uint8Array(n);

        while (n--) {
          dataArr[n] = dataStr.charCodeAt(n);
        }

        const compressed_image = new File([dataArr], file_name, { type: image_type });
        setStaffImage(compressed_image)
        
      };
    };
  };

  // SUBMIT DATA
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      data.profile_picture = staffImage
      

      if(data.profile_picture && data.profile_picture.size > 878576){
        toast.error("The size of the profile picture should be less than 878KB")
      }else{

        setLoading(true);
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `multipart/form-data`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };
          const formData = new FormData();
          Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
          });

          try {
            const res = await axios.post(
              `${API_URL}administrator/staffs/`,
              formData,
              config
            );
            setLoading(false);

            if (res.status === 201) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Staff Account Created Successfully",
              }).then(()=>{
                window.location.reload();
              });
            }
          } catch (error) {
            setLoading(false);
            if (error.response) {
              if(error.response.status === 401){
                window.location.href = "/"
              }else if(error.response?.status === 500){
                return toast.error("Something went wrong. Please try again later")
              }
              else{
                for (const property in error.response.data) {
                  if (property === "0") {
                    toast.error(`${error.response.data[property]}`);
                  } else {
                    toast.error(
                      `${property} error: ${error.response.data[property]}`
                    );
                  }

                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    const fetchSchoolPositions = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        try {
          const res = await axios.get(
            `${API_URL}administrator/staff-position/`,
            config
          );
          setStaffPositions(res.data);
        } catch (error) {}
      }
    };
    fetchSchoolPositions();
  }, []);
  return (
    <div className="main-container mb-64">
      <Header title="Add Staff" subtitle="Add New Staff" />
      <div className="bottom">
        <div className="left">
          <img
            src={staffImage ? URL.createObjectURL(staffImage) : ImageUpload}
            alt="teacher"
          />
        </div>
        <div className="right">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="formSection">
              <p className="my-5 bg-blue-300 p-2">Staff Personal Info</p>

              <div className="formRow">
                <div className="formInput">
                  <label htmlFor="">Staff Image</label>
                  <input
                    type="file"
                    name="profile_picture"
                    accept="image/*"
                    // onChange={(e) => {
                    //   setStaffImage(e.target.files[0]);
                    //   setData({
                    //     ...data,
                    //     [e.target.name]: e.target.files[0],
                    //   });
                    // }}
                    onChange={(e)=> compressFile(e.target.files[0])}
                    id="student_image"
                  />
                  <label id="upload-button" htmlFor="student_image">
                    Browse files
                  </label>
                </div>

                <FormInput
                  label="First Name*"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="first_name"
                  id="first_name"
                  required={true}
                  // pattern="^[A-Za-z\s]{3,26}$"
                  errorMessage="Should be at least 3 characters long with no number or special
        characters"
                />
                <FormInput
                  label="Last Name*"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="last_name"
                  id="last_name"
                  required={true}
                  // pattern="^[A-Za-z- ]{3,30}$"
                  errorMessage="Should be at least 3 characters long with no number or special
        characters"
                />
              </div>
              <div className="formRow">
                <div className="formInput">
                  <label>Gender*</label>
                  <div className="radioInline">
                    <input
                      type="radio"
                      name="gender"
                      required
                      onChange={(e) => onChange(e)}
                      value="Male"
                    />{" "}
                    Male
                  </div>
                  <div className="radioInline">
                    <input
                      type="radio"
                      name="gender"
                      onChange={(e) => onChange(e)}
                      value="Female"
                    />{" "}
                    Female
                  </div>
                </div>
                <FormInput
                  label="Date of Birth"
                  type="date"
                  onChange={(e) => onChange(e)}
                  name="date_of_birth"
                  id="date_of_birth"
                  required={true}
                />
                <div className="formInput">
                  <label>Phone Number*</label>
                  <PhoneInput
                    defaultCountry="GH"
                    required
                    className="phone-input"
                    value={data.phone_number}
                    onChange={(value) =>
                      setData({
                        ...data,
                        phone_number: value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="formRow">
                <div className="formInput">
                  <FormInput
                    label="Email Address"
                    type="email"
                    onChange={(e) => onChange(e)}
                    name="email"
                    id="email"
                    errorMessage="Please enter a valid email address"
                  />
                </div>
                <div className="formInput">
                  <FormInput
                    label="Residential Address*"
                    type="text"
                    onChange={(e) => onChange(e)}
                    name="residential_address"
                    id="residential_address"
                    required={true}
                    errorMessage="Please enter a valid residential address"
                  />
                  <small>Provide GPS Address Or House Number</small>
                </div>
                <div className="formInput">
                  <label>Marital Status</label>
                  <select
                    name="marital_status"
                    onChange={(e) => onChange(e)}
                    required
                  >
                    <option>Select Marital Status*</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="formSection">
              <p className="my-5 bg-blue-300 p-2">Staff Academic Info</p>
              <div className="formRow">
                <div className="formInput">
                  <label>Staff Type*</label>
                  <div className="radioInline">
                    <input
                      type="radio"
                      name="staff_type"
                      required
                      onChange={(e) => onChange(e)}
                      value="Teaching Staff"
                    />{" "}
                    Teaching Staff
                  </div>
                  <div className="radioInline">
                    <input
                      type="radio"
                      name="staff_type"
                      onChange={(e) => onChange(e)}
                      value="Non-Teaching Staff"
                    />{" "}
                    Non-Teaching Staff
                  </div>
                </div>
                <div className="formInput">
                  <label>Joining Date*</label>
                  <input
                    type="date"
                    name="joining_date"
                    required
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="formInput">
                  <label>Staff Position*</label>
                  <select
                    name="position"
                    onChange={(e) => onChange(e)}
                    required
                  >
                    <option>Select Staff Position</option>
                    {staffPositions.map((staffPosition) => {
                      return (
                        <option value={staffPosition.id} key={staffPosition.id}>
                          {staffPosition.position}
                        </option>
                      );
                    })}
                  </select>
                </div>
                
              </div>
            </div>
            <div className="formSection">
              <p className="my-5 bg-blue-300 p-2">Staff Account Info</p>
              <div className="formRow">
                <FormInput
                  label="Username*"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="username"
                  id="username"
                  required={true}
                  errorMessage="Should be at least 5 characters with no special characters"
                />
                <div className="formInput">
                  <label>Password*</label>
                  <input
                    type="text"
                    name="password"
                    value={data.password}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <br />
                <button
                  onClick={() => generatePassword()}
                  className="bg-secondary-100 px-5 py-3 rounded text-white cursor-pointer "
                >
                  Generate Password
                </button>
              </div>
            </div>
            {loading ? (
              <button style={{ cursor: "wait" }}>
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
                Adding Staff...
              </button>
            ) : (
              <button>Add Staff</button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
