import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import { DataGrid } from "@mui/x-data-grid";
import { useAuthenticate } from "../../context/AuthContext";
import SchemeDetails from "./SchemeDetails";
import NewScheme from "./NewScheme";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

const StudentReportScheme = () => {
  
  const [loadingData, setLoadingData] = useState(false);
  const [assessmentTypes, setAssessmentTypes] = useState([]);
  const [selectedSBA, setSelectedSBA] = useState([]);
  const [schemes, setSchemes] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState("");
  const { academicTerms, user } = useAuthenticate();
  const [newSchemeModal, setNewSchemeModal] = useState(false);
  const [modal, setModal] = useState(false);

  // TOGGLE MODAL
  const toggleModal = (details) => {
    setSelectedScheme(details);
    setModal(!modal);
  };

  // FETCH ALL REPORT SCHEMES
  const fetchStudentReportScheme = async () => {
    setLoadingData(true);
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/report-scheme/`,
          config
        );
        setSchemes(res.data);
        setLoadingData(false);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        setLoadingData(false);
      }
    }
  };

  // DELETE REPORT SCHEME
  const deleteStudentReportScheme = (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          try {
            const res = axios.delete(
              `${API_URL}academics/report-scheme-detail/${id}/`,
              config
            );
            if (res.response.status === 204) {
              Swal.fire(
                "Deleted!",
                "Report Scheme has been deleted.",
                "success"
              ).then(() => {
                window.location.reload();
              });
            } else if (res.response.status === 400) {
              toast.error("Error");
            }
          } catch (error) {
            if (error.response.status === 400) {
              toast.error(
                "You cannot delete this report. It is referenced by other exam results."
              );
            }else{
              toast.error("Something unexpected happened. Please try again later.")
            }
            
          }
        }
      }
    });
  };

 

  

  useEffect(() => {
    

    fetchStudentReportScheme();
    
  }, []);
  return (
    <div className="main-container">
      <Header title="Student Report Scheme" subtitle="Manage Academic Report Schemes" />
      {modal && (
        <SchemeDetails
          schemeDetails={selectedScheme}
          modal={modal}
          setModal={setModal}
        />
      )}

      {
        newSchemeModal && (
          <NewScheme
            
            setNewSchemeModal={setNewSchemeModal}
          />
        )
      }

      {
        user?.account_type === "School Administrator" && (
          <div>
            <button className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-400 rounded" onClick={()=>setNewSchemeModal(true)}>
              Add New Report Scheme
            </button>
          </div>
        )
      }

      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light text-surface border border-gray-300">
              <thead className=" w-screen  font-medium">
                <tr className="bg-gray-200 text-black">
                  <th scope="col" className="px-6 py-2 font-[500]">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Main Exam
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    SBA Components
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Actions
                  </th>

                  
                </tr>
              </thead>
              <tbody>
                {schemes?.map((report_scheme, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="px-6 py-2 font-[300]">
                      {report_scheme?.name}
                    </td>
                    <td className="px-6 py-2 font-[300]">
                      {report_scheme?.main_exam}
                    </td>
                    <td className="px-6 py-2 font-[300]">
                      {
                        report_scheme?.sba_components?.map((component,index) => (
                          <span key={index}>{component}
                          {index < report_scheme?.sba_components?.length - 1 && ", "}
                          </span>
                        ))
                      }
                    </td>
                    
                    <td className="py-3 flex gap-2">
                      <button
                        onClick={() => toggleModal(report_scheme)}
                        className=" w-max px-3 py-2 font-[500] border border-blue-400 text-blue-400 rounded hover:bg-blue-400 hover:text-white cursor-pointer"
                      >
                        View
                      </button>
                      {
                        user?.account_type === "School Administrator" &&  (
                          <button
                            onClick={() => deleteStudentReportScheme(report_scheme?.id)}
                            className=" w-max px-3 py-2 font-[500] border border-red-400 text-red-400 rounded hover:bg-red-400 hover:text-white cursor-pointer"
                          >
                            Delete
                          </button>
                        )
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {
              loadingData && (
                <div className="flex items-center justify-center py-5">
                  <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
                </div>
              )
            }
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default StudentReportScheme;
