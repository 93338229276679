import React, { useState } from "react";
import "./Login.scss";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Image1 from "../../assets/LoginPage/image1.jpg";
import Logo from "../../assets/logos/default-logo.png"
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";

import { ColorRing } from  'react-loader-spinner'

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    try{
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const data = {
        username: username,
        password: password
      }
      const body = JSON.stringify(data)
      await axios.post(`${API_URL}authentication/login/`,body,config).then((res) => {
        if(res.status === 200){
          localStorage.setItem('access',res.data.access)
          localStorage.setItem('refresh',res.data.refresh)
          getUser()
          
        }
    })

    }catch(error){
      if (error){
        if(error.response){
          toast.error(error.response.data.detail)
        }else{
          toast.error("Something went wrong, please try again")
        }
        setLoading(false)
      }
    }
  };

  const getUser = async (e) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    }
    await axios.get(`${API_URL}accounts/users/me/`, config).then((response)=>{
      if(response?.data?.account_type === "Master"){
        window.location.href = "/superadmin/dashboard"
      }else if(response?.data?.account_type === "School Administrator"){
        window.location.href = "/admin/dashboard"
      }else if(response?.data?.account_type === "School Staff"){
        if(response?.data?.position === "Accountant"){
          window.location.href = "/accountant/dashboard"
        }else{
          window.location.href = "/staff/dashboard"
        }
      }else if(response?.data?.account_type === "Student"){
        window.location.href = "/std/dashboard"
      }else{
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        window.location.href = "/"
      }
      setLoading(false)
    }).catch((error)=>{
      setLoading(false);
    })
  }


  return (
    <div>
      <Helmet>
        <title>Meskuu - Login</title>
      </Helmet>
      <main>
        <div className="box">
          <div className="inner-box">
            <div className="forms-wrap">
              <form onSubmit={loginUser} className="sign-in-form">
                <div>
                  <div className="flex items-center justify-center">
                    <img src={Logo} alt="" className="h-16" />
                  </div>
                  <div className="mt-5 text-center">
                    <h3 className="font-[600] text-[25px]">Sign in</h3>
                    <h6 className="text-gray-400">Welcome back! Please enter your details</h6>
                  </div>
                </div>
                <div className="form-body">

                  <div className="actual-form">
                    <div className="input-wrap">
                      <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        name=""
                        className="input-field"
                        required
                        id="username"
                      />
                      <label htmlFor="">Username</label>
                    </div>
                    <div className="input-wrap">
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        name=""
                        className="input-field"
                        required
                        autoComplete="false"
                        id="password"
                      />
                      <label htmlFor="">Password</label>
                    </div>
                    
                    {loading ? (
                      <div className="loading-btn">
                      <ColorRing
                          visible={true}
                          height="20"
                          width="20"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#fff', '#fff', '#fff', '#fff', '#fff']}
                          />
                      
                    </div>
                    ) : (
                      <>
                        {
                          !username || !password ? (
                            <div className="loading-btn">
                              Sign In
                            </div>
                          ): (
                            <input type="submit" value="Sign In" className="sign-btn" />
                          )
                        }
                      </>
                    )}
                    {/* {error && <p style={{color: "red", marginBottom: "20px"}}>{error}</p>} */}
                    <p className="text">
                      Forgot Password? <Link to="/reset-password">Click Here</Link> To Reset Your Password
                    </p>
                  </div>
                </div>
              </form>
            </div>

            <div className="carousel">
              <div className="images-wrapper">
                <img src={Image1} alt="" className="image img-1 show" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
