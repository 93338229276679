import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";

import Header from "../../components/Header";
import NewCollectible from "./NewCollectible";
import NoData from "../../components/NoData";




const Collectibles = () => {
  const [collectibles, setCollectibles] = useState([]);
  const [gettingCollectibles, setGettingCollectibles] = useState(false)
  const [selectedCollectible, setSelectedCollectible] = useState("");
  const [modal, setModal] = useState(false)
  const [collectibleModal, setCollectibleModal] = useState(false)
  
  

  // TOGGLE MODAL TO VIEW FEE GROUP
  const toggleModal = (details) => {
    setSelectedCollectible(details)
    setModal(!modal)
  }



  // Getting Collectibles
  const fetchCollectibles = async () => {
    if (localStorage.getItem("access")) {
      setGettingCollectibles(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(`${API_URL}finance/collectibles/`, config);

        setCollectibles(res.data);
        setGettingCollectibles(false)
      } catch (error) {
        setGettingCollectibles(false)
      }
    }
  };


  useEffect(() => {
    fetchCollectibles();
  }, []);



  return (
    <div className="main-container">
      <Header title="Collectibles" subtitle="Students fee payments aside main school fees." />
      
      {
        collectibleModal && (
          <NewCollectible setModal={setCollectibleModal} />
        )
      }


      <div>
        <button className="px-3 py-2 bg-secondary text-secondary-white hover:bg-secondary-100 rounded" onClick={()=>setCollectibleModal(true)}>
          Add Collectible
        </button>
      </div>

      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light text-surface border border-gray-300">
              <thead className=" w-screen  font-medium">
                <tr className="bg-gray-200 text-black">
                  <th scope="col" className="px-6 py-2 font-[500]">
                    SRL
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Collectible
                  </th>

                  {/* <th scope="col" className="px-6 py-2 font-[500]">
                    Actions
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {collectibles?.map((collectible, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="px-6 py-2 font-[300]">
                      {index + 1}
                    </td>
                    <td className="px-6 py-2 font-[300]">
                      {collectible?.name}
                    </td>
                    
{/*                     
                    <td className="py-2 flex gap-2">
                      <button onClick={()=>toggleModal(collectible)} className=" w-max px-3 py-2 font-[500] border border-blue-400 text-blue-400 rounded hover:bg-blue-400 hover:text-white cursor-pointer">
                         Details
                      </button>
                      <button to={`/academics/academic-year/${collectible?.id}/`} className=" w-max px-3 py-2 font-[500] border border-red-400 text-red-400 rounded hover:bg-red-400 hover:text-white cursor-pointer">
                        Delete
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            {
              gettingCollectibles && (
                <div className="flex items-center justify-center">
                  <RotatingLines type="Grid" strokeColor="blue" height="26" width="26" strokeWidth="5" />
                </div>
              )
            }
            {
              collectibles.length === 0 && !gettingCollectibles && (
                <NoData />
              )
            }
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default Collectibles;
