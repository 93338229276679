import React, { useState } from "react";
import NoDataImage from "../../assets/no_data.jpg"
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import { GiCheckMark } from "react-icons/gi";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import Signature from "../../assets/signature.png";
import Swal from "sweetalert2";
import Header from "../../components/Header";

const EyfsStudentReport = () => {
  const { academicYears, schoolClasses, schoolInfo } = useAuthenticate();
  const [academicTerms, setAcademicTerms] = useState([]);
  const [data, setData] = useState({});
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentResult, setStudentResult] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const resultRef = useRef();

  // SIGN REPORT
  const signReport = async (e) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Signing this report means, the marks cannot be changed again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sign it!",
    }).then((response) => {
      if (response.isConfirmed) {
        if (studentResult.report_details.id) {
          setIsSubmitting(true);
          const data = {
            director_approved: true,
          };
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          try {
            axios
              .put(
                `${API_URL}academics/eyfs/student-report-details/${studentResult.report_details.id}/`,
                data,
                config
              )
              .then((response) => {
                if (response.status === 200) {
                  setIsSubmitting(false);
                  toast.success("Report Approved");
                  fetchEyfsStudentResult(e);
                }
              });
          } catch (error) {
            setIsSubmitting(false);
            toast.error("Something went wrong. Please try again.");
          }
        }
      }
    });
  };

  // CHANGE DATA
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // FETCH CLASS STUDENTS
  const fetchClassStudents = async (e) => {
    if (e.target.value !== "") {
      if (localStorage.getItem("access")) {
        setLoadingStudents(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-students/${e.target.value}`,
            config
          );
          setStudents(res.data);
          setLoadingStudents(false);
        } catch (error) {
          setLoadingStudents(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            }else if(error.response.status === 500){
              toast.error("Something unexpected happened.")
            }
             else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    } else {
      setStudents([]);
    }
  };

  // CHANGE ACADEMIC YEAR WITH TERM
  const changeAcademicYear = (e) => {
    handleChange(e);

    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  // GET RESULT
  const fetchEyfsStudentResult = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      try {
        const res = await axios.get(
          `${API_URL}academics/eyfs/student-report/${data.term}/${data.related_class}/${data.student}/`,
          config
        );
        setStudentResult(res.data);
        setLoading(false);
        setDataFetched(true);
      } catch (error) {
        setLoading(false);
        if (error.response.status === 401) {
          window.location.href = "/";
        } else {
          toast.error("Something went wrong. Please try again.");
        }
        // if (error.response) {
        //   if (error.response.status === 401) {
        //     window.location.href = "/";
        //   } else {
        //     for (const property in error.response.data) {
        //       toast.error(
        //         `${property} error: ${error.response.data[property]}`
        //       );
        //     }
        //   }
        // } else if (error.message) {
        //   toast.error("Something went wrong. Please try again");
        // }
      }
    }
  };

  return (
    <div className="main-container">
      <Header title="EYFS Student Report" subtitle="Terminal report for pre-school students" />
      <div style={{ marginTop: "20px" }} className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => fetchEyfsStudentResult(e)}>
            {/* ACADEMIC YEARS DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_year"
                id="academic_year"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* ACADEMIC TERMS DROPDOWN */}
            <div className="formInput">
              <select
                name="term"
                onChange={(e) => handleChange(e)}
                id="academic_term"
              >
                {data.academic_year === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>

            {/* CLASSES DROPDOWN */}
            <div className="formInput">
              <select
                name="related_class"
                id=""
                onChange={(e) => {
                  handleChange(e);
                  fetchClassStudents(e);
                }}
              >
                <option value="">Select Class</option>
                {schoolClasses.map((schoolClass) => (
                  <React.Fragment key={schoolClass.id}>
                    {schoolClass.class_level === "Preschool" && (
                      <option key={schoolClass.id} value={schoolClass.id}>
                        {schoolClass.class_name}
                      </option>
                    )}
                  </React.Fragment>
                ))}
              </select>
            </div>

            {/* CLASS STUDENTS */}
            <div className="formInput">
              <select
                name="student"
                id="student"
                required
                onChange={(e) => handleChange(e)}
              >
                {loadingStudents ? (
                  <option>Loading Students...</option>
                ) : (
                  <>
                    {students.length < 1 ? (
                      <option>Select Class First</option>
                    ) : (
                      <>
                        <option value="">Select Student</option>
                        {students.map((student) => {
                          return (
                            <option
                              key={student.student_id}
                              value={student.student_id}
                            >
                              {student.get_full_name} 
                            </option>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </select>
            </div>
            {loading ? (
              <button style={{ backgroundColor: "gray" }}>Filtering...</button>
            ) : (
              <button type="submit">Filter</button>
            )}
          </form>
        </div>
        {/* EXAM RESULTS TO BE DISPLAYED */}
        {studentResult ? (
          <>
            <div className="buttons">
              <ReactToPrint
                trigger={() => (
                  <button
                    style={{
                      backgroundColor: "#304D7B",
                      color: "white",
                      cursor: "pointer",
                      border: "none",
                      padding: "10px 15px",
                      marginTop: "30px",
                    }}
                  >
                    Print / Download
                  </button>
                )}
                content={() => resultRef.current}
              />
            </div>

            <div
              className="eyfs-student-report"
            >
              <div
                style={{
                  textAlign: "right",
                  color: "white",
                  backgroundColor: "#5b3016",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              ></div>
              <div
                className="school-info"
              >
                <div className="school-logo">
                  <img
                    
                    src={schoolInfo.school_logo ? `${API_URL}${schoolInfo.school_logo}` : ""}
                    alt="school-logo"
                  />
                </div>
                <div
                  className="school-details"
                 
                >
                  <h3>{schoolInfo.school_name}</h3>
                  <p>
                    Please Contact us: {schoolInfo.school_phone_number} at{" "}
                    {schoolInfo.school_city} {schoolInfo.school_region}{" "}
                  </p>
                </div>
              </div>
              <div
                className="academic-info"
              >
                <div className="student-info">
                  <p>
                    {" "}
                    <b>Student Name:</b> {studentResult.student_name}
                  </p>
                  <p>
                    <b>Student ID: </b> {studentResult.student_id}
                  </p>
                  <p>
                    <b>Student Class: </b>
                    {studentResult.student_class}
                  </p>
                </div>
                
              </div>
              <div className="income-statement" style={{ width: "100%" }}>
                <div style={{ textAlign: "center", margin: "20px auto" }}>
                  <h2>
                    END OF {studentResult.term} ({studentResult.academic_year}{" "}
                    ACADEMIC YEAR)
                  </h2>
                  <h3>PRESCHOOL PROGRESS REPORT</h3>
                </div>
                <div className="text" style={{ margin: "40px auto 20px" }}>
                  <h3>Purpose of report</h3>
                  <p
                    style={{
                      lineHeight: "1.5",
                      textAlign: "justify",
                      marginBottom: "20px",
                      fontWeight: "400",
                    }}
                  >
                    This report is designed to inform you about your child’s
                    progress towards achieving the{" "}
                    <b>Early Year Foundation Stage Curriculum and standards </b>{" "}
                    for this level. The <b> EYFS </b> curriculum establishes
                    high and challenging expectations for all students and
                    describes what students should know and be able to do by the
                    end of this academic year. The areas the curriculum focuses
                    are: <b>Prime Areas</b> (
                    {studentResult.prime_areas.map((subject, index) => (
                      <span key={index}>{subject},&nbsp;</span>
                    ))}
                    ) and <b> Specific Areas </b> (
                    {studentResult.specific_areas.map((subject, index) => (
                      <span key={index}>{subject},&nbsp;</span>
                    ))}
                    ). The <b> prime areas </b> begin to develop quickly in
                    response to relationships and experiences, and run through
                    to support learning in all other areas whiles the{" "}
                    <b> Specific Areas</b> include essential skills and
                    knowledge for children to participate successfully in
                    society. The report is a working document which is
                    reflective of your child’s progress at the time of issue.
                    The
                    <b> Prime Areas</b> objectives contained within this
                    document are reflective of skills to be mastered by the end
                    of
                    <b> {studentResult.academic_year} academic year </b>,
                    whereas the other objectives are subject to changes on
                    termly basis. The report cannot communicate everything you
                    might want to know about your child’s current progress. It
                    should be considered with other information you receive from
                    the school concerning your child’s academic progress.
                    Regular communication between the family and the school is
                    highly encouraged.
                  </p>
                </div>
                <div className="eyfs-report-table" style={{overflow: "auto"}}>
                  <table style={{ borderCollapse: "collapse" }}>
                    {studentResult.results.map((result, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  fontWeight: "bold",
                                }}
                              >
                                {result.subject}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  fontWeight: "bold",
                                }}
                              >
                                Beginning (1)
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  fontWeight: "bold",
                                }}
                              >
                                Progressing (2)
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  fontWeight: "bold",
                                }}
                              >
                                Satisfactory (3)
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  fontWeight: "bold",
                                }}
                              >
                                Exceeding (4)
                              </td>
                            </tr>
                            {result.marks.map((mark, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                    }}
                                  >
                                    {mark.objective}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                    }}
                                  >
                                    {mark.mark === 1 && <GiCheckMark />}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                    }}
                                  >
                                    {mark.mark === 2 && <GiCheckMark />}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                    }}
                                  >
                                    {mark.mark === 3 && <GiCheckMark />}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                    }}
                                  >
                                    {mark.mark === 4 && <GiCheckMark />}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </React.Fragment>
                      );
                    })}
                  </table>
                </div>
                <div className="remarks">
                  <div style={{ margin: "20px auto" }} className="detail">
                    <h3 style={{ fontWeight: "bold" }}>
                      Class Teacher's Remarks:
                    </h3>

                    {studentResult.report_details.teacher_comment && (
                      <>
                        <p>{studentResult.report_details.teacher_comment}</p>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="signatures"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h3 style={{ fontWeight: "bold" }}>Teacher's signature:</h3>

                    <p style={{ marginTop: "20px" }}>
                      ........................................................
                    </p>
                  </div>
                  <div>
                    <h3 style={{ fontWeight: "bold" }}>
                      Director's Signature:
                    </h3>

                    <p style={{ marginTop: "20px" }}>
                      ........................................................
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  textAlign: "right",
                  color: "white",
                  backgroundColor: "#5b3016",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              ></div>
            </div>
            {/* {
              isSubmitting ? (
                <div style={{cursor:"wait" ,background: "gray" ,float:"right", margin: "10px 40px"}} className="classic-button">
                  Signing Report...
                </div>
              ):(
                <div onClick={(e)=>signReport(e)} style={{float:"right", margin: "10px 40px"}} className="classic-button">
                  Sign Report
                </div>
              )
            } */}
          </>
        ) : (
          <>
            {dataFetched && (
              <div className="no-data">
              <img src={NoDataImage} alt="no data" />
              <p>
                No Data Found
              </p>
            </div>
            )}
          </>
        )}



        {/* EXAM RESULTS TO BE PRINTED */}
        {studentResult && (
          <div className="hidden">
            <div
              ref={resultRef}
              className="student-report"
              style={{
                padding: "20px 55px 50px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  textAlign: "right",
                  color: "white",
                  backgroundColor: "#5b3016",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              ></div>
              <div
                className="school-info"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="schoolLogo">
                  <img
                    style={{ height: "150px", borderRadius: "50%" }}
                    src={schoolInfo.school_logo ? `${API_URL}${schoolInfo.school_logo}` : ""}
                    alt="school-logo"
                  />
                </div>
                <div
                  className="school-details"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h3 style={{ fontSize: "40px" }}>{schoolInfo.school_name}</h3>
                  <p>
                    Please Contact us: {schoolInfo.school_phone_number} at{" "}
                    {schoolInfo.school_city} {schoolInfo.school_region}{" "}
                  </p>
                </div>
              </div>
              <div
                className="academic-info"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div className="student-info">
                  <p>
                    {" "}
                    <b>Student Name:</b> {studentResult.student_name}
                  </p>
                  <p>
                    <b>Student ID: </b> {studentResult.student_id}
                  </p>
                  <p>
                    <b>Student Class: </b>
                    {studentResult.student_class}
                  </p>
                </div>
                <div className="other-details">
                  <p>
                    <b>Class Teacher:</b> &nbsp;
                    {studentResult.class_teachers.map((teacher, index) => (
                      <span key={index}>{teacher}, &nbsp;</span>
                    ))}
                  </p>
                  <p>
                    <b>Director's Name:</b> {studentResult.school_director}
                  </p>
                </div>
              </div>
              <div className="income-statement" style={{ width: "100%" }}>
                <div style={{ textAlign: "center", margin: "20px auto" }}>
                  <h2>
                    END OF {studentResult.term} ({studentResult.academic_year}{" "}
                    ACADEMIC YEAR)
                  </h2>
                  <h3>PRESCHOOL PROGRESS REPORT</h3>
                </div>
                <div className="text" style={{ margin: "40px auto 20px" }}>
                  <h3>Purpose of report</h3>
                  <p
                    style={{
                      lineHeight: "1.5",
                      textAlign: "justify",
                      marginBottom: "20px",
                      fontWeight: "400",
                    }}
                  >
                    This report is designed to inform you about your child’s
                    progress towards achieving the{" "}
                    <b>Early Year Foundation Stage Curriculum and standards </b>{" "}
                    for this level. The <b> EYFS </b> curriculum establishes
                    high and challenging expectations for all students and
                    describes what students should know and be able to do by the
                    end of this academic year. The areas the curriculum focuses
                    are: <b>Prime Areas</b> (
                    {studentResult.prime_areas.map((subject, index) => (
                      <span key={index}>{subject},&nbsp;</span>
                    ))}
                    ) and <b> Specific Areas </b> (
                    {studentResult.specific_areas.map((subject, index) => (
                      <span key={index}>{subject},&nbsp;</span>
                    ))}
                    ). The <b> prime areas </b> begin to develop quickly in
                    response to relationships and experiences, and run through
                    to support learning in all other areas whiles the{" "}
                    <b> Specific Areas</b> include essential skills and
                    knowledge for children to participate successfully in
                    society. The report is a working document which is
                    reflective of your child’s progress at the time of issue.
                    The
                    <b> Prime Areas</b> objectives contained within this
                    document are reflective of skills to be mastered by the end
                    of
                    <b> {studentResult.academic_year} academic year </b>,
                    whereas the other objectives are subject to changes on
                    termly basis. The report cannot communicate everything you
                    might want to know about your child’s current progress. It
                    should be considered with other information you receive from
                    the school concerning your child’s academic progress.
                    Regular communication between the family and the school is
                    highly encouraged.
                  </p>
                </div>
                <table style={{ borderCollapse: "collapse" }}>
                  {studentResult.results.map((result, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              {result.subject}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              Beginning (1)
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              Progressing (2)
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              Satisfactory (3)
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              Exceeding (4)
                            </td>
                          </tr>
                          {result.marks.map((mark, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                  }}
                                >
                                  {mark.objective}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                  }}
                                >
                                  {mark.mark === 1 && <GiCheckMark />}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                  }}
                                >
                                  {mark.mark === 2 && <GiCheckMark />}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                  }}
                                >
                                  {mark.mark === 3 && <GiCheckMark />}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                  }}
                                >
                                  {mark.mark === 4 && <GiCheckMark />}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </React.Fragment>
                    );
                  })}
                </table>
                <div className="remarks">
                  <div style={{ margin: "20px auto" }} className="detail">
                    <h3 style={{ fontWeight: "bold" }}>
                      Class Teacher's Remarks:
                    </h3>

                    {studentResult.report_details.teacher_comment && (
                      <>
                        <p>{studentResult.report_details.teacher_comment}</p>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="signatures"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h3 style={{ fontWeight: "bold" }}>Teacher's signature:</h3>

                    <p style={{ marginTop: "20px" }}>
                      ........................................................
                    </p>
                  </div>
                  <div>
                    <h3 style={{ fontWeight: "bold" }}>
                      Director's Signature:
                    </h3>

                    <p style={{ marginTop: "20px" }}>
                      ........................................................
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  textAlign: "right",
                  color: "white",
                  backgroundColor: "#5b3016",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              ></div>
            </div>
          </div>
        )
        }
      </div>
    </div>
  );
};

export default EyfsStudentReport;
