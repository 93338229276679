import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import Loading from "../../components/LoadingPage/Loading";
import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineEye,
} from "react-icons/ai";
import { API_URL } from "../../url";
import axios from "axios";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Link } from "react-router-dom";
import { IoIosAddCircleOutline } from "react-icons/io";
import IncomeDetailsModal from "./IncomeDetailsModal";

const IncomeList = () => {
  const incomeRef = useRef();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [incomeList, setIncomeList] = useState(null);
  const [incomeTypes, setIncomeTypes] = useState([]);
  const [incomeType, setIncomeType] = useState("");
  const [data, setData] = useState({
    start_date: "",
    end_date: "",
  });

  // Filter Income List
  const filterIncomeList = async (e) => {
    e.preventDefault();
    if (incomeType === "" || data.start_date === "" || data.end_date === "") {
      toast.error("Select the required fields");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        setIncomeList(null);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        try {
          const res = await axios.get(
            `${API_URL}finance/school-revenue/${data.start_date}/${data.end_date}/${incomeType}`,
            config
          );
          setIncomeList(res.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            toast.error("An error occurred");
          }
        }
      }
    }
  };

  // Get Income Types
  const getIncomeTypes = async (e) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}finance/school-income-type/`,
          config
        );
        setIncomeTypes(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // Export to Excel
  const { onDownload } = useDownloadExcel({
    currentTableRef: incomeRef.current,
    filename: `Income List`,
    sheet: "Income List",
  });

  useEffect(() => {
    getIncomeTypes();
  }, []);

  return (
    <div className="main-container">
      <div className="header">
        <h1>INCOME LIST</h1>
      </div>

      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => filterIncomeList(e)}>
            <div className="formInput">
              <label htmlFor="">From*:</label>
              <input
                type="date"
                name="start_date"
                onChange={(e) =>
                  setData({ ...data, start_date: e.target.value })
                }
                id=""
              />
            </div>
            <div className="formInput">
              <label htmlFor="">To*: </label>
              <input
                type="date"
                name="end_date"
                onChange={(e) => setData({ ...data, end_date: e.target.value })}
                id=""
              />
            </div>
            <div className="formInput">
              <label htmlFor="">Income Type*:</label>
              <select
                name="income_type"
                onChange={(e) => setIncomeType(e.target.value)}
              >
                <option value="">Select Income Type</option>
                <option value="All">All</option>
                {incomeTypes.map((incomeType) => (
                  <option key={incomeType.id} value={incomeType.id}>
                    {incomeType.name}
                  </option>
                ))}
              </select>
            </div>
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  color: "white",
                  padding: "15px",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data...{" "}
              </button>
            ) : (
              <button>Filter</button>
            )}
          </form>
        </div>
        {/* INCOME LIST TO DISPLAY */}
        {incomeList && (
          <div className="income-statement">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <button className="export-button" onClick={onDownload}>
                  <AiOutlineFileExcel />
                  Export to Excel
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button className="export-button">
                      <AiOutlineFilePdf />
                      Export to PDF
                    </button>
                  )}
                  content={() => incomeRef.current}
                  documentTitle="Income List"
                />
              </div>
              <div>
                <Link to="/finance/receive-income">
                  <button className="add-button">
                    <IoIosAddCircleOutline />
                    Receive Income
                  </button>
                </Link>
              </div>
            </div>

            <div>
              <h1 style={{ textAlign: "center" }}>Income List</h1>
              
              <div className="scroll-table">
                <table
                  style={{
                    marginTop: "30px",
                    borderCollapse: "collapse",
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Income Type
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Date
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Amount (GHS)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomeList.income_list.map((income) => {
                      return (
                        <>
                          <tr>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                              }}
                            >
                              {income.revenue_type}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                              }}
                            >
                              {income.date}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "right",
                              }}
                            >
                              {income.amount}
                            </td>
                            {/* <td>
                              <AiOutlineEye
                                onClick={() => setModal(!modal)}
                                title="View Details"
                                style={{ cursor: "pointer" }}
                              />
                              <AiOutlineDelete title="Delete" style={{cursor: "pointer", marginLeft: "10px"}} onClick = {(e) => deleteExpense(expense.id,e)} />
                            </td> */}
                        
                          </tr>
                          {/* {
                            !modal && <IncomeDetailsModal modal={modal} setModal={setModal} />
                          } */}
                        </>
                      );
                    })}

                    
                    <tr>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          fontWeight: "bold",
                        }}
                      >
                        Total Income
                      </td>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        {incomeList.total_income}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
            </div>
          </div>
        )}


        {/* INCOME LIST TO PRINT */}
        {incomeList && (
          <div className="income-statement hidden">

            <div ref={incomeRef} >
              <h1 style={{ textAlign: "center" }}>Income List</h1>
              <div>
                <div className="scroll-table">
                  <table
                    style={{
                      marginTop: "30px",
                      borderCollapse: "collapse",
                      width: "100%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ fontWeight:"bold", border: "1px solid #ddd", padding: "8px" }}>
                          Income Type
                        </td>
                        <td style={{ fontWeight:"bold", border: "1px solid #ddd", padding: "8px" }}>
                          Date
                        </td>
                        <td style={{ fontWeight:"bold", border: "1px solid #ddd", padding: "8px" }}>
                          Amount (GHS)
                        </td>
                      </tr>
                      {incomeList.income_list.map((income) => {
                        return (
                          <React.Fragment>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                }}
                              >
                                {income.revenue_type}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                }}
                              >
                                {income.date}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "right",
                                }}
                              >
                                {income.amount}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}

                      <tr>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            fontWeight: "bold",
                          }}
                        >
                          Total Income
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          {incomeList.total_income}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IncomeList;
