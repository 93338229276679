import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL, img_base_url } from "../../url";
import { useAuthenticate } from "../../context/AuthContext";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import { AiOutlineUpload } from "react-icons/ai";
import Loading from "../../components/LoadingPage/Loading";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import FormInput from "../../components/Forms/FormInput";
import UserImg from "../../assets/avatar.jpg";
import ResetPasswordModal from "./ResetPasswordModal";

const StaffProfile = () => {
  const { user } = useAuthenticate();
  const { id } = useParams();
  const [staff, setStaff] = useState([]);
  const { staffPositions } = useAuthenticate();
  const [staffImage, setStaffImage] = useState("");
  const [updateData, setUpdateData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [modal, setModal] = useState(false);

  // FETCH PARTICULAR STAFF
  const fetchStaff = async () => {
    if (localStorage.getItem("access")) {
      setLoadingData(true);
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}administrator/staff/${id}`,
          config
        );
        setStaff(res.data);

        setLoadingData(false);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // UPDATE STAFF DETAILS
  const updateStaff = async (e) => {
    e.preventDefault();
    if (!updating) {
      if (
        updateData.profile_picture &&
        updateData.profile_picture.size > 878576
      ) {
        toast.error(
          "The size of the profile picture should be less than 878KB"
        );
      } else {
        if (localStorage.getItem("access")) {
          setUpdating(true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          const formData = new FormData();
          Object.keys(updateData).forEach((key) => {
            formData.append(key, updateData[key]);
          });
          try {
            await axios
              .put(`${API_URL}administrator/staff/${id}/`, formData, config)
              .then((response) => {
                setUpdating(false);
                Swal.fire({
                  icon: "success",
                  title: "Updated",
                  text: "Staff Information Updated Successfully",
                }).then(() => {
                  fetchStaff();
                });
              });
          } catch (error) {
            setUpdating(false);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/";
              } else {
                for (const property in error.response.data) {
                  if (property === "0") {
                    toast.error(`${error.response.data[property]}`);
                  } else {
                    toast.error(
                      `${property} error: ${error.response.data[property]}`
                    );
                  }
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    }
  };

  // UPDATE DATA TO BE SUBMITTED
  const onChange = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  // DELETE STAFF FUNCTION
  const deleteStaff = (staff_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "All information about this staff will be lost. You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setUpdatingStatus(true);
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          try {
            axios.delete(`${API_URL}administrator/staff/${staff_id}`, config);
            Swal.fire("Deleted!", "Staff has been deleted.", "success").then(
              () => {
                window.location.href = "/admin/staffs/staff-list";
              }
            );
          } catch (error) {
            setUpdatingStatus(false);
            if (error.response.status === 400) {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            } else {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    });
  };

  // ACTIVATE OR DEACTIVE STAFF ACCOUNT
  const changeStaffStatus = (user_account_id, status) => {
    let message = "";
    let success_message = "";
    let data = {
      is_active: false,
    };
    if (status === "activate") {
      data.is_active = true;
      message =
        "Once the staff is activated, he will have access to his account";
      success_message = "Staff account activated successfully";
    } else {
      data.is_active = false;
      message =
        "Once the staff is deactivated, he will not have access to his account";
      success_message = "Staff account deactivated successfully";
    }
    Swal.fire({
      title: `Are you sure?`,
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setUpdatingStatus(true);
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          await axios
            .put(
              `${API_URL}administrator/user-account/${user_account_id}/update/`,
              data,
              config
            )
            .then((response) => {
              console.log(response?.data);
              if (response?.data?.success) {
                Swal.fire("Updated!", success_message, "success").then(() => {
                  window.location.reload();
                });
              } else {
                toast.error(response?.data?.message);
                setUpdatingStatus(false);
              }
            })
            .catch((error) => {
              setUpdatingStatus(false);
              if (error.response.status) {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              } else {
                toast.error("Something went wrong. Please try again");
              }
            });
        }
      }
    });
  };

  // COMPRESS FILE
  const compressFile = (image) => {
    let file_name = image.name;
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      let image_url = event.target.result;
      let image = document.createElement("img");
      image.src = image_url;

      image.onload = (e) => {
        // creating a new canvas for the image
        const canvas = document.createElement("canvas");
        let ratio = 300 / e.target.width;
        canvas.width = 300;
        canvas.height = ratio * e.target.height;

        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        // converting the image quality to 50% and the file type to webp
        let new_image_url = canvas.toDataURL("image/webp", 50);

        // getting the image type and the data
        let arr = new_image_url.split(",");
        let image_type = arr[0].match(/:(.*?);/)[1];
        let data = arr[1];
        let dataStr = atob(data);
        let n = dataStr.length;
        let dataArr = new Uint8Array(n);

        while (n--) {
          dataArr[n] = dataStr.charCodeAt(n);
        }

        const compressed_image = new File([dataArr], file_name, {
          type: image_type,
        });
        setStaffImage(compressed_image);
        setUpdateData({
          ...updateData,
          profile_picture: compressed_image,
        });
      };
    };
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  return (
    <div className="main-container">
      <p className="font-[500] text-primary text-[20px] my-6">
        Staff Personal Info
      </p>

      {!loadingData ? (
        <div className="">
          {modal && (
            <ResetPasswordModal
              setModal={setModal}
              account_id={staff?.user_account?.id}
            />
          )}
          <div className="border border-slate-300 rounded p-[16px]">
            <div className="flex justify-between lg:items-center lg:flex-row flex-col gap-7">
              <div className="flex gap-5">
                <div className="bg-light-primary h-[90px] w-[90px] flex items-center justify-center">
                  <img
                    src={
                      staff?.user_account?.profile_picture
                        ? `${img_base_url}${staff?.user_account?.profile_picture}`
                        : { UserImg }
                    }
                    alt=""
                  />
                </div>
                <div>
                  <p className="font-[500] text-[18px]">
                    {staff?.user_account?.first_name}{" "}
                    {staff?.user_account?.last_name}
                  </p>
                  <div className="flex lg:flex-row flex-col lg:gap-4 text-[14px]">
                    <p>{staff.staff_school_id}</p>
                    <p>{staff.position}</p>
                    <p>{staff.phone_number}</p>
                  </div>
                  {staff?.status === "Active" && (
                    <div className="w-max bg-green-100 text-green-600 font-bold px-[8px] py-1 text-center text-[11px]">
                      {staff?.status}
                    </div>
                  )}
                  {staff?.status === "Inactive" && (
                    <div className="w-max bg-red-100 text-red-600 px-[8px] py-1 text-[11px] text-center">
                      {staff?.status}
                    </div>
                  )}
                </div>
              </div>
              {user?.account_type === "School Administrator" && (
                <div>
                  {updatingStatus ? (
                    <div className="flex items-center ">
                      <RotatingLines
                        visible={true}
                        height="26"
                        width="26"
                        strokeColor="black"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      {staff?.status === "Active" ? (
                        <button
                          onClick={() =>
                            changeStaffStatus(
                              staff?.user_account?.id,
                              "deactivate"
                            )
                          }
                          className="py-2 px-2 bg-red-500 hover:bg-red-600 text-[12px] text-white rounded"
                        >
                          Deactivate
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            changeStaffStatus(
                              staff?.user_account?.id,
                              "activate"
                            )
                          }
                          className="py-2 px-2 bg-green-500 hover:bg-green-600 text-[12px] text-white rounded"
                        >
                          Activate
                        </button>
                      )}
                      <button
                        onClick={() => deleteStaff(staff?.id)}
                        className="py-2 px-2  hover:bg-red-50 text-[12px] border border-red-500 text-red-500 rounded"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => setModal(!modal)}
                        className="py-2 px-2  hover:bg-blue-50 text-[12px] border border-blue-500 text-blue-500 rounded"
                      >
                        Reset Password
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="school-profile-left">
            <form onSubmit={(e) => updateStaff(e)}>
              <div className="formSection">
                <div className="formRow">
                  <div className="formInput">
                    <label htmlFor="student_image">Staff Image</label>
                    <input
                      type="file"
                      name="profile_picture"
                      // onChange={(e) => {
                      //   setUpdateData({
                      //     ...updateData,
                      //     [e.target.name]: e.target.files[0],
                      //   });
                      // }}
                      onChange={(e) => compressFile(e.target.files[0])}
                      id="student_image"
                    />
                    <label className="upload-button" htmlFor="student_image">
                      <AiOutlineUpload style={{ fontSize: "15px" }} />
                      Upload Picture
                    </label>
                    <p style={{ fontWeight: "400", fontSize: "12px" }}>
                      {staffImage !== "" ? staffImage.name : "No file selected"}
                    </p>
                  </div>

                  <div className="formInput">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={
                        updateData.first_name
                          ? updateData.first_name
                          : staff?.user_account?.first_name
                      }
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="formInput">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={
                        updateData.last_name
                          ? updateData.last_name
                          : staff?.user_account?.last_name
                      }
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formInput">
                    <label>Gender</label>
                    {staff.gender === "Male" && (
                      <>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="gender"
                            required
                            checked
                            value="Male"
                          />{" "}
                          Male
                        </div>
                        <div className="radioInline">
                          <input type="radio" name="gender" value="Female" />{" "}
                          Female
                        </div>
                      </>
                    )}
                    {staff.gender === "Female" && (
                      <>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="gender"
                            required
                            value="Male"
                          />{" "}
                          Male
                        </div>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="gender"
                            checked
                            value="Female"
                          />{" "}
                          Female
                        </div>
                      </>
                    )}
                  </div>
                  <div className="formInput">
                    <label>Date Of Birth</label>
                    <input
                      type="date"
                      name="date_of_birth"
                      value={
                        updateData.date_of_birth
                          ? updateData.date_of_birth
                          : staff.date_of_birth
                      }
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="formInput">
                    <label>Phone Number</label>

                    <PhoneInput
                      defaultCountry="GH"
                      required
                      className="phone-input"
                      value={
                        updateData.phone_number
                          ? updateData.phone_number
                          : staff.phone_number
                      }
                      onChange={(value) =>
                        setUpdateData({
                          ...updateData,
                          phone_number: value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formInput">
                    <label>Email Address</label>
                    <input
                      type="email"
                      name="email"
                      value={updateData.email ? updateData.email : staff.email}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="formInput">
                    <label>Residential Address</label>
                    <input
                      type="text"
                      name="residential_address"
                      value={
                        updateData.residential_address
                          ? updateData.residential_address
                          : staff.residential_address
                      }
                      onChange={(e) => onChange(e)}
                    />
                    <small>Provide GPS Address Or House Number</small>
                  </div>
                  <div className="formInput">
                    <label>Marital Status</label>
                    <select name="marital_status" onChange={(e) => onChange(e)}>
                      <option>Select Marital Status</option>
                      <option
                        selected={staff.marital_status === "Single"}
                        value="Single"
                      >
                        Single
                      </option>
                      <option
                        selected={staff.marital_status === "Married"}
                        value="Married"
                      >
                        Married
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="formSection">
                <p className="formRowHeader">Staff Academic Info</p>
                <div className="formRow">
                  <div className="formInput">
                    <label>Staff Type</label>
                    {staff.staff_type === "Teaching Staff" && (
                      <>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="staff_type"
                            onChange={(e) => onChange(e)}
                            checked
                            value="Teaching Staff"
                          />{" "}
                          Teaching Staff
                        </div>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="staff_type"
                            onChange={(e) => onChange(e)}
                            value="Non-Teaching Staff"
                          />{" "}
                          Non-Teaching Staff
                        </div>
                      </>
                    )}

                    {staff.staff_type === "Non-Teaching Staff" && (
                      <>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="staff_type"
                            onChange={(e) => onChange(e)}
                            value="Teaching Staff"
                          />{" "}
                          Teaching Staff
                        </div>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="staff_type"
                            onChange={(e) => onChange(e)}
                            checked
                            value="Non-Teaching Staff"
                          />{" "}
                          Non-Teaching Staff
                        </div>
                      </>
                    )}
                  </div>
                  <div className="formInput">
                    <label>Joining Date</label>
                    <input
                      type="date"
                      name="joining_date"
                      value={
                        updateData.joining_date
                          ? updateData.joining_date
                          : staff.joining_date
                      }
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="formInput">
                    <label>Staff Position</label>
                    <select name="position" onChange={(e) => onChange(e)}>
                      <option>Select Staff Position</option>
                      {staffPositions.map((staffPosition) => {
                        return (
                          <option
                            value={staffPosition.id}
                            key={staffPosition.id}
                            selected={staffPosition.position === staff.position}
                          >
                            {staffPosition.position}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="formSection">
                <p className="formRowHeader">Staff Account Info</p>
                <div className="formRow">
                  <div className="formInput">
                    <label>Username</label>
                    <input
                      type="text"
                      name="username"
                      value={staff?.user_account?.username}
                      disabled
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="formInput">
                    <label>Password</label>
                    <input
                      type="text"
                      name="password"
                      placeholder="********************"
                      disabled
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </div>
              {updating ? (
                <button style={{ cursor: "wait" }}>
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                  Updating...
                </button>
              ) : (
                <button type="submit">Update</button>
              )}
            </form>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default StaffProfile;
