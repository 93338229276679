import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../url";
import Loading from "../../components/LoadingPage/Loading";
import Header from "../../components/Header";

const StudentClass = () => {
  const [classDetails, setClassDetails] = useState(null);
  const [gettingClassDetails, setGettingClassDetails] = useState(false)
  const [activeTab, setActiveTab] = useState(1)

  const getClassDetails = async () => {
    setGettingClassDetails(true)
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${API_URL}student/class-profile`, config)
      .then((response) => {
        setClassDetails(response.data);
        setGettingClassDetails(false)
      })
      .catch((error) => {
        setGettingClassDetails(false)
      });
  };

  useEffect(() => {
    getClassDetails();
  }, []);

      return (
        
        <div className="main-container">
            {
                gettingClassDetails ? (
                    <Loading />
                ):(
                    <>
                        <Header title="Student Class" subtitle="My class details" />
                        <div className="header">
                            <p>
                            {" "}
                            <b>Class Level:</b> {classDetails?.class_level}
                            </p>
                            <p>
                            <b>Class Teacher(s):</b>
                            {classDetails?.class_teachers.map((teacher, index) => {
                                return (
                                <span key={index}>
                                    {teacher?.user_account?.first_name} {teacher?.user_account?.last_name}{" "}
                                    {classDetails?.class_teachers.length > 1 && ","}
                                </span>
                                );
                            })}
                            </p>
                        </div>

                        <div className="schedule-body">
                            <div className="student-list">
                            <div className="panel-header">
                                <h4>Class Subjects</h4>
                            </div>
                            <div className="list-body">
                                <table>
                                <thead>
                                    <tr>
                                    <th>SRL</th>
                                    <th>Subject name</th>
                                    <th>Teacher</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {classDetails?.class_subjects?.subjects?.map(
                                    (subject, index) => (
                                        <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{subject?.subject}</td>
                                        <td>{subject?.teacher?.get_full_name}</td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        <div className="schedule-body">
                            <div className="schedule">
                                
                                <div className="schedule-table">
                                <div className="day-schedule">
                                    <div className="day">Monday</div>
                                    {classDetails?.class_schedule.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                        {schedule.day === "Monday" && (
                                            <div className="schedule-details">
                                            <div className="left">
                                                <p>
                                                {schedule.start_time} - {schedule.end_time}
                                                </p>
                                                <b>{schedule.activity}</b>
                                                <b>{schedule.subject}</b>
                                                <p>{schedule.teacher}</p>
                                                {schedule.is_break && (
                                                <>
                                                    <br />
                                                    <p>
                                                    <b>BREAK TIME</b>
                                                    </p>
                                                </>
                                                )}
                                            </div>
                                            </div>
                                        )}
                                        </div>
                                    );
                                    })}
                                </div>
                                <div className="day-schedule">
                                    <div className="day">Tuesday</div>
                                    {classDetails?.class_schedule.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                        {schedule.day === "Tuesday" && (
                                            <div className="schedule-details">
                                            <div className="left">
                                                <p>
                                                {schedule.start_time} - {schedule.end_time}
                                                </p>
                                                <b>{schedule.subject}</b>
                                                <b>{schedule.activity}</b>
                                                <p>{schedule.teacher}</p>
                                                {schedule.is_break && (
                                                <>
                                                    <br />
                                                    <p>
                                                    <b>BREAK TIME</b>
                                                    </p>
                                                </>
                                                )}
                                            </div>
                                            
                                            </div>
                                        )}
                                        </div>
                                    );
                                    })}
                                </div>
                                <div className="day-schedule">
                                    <div className="day">Wednesday</div>

                                    {classDetails?.class_schedule.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                        {schedule.day === "Wednesday" && (
                                            <div className="schedule-details">
                                            <div className="left">
                                                <p>
                                                {schedule.start_time} - {schedule.end_time}
                                                </p>
                                                <b>{schedule.subject}</b>
                                                <b>{schedule.activity}</b>
                                                <p>{schedule.teacher}</p>
                                                {schedule.is_break && (
                                                <>
                                                    <br />
                                                    <p>
                                                    <b>BREAK TIME</b>
                                                    </p>
                                                </>
                                                )}
                                            </div>
                                            
                                            
                                            </div>
                                        )}
                                        </div>
                                    );
                                    })}
                                </div>
                                <div className="day-schedule">
                                    <div className="day">Thursday</div>
                                    {classDetails?.class_schedule.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                        {schedule.day === "Thursday" && (
                                            <div className="schedule-details">
                                            <div className="left">
                                                <p>
                                                {schedule.start_time} - {schedule.end_time}
                                                </p>
                                                <b>{schedule.subject}</b>
                                                <b>{schedule.activity}</b>
                                                <p>{schedule.teacher}</p>
                                                {schedule.is_break && (
                                                <>
                                                    <br />
                                                    <p>
                                                    <b>BREAK TIME</b>
                                                    </p>
                                                </>
                                                )}
                                            </div>
                                            
                                            
                                            </div>
                                        )}
                                        </div>
                                    );
                                    })}
                                </div>
                                <div className="day-schedule">
                                    <div className="day">Friday</div>
                                    {classDetails?.class_schedule.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                        {schedule.day === "Friday" && (
                                            <div key={schedule.id} className="schedule-details">
                                            <div className="left">
                                                <p>
                                                {schedule.start_time} - {schedule.end_time}
                                                </p>
                                                <b>{schedule.subject}</b>
                                                <b>{schedule.activity}</b>
                                                <p>{schedule.teacher}</p>
                                                {schedule.is_break && (
                                                <>
                                                    <br />
                                                    <p>
                                                    <b>BREAK TIME</b>
                                                    </p>
                                                </>
                                                )}
                                            </div>
                                            
                                            
                                            </div>
                                        )}
                                        </div>
                                    );
                                    })}
                                </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

        </div>
      );
  
};

export default StudentClass;
