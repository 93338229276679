import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { useAuthenticate } from "../../context/AuthContext";
import { RotatingLines } from "react-loader-spinner";
import StatsCard from "../../components/StatsCard/StatsCard";
import ReactPaginate from "react-paginate";
import Header from "../../components/Header";

const StaffList = () => {
  const [staffs, setStaffs] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [statistics, setStatistics] = useState(null);
  const [loadingStaffs, setLoadingStaffs] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');  // Track the search query
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [totalStaffs, setTotalStaffs] = useState(0);
  const { user } = useAuthenticate();
  
  const filterstaffList = (searchValue) => {
    setSearch(searchValue);
    setCurrentPage(1);  // Reset to page 1 whenever search query changes
  };

  const filterstaffListByStatus = (search) => {
    if (search === "") {
      setStaffList(staffs);
      return true;
    }
    const filteredstaffList = staffs.filter((staff) => {
      return (
        staff?.status
          ?.toLowerCase()
           === search.toLowerCase()
      );
    });
    setStaffList(filteredstaffList);
  };

  const filterstaffListByType = (search) => {
    if (search === "") {
      setStaffList(staffs);
      return true;
    }
    const filteredstaffList = staffs.filter((staff) => {
      return (
        staff?.staff_type
          ?.toLowerCase()
          === search.toLowerCase()
      );
    });
    setStaffList(filteredstaffList);
  };

  const fetchStaffs = async (page,searchQuery) => {
    if (localStorage.getItem("access")) {
      setLoadingStaffs(true);
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(`${API_URL}administrator/staffs/?page=${page}&search=${searchQuery}`, config);
        setLoadingStaffs(false);
        setStaffs(res.data?.results);
        setStaffList(res.data?.results);
        setNextPage(res.data?.next);
        setPrevPage(res.data?.previous);
        setTotalPages(Math.ceil(res.data?.count / 20));
        setTotalStaffs(res.data?.count);
      } catch (error) {
        setLoadingStaffs(false);
        if (error.response.status === 401) {
          window.location.href = "/";
        } else {
          toast.error("Failed to get staff list");
        }
      }
    }
  };

  const getStaffStatistics = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}administrator/staffs/statistics/`,
          config
        );

        setStatistics(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        } else {
          toast.error("Couldn't get staff statistics");
        }
      }
    }
  };

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (prevPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
};

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbersToShow = 3; // Number of pages to show around the current page
    const totalPagesToShow = 5; // Total pages to show at start and end

    let startPage = Math.max(1, currentPage - totalNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);

    // Always show the first page
    if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) {
            pageNumbers.push('...');
        }
    }

    // Show the numbers around the current page
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // Always show the last page
    if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
            pageNumbers.push('...');
        }
        pageNumbers.push(totalPages);
    }

    return pageNumbers.map(number => (
        <button
            key={number}
            onClick={() => number !== '...' && handlePageClick(number)}
            className={number === currentPage ? 'bg-secondary border border-secondary text-white rounded py-2 px-3 min-w-10' : 'border border-secondary text-main bg-transparent py-2 px-3 rounded min-w-10'}
        >
            {number}
        </button>
    ));
};

  useEffect(() => {
    getStaffStatistics();
  },[])

  useEffect(() => {
    fetchStaffs(currentPage, search);
  }, [currentPage, search]);

  return (
    <div className="main-container">
      <Header title="Staffs" subtitle="Staff Management" />
      <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-3 mb-10">
        <StatsCard
          
          title="Total Staff"
          text={statistics?.all_staff}
        />
        <StatsCard
          
          title="Active Staff"
          text={statistics?.active_staff}
        />
        <StatsCard
          
          title="Inactive Staff"
          text={statistics?.inactive_staff}
        />
        <StatsCard
          
          title="Male Staff"
          text={statistics?.male_staff}
        />
        <StatsCard
          
          title="Female Staff"
          text={statistics?.female_staff}
        />
      </div>
      <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-5">
        <div className="flex flex-col lg:flex-row lg:items-center gap-2">
          <input
            type="text"
            placeholder="Search staff"
            className="px-[10px] py-2 border border-gray-300 outline-none text-[12px]"
            onChange={(e) => filterstaffList(e.target.value)}
          />

          {/* <div className="flex items-center gap-5 w-full">
            
              <select
                name="school_class"
                id=""
                onChange={(e) => filterstaffListByType(e.target.value)}
                className="border border-gray-300 px-3 py-2 text-[12px] w-full"
              >
                <option value="">Select Staff Type</option>
                <option value="Teaching Staff">Teaching Staff</option>
                <option value="Non-Teaching Staff">Non-Teaching Staff</option>
              </select>
            
          </div> */}

          {/* <div className="flex items-center gap-5 w-full">
            
              <select
                name="school_class"
                id=""
                onChange={(e) => filterstaffListByStatus(e.target.value)}
                className="w-full border border-gray-300 px-3 py-2 text-[12px]"
              >
                <option value="">Select status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            
          </div> */}
        </div>
        {
          user?.account_type === "School Administrator" && (
            <div className="flex">
              <Link to="/admin/staffs/add-multiple-staff" className="px-[10px] py-2 text-[12px] text-light-primary border border-light-primary">
                Upload Multiple Staff
              </Link>
              <Link to="/admin/staffs/add-staff" className="px-[10px] py-2 text-[12px] border border-light-primary bg-light-primary text-white ">
                Create Staff
              </Link>
              <Link to="/admin/staffs/staff-position" className="px-[10px] py-2 text-[12px] text-light-primary border border-light-primary">
                Staff Position
              </Link>
            </div>
          )
        }

      </div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light text-surface ">
              <thead className=" w-screen  font-medium">
                <tr className="border-b border-t border-slate-200 text-black">
                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Staff
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Gender
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Staff Type
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Phone Number
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Email
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Date Registered
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Status
                  </th>


                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {staffs?.map((staff, index) => (
                  <tr key={index} className="text-[12px] border-l-2 border-transparent py-3 hover:border-primary hover:bg-slate-50 ">
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      <p className="text-[13px]">
                      {staff?.user_account?.first_name} {staff?.user_account?.last_name}
                      </p>
                      <p className="font-[500]">
                        {staff?.staff_school_id}
                      </p>
                    </td>
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      {staff?.gender}
                    </td>
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      <p>
                        {staff?.staff_type}
                      </p>
                      <p>
                        {staff?.position}
                      </p>
                    </td>
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">{staff?.phone_number}</td>
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      {staff?.user_account?.email}
                    </td>

                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      {staff?.date_registered}
                    </td>

                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      {
                        staff?.status === "Active" && (
                          <div className="bg-green-100 text-green-600 font-bold px-[8px] py-1 text-center text-[11px]">
                            {staff?.status}
                          </div>
                        )
                      }
                      {
                        staff?.status === "Inactive" && (
                          <div className="bg-red-100 text-red-600 px-[8px] py-1 text-[11px] text-center">
                            {staff?.status}
                          </div>
                        )
                      }
                    </td>

                    <td className="px-4 py-3">
                      <Link
                        to={`/admin/staff/${staff?.staff_id}/`}
                        className="text-[12px] w-max px-[10px] py-2 font-[500] border border-blue-400 text-blue-400  hover:bg-blue-400 hover:text-white cursor-pointer"
                      >
                         Details
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadingStaffs && (
              <div className="flex items-center justify-center py-5">
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
            {
              !loadingStaffs && staffs.length == 0 && (
                <p className="text-surface text-gray-400 py-10 text-center">
                  No staff found.
                </p>
              )
            }
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-10">
        <p>
          Page {currentPage} of {totalPages}
        </p>

        <div className="flex flex-col gap-2 items-start md:px-5 md:flex-row">
          <button className={`border border-primary py-2 px-4 ${!prevPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handlePreviousPage} disabled={!prevPage}>
            Previous
          </button>
          {renderPageNumbers()}
          <button className={`border border-primary py-2 px-4 ${!nextPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handleNextPage} disabled={!nextPage}>
            Next
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default StaffList;
