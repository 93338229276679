import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import NoDataImage from "../../assets/no_data.jpg"
import axios from 'axios';
import { API_URL } from '../../url';
import { Link } from 'react-router-dom';
import { useAuthenticate } from '../../context/AuthContext';
import VehicleDetails from './VehicleDetails';
import AddVehicle from './AddVehicle';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const Vehicles = () => {
    const [vehicles, setVehicles] = useState([])
    const [loadingVehicles, setLoadingVehicles] = useState(true)
    const [selectedVehicle, setSelectedVehicle] = useState(null)
    const {user} = useAuthenticate()
    const [modal, setModal] = useState(false);
    const [addVehicleModal, setAddVehicleModal] = useState(false);

    const toggleModal = (details) => {
        setSelectedVehicle(details)
        setModal(!modal)
    }

    const columns = [
        { field: 'vehicle_number', headerName: 'Vehicle Number', width: 150 },
        { field: 'vehicle_model', headerName: 'Model', width: 120 },
        { field: 'vehicle_identification_number', headerName: 'Identification Number', width: 200 },
        { field: 'seat_capacity', headerName: 'Seat Capacity', width: 130 },
        { field: 'driver', headerName: 'Driver', width: 250, 
            renderCell: (params) => {
                return (
                    <>
                      {
                        params.row.driver ? (
                          <div>
                            <p>
                                <b>Name:</b>{params.row.driver.first_name} {params.row.driver.last_name}
                            </p>
                            <p>
                                <b>Phone:</b> {params.row.driver.phone_number}
                            </p>
                          </div>
            
                        ):(
                          "No Assigned Yet"
                        )
                      }
                    </>
                  )
            }
        },
        { 
          field: 'action', headerName: 'Actions', width: 200,
          renderCell: (params) => {
            return (
              <>
                {user.account_type === "School Administrator" && (
                  <div className='cellAction'>
                    <div className='viewButton'>
                    
                      <div onClick={()=>toggleModal(params.row)}>
                        Edit
                      </div>
                    </div>
                    <div className='deleteButton'>
                    
                      <div onClick={()=>deleteVehicle(params.row.id)}>
                        Delete
                      </div>
                    </div>
                    
                  </div>
                )}
              </>
            )
          }
        },
        
    ];
    
    const deleteVehicle = (id) => {
        Swal.fire({
          title: `Are you sure?`,
          text: "This action is not reversible",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
    
            if(localStorage.getItem('access')){
              const config={
                headers:{
                  'Content-Type': `application/json`,
                  'Authorization':`JWT ${localStorage.getItem('access')}`,
                  'Accept':'application/json'
                }
              }
              
        
              try{
                axios.delete(`${API_URL}transport/vehicle/${id}`, config)
                Swal.fire(
                  'Deleted!',
                  'Vehicle has been deleted.',
                  'success'
                ).then(()=>{
                  window.location.reload()
                }
                )
                
              }catch(error){
                
             
                toast.error("Something went wrong. Please try again")
                
              }
    
            }
            
          }
        })
      }

    useEffect(() => {
        const fetchVehicles = async () =>{
            if(localStorage.getItem('access')){
                setLoadingVehicles(true);
              const config={
                headers:{
                  'content-type': 'application/json',
                  'Authorization': `JWT ${localStorage.getItem('access')}`,
                }
              };
              try{
                const res = await axios.get(`${API_URL}transport/vehicles/`, config);
                setLoadingVehicles(false)
                setVehicles(res.data);
              }catch(error){
                if(error.response.status === 401){
                  window.location.href = "/"
                }
              }
            }
          }
          fetchVehicles();
    },[])
  return (
    <div className="main-container">
        <div className="header">
            <h1>
                VEHICLES
            </h1>
        </div>
        {
            addVehicleModal && (
                <AddVehicle modal={modal} setModal={setModal} />
            )
        }
        {
            modal && (
                <VehicleDetails vehicleDetails={selectedVehicle} modal={modal} setModal={setModal} />
            )
        }
        {
            !vehicles.length > 0 ? (
                <div>
                    <div onClick={()=>setAddVehicleModal(!addVehicleModal)} className="classic-button" style={{marginBottom:"10px"}}>
                        Add New Vehicle
                    </div>
                    <DataGrid
                        rows = {vehicles}
                        loading = {loadingVehicles}
                        columns = {columns}
                        initialState={{
                            pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        getRowId={(row) => row.id}
                        components={{ Toolbar: GridToolbar }}
                    />
                </div>
            ):(
                <React.Fragment>
                {
                    loadingVehicles ? (
                        "Loading..."
                    ):(
                    <div className="no-data">
                            <img src={NoDataImage} alt="no data" />
                            <p>
                                No Vehicles Found.
                            </p>
                            <div onClick={()=>setAddVehicleModal(!addVehicleModal)} className="classic-button" style={{marginBottom:"10px"}}>
                                Add New Vehicle
                            </div>
                    </div>
                    )
                }
                </React.Fragment>
            )
        }
    </div>
  )
}

export default Vehicles