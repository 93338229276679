import React, { useState} from 'react'
import './Topbar.scss'
import SchoolLogo from '../../assets/logos/default-logo.png'
import { Link } from 'react-router-dom'
import {AiOutlineUser} from 'react-icons/ai'
import {IoMdSettings,IoIosArrowDropdown} from 'react-icons/io'
import { RxExit } from 'react-icons/rx'
import { useDispatch } from 'react-redux'
import { logout } from '../../actions/auth'
import { useAuthenticate } from '../../context/AuthContext'
import meskuuSmall from "../../assets/logos/meskuu-min.png"
import userIcon from "../../assets/avatar.jpg"
import { API_URL, img_base_url } from '../../url'





const Topbar = ({icon}) => {
    
    const dispatch = useDispatch();
    const logoutUser = (e) => {
        dispatch(logout())
        window.location.href="/"
    }
    const { user,schoolInfo } = useAuthenticate();
    const [dropdown, setDropdown] = useState(false)
  return (
    <>
        <div id='topbar'>
            <div className="school-details">
                <div className="bars">
                    {icon}
                </div>
                {
                    user?.account_type !== "Master" && (
                        <div className="school-logo">
                            {
                                schoolInfo.school_logo ? (
                                    <img src={`${img_base_url}${schoolInfo.school_logo}`} alt="" />
                                ):(
                                    <img style={{height: "30px"}} src={meskuuSmall} alt="" />
                                )
                            }
                            
                        </div>
                    )
                }
                <div className="school-name-position">
                    <h3 className="font-[500]">
                        {schoolInfo.school_name ? schoolInfo.school_name : "Meskuu School Management Software"}
                    </h3>
                    <p className='school-motto'>
                        {schoolInfo.school_motto}
                    </p>
                    
                </div>
            </div>
            
            <div className='user-section'>
                <div className='user-image '>
                    <img src={user.profile_picture ? `${img_base_url}${user.profile_picture}` :  userIcon} alt="" className='object-cover border border-gray-300' />
                </div>
                <div className="details">
                    <Link to="">
                        <p className='username'>{user.first_name} {user.last_name}</p>
                    </Link>
                    <p className='email text-red-500'>
                        {user.position}
                    </p>
                </div>
                <div>
                    <IoIosArrowDropdown onClick={()=>setDropdown(!dropdown)} className="dropdown-arrow"/>
                </div>
                {
                    dropdown && (
                    <div className="dropdown">
                        <ul>
                            <li>
                                
                                <AiOutlineUser />
                                <Link to="/profile" onClick={e => setDropdown(!dropdown)}>
                                    Profile
                                </Link>
                                
                            </li>
                            {/* {
                                user?.account_type === "School Administrator" && (

                                    <li>
                                        <IoMdSettings />
                                        <Link to="settings/subscription">
                                            Subscription
                                        </Link>
                                    </li>
                                )
                            } */}
                            <li onClick={e => logoutUser(e)} style={{color: "red"}}>
                                <RxExit />
                                Logout
                            </li>
                        </ul>
                    </div>
                    )
                }
            </div>
            
        </div>
    </>
  )
}

export default Topbar