import React, { useState, useRef } from "react";
import NoDataImage from "../../assets/no_data.jpg";
import "./Fees.scss";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import ReactToPrint from "react-to-print";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const FeesDebtors = () => {
  const { academicYears, schoolClasses } = useAuthenticate();
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [academicTerms, setAcademicTerms] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedAcademicTerm, setSelectedAcademicTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [debtorsList, setDebtorsList] = useState([]);
  const [sendingReminder, setSendingReminder] = useState(false);

  const debtorsRef = useRef();

  // CHANGE ACADEMIC YEAR WITH TERM
  const changeAcademicYear = (e) => {
    setSelectedAcademicYear(e.target.value);
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  const getDebtorsList = async (e) => {
    e.preventDefault();
    if (
      selectedAcademicTerm === "" ||
      selectedAcademicYear === "" ||
      selectedClass === ""
    ) {
      toast.error("Please select all fields");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}finance/fee-debtors/${selectedClass}/${selectedAcademicYear}/${selectedAcademicTerm}/`,
            config
          );

          setDebtorsList(res.data);
          setDataFetched(true);
          setLoading(false);
        } catch (error) {
          toast.error("An unexpected error occurred");
          setLoading(false);
        }
      }
    }
  };

  const sendDebtorRemainder = async (student_fee_id) => {
    setSendingReminder(true);
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      await axios.get(
        `${API_URL}finance/fees-debt/${student_fee_id}/send-reminder/`,config).then((response)=>{
          console.log(response)
          Swal.fire({
            "icon":response?.data?.icon || "success",
            "title":response?.data?.title || "Success",
            "text":response?.data?.text || "Message Sent",
          })
          setSendingReminder(false)
        }).catch((error)=>{
          toast.error("Something went wrong")
          setSendingReminder(false)
        }) 
    }
  }

  return (
    <div className="main-container">
      <Header title="Fees Debtors List" subtitle="Students with fees arrears" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => getDebtorsList(e)}>
            <div className="formInput">
              <select
                name=""
                id=""
                onChange={(e) => setSelectedClass(e.target.value)}
              >
                <option value="">Select Class</option>
                {schoolClasses.map((school_class) => (
                  <option value={school_class.id} key={school_class.id}>
                    {school_class.class_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="formInput">
              <select
                name="academic_year"
                id="academic_year"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* ACADEMIC TERMS DROPDOWN */}
            <div className="formInput">
              <select
                name="term"
                id="academic_term"
                onChange={(e) => setSelectedAcademicTerm(e.target.value)}
              >
                {selectedAcademicYear === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            {loading ? (
              <div className="loading-btn">
                <button
                  style={{
                    backgroundColor: "gray",
                    cursor: "wait",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    color: "white",
                    padding: "15px",
                    border: "none",
                  }}
                >
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />{" "}
                  Getting Data...{" "}
                </button>
              </div>
            ) : (
              <button type="submit">Get Debtors List</button>
            )}
          </form>
        </div>
      </div>
      {/* {
        dataFetched && (
            <React.Fragment>
              {
                debtorsList.length > 0 ? (
                  <div className="debtors-list">
                      <ReactToPrint
                        trigger={()=>(
                          <div className="print-button">
                            Print List
                          </div>
                        )}
                        documentTitle="Debtors List"
                        bodyClass="debtors-table debtor debtor-name debtor-id total-amount amount-paid outstanding-balance"
                        content = {()=> debtorsRef.current}
                      />
                      <table style={{borderCollapse:"collapse"}} className="debtors-table" ref={debtorsRef}>
                        <thead style={{backgroundColor: "#EEF8FF", border: "1px solid #9FA7B5"}}>
                            <tr>
                            <th style={{padding:"14px 5px", color: "#045A8D", fontWeight:"bold", textAlign:"left"}}>No.</th>
                            <th style={{padding:"14px 5px", color: "#045A8D", fontWeight:"bold", textAlign:"left"}}>STUDENT NAME</th>
                            <th style={{padding:"14px 5px", color: "#045A8D", fontWeight:"bold", textAlign:"right"}}>TOTAL AMOUNT</th>
                            <th style={{padding:"14px 5px", color: "#045A8D", fontWeight:"bold", textAlign:"right"}}>AMOUNT PAID</th>
                            <th style={{padding:"14px 5px", color: "#045A8D", fontWeight:"bold", textAlign:"right"}}>OUTSTANDING BALANCE</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            debtorsList.map((debtor, index) => (
                              <tr style={{border:"1px solid #9FA7B5"}} key={index}>
                                <td style={{padding: "14px 5px", fontWeight:"bold"}}>{index+1}.</td>
                                <td style={{padding: "14px 5px", fontWeight:"bold"}} className="debtor">
                                    <p className="debtor-name">{debtor?.student?.student_name}</p>
                                    <p className="debtor-id" style={{fontWeight: 400}}>{debtor?.student?.student_school_id}</p>
                                </td>
                                <td style={{padding: "14px 5px", fontWeight:"bold", textAlign:"right"}} className="total-amount">{debtor.total_fees_amount}</td>
                                <td style={{padding: "14px 5px", fontWeight:"bold", textAlign:"right"}} className="amount-paid">{debtor.amount_paid}</td>
                                <td style={{padding: "14px 5px", fontWeight:"bold", textAlign:"right"}} className="outstanding-balance">{debtor.outstanding_balance}</td>
                              </tr>

                            ))
                          }
                        </tbody>
                      </table>
                    

                  </div>
                ):(
                  <div className="no-data">
                    <img src={NoDataImage} alt="no data" />
                    <p>
                      No Fee Debtors Found
                    </p>
                  </div>
                )
              }
            </React.Fragment>
        )
      } */}
      {
        dataFetched && (
          <div className="overflow-x-auto">
            <table className="mt-5 min-w-full text-left text-sm font-light text-surface ">
              <thead className=" w-screen  font-medium">
                <tr className="border-b border-t border-slate-200 text-black bg-slate-200">
                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Student
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Student Class
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Total Amount
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Amount Paid
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Outstanding Balance
                  </th>


                  <th scope="col" className="px-6 py-2 font-[500] text-[12px]">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {debtorsList?.map((debtor, index) => (
                  <tr
                    key={index}
                    className="text-[12px] border-l-2 border-transparent py-3 hover:border-primary hover:bg-slate-50 "
                  >
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      <p className="">{debtor?.student?.student_name}</p>
                      <p className="">{debtor?.student?.student_school_id}</p>
                    </td>
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      {debtor?.student_class}
                    </td>
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      {debtor.total_fees_amount}
                    </td>
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      
                      {debtor.amount_paid}
                    </td>
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      {debtor.outstanding_balance}
                    </td>

                    <td className="px-2 py-3">
                      {
                        sendingReminder ? (
                          <RotatingLines
                            strokeColor="green"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={true}
                          />
                        ):(
                          <div
                            onClick={()=>sendDebtorRemainder(debtor?.id)}
                            className=" text-[12px] w-max px-[10px] py-2 font-[500] border border-blue-400 text-blue-400  hover:bg-blue-400 hover:text-white cursor-pointer"
                          >
                            Send Reminder
                          </div>
                        )
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      }
    </div>
  );
};

export default FeesDebtors;
