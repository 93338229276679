import Header from '../../components/Header'
import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";

const NewSchoolAdministrator = () => {
    const [creatingAdmin, setCreatingAdmin] = useState(false)
    const [data, setData] = useState({})

    const updateData = (e) => {
        setData({
          ...data, [e.target.name] : e.target.value
        })
      }

    const createAdmin = async (e) => {
        e.preventDefault()
        setCreatingAdmin(true)
        const config = {
          headers:{
            "Content-Type": "application/json",
            Authorization : `JWT ${localStorage.getItem("access")}`
          }
        }
    
        const body =JSON.stringify(data)
    
        await axios.post(`${API_URL}administrator/administrators/`,body, config).then((response)=>{
          setCreatingAdmin(false)
          if(response.data){
            Swal.fire({
              title:"Account Created",
              text:"School Administrator Created Successfully",
              icon:"success",
            }).then(()=>{
              window.location.reload();
            })
          }else{
            toast.error(response?.data?.message)
            if(response?.data?.error){
              for (const property in response?.data?.errors) {
                if (property === "0") {
                  toast.error(`${response?.data?.errors[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${response.data?.errors[property]}`
                  );
                }
              }
            }
          }
        }).catch((error)=>{
          setCreatingAdmin(false)
          toast.error("Something went wrong. Please try again")
        })
      }
    
  return (
    <div className="main-container">
      <Header title="New School Administrator" subtitle="Create New School Administrator" />
      <div>
        <form onSubmit={(e)=>createAdmin(e)}>
          

          <div className="py-5 border-t border-gray-400 mt-5">
            
            <div className="grid lg:grid-cols-2 gap-3">
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-[500]">
                  First Name*
                </label>
                <input
                required
                  type="text"
                  name="first_name"
                  onChange={(e)=>updateData(e)}
                  className="border border-slate-400 rounded py-3 px-2"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-[500]">
                  Last Name*
                </label>
                <input
                required
                  type="text"
                  name="last_name"
                  onChange={(e)=>updateData(e)}
                  className="border border-slate-400 rounded py-3 px-2"
                />
              </div>
              <div className='flex flex-col gap-1'>
                <label htmlFor="" className='font-[500]'>Email Address*</label>
                <input type="text" onChange={(e)=>updateData(e)} name='user_email' required className='border border-slate-400 rounded py-3 px-2' />
              </div>
              <div className='flex flex-col gap-1'>
                <label htmlFor="" className='font-[500]'>Phone Number*</label>
                <input type="text" required onChange={(e)=>updateData(e)} name='phone_number' className='border border-slate-400 rounded py-3 px-2' />
              </div>
              <div className='flex flex-col gap-1'>
                <label htmlFor="" className='font-[500]'>Username*</label>
                <input type="text" onChange={(e)=>updateData(e)} name='username' required className='border border-slate-400 rounded py-3 px-2' />
              </div>
              <div className='flex flex-col gap-1'>
                <label htmlFor="" className='font-[500]'>Password*</label>
                <input type="text" required onChange={(e)=>updateData(e)} name='password' className='border border-slate-400 rounded py-3 px-2' />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            {
              creatingAdmin ? (
                <div className="flex items-center gap-1 bg-blue-200 cursor-wait px-5 py-3 rounded">
                  <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#055", "#055", "#055", "#055", "#055"]}
                /> Creating Admin
                </div>
              ):<button type="submit" className="bg-blue-500 text-white px-5 py-3 rounded">Create Admin</button>
            }
            
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewSchoolAdministrator