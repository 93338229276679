import axios from 'axios';
import React, {useState} from 'react'
import { ColorRing } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useAuthenticate } from '../../context/AuthContext'
import { API_URL } from '../../url';

const AddVehicle = ({modal, setModal}) => {
    const [adding, setAdding] = useState(false)
    const {staffs} = useAuthenticate();
    const [data, setData] = useState({})

    const changeData = (e) => {
        setData({
          ...data,
          [e.target.name]: e.target.value,
        });
      };

    const addVehicle = async(e)=>{
        e.preventDefault()
        if (!adding) {
      
            if (localStorage.getItem("access")) {
              setAdding(true);
              const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${localStorage.getItem("access")}`,
                },
              };
      
              const body = JSON.stringify(data);
              try {
                await axios
                  .post(`${API_URL}transport/vehicles/`, body, config)
                  .then((response) => {
                    setAdding(false);
                    toast.success("Vehicle added successfully");
                    window.location.reload();
                  });
              } catch (error) {
                setAdding(false);
                toast.error("Something went wrong please try again")
              }
            }
          }
    }

    return (
    <div className="modal">
      <div className="overlay" onClick={() => setModal(!modal)}>
        <div className="modal-content" onClick={(e)=>e.stopPropagation(e)}>
            <div onClick={() => setModal(!modal)} className="modal-close-btn">
            x
            </div>
            <div className="modal-header" style={{ fontWeight: "bold" }}>
            {" "}
            Add New Vehicle
            </div>
            <form onSubmit={(e) => addVehicle(e)}>
            <div className="formSection">
                <div className="formRow">
                {/* VEHICLE NUMBER */}
                <div className="formInput">
                    <label htmlFor="">Vehicle Number</label>
                    <input
                    type="text"
                    name="vehicle_number"
                    onChange={(e) => changeData(e)}
                    />
                </div>

                {/* VEHICLE MODEL */}
                <div className="formInput">
                    <label htmlFor="">Vehicle Model</label>
                    <input
                    type="text"
                    name="vehicle_model"
                    
                    onChange={(e) => changeData(e)}
                    />
                </div>
                </div>
                <div className="formRow">
                {/* VEHICLE IDENTIFICATION NUMBER */}
                <div className="formInput">
                    <label htmlFor="">Vehicle Identification Number</label>
                    <input
                    type="text"
                    name="vehicle_identification_number"
                    onChange={(e) => changeData(e)}
                    />
                </div>

                <div className="formInput">
                    <label htmlFor="">Seat Capacity</label>
                    <input
                    type="number"
                    name="seat_capacity"
                    onChange={(e) => changeData(e)}
                    />
                </div>
                </div>

                <div className="formRow">
                {/* DRIVER */}
                <div className="formInput">
                    <label htmlFor="">Driver</label>
                    <select onChange={(e) => changeData(e)} name="driver" id="driver">
                        <option value=""></option>
                        {
                            staffs.map((staff) => (
                                <>
                                    {
                                        staff.position === "Driver" && (
                                            <option key={staff.staff_id} value={staff.staff_id}>
                                                {staff?.first_name} {staff?.last_name}
                                            </option>
                                        )
                                    }
                                </>
                            ))
                        }
                        
                    </select>
                    
                    
                    
                </div>
                {/* ROUTE */}
                <div className="formInput">
                    <label htmlFor="">Route:</label>
                    <textarea
                    onChange={(e) => changeData(e)}
                    name="route"
                    id="route"
                    cols="30"
                    rows="10"
                    ></textarea>
                </div>
                </div>
                {adding ? (
                <button style={{ cursor: "wait", backgroundColor: "gray" }}>
                    <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                    />
                    Adding...
                </button>
                ) : (
                <button type="submit">Add</button>
                )}
            </div>
            </form>
        </div>
      </div>
    </div>
  )
}

export default AddVehicle