import React from 'react'
import { useState } from 'react'
import {HiOutlineIdentification} from "react-icons/hi2"
import './Graduation.scss'
import Header from '../../components/Header'

const GraduateDetails = () => {
    const [activeMainTab, setActiveMainTab] = useState(1)
    return (
    <div className='main-container'>
        <Header title="Student Graduation" subtitle="Graduate Students" />
        <div className="graduate-details">
            <div className="graduate-basic-details">
                <div className="graduate-details-top">
                    <div className="graduate-basic-details-left">
                        <div className="graduate-profile-picture">
                            <img src="https://res.cloudinary.com/dbhrst5cz/image/upload/v1/media/sch_C23BBABBAC/students/789501979073/0239_do3j40" alt="" />
                        </div>
                        <div className="graduate-personal-info">
                            <div className="graduate-name">
                                Michael Adams
                            </div>
                            
                            <div className="graduate-school-id">
                                <HiOutlineIdentification />
                                <span>C&A/001/0482</span>
                            </div>

                            <div className="green-badge">
                                Graduate
                            </div>
                            
                        </div>
                    </div>
                    <div className="graduate-basic-details-left">
                        <div className="classic-button-outline">
                            Reset Password
                        </div>
                        <div className="remove-button">
                            Delete Account
                        </div>
                    </div>
                </div>
                <div className="graduate-details-tabs">
                    <div className={`${activeMainTab === 1 ? "graduate-details-tab graduate-details-tab-active" : "graduate-details-tab"}`} onClick={()=>setActiveMainTab(1)}>
                        Personal Details
                    </div>
                    <div className={`${activeMainTab === 2 ? "graduate-details-tab graduate-details-tab-active" : "graduate-details-tab"}`} onClick={()=>setActiveMainTab(2)}>
                        Fees & Fee Payments
                    </div>
                    <div className={`${activeMainTab === 3 ? "graduate-details-tab graduate-details-tab-active" : "graduate-details-tab"}`} onClick={()=>setActiveMainTab(3)}>
                        Academic Reports
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GraduateDetails