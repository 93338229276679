import React, { useState, useRef } from "react";
import NoDataImage from "../../assets/no_data.jpg"
import "./Class.scss";
import AddClassScheduleModal from "./AddClassScheduleModal";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";
import {MdDelete} from 'react-icons/md'
import ReactToPrint from "react-to-print";
import Header from "../../components/Header";

const ClassSchedule = () => {
  const scheduleRef = useRef()
  const { academicYears, schoolClasses, user } = useAuthenticate();
  const [academicTerms, setAcademicTerms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    term: "",
    school_class: "",
    academic_year: "",
  });
  const [classSchedules, setClassSchedules] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [dataFetched, setDataFetched] = useState(false);

  // TOGGLE TABS
  const updateToggle = (tab) => {
    setToggle(tab);
  };

  // UPDATE SUBMISSION DATA
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // CHANGE ACADEMIC YEARS
  const changeAcademicYear = (e) => {
    setData({
      ...data,
      academic_year: e.target.value,
    });

    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  // GET CLASS SCHEDULES
  const getClassSchedule = async () => {
    if (data.term === "" || data.school_class === "") {
      toast.error("Please select all required fiedls");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-schedule/${data.school_class}/${data.term}/`,
            config
          );
          setClassSchedules(res.data);
          setLoading(false);
          setDataFetched(true);
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if(error.response.status === 401){
              window.location.href="/"
            }else{
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  // DELETE CLASS SCHEDULE
  const deleteClassSchedule = (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          try {
            axios
              .delete(`${API_URL}academics/class-schedule/${id}/`, config)
              .then((response) => {
                Swal.fire(
                  "Deleted!",
                  "Class Schedule has been deleted.",
                  "success"
                ).then(() => {
                  getClassSchedule();
                });
              });
          } catch (error) {
            if (error.response) {
              if(error.response.status === 401){
                window.location.href="/"
              }else{
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getClassSchedule();
  };

  return (
    
      <div className="main-container">
        <Header title="Class Schedules" subtitle="Manage Class Schedules" />
        <div className="positions-container">
          <div className="tabs">
            <div
              onClick={() => {
                updateToggle(1);
              }}
              className={toggle === 1 ? "active-tab" : "tab"}
            >
              Class Schedules
            </div>
            {user?.account_type === "School Administrator" && (
              <div
              onClick={() => {
                updateToggle(2);
              }}
              className={toggle === 2 ? "active-tab" : "tab"}
            >
              Add Class Schedules
            </div>
            )}
            {user?.account_type === "School Staff" && user?.position === "Teacher" && (
              <div
              onClick={() => {
                updateToggle(2);
              }}
              className={toggle === 2 ? "active-tab" : "tab"}
            >
              Add Class Schedules
            </div>
            )}
            
          </div>
          <div className={toggle === 1 ? "show-content" : "content"}>
            <div style={{ margin: "25px" }} className="schedule-body">
              <div className="filter-columns">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="formInput">
                    <select
                      name="school_class"
                      required
                      onChange={(e) => handleChange(e)}
                    >
                      <option>Select Class*</option>
                      {schoolClasses.map((schoolClass) => {
                        return (
                          <option value={schoolClass.id} key={schoolClass.id}>
                            {schoolClass.class_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="formInput">
                    <select
                      required
                      name="academic_year"
                      id="academic_term"
                      onChange={(e) => changeAcademicYear(e)}
                    >
                      <option value="">Select Academic Year*</option>
                      {academicYears.map((academicYear) => {
                        return (
                          <option value={academicYear.id} key={academicYear.id}>
                            {academicYear.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="formInput">
                    <select
                      name="term"
                      required
                      onChange={(e) => handleChange(e)}
                    >
                      {data.academic_year === "" ? (
                        <option value="">Select Academic Year First</option>
                      ) : (
                        <>
                          <option value="">Academic Term*</option>
                          {academicTerms.map((academicTerm) => {
                            return (
                              <option
                                value={academicTerm.id}
                                key={academicTerm.id}
                              >
                                {academicTerm.name}
                              </option>
                            );
                          })}
                        </>
                      )}
                    </select>
                  </div>
                  {loading ? (
                    <button
                      style={{
                        backgroundColor: "gray",
                        cursor: "wait",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "10px",
                        color: "white",
                        border: "none",
                      }}
                    >
                      <ColorRing
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />{" "}
                      Getting Data{" "}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      type="submit"
                    >
                      Get Schedule
                    </button>
                  )}
                </form>
              </div>
              {!dataFetched ? (
                <>
                  <p style={{ textAlign: "center", marginTop: "20px" }}>
                    Please Select Class And Term
                  </p>
                </>
              ) : (
                <>
                  {classSchedules.length > 0 ? (
                    <div className="schedule">
                      {/* <ReactToPrint
                        trigger={() => (
                          <button className="export-button">
                            Export To PDF
                          </button>
                        )}
                        content={()=> scheduleRef.current}
                        documentTitle = "Class Time Table"
                        /> */}
                      <div className="schedule-table" ref={scheduleRef}>
                        <div className="day-schedule">
                          <div className="day">Monday</div>
                          {classSchedules.map((schedule) => {
                            return (
                              <div key={schedule.id}>
                                {schedule.day === "Monday" && (
                                  <div className="schedule-details">
                                    <div className="left">

                                      <p>
                                        {schedule.start_time} -{" "}
                                        {schedule.end_time}
                                      </p>
                                      <b>{schedule.activity}</b>
                                      <b>{schedule.subject}</b>
                                      <p>{schedule.teacher}</p>
                                      {schedule.is_break && (
                                        <>
                                          <br />
                                          <p>
                                            <b>BREAK TIME</b>
                                          </p>
                                        </>
                                      )}
                                    </div>
                                    {
                                      user?.account_type === "School Administrator" && (
                                      <div className="right">
                                        <MdDelete onClick={()=>deleteClassSchedule(`${schedule.id}`)} />
                                      </div>
                                      )
                                    }
                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div className="day-schedule">
                          <div className="day">Tuesday</div>
                          {classSchedules.map((schedule) => {
                            return (
                              <div key={schedule.id}>
                                {schedule.day === "Tuesday" && (
                                  <div className="schedule-details">
                                    <div className="left">

                                      <p>
                                        {schedule.start_time} -{" "}
                                        {schedule.end_time}
                                      </p>
                                      <b>{schedule.subject}</b>
                                      <b>{schedule.activity}</b>
                                      <p>{schedule.teacher}</p>
                                      {schedule.is_break && (
                                        <>
                                          <br />
                                          <p>
                                            <b>BREAK TIME</b>
                                          </p>
                                        </>
                                      )}
                                    </div>
                                    {
                                      user?.account_type === "School Administrator" && (
                                      <div className="right">
                                        <MdDelete onClick={()=>deleteClassSchedule(`${schedule.id}`)} />
                                      </div>
                                      )
                                    }
                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div className="day-schedule">
                          <div className="day">Wednesday</div>

                          {classSchedules.map((schedule) => {
                            return (
                              <div key={schedule.id}>
                                {schedule.day === "Wednesday" && (
                                  <div className="schedule-details">
                                    <div className="left">
                                      <p>
                                        {schedule.start_time} -{" "}
                                        {schedule.end_time}
                                      </p>
                                      <b>{schedule.subject}</b>
                                      <b>{schedule.activity}</b>
                                      <p>{schedule.teacher}</p>
                                      {schedule.is_break && (
                                        <>
                                          <br />
                                          <p>
                                            <b>BREAK TIME</b>
                                          </p>
                                        </>
                                      )}
                                    </div>
                                    {
                                      user?.account_type === "School Administrator" && (
                                      <div className="right">
                                        <MdDelete onClick={()=>deleteClassSchedule(`${schedule.id}`)} />
                                      </div>
                                      )
                                    }
                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div className="day-schedule">
                          <div className="day">Thursday</div>
                          {classSchedules.map((schedule) => {
                            return (
                              <div key={schedule.id}>
                                {schedule.day === "Thursday" && (
                                  <div className="schedule-details">
                                    <div className="left">

                                      <p>
                                        {schedule.start_time} -{" "}
                                        {schedule.end_time}
                                      </p>
                                      <b>{schedule.subject}</b>
                                      <b>{schedule.activity}</b>
                                      <p>{schedule.teacher}</p>
                                      {schedule.is_break && (
                                        <>
                                          <br />
                                          <p>
                                            <b>BREAK TIME</b>
                                          </p>
                                        </>
                                      )}
                                    </div>
                                    {
                                      user?.account_type === "School Administrator" && (
                                      <div className="right">
                                        <MdDelete onClick={()=>deleteClassSchedule(`${schedule.id}`)} />
                                      </div>
                                      )
                                    }
                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div className="day-schedule">
                          <div className="day">Friday</div>
                          {classSchedules.map((schedule) => {
                            return (
                              <div key={schedule.id}>
                                {schedule.day === "Friday" && (
                                  <div
                                    key={schedule.id}
                                    className="schedule-details"
                                  >
                                    <div className="left">

                                      <p>
                                        {schedule.start_time} -{" "}
                                        {schedule.end_time}
                                      </p>
                                      <b>{schedule.subject}</b>
                                      <b>{schedule.activity}</b>
                                      <p>{schedule.teacher}</p>
                                      {schedule.is_break && (
                                        <>
                                          <br />
                                          <p>
                                            <b>BREAK TIME</b>
                                          </p>
                                        </>
                                      )}
                                    </div>
                                    {
                                      user?.account_type === "School Administrator" && (
                                      <div className="right">
                                        <MdDelete onClick={()=>deleteClassSchedule(`${schedule.id}`)} />
                                      </div>
                                      )
                                    }
                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="no-data">
                    <img src={NoDataImage} alt="no data" />
                    <p>
                      No Data Found
                    </p>
                  </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={toggle === 2 ? "show-content" : "content"}>
            <AddClassScheduleModal />
          </div>
        </div>
      </div>
    
  );
};

export default ClassSchedule;
