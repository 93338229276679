import React, { useState,useEffect } from 'react'
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Header from '../../components/Header';

const Graduates = () => {
    const UserImage = "https://epasmart.s3.eu-north-1.amazonaws.com/assets/user_image.svg"
    const [graduates, setGraduates] = useState([])
    const [loadingData, setLoadingData] = useState(false)

    const fetchGraduates = async () => {
        if (localStorage.getItem("access")) {
            setLoadingData(true)
            const config = {
              headers: {
                Authorization: `JWT ${localStorage.getItem("access")}`,
              },
            };
            await axios.get(
              `${API_URL}administrator/graduates/`,
              config
            ).then(response => {
              setGraduates(response.data)
              setLoadingData(false)
            }).catch((error)=>{
              if (error.response.status === 401) {
                window.location.href = "/";
              }
            })
            
          }
    }

    const deleteStudent = (student_id) => {
        Swal.fire({
          title: `Are you sure?`,
          text: "All information about this student will be lost. You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            if (localStorage.getItem("access")) {
              const config = {
                headers: {
                  "Content-Type": `application/json`,
                  Authorization: `JWT ${localStorage.getItem("access")}`,
                  Accept: "application/json",
                },
              };
    
              try {
                axios.delete(
                  `${API_URL}administrator/students/${student_id}`,
                  config
                );
                Swal.fire("Deleted!", "Student has been deleted.", "success").then(
                  () => {
                    window.location.reload();
                  }
                );
              } catch (error) {
                if (error.response) {
                  for (const property in error.response.data) {
                    toast.error(
                      `${property} error: ${error.response.data[property]}`
                    );
                  }
                } else if (error.message) {
                  toast.error("Something went wrong. Please try again");
                }
              }
            }
          }
        });
      };

    const columns = [
        {
          field: "profile_picture",
          headerName: "",
          width: 60,
          renderCell: (params) => {
            return (
              <>
                {params.row.profile_picture ? (
                  <img
                    style={{ height: "40px", borderRadius: "50%", width: "100%" }}
                    src={params.row.profile_picture}
                    alt=""
                  />
                ) : (
                  <img
                    src={UserImage}
                    style={{ height: "40px", borderRadius: "50%" }}
                    alt="user"
                  />
                )}
              </>
            );
          },
        },
        { field: "student_school_id", headerName: "Student ID", width: 200 },
        { field: "first_name", headerName: "First Name", width: 200 },
        { field: "last_name", headerName: "Last Name", width: 200 },
        { field: "gender", headerName: "Gender", width: 120 },
        { field: "graduation_badge", headerName: "Graduation Badge", width: 170 },
        {
          field: "action",
          headerName: "Actions",
          width: 170,
          renderCell: ({ id }) => {
            return (
              <div className="cellAction">
                <div className="viewButton">
                  <Link to={`/student/${id}`}>View</Link>
                </div>
                <div className="deleteButton" onClick={() => deleteStudent(id)}>
                  Delete
                </div>
              </div>
            );
          },
        },
      ];

    useEffect(()=>{
        fetchGraduates()
    }, [])
  return (
    <div className="main-container">
        <Header title="Graduated Students" subtitle="Alumni List" />
        <div style={{ height: `${loadingData ? 700 : "auto"}`, width: "100%" }}>
        <DataGrid
          rows={graduates}
          columns={columns}
          loading={loadingData}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20,30,40]}
          getRowId={(row) => row.student_id}
          components={{ Toolbar: GridToolbar }}
        />
      </div>
    </div>
  )
}

export default Graduates