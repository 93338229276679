import axios from "axios";
import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";
import { API_URL } from "../../url";

const VehicleDetails = ({ modal, setModal, vehicleDetails }) => {
    const {staffs} = useAuthenticate()
    const [updating, setUpdating] = useState(false)
    const [updateData, setUpdateData] = useState({})
    const changeUpdateData = (e) => {
        setUpdateData({
          ...updateData,
          [e.target.name]: e.target.value,
        });
      };
    

    const updateVehicle = async (e) => {
        e.preventDefault();
        if (!updating) {
      
            if (localStorage.getItem("access")) {
              setUpdating(true);
              const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${localStorage.getItem("access")}`,
                },
              };
      
              const body = JSON.stringify(updateData);
              console.log(body)
              try {
                await axios
                  .put(`${API_URL}transport/vehicle/${vehicleDetails.id}/`, body, config)
                  .then((response) => {
                    setUpdating(false);
                    toast.success("Vehicle Details updated successfully");
                    window.location.reload();
                  });
              } catch (error) {
                setUpdating(false);
                console.log(error)
                toast.error("Something went wrong please try again")
              }
            }
          }
    };
  return (
    <div className="modal">
      <div className="overlay" onClick={() => setModal(!modal)}>
        <div className="modal-content" onClick={(e)=>e.stopPropagation(e)}>
            <div onClick={() => setModal(!modal)} className="modal-close-btn">
            x
            </div>
            <div className="modal-header" style={{ fontWeight: "bold" }}>
            {" "}
            {vehicleDetails.vehicle_number} Details
            </div>
            <form onSubmit={(e) => updateVehicle(e)}>
            <div className="formSection">
                <div className="formRow">
                {/* VEHICLE NUMBER */}
                <div className="formInput">
                    <label htmlFor="">Vehicle Number</label>
                    <input
                    type="text"
                    name="vehicle_number"
                    value={
                        !updateData.vehicle_number
                        ? vehicleDetails.vehicle_number
                        : updateData.vehicle_number
                    }
                    onChange={(e) => changeUpdateData(e)}
                    />
                </div>

                {/* VEHICLE MODEL */}
                <div className="formInput">
                    <label htmlFor="">Vehicle Model</label>
                    <input
                    type="text"
                    name="vehicle_model"
                    value={
                        !updateData.vehicle_model
                        ? vehicleDetails.vehicle_model
                        : updateData.vehicle_model
                    }
                    onChange={(e) => changeUpdateData(e)}
                    />
                </div>
                </div>
                <div className="formRow">
                {/* VEHICLE IDENTIFICATION NUMBER */}
                <div className="formInput">
                    <label htmlFor="">Vehicle Identification Number</label>
                    <input
                    type="text"
                    name="vehicle_identification_number"
                    value={
                        !updateData.vehicle_identification_number
                        ? vehicleDetails.vehicle_identification_number
                        : updateData.vehicle_identification_number
                    }
                    onChange={(e) => changeUpdateData(e)}
                    />
                </div>

                <div className="formInput">
                    <label htmlFor="">Seat Capacity</label>
                    <input
                    type="number"
                    name="seat_capacity"
                    value={
                        !updateData.seat_capacity
                        ? vehicleDetails.seat_capacity
                        : updateData.seat_capacity
                    }
                    onChange={(e) => changeUpdateData(e)}
                    />
                </div>
                </div>

                <div className="formRow">
                {/* DRIVER */}
                <div className="formInput">
                    <label htmlFor="">Driver</label>
                    <select onChange={(e) => changeUpdateData(e)} name="driver" id="driver">
                        <option value=""></option>
                        {
                            staffs.map((staff) => (
                                <>
                                    {
                                        staff.position === "Driver" && (
                                            <option key={staff.staff_id} value={staff.staff_id}>
                                                {staff?.first_name} {staff?.last_name}
                                            </option>
                                        )
                                    }
                                </>
                            ))
                        }
                        
                    </select>
                    
                    
                    
                </div>
                {/* ROUTE */}
                <div className="formInput">
                    <label htmlFor="">route:</label>
                    <textarea
                    value={
                        !updateData.route
                        ? vehicleDetails.route
                        : updateData.route
                    }
                    onChange={(e) => changeUpdateData(e)}
                    name="route"
                    id="route"
                    cols="30"
                    rows="10"
                    ></textarea>
                </div>
                </div>
                {updating ? (
                <button style={{ cursor: "wait", backgroundColor: "gray" }}>
                    <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                    />
                    Updating...
                </button>
                ) : (
                <button type="submit">Update</button>
                )}
            </div>
            </form>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
