import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import Multiselect from "multiselect-react-dropdown";

const NewScheme = ({ setNewSchemeModal }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    academic_term: "",
    main_exam: "",
    sba_components: [],
    sba_scaling: 0,
    exam_scaling: 0,
  });
  const [assessmentTypes, setAssessmentTypes] = useState([]);
  const [selectedSBA, setSelectedSBA] = useState([]);
  const [academicTerms, setAcademicTerms] = useState([]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedSBAIds = [];
    for (let i = 0; i < selectedSBA.length; i++) {
      selectedSBAIds.push(selectedSBA[i].id);
    }
    if (parseInt(data.exam_scaling) + parseInt(data.sba_scaling) !== 100) {
      toast.error("SBA scaling and main exam scaling must sum up to 100");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        const body = JSON.stringify({
          term: data.term,
          sba_components: selectedSBAIds,
          main_exam: data.main_exam,
          sba_scaling: data.sba_scaling,
          exam_scaling: data.exam_scaling,
        });

        try {
          const res = await axios.post(
            `${API_URL}academics/report-scheme/`,
            body,
            config
          );
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Report Scheme Created Successfully",
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else {
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  const fetchAcademicTerms = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      await axios
        .get(`${API_URL}academics/academic-terms/`, config)
        .then((response) => {
          setAcademicTerms(response.data);
        })
        .catch((error) => {});
    }
  };

  const fetchAssessmentTypes = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/assessment-types/`,
          config
        );
        setAssessmentTypes(res.data);
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchAcademicTerms();
    fetchAssessmentTypes();
  },[]);

  return (
    <div
      id="modal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal"
    >
      <div class="bg-white rounded-lg shadow-lg lg:w-1/3 w-4/5 max-h-[70%] overflow-auto">
        <div class="px-6 py-4 border-b text-black items-center flex justify-between">
          <h3 class="text-lg  font-semibold">New Report Scheme</h3>
          <IoCloseCircleOutline
            onClick={() => setNewSchemeModal(false)}
            className="text-[20px] cursor-pointer"
          />
        </div>
        <div class="px-4">
          <div className="shadow mt-5 lg:mt-0 overflow-hidden sm:rounded-md bg-white">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="px-4 py-2 bg-white sm:p-6"
            >
              <div className="grid grid-cols-6 gap-2">
                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Term
                  </label>
                  <select
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                    name="term"
                    id=""
                    onChange={(e) => handleChange(e)}
                  >
                    <option>Select Term</option>
                    {academicTerms.map((term) => {
                      return (
                        <option value={term.id} key={term.id}>
                          {term.term_full_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    SBA Components*
                  </label>
                  <Multiselect
                    onSelect={(e) => setSelectedSBA(e)}
                    avoidHighlightFirstOption={true}
                    placeholder="Select SBA Components"
                    name="sba_components"
                    options={assessmentTypes} // Options to display in the dropdown
                    displayValue="name" // Property name to display in the dropdown options
                  />
                  <small>
                    This comprises of what will be added to form the class
                    assessment marks
                  </small>
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Main Exam*
                  </label>
                  <select
                    class="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                    name="main_exam"
                    onChange={(e) => handleChange(e)}
                  >
                    <option>Select Main Exam</option>
                    {assessmentTypes.map((type, index) => {
                      return (
                        <option value={type.id} key={index}>
                          {type.name}
                        </option>
                      );
                    })}
                  </select>
                  <small>
                    This option shouldn't be part of the SBA components selected
                  </small>
                  
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    SBA Scaling*
                  </label>

                  <input
                    type="number"
                    name="sba_scaling"
                    onChange={(e) => handleChange(e)}
                    required
                    class="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  />
                  <small>
                    This will be the scaling used for the sba components when
                    computing the main results
                  </small>
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Main Exam Scaling*
                  </label>

                  <input
                    type="number"
                    name="exam_scaling"
                    onChange={(e) => handleChange(e)}
                    required
                    class="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  />
                  <small>
                    This will be the scaling used for the main exams when
                    computing the main results for the student
                  </small>
                </div>
              </div>

              {loading ? (
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                <button
                  type="submit"
                  value="Save"
                  id="payouts-account-submit"
                  class="mt-2 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-600"
                >
                  Add Report Scheme
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewScheme;
