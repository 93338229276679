import React from "react";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../url";
import FormInput from "../../components/Forms/FormInput";
import { useAuthenticate } from "../../context/AuthContext";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import { AiOutlineUpload } from "react-icons/ai";
import Loading from "../../components/LoadingPage/Loading";
import { toast } from "react-toastify";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import UserImg from "../../assets/avatar.jpg";
import ResetPasswordModal from "../Staffs/ResetPasswordModal";

const StudentProfile = () => {
  const { id } = useParams();
  const { schoolClasses, user } = useAuthenticate();
  const [student, setStudent] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [updating, setUpdating] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [passwordData, setPasswordData] = useState({
    new_password: "",
    confirm_password: "",
  });

  // TOGGLE CHANGE PASSWORD MODAL
  const toggleModal = () => {
    setModal(!modal);
  };

  // FETCH STUDENT PROFILE
  const fetchStudent = async () => {
    if (localStorage.getItem("access")) {
      setLoadingData(true);
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}administrator/students/${id}`,
          config
        );
        setStudent(res.data);
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
        if (error.response.status === 401) {
          window.location.href = "/";
        } else if (error.response.status === 500) {
          toast.error("Something went wrong");
        } else {
          for (const property in error.response.data) {
            if (property === "0") {
              toast.error(`${error.response.data[property]}`);
            } else {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          }
        }
      }
    }
  };

  // CHANGE STUDENT PASSWORD
  const changePassword = async (e) => {
    e.preventDefault();
    if (!updating) {
      if (passwordData.new_password !== passwordData.confirm_password) {
        toast.error("The two passwords do not match");
      } else {
        if (localStorage.getItem("access")) {
          setUpdating(true);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          const body = JSON.stringify(passwordData);
          try {
            await axios
              .post(
                `${API_URL}accounts/reset-user-password/${student.user_account}/`,
                body,
                config
              )
              .then((response) => {
                setUpdating(false);
                Swal.fire({
                  icon: "success",
                  title: "Updated",
                  text: "Password Changed Successfully",
                }).then(() => {
                  window.location.reload();
                });
              });
          } catch (error) {
            if (error.response) {
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    }
  };

  // UPDATE STUDENT PROFILE
  const updateStudent = async (e) => {
    e.preventDefault();

    if (!updating) {
      if (
        updateData.profile_picture &&
        updateData.profile_picture.size > 878576
      ) {
        toast.error(
          "The size of the profile picture should be less than 878KB"
        );
      } else {
        if (localStorage.getItem("access")) {
          setUpdating(true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          const formData = new FormData();
          Object.keys(updateData).forEach((key) => {
            formData.append(key, updateData[key]);
          });
          try {
            await axios
              .put(`${API_URL}administrator/students/${id}/`, formData, config)
              .then((response) => {
                setUpdating(false);
                Swal.fire({
                  icon: "success",
                  title: "Updated",
                  text: "Student Information Updated Successfully",
                }).then(() => {
                  fetchStudent();
                });
              });
          } catch (error) {
            setUpdating(false);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/";
              } else if (error.response.status === 400) {
                for (const property in error.response.data) {
                  if (property === "0") {
                    toast.error(`${error.response.data[property]}`);
                  } else {
                    toast.error(
                      `${property} error: ${error.response.data[property]}`
                    );
                  }
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    }
  };

  // CHANGE UPDATE DATA
  const handleUpdateDetails = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  // COMPRESS FILE
  const compressFile = (image) => {
    let file_name = image.name;
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      let image_url = event.target.result;
      let image = document.createElement("img");
      image.src = image_url;

      image.onload = (e) => {
        // creating a new canvas for the image
        const canvas = document.createElement("canvas");
        let ratio = 300 / e.target.width;
        canvas.width = 300;
        canvas.height = ratio * e.target.height;

        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        // converting the image quality to 50% and the file type to webp
        let new_image_url = canvas.toDataURL("image/webp", 50);

        // getting the image type and the data
        let arr = new_image_url.split(",");
        let image_type = arr[0].match(/:(.*?);/)[1];
        let data = arr[1];
        let dataStr = atob(data);
        let n = dataStr.length;
        let dataArr = new Uint8Array(n);

        while (n--) {
          dataArr[n] = dataStr.charCodeAt(n);
        }

        const compressed_image = new File([dataArr], file_name, {
          type: image_type,
        });

        setUpdateData({
          ...updateData,
          profile_picture: compressed_image,
        });
      };
    };
  };

  // ACTIVATE OR DEACTIVE STUDE T ACCOUNT
  const changeStudentStatus = (user_account_id, status) => {
    let message = "";
    let success_message = "";
    let data = {
      is_active: false,
    };
    if (status === "activate") {
      data.is_active = true;
      message =
        "Once the student is activated, he will have access to his account";
      success_message = "student account activated successfully";
    } else {
      data.is_active = false;
      message =
        "Once the student is deactivated, he will not have access to his account";
      success_message = "student account deactivated successfully";
    }
    Swal.fire({
      title: `Are you sure?`,
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setUpdatingStatus(true);
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          
            await axios.put(
              `${API_URL}administrator/user-account/${user_account_id}/update/`,data,
              config
            ).then((response)=>{
              
              if (response?.data?.success) {
                Swal.fire("Updated!", success_message, "success").then(() => {
                  window.location.reload();
                });
              } else {
                toast.error(response?.data?.message);
                setUpdatingStatus(false);
              }
            }).catch((error) =>{
              setUpdatingStatus(false);
              if (error.response.status === 400) {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              } else {
                toast.error("Something went wrong. Please try again");
              }
            } )
           
        }
      }
    });
  };

  const deleteStudent = (student_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "All information about this student will be lost. You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          axios
            .delete(`${API_URL}administrator/students/${student_id}/`, config)
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Student has been deleted.",
                icon: "success",
                timer: 1500,
              }).then(() => {
                window.location.href = "/students/student-list"
              });
            })
            .catch((error) => {
              if (error.response) {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              } else if (error.message) {
                toast.error("Something went wrong. Please try again");
              }
            });
        }
      }
    });
  };

  useEffect(() => {
    fetchStudent();
  }, []);

  return (
    <div className="main-container">
      {!loadingData ? (
        <div className="mt-8">
          {modal && (
            <ResetPasswordModal
              setModal={setModal}
              account_id={student?.user_account?.id}
            />
          )}
          <div className="border border-slate-300 rounded p-[16px]">
            <div className="flex justify-between lg:items-center lg:flex-row flex-col gap-7">
              <div className="flex gap-5">
                <div className="bg-light-primary h-[90px] w-[90px] flex items-center justify-center">
                  <img
                    src={
                      student?.user_account?.profile_picture ? (
                        <img
                          className="school-logo"
                          src={`${API_URL}${student?.user_account?.profile_picture}`}
                          alt=""
                        />
                      ) : (
                        <img
                          src={UserImg}
                          alt="profile_image"
                          className="school-logo"
                        />
                      )
                    }
                    alt=""
                  />
                </div>
                <div>
                  <p className="font-[500] text-[18px]">
                    {student?.user_account?.first_name}{" "}
                    {student?.user_account?.last_name}
                  </p>
                  <div className="flex lg:flex-row flex-col lg:gap-4 text-[14px]">
                    <p>{student.student_school_id}</p>
                    <p>{student.phone_number}</p>
                    <p>{student.student_class}</p>
                  </div>
                  {student?.status === "Active" && (
                    <div className="w-max bg-green-100 text-green-600 font-bold px-[8px] py-1 text-center text-[11px]">
                      {student?.status}
                    </div>
                  )}
                  {student?.status === "Inactive" && (
                    <div className="w-max bg-red-100 text-red-600 px-[8px] py-1 text-[11px] text-center">
                      {student?.status}
                    </div>
                  )}
                </div>
              </div>
              {user?.account_type === "School Administrator" && (
                <div>
                  {updatingStatus ? (
                    <div className="flex items-center ">
                      <RotatingLines
                        visible={true}
                        height="26"
                        width="26"
                        strokeColor="black"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      {student?.status === "Active" ? (
                        <button
                          onClick={() =>
                            changeStudentStatus(student?.user_account?.id, "deactivate")
                          }
                          className="py-2 px-2 bg-red-500 hover:bg-red-600 text-[12px] text-white rounded"
                        >
                          Deactivate
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            changeStudentStatus(student?.user_account?.id, "activate")
                          }
                          className="py-2 px-2 bg-green-500 hover:bg-green-600 text-[12px] text-white rounded"
                        >
                          Activate
                        </button>
                      )}
                      <button
                        onClick={() => deleteStudent(student?.student_id)}
                        className="py-2 px-2  hover:bg-red-50 text-[12px] border border-red-500 text-red-500 rounded"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => setModal(!modal)}
                        className="py-2 px-2  hover:bg-blue-50 text-[12px] border border-blue-500 text-blue-500 rounded"
                      >
                        Reset Password
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex border-b border-slate-300 text-[12px] my-10">
            <div
              onClick={() => setActiveTab(1)}
              className={`min-h-[40px] px-[10px] flex items-center justify-center cursor-pointer border-b-4 ${
                activeTab === 1 ? "border-primary" : "border-transparent"
              }`}
            >
              Profile
            </div>
            <div
              onClick={() => setActiveTab(2)}
              className={`min-h-[40px] px-[10px] flex items-center justify-center cursor-pointer border-b-4 ${
                activeTab === 2 ? "border-primary" : "border-transparent"
              }`}
            >
              Fees
            </div>
            <div
              onClick={() => setActiveTab(3)}
              className={`min-h-[40px] px-[10px] flex items-center justify-center cursor-pointer border-b-4 ${
                activeTab === 3 ? "border-primary" : "border-transparent"
              }`}
            >
              Academic Results
            </div>
          </div>
          <div className="school-profile-body">
            <div className="school-profile-left">
              <form onSubmit={(e) => updateStudent(e)}>
                <div className="formSection">
                  <p className="formRowHeader">Student Personal Info</p>
                  <div className="formRow">
                    <div className="formInput">
                      <label>Student Image</label>
                      <input
                        type="file"
                        name="profile_picture"
                        id="student_image"
                        value={student.profile_image}
                        onChange={(e) => {
                          compressFile(e.target.files[0]);
                        }}
                      />
                      <label className="upload-button" htmlFor="student_image">
                        <AiOutlineUpload style={{ fontSize: "15px" }} />
                        Upload Picture
                      </label>
                      <p style={{ fontWeight: "400", fontSize: "12px" }}>
                        {updateData.profile_picture
                          ? updateData.profile_picture.name
                          : "No file selected"}
                      </p>
                    </div>

                    <FormInput
                      label="First Name"
                      type="text"
                      name="first_name"
                      id="first_name"
                      value={
                        updateData.first_name
                          ? updateData.first_name
                          : student?.user_account?.first_name
                      }
                      required={true}
                      onChange={(e) => handleUpdateDetails(e)}
                      // pattern="^[A-Za-z\s]{3,26}$"
                      errorMessage="Should be at least 3 characters long with no number or special
                  characters"
                    />

                    <FormInput
                      label="Last Name"
                      type="text"
                      name="last_name"
                      id="last_name"
                      required={true}
                      value={
                        updateData.last_name
                          ? updateData.last_name
                          : student.user_account?.last_name
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                      // pattern="^[A-Za-z ]{3,30}$"
                      errorMessage="Should be at least 3 characters long with no number or special
                  characters"
                    />
                  </div>
                  <div className="formRow">
                    <div className="formInput">
                      <label>Gender</label>
                      {student.gender === "Male" ? (
                        <>
                          <div className="radioInline">
                            <input
                              type="radio"
                              name="gender"
                              required
                              checked
                              value="Male"
                              onChange={(e) => handleUpdateDetails(e)}
                            />{" "}
                            Male
                          </div>
                          <div className="radioInline">
                            <input
                              type="radio"
                              name="gender"
                              onChange={(e) => handleUpdateDetails(e)}
                              value="Female"
                            />{" "}
                            Female
                          </div>
                        </>
                      ) : (
                        <>
                          {student.gender === "Female" ? (
                            <>
                              <div className="radioInline">
                                <input
                                  type="radio"
                                  name="gender"
                                  required
                                  onChange={(e) => handleUpdateDetails(e)}
                                  value="Male"
                                />{" "}
                                Male
                              </div>
                              <div className="radioInline">
                                <input
                                  type="radio"
                                  name="gender"
                                  checked
                                  value="Female"
                                  onChange={(e) => handleUpdateDetails(e)}
                                />{" "}
                                Female
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="radioInline">
                                <input
                                  type="radio"
                                  name="gender"
                                  required
                                  value="Male"
                                  onChange={(e) => handleUpdateDetails(e)}
                                />{" "}
                                Male
                              </div>
                              <div className="radioInline">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                  onChange={(e) => handleUpdateDetails(e)}
                                />{" "}
                                Female
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <FormInput
                      label="Date of Birth"
                      type="date"
                      name="date_of_birth"
                      id="date_of_birth"
                      required={true}
                      value={
                        updateData.date_of_birth
                          ? updateData.date_of_birth
                          : student.date_of_birth
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                    />

                    <div className="formInput">
                      <label>Phone Number</label>
                      <PhoneInput
                        defaultCountry="GH"
                        className="phone-input"
                        value={
                          updateData.phone_number
                            ? updateData.phone_number
                            : student.phone_number
                        }
                        onChange={(value) =>
                          setUpdateData({
                            ...updateData,
                            phone_number: value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <FormInput
                      label="Email Address"
                      type="email"
                      name="email"
                      id="email"
                      value={
                        updateData.email
                          ? updateData.email
                          : student.user_account?.email
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                      errorMessage="Please enter a valid email address"
                    />

                    <FormInput
                      label="Residential Address"
                      type="text"
                      name="residential_address"
                      id="residential_address"
                      required={true}
                      value={
                        updateData.residential_address
                          ? updateData.residential_address
                          : student.residential_address
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                      errorMessage="Please enter a valid residential address"
                    />

                    <FormInput
                      label="Religion"
                      type="text"
                      name="religion"
                      id="religion"
                      value={
                        updateData.religion
                          ? updateData.religion
                          : student.religion
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                    />
                  </div>

                  <div className="formRow">
                    <FormInput
                      label="Father's Name"
                      type="text"
                      name="father_name"
                      id="father_name"
                      // pattern="^[A-Za-z ]{3,30}$"
                      // required={true}
                      errorMessage="Enter A Valid Name"
                      onChange={(e) => handleUpdateDetails(e)}
                      value={
                        updateData.father_name
                          ? updateData.father_name
                          : student.father_name
                      }
                    />

                    <FormInput
                      label="Father's Contact"
                      type="text"
                      name="father_phone_number"
                      id="father_phone_number"
                      value={
                        updateData.father_phone_number
                          ? updateData.father_phone_number
                          : student.father_phone_number
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                    />

                    <FormInput
                      label="Mother's Name"
                      type="text"
                      name="mother_name"
                      id="mother_name"
                      // pattern="^[A-Za-z ]{3,30}$"
                      // required={true}
                      errorMessage="Enter A Valid Name"
                      value={
                        updateData.mother_name
                          ? updateData.mother_name
                          : student.mother_name
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                    />

                    <FormInput
                      label="Mother's Contact"
                      type="text"
                      name="mother_phone_number"
                      id="mother_phone_number"
                      value={
                        updateData.mother_phone_number
                          ? updateData.mother_phone_number
                          : student.mother_phone_number
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                    />
                  </div>
                  <div className="formRow">
                    <div className="formInput">
                      <label>Guardian</label>
                      <input
                        type="text"
                        onChange={(e) => handleUpdateDetails(e)}
                        value={
                          updateData.guardian
                            ? updateData.guardian
                            : student.guardian
                        }
                        name="guardian"
                      />
                    </div>
                    <div className="formInput">
                      <label>Guardian Phone Number</label>
                      <PhoneInput
                        defaultCountry="GH"
                        className="phone-input"
                        value={
                          updateData.guardian_phone_number
                            ? updateData.guardian_phone_number
                            : student.guardian_phone_number
                        }
                        onChange={(value) =>
                          setUpdateData({
                            ...loadingData,
                            guardian_phone_number: value,
                          })
                        }
                      />
                    </div>
                    <div className="formInput">
                      <label>Guardian Relation</label>
                      <input
                        type="text"
                        name="guardian_relation"
                        value={
                          updateData.guardian_relation
                            ? updateData.guardian_relation
                            : student.guardian_relation
                        }
                        onChange={(e) => handleUpdateDetails(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="formSection">
                  <p className="formRowHeader">Student Academic Info</p>
                  <div className="formRow">
                    <div className="formInput">
                      <label>Admission ID</label>
                      <input
                        type="text"
                        onChange={(e) => handleUpdateDetails(e)}
                        name="admission_id"
                        value={
                          updateData.admission_id
                            ? updateData.admission_id
                            : student.admission_id
                        }
                      />
                    </div>
                    <div className="formInput">
                      <label>Roll Number</label>
                      <input
                        type="text"
                        name="roll_number"
                        onChange={(e) => handleUpdateDetails(e)}
                        value={
                          updateData.roll_number
                            ? updateData.roll_number
                            : student.roll_number
                        }
                      />
                    </div>
                    <div className="formInput">
                      <label>Index Number</label>
                      <input
                        type="text"
                        name="index_number"
                        onChange={(e) => handleUpdateDetails(e)}
                        value={
                          updateData.index_number
                            ? updateData.index_number
                            : student.index_number
                        }
                      />
                    </div>
                  </div>
                  <div className="formRow">
                    <div className="formInput">
                      <label>Admission Date</label>
                      <input
                        type="date"
                        name="admission_date"
                        onChange={(e) => handleUpdateDetails(e)}
                        value={
                          updateData.admission_date
                            ? updateData.admission_date
                            : student.admission_date
                        }
                      />
                    </div>

                    {!student.is_graduate ? (
                      <div className="formInput">
                        <label>Class</label>
                        <select
                          name="student_class"
                          onChange={(e) => handleUpdateDetails(e)}
                        >
                          <option>Select Class</option>
                          {schoolClasses.map((schoolClass) => {
                            return (
                              <option
                                key={schoolClass.id}
                                value={schoolClass.id}
                                selected={
                                  schoolClass.class_name === student.student_class
                                }
                              >
                                {schoolClass.class_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    ) : (
                      <div className="formInput">
                        <label>Graduation Badge</label>
                        <input
                          type="text"
                          name="admission_date"
                          disabled
                          value={student.graduation_badge}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="formSection">
                  <p className="formRowHeader">Student Account Info</p>
                  <div className="formRow">
                    <FormInput
                      label="Username"
                      type="text"
                      name="username"
                      id="username"
                      pattern="^[A-Za-z0-9]{5,16}$"
                      required={true}
                      value={student.username}
                      disabled
                      errorMessage="Should be at least 5 characters with no special characters"
                    />

                    <div className="formInput">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        disabled
                        placeholder="**************"
                      />
                    </div>
                  </div>
                </div>
                {user.account_type === "School Administrator" && (
                  <>
                    {updating ? (
                      <button style={{ cursor: "wait" }}>
                        <ColorRing
                          visible={true}
                          height="20"
                          width="20"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                        Updating...
                      </button>
                    ) : (
                      <button type="submit">Update</button>
                    )}
                  </>
                )}
              </form>
            </div>
            
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default StudentProfile;
