import React from "react";
import { TbMoneybag } from "react-icons/tb";
import { GiReceiveMoney } from "react-icons/gi";
import {Link} from "react-router-dom"
import PieC from "../../components/Charts/Pie";
import Barchart from "../../components/Charts/BarChart";
import { useState, useEffect } from "react";
import Loading from "../../components/LoadingPage/Loading";
import axios from "axios";
import { API_URL } from "../../url";
import Header from "../../components/Header";

const Expense = () => {
  const [schoolExpense, setSchoolExpense] = useState(null)
  const [expenseStats, setExpenseStats] = useState({});
  const [expenseData, setExpenseData] = useState({});
 
  const getSchoolExpense = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}finance/school-financial-expense/`,
          config
        );
        setSchoolExpense(res.data);
        
        setExpenseStats({
          labels: res.data.expense_stats.map((data) => data.type),
          datasets: [
            {
              label: "Total",
              data: res.data.expense_stats.map((data) => data.amount),
            },
          ],
        });

        setExpenseData({
          labels: res.data.expense_data.map((data) => data.month),
          datasets: [
            {
              label: "Expense",
              data: res.data.expense_data.map((data) => data.amount),
            },
          ],
        });
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  useEffect(()=>{
    getSchoolExpense()
  },[])

  return (
    <div className="main-container">
      <Header title="Financial Expenses" subtitle="Expense" />
      {
        schoolExpense ? (<div className="finance">
        <div className="left-finance">
          <div className="cards">
            <div style={{ backgroundColor: "#C9CDEE" }} className="finance-card">
              
              <h3>Total Expense</h3>
              <p>GHS {schoolExpense.total_expense}</p>
            </div>
            <div style={{ backgroundColor: "#C1DACF" }} className="finance-card">
              
              <h3>Today's Expense</h3>
              <p>GHS {schoolExpense.today_expense}</p>
            </div>
            <div style={{ backgroundColor: "#C1DACF" }} className="finance-card">
              
              <h3>This Month Expense</h3>
              <p>GHS {schoolExpense.this_month_expense}</p>
            </div>
          </div>
          <div className="expenses-chart">
            <p className="title">Expense Stats</p>
            <p className="amount">GHS {schoolExpense.total_expense}</p>
            <div className="chart">
              <Barchart className="bar" chartData={expenseData} />
            </div>
          </div>
        </div>

        <div className="right-finance">
          <div className="buttons">
            <button>
              <Link to="/finance/record-expense">
                <span>+</span> Record Expense
              </Link>
            </button>
            
            <button>
              <Link to="/finance/expense-list">
                Expense List
              </Link>
            </button>
          </div>
          <div className="overview-pie">
            <div className="header">
              <h5>Total Expense</h5>
              <p style={{fontSize: "14px"}}>GHS {schoolExpense.total_expense}</p>
            </div>
            <div className="chart">
              <PieC className="bar" chartData={expenseStats} />
            </div>
          </div>
        </div>
      </div>):(
        <Loading />
      )
      }
      
    </div>
  );
};

export default Expense;
