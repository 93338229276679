import React, { useEffect, useState } from "react";
import { useAuthenticate } from "../../context/AuthContext";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Header from "../../components/Header";

const MarksEntry = () => {
  const { academicYears } =
    useAuthenticate();

  const [academicTerms, setAcademicTerms] = useState([]);
  const [academicYear, setAcademicYear] = useState("");
  const [selectedAcademicTerm, setSelectedAcademicTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedAssessmentType, setSelectedAssessmentType] = useState(null);
  const [currentAssessmentType, setCurrentAssessmentType] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [assessmentMarks, setAssessmentMarks] = useState([]);
  const [teacherClasses, setTeacherClasses] = useState([]);
  const [teacherSubjects, setTeacherSubjects] = useState([])
  const [assessmentTypes, setAssessmentTypes] = useState([])
  const [savingMarks, setSavingMarks] = useState(false)

  const getExamMarks = async (e) => {
    console.log(selectedAssessmentType);
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    axios
      .get(
        `${API_URL}academics/student-assessment-marks/${selectedClass}/${selectedAcademicTerm}/${selectedAssessmentType}/${selectedSubject}`,
        config
      )
      .then((response) => {
        setAssessmentMarks(response?.data);
        // setAssessmentSnapShot(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const changeAcademicYear = (e) => {
    setAcademicYear(e.target.value);

    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  const handleUpdateCurrentAssesmentType = (value) => {
    setSelectedAssessmentType(value);
    const foundAssessment = assessmentTypes?.find(
      (item) => item.id === Number(value)
    );
    if (foundAssessment !== undefined) {
      setCurrentAssessmentType(foundAssessment);
    } else {
      setCurrentAssessmentType(null);
    }
  };

  const handleUpdateStudentAssessmentMark = (newScore, assessmentId) => {
    if (currentAssessmentType && assessmentMarks?.length > 0) {
      //get student id
      if (newScore > currentAssessmentType?.total_marks) {
        return;
      } else {
        const updatedAssessmentList = assessmentMarks?.map((assessment) => {
          if (assessment?.id === assessmentId) {
            return {
              ...assessment,
              marks: Number(newScore),
            };
          }
          return assessment;
        });
        setAssessmentMarks(updatedAssessmentList);
      }
    }
  };

  //save assessment record
  const saveAssessmentMarks = () => {
    // first map assessmentList to get desired data for submission
    setSavingMarks(true);
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
    const newAssessmentMarks = assessmentMarks?.map((assessment) => {
      return {
        marks: Number(assessment?.marks),
        student: assessment?.id,
      };
    });

    const data = {
      assessment_type: selectedAssessmentType,
      student_marks: newAssessmentMarks,
      subject: selectedSubject,
      term: selectedAcademicTerm,
    };

    axios
      .post(`${API_URL}academics/assessment-marks/`, data, config)
      .then((response) => {
        if(response?.status === 201){
            Swal.fire({
                title:"Success",
                icon:"success",
                text:"Marks Updated successfully"
            })
            setSavingMarks(false);
        }
        // if (response.data?.length > 0) {
        //   notify("Assessment entries sucessfully submitted!");
        //   setAssessmentSnapShot(assessmentList);
        //   setIsSubmittingAssessments(false);
        // }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
        setSavingMarks(false);
      });
  };

  // teacher classes
  const getTeacherClasses = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      await axios.get(
        `${API_URL}teacher/assigned-classes/`,
        config
      ).then(response => {
        setTeacherClasses(response.data)
      }).catch(error => {
        
      })

  
    }
  };

  const getTeacherSubjects = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      await axios.get(
        `${API_URL}teacher/subjects/`,
        config
      ).then(response => {
        setTeacherSubjects(response.data)
      }).catch(error => {
        
      })

  
    }
  };

  const getAssessmentTypes = async (academicTerm) =>{
    
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        }

        await axios.get(`${API_URL}academics/term-assessment-type/${academicTerm}/`,config).then(response => {
          setAssessmentTypes(response.data)
        }).catch(error => {
          
        })

    }
  
}

  useEffect(() => {
    getTeacherClasses();
    getTeacherSubjects();
  },[])
  useEffect(()=>{
    getAssessmentTypes(selectedAcademicTerm);
  },[selectedAcademicTerm])

  return (
    <div className="main-container">
      <Header title="Marks Entry" subtitle="Enter assessment marks" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => getExamMarks(e)}>
            {/* ACADEMIC YEAR DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_year"
                id="academic_term"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* ACADEMIC TERM DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_term"
                onChange={(e) => setSelectedAcademicTerm(e.target.value)}
                id="academic_term"
              >
                {academicYear === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>

            {/* ASSESSMENT TYPES DROPDOWN */}
            <div className="formInput">
              <select
                onChange={(e) =>
                  handleUpdateCurrentAssesmentType(e.target.value)
                }
                name="assessment_type"
                id=""
              >
                <option value="">Select Assessment Type</option>
                {assessmentTypes?.map((assessment_type, index) => (
                  <option key={index} value={assessment_type?.id}>
                    {assessment_type?.name}
                  </option>
                ))}
              </select>
            </div>

            {/* SUBJECTS DROPDOWN */}
            <div className="formInput">
              <select
                onChange={(e) => setSelectedSubject(e.target.value)}
                name="subject"
                id=""
              >
                <option value="">Select Subject</option>
                {teacherSubjects?.map((teacherSubject, index) => (
                  <option key={index} value={teacherSubject?.subject?.id}>
                    {teacherSubject?.subject?.subject_name}
                  </option>
                ))}
              </select>
            </div>

            {/* CLASSES DROPDOWN */}
            <div className="formInput">
              <select
                onChange={(e) => setSelectedClass(e.target.value)}
                name="school_class"
                id=""
              >
                <option value="">Select Class</option>
                {teacherClasses.map((schoolClass) => (
                  <option key={schoolClass.id} value={schoolClass.id}>
                    {schoolClass.class_name}
                  </option>
                ))}
              </select>
            </div>

            {/* IF FILTERING DATA SHOW A LOADING BUTTON ELSE SHOW A SUBMIT BUTTON */}
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data...{" "}
              </button>
            ) : (
              <button type="submit">Filter</button>
            )}
          </form>
        </div>
        {assessmentMarks.length > 0 && (
          <div className="schedule-body">
            <div className="student-list">
              <div className="panel-header">
                <h4>{currentAssessmentType?.name} Marks ({currentAssessmentType?.total_marks})</h4>
              </div>
              <div className="list-body">
                <table>
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Student ID</th>
                      <th>Student Class</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assessmentMarks?.map((assessment_mark, index) => (
                      <tr key={index}>
                        <td>{assessment_mark?.student?.get_full_name}</td>
                        <td>{assessment_mark?.student?.student_school_id}</td>
                        <td>{assessment_mark?.student?.student_class}</td>
                        <td>
                          <input
                            type="number"
                            max={Number(currentAssessmentType?.total_marks)}
                            value={assessment_mark?.marks}
                            onChange={(e) =>
                              handleUpdateStudentAssessmentMark(
                                e.target?.value,
                                assessment_mark?.id
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end px-10">
                <div className="bg-green-800 hover:bg-green-700 cursor-pointer px-3 py-2 text-white w-full text-center ml-5 mb-10" onClick={()=>saveAssessmentMarks()}>
                    Save Marks
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarksEntry;
