import React from "react";

import SideMenuItem from "../SideMenuItem/SideMenuItem";
import "./Sidebar.scss";
import logo from "../../assets/logos/light-logo.png";
import { Link } from "react-router-dom";
import { useAuthenticate } from "../../context/AuthContext";

import { superadminMenuItems ,accountantMenuItems, menuItems, studentMenuItems, teacherMenuItems} from "./menuItems";


const Sidebar = ({ isOpen }) => {
  const { user, schoolInfo } = useAuthenticate();

  return (
    <div
      id="sidebar-container"
      className="bg-dark-color"
      style={{
        width: isOpen ? "260px" : "0px",
        padding: isOpen ? "15px 0px" : "0px",
        alignItems: isOpen ? "inherit" : "center",
        flex: isOpen ? "1" : "0",
      }}
    >
      <div className="top-section">
        <Link to="/">
          <img
            alt="meskuu logo"
            src={logo}
            style={{
              transition: ".5s ease-in",
              display: isOpen ? "block" : "none",
              padding: "0px 20px"
            }}
            className="h-10 object-cover"
          />
        </Link>
      </div>
      

      {user?.account_type === "School Staff" && (
        <div>
          {
            user?.position === "Teacher" && (
              <ul
                className="sidebar-menu"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                {teacherMenuItems.map((menuItem, index) => (
                  <SideMenuItem
                    name={menuItem.name}
                    path={menuItem.path}
                    icon={menuItem.icon}
                    key={index}
                    isOpen={isOpen}
                    submenus={menuItem.submenus}
                  />
                ))}
              </ul>
            )
          }
          {
            user?.position === "Accountant" && (
              <ul
                className="sidebar-menu"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                {accountantMenuItems.map((menuItem, index) => (
                  <SideMenuItem
                    name={menuItem.name}
                    path={menuItem.path}
                    icon={menuItem.icon}
                    key={index}
                    isOpen={isOpen}
                    submenus={menuItem.submenus}
                  />
                ))}
              </ul>
            )
          }
        </div>
      )}

      {user?.account_type === "Student" && (
        <ul
          className="sidebar-menu"
          style={{ display: isOpen ? "flex" : "none" }}
        >
          {studentMenuItems.map((menuItem, index) => (
            <SideMenuItem
              name={menuItem.name}
              path={menuItem.path}
              icon={menuItem.icon}
              key={index}
              isOpen={isOpen}
              submenus={menuItem.submenus}
            />
          ))}
        </ul>
      )}

      {user?.account_type === "School Administrator" && (
        <ul
          className="sidebar-menu"
          style={{ display: isOpen ? "flex" : "none" }}
        >
          {schoolInfo.uses_eyfs ? (
            <>
              {menuItems.map((menuItem, index) => (
                <SideMenuItem
                  name={menuItem.name}
                  path={menuItem.path}
                  icon={menuItem.icon}
                  key={index}
                  isOpen={isOpen}
                  submenus={menuItem.submenus}
                />
              ))}
            </>
          ) : (
            <>
              {menuItems
                .filter((navItem) => navItem.name !== "EYFS")
                .map((menuItem, index) => (
                  <SideMenuItem
                    name={menuItem.name}
                    path={menuItem.path}
                    icon={menuItem.icon}
                    key={index}
                    isOpen={isOpen}
                    submenus={menuItem.submenus}
                  />
                ))}
            </>
          )}
        </ul>
      )}
      
      {user?.account_type === "Master" && (
        <ul
          className="sidebar-menu"
          style={{ display: isOpen ? "flex" : "none" }}
        >
          {superadminMenuItems.map((menuItem, index) => (
            <SideMenuItem
              name={menuItem.name}
              path={menuItem.path}
              icon={menuItem.icon}
              key={index}
              isOpen={isOpen}
              submenus={menuItem.submenus}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default Sidebar;
