import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {MdOutlineKeyboardArrowRight,MdOutlineKeyboardArrowDown} from 'react-icons/md'
import "./SideMenuItem.scss"

const SideMenuItem = ({icon, path, name, isOpen, submenus}) => {
    const [openSubmenu, setOpenSubmenu] = useState(false)
    const [activePath, setActivePath] = useState(path)
    const location = useLocation()
    const toggleSubMenu = () =>{
        setOpenSubmenu(!openSubmenu)
    }

    const isActiveLink = (path)=>{
        return location.pathname.includes(activePath)
    }

  return (
    <li className="side-menu-item" style={{ padding: isOpen ? "0px" : "5px 10px", backgroundColor: openSubmenu ? "#045b8d00" : "" }}>

        <NavLink onClick={toggleSubMenu} className={`side-menu-link px-7 py-2 ${isActiveLink(path) ? "bg-secondary text-secondary-white" : "text-secondary-white"} `} to={path} >
            <div className="icon-name">
                {/* <div className="icon">
                    {icon}
                </div> */}
                <div className="name" style={{display: isOpen ? "block" : 'none'}}>
                    {name}
                </div>
            </div>
            {
                submenus ? (
                    <div style={{display: isOpen ? "block" : 'none'}}>
                    
                    {
                        openSubmenu ? (
                            <MdOutlineKeyboardArrowDown onClick={toggleSubMenu} style={{fontSize:"20px", padding: "0px 0px 0px 10px", transition:".5s ease"}} />
                            ):(
                            <MdOutlineKeyboardArrowRight onClick={toggleSubMenu} style={{fontSize:"20px", padding: "0px 0px 0px 10px", transition:".5s ease"}} />
                            )
                    }
                    </div>
                ):(<></>)
            }
        </NavLink>
        {
            submenus && submenus.length > 0 && (
                <ul className={`submenu ${isActiveLink(path) ? "bg-secondary text-secondary-white" : "text-secondary-white"} `} style={{display: openSubmenu ? "flex":"none"}}>
                    {
                        submenus.map((submenu, index) => (
                            <li style={{display: isOpen ? "block" : 'none'}} className='submenu-item' key={index}>
                                <NavLink to={submenu.path} className={`${isActiveLink(path) ? "bg-secondary text-secondary-white" : "text-secondary-white"}`}>

                                    {submenu.name}
                                    
                                </NavLink>
                            </li>
                        ))
                    }
                </ul>
            )
        }
    </li>
    
  )
}

export default SideMenuItem