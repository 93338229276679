import React, { useEffect, useState } from "react";
import FormInput from "../../components/Forms/FormInput";

import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { API_URL } from "../../url";
import { AiOutlineUpload } from "react-icons/ai";
import Loading from "../../components/LoadingPage/Loading";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../context/AuthContext";

const Profile = () => {
  const UserImage = "https://epasmart.s3.eu-north-1.amazonaws.com/assets/user_image.svg"
  const [userProfile, setUserProfile] = useState("");
  const [passwordData, setPasswordData] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [updateData, setUpdateData] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [modal, setModal] = useState(false);
  const { user } = useAuthenticate();

  // TOGGLE SET PASSWORD TOGGLE
  const toggleModal = () => {
    setModal(!modal);
  };

  // DISPLAYING MODAL
  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  // GET ADMIN PROFILE
  const adminProfile = async () => {
    if (localStorage.getItem("access")) {
      setLoadingData(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        const res = await axios.get(`${API_URL}administrator/profile/`, config);

        setUserProfile(res.data);
        setLoadingData(false);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // GET STAFF PROFILE
  const staffProfile = async () => {
    if (localStorage.getItem("access")) {
      setLoadingData(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        const res = await axios.get(`${API_URL}teacher/profile/`, config);
        
        setUserProfile(res.data);
        setLoadingData(false);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // GET STAFF PROFILE
  const studentProfile = async () => {
    if (localStorage.getItem("access")) {
      setLoadingData(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        const res = await axios.get(`${API_URL}student/profile/`, config);
        console.log(res.data);
        setUserProfile(res.data);
        setLoadingData(false);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // UPDATE DATA FIELDS
  const handleUpdateDetails = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  // UPDATE PROFILE
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (!updating) {
      if (
        updateData.profile_picture &&
        updateData.profile_picture.size > 878576
      ) {
        toast.error(
          `The size of the profile picture should be less than 878KB`
        );
      } else {
        if (localStorage.getItem("access")) {
          setUpdating(true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          const formData = new FormData();
          Object.keys(updateData).forEach((key) => {
            formData.append(key, updateData[key]);
          });
          try {
            let endpoint = "administrator/profile/"
            if(user.account_type === "School Administrator"){
              endpoint = "administrator/profile/"
            }else if(user.account_type === "School Staff"){
              endpoint = "teacher/profile/"
            }else if(user?.account_type === "Student"){
              endpoint = "student/profile/"
            }
            await axios
              .put(`${API_URL}${endpoint}`, formData, config)
              .then((response) => {
                setUpdating(false);
                Swal.fire({
                  icon: "success",
                  title: "Updated",
                  text: "Profile Updated Successfully",
                }).then(() => {
                  if(user.account_type === "School Administrator"){
                    adminProfile();
                  }else if(user.account_type === "School Staff"){
                    staffProfile()
                  }else{
                    studentProfile()
                  }
                });
              });
          } catch (error) {
            setUpdating(false)
            if (error.response) {
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    }
  };

  // CHANGE PASSWORD
  const changePassword = async (e) => {
    e.preventDefault();
    if (!updating) {
      if (passwordData.new_password !== passwordData.confirm_password) {
        toast.error("The two passwords do not match");
      } else {
        if (localStorage.getItem("access")) {
          setUpdating(true);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          const body = JSON.stringify(passwordData);
          try {
            await axios
              .post(`${API_URL}accounts/change-password/`, body, config)
              .then((response) => {
                setUpdating(false);
                Swal.fire({
                  icon: "success",
                  title: "Updated",
                  text: "Password Changed Successfully",
                }).then(() => {
                  if(user.account_type === "School Administrator"){
                    adminProfile();
                  }else{
                    staffProfile()
                  }
                });
              });
          } catch (error) {
            if (error.response) {
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (user.account_type === "School Administrator") {
      adminProfile();
    }else if(user.account_type === "Student"){
      studentProfile();
    } else {
      staffProfile();
    }
  }, []);

  return (
    <div className="main-container">
      <div className="header">
        <h1>PROFILE</h1>
      </div>
      {!loadingData ? (
        <div className="school-profile-body">
          <div className="school-profile-left">
            <form onSubmit={(e) => handleUpdateProfile(e)}>
              <div className="formSection">
                <p className="formRowHeader">Your Personal Info</p>
                <div className="formRow">
                  <div className="formInput">
                    <label htmlFor="">Profile Picture</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          [e.target.name]: e.target.files[0],
                        })
                      }
                      name="profile_picture"
                      id="profile_picture"
                    />
                    <label className="upload-button" htmlFor="profile_picture">
                      <AiOutlineUpload style={{ fontSize: "15px" }} />
                      Upload Picture
                    </label>
                    <p style={{ fontWeight: "400", fontSize: "12px" }}>
                      {updateData.profile_picture
                        ? updateData.profile_picture.name
                        : "No file selected"}
                    </p>
                  </div>
                </div>
                <div className="formRow">
                  <FormInput
                    label="First Name"
                    value={
                      updateData.first_name
                        ? updateData.first_name
                        : userProfile.user_account?.first_name
                    }
                    type="text"
                    name="first_name"
                    id="first_name"
                    onChange={(e) => handleUpdateDetails(e)}
                    // pattern="^[A-Za-z]{3,16}$"
                    errorMessage="Should be at least 3 characters long with no number or special
                characters"
                  />
                  <FormInput
                    label="Last Name"
                    value={
                      updateData.last_name
                        ? updateData.last_name
                        : userProfile.user_account?.last_name
                    }
                    type="text"
                    name="last_name"
                    id="last_name"
                    onChange={(e) => handleUpdateDetails(e)}
                    // pattern="^[A-Za-z ]{3,30}$"
                    errorMessage="Should be at least 3 characters long with no number or special
                characters"
                  />
                  
                </div>

                <div className="formRow">
                  <div className="formInput">
                    <label htmlFor="">Gender</label>
                    {userProfile.gender === "Male" && (
                      <>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="gender"
                            required
                            checked
                            value="Male"
                            onChange={(e) => handleUpdateDetails(e)}
                          />{" "}
                          Male
                        </div>
                        <div className="radioInline">
                          <input
                            type="radio"
                            onChange={(e) => handleUpdateDetails(e)}
                            name="gender"
                            value="Female"
                          />{" "}
                          Female
                        </div>
                      </>
                    )}
                    {userProfile.gender === "Female" && (
                      <>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="gender"
                            required
                            value="Male"
                            onChange={(e) => handleUpdateDetails(e)}
                          />{" "}
                          Male
                        </div>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="gender"
                            checked
                            value="Female"
                            onChange={(e) => handleUpdateDetails(e)}
                          />{" "}
                          Female
                        </div>
                      </>
                    )}
                    {!userProfile.gender && (
                      <>
                        <div className="radioInline">
                          <input
                            type="radio"
                            name="gender"
                            required
                            value="Male"
                            onChange={(e) => handleUpdateDetails(e)}
                          />{" "}
                          Male
                        </div>
                        <div className="radioInline">
                          <input
                            type="radio"
                            onChange={(e) => handleUpdateDetails(e)}
                            name="gender"
                            value="Female"
                          />{" "}
                          Female
                        </div>
                      </>
                    )}
                  </div>
                  <div className="formInput">
                    <label htmlFor="">Phone Number</label>
                    <PhoneInput
                      defaultCountry="GH"
                      required
                      className="phone-input"
                      value={
                        updateData.phone_number
                          ? updateData.phone_number
                          : userProfile.phone_number
                      }
                      onChange={(value) =>
                        setUpdateData({
                          ...updateData,
                          phone_number: value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <FormInput
                    label="Email Address"
                    value={
                      updateData.email ? updateData.email : userProfile.user_account?.email
                    }
                    type="email"
                    id="email"
                    name="user_email"
                    onChange={(e) => handleUpdateDetails(e)}
                  />
                  <FormInput
                    label="Residential Address"
                    value={
                      updateData.residential_address
                        ? updateData.residential_address
                        : userProfile.residential_address
                    }
                    type="text"
                    name="residential_address"
                    onChange={(e) => handleUpdateDetails(e)}
                  />
                </div>
              </div>
              {updating ? (
                <button style={{ cursor: "wait" }}>
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                  Updating...
                </button>
              ) : (
                <button type="submit">Update</button>
              )}
            </form>
          </div>

          <div className="school-profile-right">
            <h4>Your Profile</h4>
            <div className="school-details">
              <img
                src={
                  userProfile.user_account?.profile_picture
                    ? `${API_URL}${userProfile.user_account?.profile_picture}`
                    : UserImage
                }
                className="school-logo"
                alt="profile"
              />
              <h2>
                {userProfile?.user_account?.first_name} {userProfile?.user_account?.last_name}{" "}
                
              </h2>
            </div>
            <div className="school-location">
              <div className="columns">
                <p>
                  <b>Username: </b> &nbsp; {user.username}
                </p>
                {user.account_type === "School Administrator" ? (
                  <p>
                    <b>Administrator ID: </b> &nbsp; {userProfile.admin_id}
                  </p>
                ) : (
                  <>
                  {
                    user?.account_type === "School Staff" ? (
                      <p>
                      <b>Staff ID: </b> &nbsp; {userProfile.staff_school_id}
                    </p>  
                    ):(
                      <p>
                      <b>Student ID: </b> &nbsp; {userProfile.student_school_id}
                    </p>
                    )
                  }
                  </>
                )}

                <p>
                  <b>Phone Number: </b> &nbsp; {userProfile.phone_number}
                </p>
                <p>
                  <b>Email: </b> &nbsp; {userProfile?.user_account?.email}
                </p>
              </div>
            </div>
            <div className="action-buttons">
              <button onClick={toggleModal}>Reset Password</button>
            </div>

            {modal && (
              <div className="modal">
                <div onClick={toggleModal} className="overlay">
                <div className="modal-content" onClick={(e)=>e.stopPropagation()} style={{top:"300px"}}>
                  <div className="modal-close-btn" onClick={toggleModal}>
                    x
                  </div>
                  <div className="modal-header">Reset Password</div>
                  <p className="modal-text">Enter your new password</p>
                  <form onSubmit={(e) => changePassword(e)}>
                    <div className="formSection">
                      <div className="formRow">
                        <FormInput
                          label="New Password"
                          type="password"
                          id="new_password"
                          name="new_password"
                          required={true}
                          onChange={(e) =>
                            setPasswordData({
                              ...passwordData,
                              new_password: e.target.value,
                            })
                          }
                          
                          infoMessage="Minimum eight characters, at least one letter and one number"
                          errorMessage="Minimum eight characters, at least one letter and one number"
                        />
                        <FormInput
                          label="Confirm Password"
                          type="password"
                          id="confirm_password"
                          name="confirm_password"
                          required={true}
                          errorMessage="This field is required"
                          onChange={(e) =>
                            setPasswordData({
                              ...passwordData,
                              confirm_password: e.target.value,
                            })
                          }
                        />
                      </div>
                      {updating ? (
                        <button
                          style={{ cursor: "wait", backgroundColor: "gray" }}
                        >
                          <ColorRing
                            visible={true}
                            height="20"
                            width="20"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          />
                          Changing Password...
                        </button>
                      ) : (
                        <button type="submit">Change Password</button>
                      )}
                    </div>
                  </form>
                </div>
                </div>
                
              </div>
            )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Profile;
