import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useAuthenticate } from "../../context/AuthContext";
import Loading from "../../components/LoadingPage/Loading";
import { ColorRing } from "react-loader-spinner";
import { API_URL } from "../../url";
import axios from "axios";

const StudentFeePayments = () => {
    const {schoolClasses} = useAuthenticate()
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [loading,setLoading] = useState(false)
    const [payments,setPayments] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [selectedClass, setSelectedClass] = useState("")
  const [totalPaymentsList, setTotalPaymentsList] = useState(0); 

    const fetchPayments = async (page,startDate, endDate, selectedClass) => {
        if (localStorage.getItem("access")) {
          setLoading(true);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };
          await axios
            .get(`${API_URL}finance/school-fees/transactions/?page=${page}&start_date=${startDate}&end_date=${endDate}&student_class=${selectedClass}`, config)
            .then((response) => {
                console.log(response?.data?.payload?.results)
              setPayments(response.data?.payload?.results);
              setNextPage(response.data?.payload?.next);
              setPrevPage(response.data?.payload?.previous);
              setTotalPages(Math.ceil(response.data?.payload?.count / 20));
              setTotalPaymentsList(response.data?.payload?.count);
              setLoading(false);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                window.location.href = "/";
              }
            });
        }
      };

      const handleNextPage = () => {
        if (nextPage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const handlePreviousPage = () => {
        if (prevPage) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    
      const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalNumbersToShow = 3; // Number of pages to show around the current page
        const totalPagesToShow = 5; // Total pages to show at start and end
    
        let startPage = Math.max(1, currentPage - totalNumbersToShow);
        let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);
    
        // Always show the first page
        if (startPage > 1) {
            pageNumbers.push(1);
            if (startPage > 2) {
                pageNumbers.push('...');
            }
        }
    
        // Show the numbers around the current page
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
    
        // Always show the last page
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push('...');
            }
            pageNumbers.push(totalPages);
        }
    
        return pageNumbers.map(number => (
            <button
                key={number}
                onClick={() => number !== '...' && handlePageClick(number)}
                className={number === currentPage ? 'bg-secondary border border-secondary text-white rounded py-2 px-3 min-w-10' : 'border border-secondary text-main bg-transparent py-2 px-3 rounded min-w-10'}
            >
                {number}
            </button>
        ));
    };
    

      useEffect(() => {
        fetchPayments(currentPage, startDate, endDate, selectedClass);
      }, [currentPage,startDate, endDate, selectedClass]);
    

  return (
    <div className="main-container">
      <Header title="Fee Payments" subtitle="List of fees payments made" />

      <div className="schedule-body">
        <div className="filter-columns">
          <form>
            <div className="formInput">
              <label htmlFor="">From:</label>
              <input
                type="date"
                name="start_date"
                id=""
                onChange={(e) =>
                    setStartDate(e.target.value )
                }
              />
            </div>
            <div className="formInput">
              <label htmlFor="">To:</label>
              <input
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                name="end_date"
                onChangeCapture={(e) => setEndDate(e.target.value)}
                id=""
              />
            </div>
            <div className="formInput">
              <label htmlFor="">Class</label>
              <select name="" id="" onChange={(e)=>setSelectedClass(e.target.value)}>
                <option value="">Select Class</option>
                {schoolClasses.map((schoolClass) => (
                    <option key={schoolClass.id} value={schoolClass.id}>
                    {schoolClass.class_name}
                    </option>
                ))}
              </select>
              
            </div>
            
          </form>
        </div>
        {!loading ? (
          <div className="income-statement">
            
            <div>
              <h1 style={{ textAlign: "center" }}>
                School Fees Transactions
              </h1>
              <div>
                <table
                  style={{
                    marginTop: "30px",
                    borderCollapse: "collapse",
                    width: "100%",
                  }}
                  className="income-statement-table"
                >
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Student
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Class
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Bill Type
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Amount Paid
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {payments?.map((payment) => {
                      return (
                        <tr>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            <p>

                                {payment.student?.get_full_name}
                            </p>
                            <small className="">
                            {payment.student?.student_school_id}
                            </small>
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              
                            }}
                          >
                             {payment?.current_class}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              
                            }}
                          >
                             {payment?.bill_type}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "right",
                            }}
                          >
                            GHS {payment?.amount_paid}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {payment?.date_paid}
                          </td>
                        </tr>
                      );
                    })}

                   
                    
                    
                  </tbody>
                 
                </table>
              </div>
            </div>
            {/* <div className="hidden">
              <div ref={incomeRef} className="p-3">
                <div className="flex flex-col items-center justify-center py-5 border-b-2 border-slate-500">
                  <div className="flex items-center">
                    <img
                      src={`${img_base_url}${schoolInfo?.school_logo}`}
                      alt=""
                      className="h-20"
                    />
                    <h3 className="text-[30px] font-bold">
                      {schoolInfo?.school_name}
                    </h3>
                  </div>
                  <p>
                    {schoolInfo?.school_city} - {schoolInfo?.school_region}
                  </p>
                  <p>
                    {schoolInfo?.school_phone_number} -{" "}
                    {schoolInfo?.school_email}
                  </p>
                </div>
                <h1 style={{ textAlign: "center" }} className="mt-5">
                  Income Statement{" "}
                  {data.start_date && `${data.start_date} to ${data.end_date}`}
                </h1>
                <div>
                  <table
                    style={{
                      marginTop: "30px",
                      borderCollapse: "collapse",
                      width: "100%",
                    }}
                    className="income-statement-table"
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Item
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          GHS
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          GHS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Income
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                      </tr>
                      {incomeStatement.income_list.map((income) => {
                        return (
                          <tr>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                              }}
                            >
                              {income.revenue_type}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "right",
                              }}
                            >
                              {income.amount}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                              }}
                            ></td>
                          </tr>
                        );
                      })}

                      <tr style={{ fontWeight: "bold" }}>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Total Income
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "right",
                          }}
                        >
                          {incomeStatement.total_income}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                      </tr>

                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Expenses
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                      </tr>
                      {incomeStatement.expense_list.map((expense) => {
                        return (
                          <tr>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                              }}
                            >
                              {expense.expense_type}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "right",
                              }}
                            >
                              {expense.amount}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                              }}
                            ></td>
                          </tr>
                        );
                      })}

                      <tr style={{ fontWeight: "bold" }}>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Total Expenses
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "right",
                          }}
                        >
                          ({incomeStatement.total_expense})
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: "bold" }}>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Net Income
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        ></td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "right",
                          }}
                        >
                          {incomeStatement.net_income}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div> */}
          </div>
        ) : (
          <Loading />
        )}
        <div className="flex justify-between items-center mt-10">
        <p>
          Page {currentPage} of {totalPages}
        </p>

        <div className="flex flex-col gap-2 items-start md:px-5 md:flex-row">
          <button className={`border border-primary py-2 px-4 ${!prevPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handlePreviousPage} disabled={!prevPage}>
            Previous
          </button>
          {renderPageNumbers()}
          <button className={`border border-primary py-2 px-4 ${!nextPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handleNextPage} disabled={!nextPage}>
            Next
          </button>
          
        </div>
      </div>
      </div>
    </div>
  );
};

export default StudentFeePayments;
