import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/LoadingPage/Loading";
import { API_URL } from "../../url";
import ReactToPrint from "react-to-print";
import AddClassTeacherModal from "./AddClassTeacherModal";
import UpdateClassDetails from "./UpdateClassDetails";
import { useAuthenticate } from "../../context/AuthContext";

const ClassDetails = () => {
  const { id } = useParams();
  const { user } = useAuthenticate();
  const [classDetails, setClassDetails] = useState(null);
  const [toggle, setToggle] = useState(1);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [removing, setRemoving] = useState(false);
  const studentListRef = useRef();
  const subjectListRef = useRef();

  // UNASSIGN CLASS TEACHER
  const unassignClassTeacher = async (staff_id) => {
    if (!removing) {
      if (localStorage.getItem("access")) {
        setRemoving(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        try {
          await axios
            .delete(
              `${API_URL}academics/remove-class-teacher/${classDetails.id}/${staff_id}/`,
              config
            )
            .then((response) => {
              setRemoving(false);
              toast.success("Class Teacher Removed Successfully");
              window.location.reload();
            });
        } catch (error) {
          setRemoving(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else if (error.response.status === 400) {
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  // TOGGLE TABS
  const updateToggle = (tab) => {
    setToggle(tab);
  };

  // SET MODAL
  const toggleModal = () => {
    setModal(!modal);
  };

  // GET DETAILS OF A CLASS
  const getClassDetails = async (class_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/class/${class_id}`,
          config
        );
        setClassDetails(res.data);
      } catch (error) {
        if (error.response.status === 404) {
          toast.error("Class not found");
        } else {
          window.location.href = "/";
        }
      }
    }
  };

  useEffect(() => {
    getClassDetails(id);
  }, []);
  return (
    <div className="main-container">
      {updateModal && (
        <UpdateClassDetails
          modal={updateModal}
          setModal={setUpdateModal}
          classDetails={classDetails}
        />
      )}
      {classDetails ? (
        <div className="">
          <div className="header">
            <h1>{classDetails.class_name}</h1>
            <p>
              {" "}
              <b>Class Level:</b> {classDetails.class_level}
            </p>
            <p>
              <b>Class Teacher(s):</b>
              {classDetails.class_teachers.map((teacher, index) => {
                return (
                  <span>
                    {teacher?.user_account?.first_name}{" "}
                    {teacher?.user_account?.last_name},
                  </span>
                );
              })}
            </p>
          </div>
          <div className="positions-container">
            <div className="tabs">
              <div
                onClick={() => {
                  updateToggle(1);
                }}
                className={toggle === 1 ? "active-tab" : "tab"}
              >
                Class Members
              </div>
              <div
                onClick={() => {
                  updateToggle(2);
                }}
                className={toggle === 2 ? "active-tab" : "tab"}
              >
                Class Subjects
              </div>
              <div
                onClick={() => {
                  updateToggle(3);
                }}
                className={toggle === 3 ? "active-tab" : "tab"}
              >
                Class Configuration
              </div>
            </div>

            {/* CLASS LIST */}
            <div className={toggle === 1 ? "show-content" : "content"}>
              <div style={{ margin: "50px 25px" }} className="schedule-body">
                <div className="tab-top">
                  <div className="tab-top-left">
                    <b>{classDetails.students_in_class}</b> students in class
                  </div>
                  <div className="tab-top-right">
                    <div className="search-box">
                      {/* <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Search Students"
                      /> */}
                    </div>
                    <ReactToPrint
                      trigger={() => (
                        <div className="print-button">Print Class List</div>
                      )}
                      content={() => studentListRef.current}
                      documentTitle={`${classDetails.class_name} Student List`}
                      pageStyle="tab-bottom"
                    />
                  </div>
                </div>
                <div className="tab-bottom">
                  <table
                    style={{
                      backgroundColor: "rgb(255, 255, 255)",
                      borderCollapse: "collapse",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    ref={studentListRef}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            padding: "20px",
                            borderBottom: "1px solid rgb(196,196,196)",
                          }}
                        >
                          Student Name
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            padding: "20px",
                            borderBottom: "1px solid rgb(196,196,196)",
                          }}
                        >
                          Student ID
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            padding: "20px",
                            borderBottom: "1px solid rgb(196,196,196)",
                          }}
                        >
                          Date Of Birth
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            padding: "20px",
                            borderBottom: "1px solid rgb(196,196,196)",
                          }}
                        >
                          Gender
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {classDetails.students_list.map((student) => {
                        return (
                          <tr key={student.student_id}>
                            <td
                              style={{
                                padding: "13px 20px",
                                textAlign: "left",
                              }}
                            >
                              <Link>{student.get_full_name}</Link>
                            </td>
                            <td
                              style={{
                                padding: "13px 20px",
                                textAlign: "left",
                              }}
                            >
                              {student.student_school_id}
                            </td>
                            <td
                              style={{
                                padding: "13px 20px",
                                textAlign: "left",
                              }}
                            >
                              {student.date_of_birth}
                            </td>
                            <td
                              style={{
                                padding: "13px 20px",
                                textAlign: "left",
                              }}
                            >
                              {student.gender}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* CLASS SUBJECTS */}
            <div className={toggle === 2 ? "show-content" : "content"}>
              <div style={{ margin: "50px 25px" }} className="schedule-body">
                <div className="tab-top">
                  <div className="tab-top-left">
                    <b>{classDetails.class_subjects.length}</b> subjects
                  </div>
                  <div className="tab-top-right">
                    <div className="search-box">
                      {/* <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Search Subject"
                      /> */}
                    </div>
                    <ReactToPrint
                      trigger={() => (
                        <div className="print-button">Print Subject List</div>
                      )}
                      content={() => subjectListRef.current}
                      documentTitle={`${classDetails.class_name} Subject List`}
                    />
                  </div>
                </div>
                <div className="tab-bottom">
                  <table
                    style={{
                      backgroundColor: "rgb(255, 255, 255)",
                      borderCollapse: "collapse",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    ref={subjectListRef}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            padding: "20px",
                            borderBottom: "1px solid rgb(196,196,196)",
                          }}
                        >
                          Subject Name
                        </th>
                        {user?.account_type === "School Administrator" && (
                          <th>Actions</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {classDetails.class_subjects.map((subject) => {
                        return (
                          <tr key={subject.id}>
                            <td
                              style={{
                                padding: "13px 20px",
                                textAlign: "left",
                              }}
                            >
                              {subject.subject_name}
                            </td>
                            {user?.account_type === "School Administrator" && (
                              <td>
                                <div className="remove-button">
                                  Remove Subject
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* CLASS CONFIGURATION */}
            <div className={toggle === 3 ? "show-content" : "content"}>
              <div style={{ margin: "30px 25px" }} className="schedule-body">
                {user?.account_type === "School Administrator" && (
                  <div className="tab-top">
                    <div
                      onClick={() => toggleModal()}
                      className="classic-button"
                    >
                      Add Class Teacher
                    </div>
                    {modal && (
                      <AddClassTeacherModal
                        classDetails={classDetails}
                        modal={modal}
                        setModal={setModal}
                      />
                    )}

                    <div
                      onClick={() => setUpdateModal(!updateModal)}
                      className="classic-button-outline"
                    >
                      Update Class Details
                    </div>
                  </div>
                )}
                <div className="tab-bottom">
                  <table
                    style={{
                      backgroundColor: "rgb(255, 255, 255)",
                      borderCollapse: "collapse",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    ref={subjectListRef}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            padding: "20px",
                            borderBottom: "1px solid rgb(196,196,196)",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            padding: "20px",
                            borderBottom: "1px solid rgb(196,196,196)",
                          }}
                        >
                          Staff ID
                        </th>
                        <th>Staff Position</th>
                        <th>Gender</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classDetails.class_teachers.map((teacher, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                padding: "13px 20px",
                                textAlign: "left",
                              }}
                            >
                              {teacher?.user_account?.first_name}{" "}
                              {teacher?.user_account?.last_name}
                            </td>
                            <td
                              style={{
                                padding: "13px 20px",
                                textAlign: "left",
                              }}
                            >
                              {teacher.staff_school_id}
                            </td>
                            <td
                              style={{
                                padding: "13px 20px",
                                textAlign: "left",
                              }}
                            >
                              {teacher.position}
                            </td>
                            <td
                              style={{
                                padding: "13px 20px",
                                textAlign: "left",
                              }}
                            >
                              {teacher.gender}
                            </td>
                            {user?.account_type === "School Administrator" && (
                              <td>
                                <div
                                  onClick={() =>
                                    unassignClassTeacher(teacher.staff_id)
                                  }
                                  className="remove-button"
                                >
                                  Unassign
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ClassDetails;
