import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/LoadingPage/Loading";
import { API_URL } from "../../url";
import ReactPaginate from "react-paginate";

const SchoolsList = () => {
  const [registeredSchools, setRegisteredSchools] = useState([]);
  const [loading, setLoading] = useState(true);

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = registeredSchools.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(registeredSchools.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % registeredSchools.length;
    setItemOffset(newOffset);
  };

  const getRegisteredSchools = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      await axios
        .get(`${API_URL}superadmin/schools/`, config)
        .then((response) => {
          setRegisteredSchools(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getRegisteredSchools();
  }, []);

  return (
    <div className="main-container">
      <div className="header">
        <h1>SCHOOL LIST</h1>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="schedule-body">
          <div className="student-list">
            <div className="panel-header">
              <h4>Registered Schools</h4>
            </div>
            <div className="list-body">
              <table>
                <thead>
                  <tr>
                    <th>School ID</th>
                    <th>School Name</th>
                    <th>Date Registered</th>
                    <th>Total Students</th>
                    <th>ACcount Status</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((school, index) => (
                    <tr key={index}>
                      <td>{school.school_id}</td>
                      <td>{school.school_name}</td>
                      <td>{school.date_registered}</td>
                      <td>{school.number_of_students}</td>
                      <td>
                        {school.is_active ? (
                          <span className="green-badge">Active</span>
                        ) : (
                          <span className="red-badge">Inactive</span>
                        )}
                      </td>
                      <td>
                        <Link to={`/school-details/${school.school_id}`}>
                          <button className="viewButton">View Details</button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="pagination-container">
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="pagination-active-page"
                pageLinkClassName="page-link"
              />
              {/* <div class="pagination">
                    <a href="#">&laquo;</a>
                    <a href="#">1</a>
                    <a href="#" class="active">2</a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#">5</a>
                    <a href="#">6</a>
                    <a href="#">&raquo;</a>
                </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SchoolsList;
