import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Multiselect from "multiselect-react-dropdown";
import { useAuthenticate } from "../../context/AuthContext";

const AddClassSubjects = () => {
  const { schoolClasses } = useAuthenticate();
  const [subjects, setSubjects] = useState([])
  const [loading, setLoading] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const selectedSubjectsIds = [];
    for (let i = 0; i < selectedSubjects.length; i++) {
      selectedSubjectsIds.push(selectedSubjects[i].id);
    }
    const body = JSON.stringify({
      related_class: selectedClass,
      subjects: selectedSubjectsIds,
    });
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      try {
        const res = await axios.post(
          `${API_URL}academics/assign-class-subjects/`,
          body,
          config
        );
        
        setLoading(false);
        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Class subjects has been assigned successfully",
          }).then(() => {
            window.location.reload();
          });
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  const getSubjects = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      
      try {
        const res = await axios.get(`${API_URL}academics/subjects/`, config);
        
        setSubjects(res.data);
      } catch (error) {}
    }
  };

  useEffect(()=>{
    getSubjects()
  },[])

  return (
    <div className="exam-schedule">
      <div className="exam-schedule-content">
        <div className="">
          <p>Add Class Subject</p>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="formSection">
            <div className="formRow">
              <div className="formInput">
                <label>Class*</label>
                <select
                    name="related_class"
                    onChange={(e) => setSelectedClass(e.target.value)}
                    required
                >
                    <option>Select Class*</option>
                    {schoolClasses.map((schoolClass) => {
                    return (
                        <option value={schoolClass.id} key={schoolClass.id}>
                        {schoolClass.class_name}
                        </option>
                    );
                    })}
                </select>
              </div>
            </div>
            <div className="formRow">
                <div className="formInput">
                <label>Subjects</label>
                <Multiselect
                    onSelect={(e) => setSelectedSubjects(e)}
                    avoidHighlightFirstOption={true}
                    placeholder="Select Subjects"
                    name="classes"
                    options={subjects} // Options to display in the dropdown
                    displayValue="subject_name" // Property name to display in the dropdown options
                />
                </div>
            </div>
          </div>
          {loading ? (
            <button
              style={{
                backgroundColor: "gray",
                cursor: "wait",
              }}
            >
              <ColorRing
                visible={true}
                height="20"
                width="20"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />{" "}
              Adding Class Subjects
            </button>
          ) : (
            <div>
              <button type="submit">Add Class Subjects</button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddClassSubjects;
