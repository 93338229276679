import React, { useState } from "react";
import { useAuthenticate } from "../../context/AuthContext";
import { ColorRing } from "react-loader-spinner";
import { AiFillDelete } from "react-icons/ai";
import "./ExamSchedule.scss";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ExamScheduleModal = () => {
  const { academicYears, schoolClasses } = useAuthenticate();
  const [subjects, setSubjects] = useState([])
  const [academicTerms, setAcademicTerms] = useState([])
  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState([
    {
      date: "",
      subject: "",
      exams_venue: "",
      starting_time: "",
      ending_time: "",
    },
  ]);
  const [data, setData] = useState({
    school_class: "",
    term: "",
  });

  const changeAcademicYear = (e) => {

    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  const fetchClassTermSubjects = async (e) =>{
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(`${API_URL}academics/class-term-subjects/${data.school_class}/`, config);
        setSubjects(res.data)
      } catch (error) {
        
      }
    }
  }

  const handleFormFieldsChange = (e, index) => {
    const formData = [...formFields];
    formData[index][e.target.name] = e.target.value;
    setFormFields(formData);
  };

  const addFields = () => {
    const object = {
      date: "",
      subject: "",
      exams_venue: "",
      starting_time: "",
      ending_time: "",
    };
    setFormFields([...formFields, object]);
  };

  const removeFields = (index) => {
    const formFieldsData = [...formFields];
    formFieldsData.splice(index, 1);
    setFormFields(formFieldsData);
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(e);

    if (localStorage.getItem("access")) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      const body = JSON.stringify({
        ...data,
        schedules: formFields,
      });

      try {
        await axios.post(`${API_URL}academics/exam-schedule/`, body, config);

        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Exam Schedule Created Successfully",
        }).then(()=>{
          window.location.reload()
        });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if(error.response.status === 401){
            window.location.href="/"
          }else{
            for (const property in error.response.data) {
              toast.error(`${property} error: ${error.response.data[property]}`);
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  return (
    <div className="exam-schedule">
      <div className="exam-schedule-content">
        <div className="exam-schedule-header">
          <p>Add Exam Schedule</p>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="formSection">
            <div className="formRow">
              <div className="formInput">
                <label>Class</label>
                <select name="school_class" onChange={(e) => handleChange(e)}>
                  <option value="">Select Class</option>
                  {schoolClasses.map((schoolClass) => {
                    return (
                      <option value={schoolClass.id} key={schoolClass.id}>
                        {schoolClass.class_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="formInput">
                <label>Academic Year</label>
                <select name="academic_year" onChange={(e) => changeAcademicYear(e)}>
                  {data.school_class === "" ? (
                    <option>Select Class First</option>
                  ) : (
                    <>
                      <option>Academic Year</option>
                      {academicYears.map((year) => {
                        return (
                          <option value={year.id} key={year.id}>
                            {year.name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
              <div className="formInput">
                <label>Term</label>
                <select name="term" onChange={(e) => {handleChange(e); fetchClassTermSubjects(e)}}>
                  {data.school_class === "" ? (
                    <option>Select Class First</option>
                  ) : (
                    <>
                      <option>Academic Term</option>
                      {academicTerms.map((term) => {
                        return (
                          <option value={term.id} key={term.id}>
                            {term.name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>
          {formFields.map((formField, index) => {
            return (
              <div className="formSection" key={index}>
                <div className="sectionHeader">
                  <p>Schedule Detail</p>
                  <AiFillDelete
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => removeFields(index)}
                  />
                </div>
                <div className="formRow">
                  <div className="formInput">
                    <label>Date</label>
                    <input
                      onChange={(e) => handleFormFieldsChange(e, index)}
                      type="date"
                      name="date"
                      id="date"
                    />
                  </div>
                  <div className="formInput">
                    <label>Subject</label>
                    <select
                      name="subject"
                      onChange={(e) => handleFormFieldsChange(e, index)}
                    >
                      <option>Select Subject</option>
                      {subjects.map((subject) => {
                        return (
                          <option value={subject.id} key={subject.id}>
                            {subject.subject_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="formInput">
                    <label>Venue</label>
                    <input
                      type="text"
                      name="exams_venue"
                      id="venue"
                      onChange={(e) => handleFormFieldsChange(e, index)}
                    />
                    <small>Specify the hall they will write the exam at</small>
                  </div>
                </div>
                <div className="formRow">
                  <div className="formInput">
                    <label>Start Time</label>
                    <input
                      type="time"
                      name="starting_time"
                      onChange={(e) => handleFormFieldsChange(e, index)}
                    />
                  </div>
                  <div className="formInput">
                    <label>End Time</label>
                    <input
                      type="time"
                      name="ending_time"
                      onChange={(e) => handleFormFieldsChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          {loading ? (
            <button
              style={{
                backgroundColor: "gray",
                cursor: "wait",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                border: "none",
                padding: "10px",
                color: "white",
                margin: "auto",
              }}
            >
              <ColorRing
                visible={true}
                height="20"
                width="20"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />{" "}
              Adding Exam Schedule
            </button>
          ) : (
            <div className="schedule-buttons">
              <div
                title="Click To Add New Exam Schedule for the same class"
                onClick={addFields}
                className="addBtn"
              >
                Add Schedule Detail
              </div>
              <button type="submit" style={{backgroundColor:"rgb(71, 169, 146)"}}>Submit Exam Schedules</button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ExamScheduleModal;
