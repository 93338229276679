import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SchoolAdminDashboard from '../pages/SchoolAdminPages/SchoolAdminDashboard'
import { useAuthenticate } from '../context/AuthContext'
import AddStaff from '../pages/Staffs/AddStaff'
import AddMultipleStaff from '../pages/Staffs/AddMultipleStaff'
import StaffList from '../pages/Staffs/StaffList'
import StaffPosition from '../pages/Staffs/StaffPosition'
import StaffProfile from '../pages/Staffs/StaffProfile'
import Administrators from '../pages/Administrators/Administrators'
import NewSchoolAdministrator from '../pages/Administrators/NewSchoolAdministrator'
import AdministratorDetails from '../pages/Administrators/AdministratorDetails'


const SchoolAdminRoutes = () => {
  const {schoolInfo} = useAuthenticate()
  // if (!schoolInfo?.active_subscription){
  //   window.location.href = "/settings/subscription"
  // }
  return (
    <Routes>
        <Route path='/dashboard' element={<SchoolAdminDashboard />} />

        {/* STAFFS */}
        <Route path="staffs/add-staff/" element={<AddStaff />} />
            <Route
              path="staffs/add-multiple-staff/"
              element={<AddMultipleStaff />}
            />
            <Route path="staffs/staff-list" element={<StaffList />} />
            <Route path="staffs/staff-position" element={<StaffPosition />} />
            <Route path="staff/:id" element={<StaffProfile />} />
            <Route path='school-administrators/' element={<Administrators />} />
            <Route path="school-administrators/new" element={<NewSchoolAdministrator />} />
            <Route path="school-administrator/:id/details" element={<AdministratorDetails />} />
    </Routes>
  )
}

export default SchoolAdminRoutes