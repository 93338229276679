import axios from "axios";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const ResetPasswordModal = ({ setModal, account_id }) => {
  const [loading, setLoading] = useState(false);
  const [passwordData, setPasswordData] = useState({});
  const [showPassword, setShowPassword] = useState(false)

  const onChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  // CHANGE STAFF PASSWORD
  const changePassword = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (passwordData.new_password !== passwordData.confirm_password) {
        toast.error("The two passwords do not match");
      } else {
        if (localStorage.getItem("access")) {
          setLoading(true);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          const body = JSON.stringify(passwordData);
          try {
            await axios
              .post(
                `${API_URL}accounts/reset-user-password/${account_id}/`,
                body,
                config
              )
              .then((response) => {
                setLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "Updated",
                  text: "Password Changed Successfully",
                }).then(() => {
                  setModal(false);
                });
              });
          } catch (error) {
            setLoading(false);
            if (error.response.status === 400) {
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    }
  };

  return (
    <div
      id="modal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal"
      onClick={() => setModal(false)}
    >
      <div class="bg-white rounded-lg shadow-lg lg:w-1/3 w-4/5 max-h-screen overflow-auto" onClick={(e)=>e.stopPropagation(e)}>
        <div class="px-6 py-4 border-b text-black items-center flex justify-between">
          <h3 class="text-lg  font-semibold">Reset Password</h3>
          <IoCloseCircleOutline
            onClick={() => setModal(false)}
            className="text-[20px] cursor-pointer"
          />
        </div>
        <div class="px-4">
          <div className="shadow mt-5 lg:mt-0 overflow-hidden sm:rounded-md bg-white">
            <form
              onSubmit={(e) => changePassword(e)}
              className="px-4 py-2 bg-white sm:p-6"
            >
              <div className="grid grid-cols-6 gap-2">
                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    New Password
                  </label>

                  <input
                    type={`${showPassword ? "text" : 'password'}`}
                    name="new_password"
                    onChange={(e) => onChange(e)}
                    id="newPassword"
                    required
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  />
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Confirm Password
                  </label>
                  <input
                    type={`${showPassword ? "text" : 'password'}`}
                    name="confirm_password"
                    onChange={(e) => onChange(e)}
                    required
                    class="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  />
                </div>

                
                <div className="col-span-6">
                  <div className="radioInline">
                    <input
                      type="checkbox"
                      name="show_password"
                      onChange={(e) =>
                        setShowPassword(e.target.checked ? true : false)
                      }
                    />{" "}
                    Show Password
                  </div>
                  
                </div>
              </div>

              {loading ? (
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                <button
                  type="submit"
                  value="Save"
                  id="payouts-account-submit"
                  class="mt-2 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out text-white bg-primary hover:bg-secondary"
                >
                  Reset Password
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
