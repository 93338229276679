import React, { useState, useRef, useEffect } from "react";

import Logo from "../../assets/schoolLogo.png";
import { useAuthenticate } from "../../context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL, img_base_url } from "../../url";
import { ColorRing } from "react-loader-spinner";
import ReactToPrint from "react-to-print";
import ApproveReportModal from "./ApproveReportModal";
import TeacherRemarksModal from "./TeacherRemarksModal";
import Header from "../../components/Header";
const today = new Date().toISOString().slice(0, 10);

const StudentExamResults = () => {
  const { academicYears, schoolInfo, schoolClasses, user } = useAuthenticate();
  const [students, setStudents] = useState([]);
  const [teacherClasses, setTeacherClasses] = useState([]);
  const [academicTerms, setAcademicTerms] = useState([]);
  const [academicYear, setAcademicYear] = useState("");
  const [academicTerm, setAcademicTerm] = useState("");
  const [student, setStudent] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [studentResult, setStudentResult] = useState([]);
  const [modal, setModal] = useState(false);
  const [teacherRemarksModal, setTeacherRemarksModal] = useState(false)
  const componentRef = useRef();

  const changeAcademicYear = (e) => {
    setAcademicYear(e.target.value);

    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  // GET STUDENT REPORT
  const fetchStudentReport = async (e) => {
    e.preventDefault();
    if (student === "" || academicYear === "" || academicTerm === "") {
      toast.error("Please select all fields");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/exam-result/${academicTerm}/${student}`,
            config
          );
          setStudentResult(res.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  // FETCH CLASS STUDENTS
  const fetchClassStudents = async (e) => {
    if (e.target.value !== "") {
      if (localStorage.getItem("access")) {
        setLoadingStudents(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-students/${e.target.value}`,
            config
          );
          setStudents(res.data);
          setLoadingStudents(false);
        } catch (error) {
          setLoadingStudents(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    } else {
      setStudents([]);
    }
  };

  // teacher classes
  const getTeacherClasses = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      await axios.get(
        `${API_URL}teacher/assigned-classes`,
        config
      ).then(response => {
        setTeacherClasses(response.data)
      }).catch(error => {
        
      })

  
    }
  };

  useEffect(()=>{
    if(user?.account_type === "School Staff"){
      getTeacherClasses();
    }
  },[])
  
  return (
    <div className="main-container">
      <Header title="Student Report Card" subtitle="View Student Report" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => fetchStudentReport(e)}>
            {/* CLASSES DROPDOWN */}
            <div className="formInput">
              <select
                name="school_class"
                id=""
                onChange={(e) => fetchClassStudents(e)}
              >
                <option value="">Select Class</option>
                {
                  user?.account_type === "School Administrator" && (
                    <>
                      {schoolClasses.map((schoolClass) => (
                        <option key={schoolClass.id} value={schoolClass.id}>
                          {schoolClass.class_name}
                        </option>
                      ))}
                    </>
                  )
                }

                {/* IF LOGGED IN USER IS A TEACHER */}
                {
                  user?.account_type === "School Staff" && user?.position === "Teacher" && (
                    <>
                      {teacherClasses.map((schoolClass) => (
                        <option key={schoolClass.id} value={schoolClass.id}>
                          {schoolClass.class_name}
                        </option>
                      ))}
                    </>
                  )
                }
              </select>
            </div>
            {/* STUDENTS LIST DROPDOWN */}
            <div className="formInput">
              <select
                name="student"
                id="student"
                required
                onChange={(e) => setStudent(e.target.value)}
              >
                {loadingStudents ? (
                  <option>Loading Students...</option>
                ) : (
                  <>
                    {students.length < 1 ? (
                      <option>Select Class First</option>
                    ) : (
                      <>
                        <option value="">Select Student</option>
                        {students.map((student) => {
                          return (
                            <option
                              key={student.student_id}
                              value={student.student_id}
                            >
                              {student?.get_full_name}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </select>
            </div>
            {/* ACADEMIC YEAR DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_year"
                id="academic_term"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* ACADEMIC TERM DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_term"
                onChange={(e) => setAcademicTerm(e.target.value)}
                id="academic_term"
              >
                {academicYear === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            {/* IF FILTERING DATA SHOW A LOADING BUTTON ELSE SHOW A SUBMIT BUTTON */}
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data...{" "}
              </button>
            ) : (
              <button type="submit">Filter</button>
            )}
          </form>
        </div>
        {/* IF NO RESULT IS FOUND DISPLAY A NOT FOUND MESSAGE ELSE DISPLAY THE RESULTS */}
        {studentResult.length <= 0 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              fontSize: "30px",
              fontWeight: "500",
            }}
          >
            There is No Result For This Student For This Term
          </div>
        ) : (
          <div>
            <div className="buttons">
              <ReactToPrint
                trigger={() => (
                  <button
                  className="px-[25px] py-[10px] bg-secondary-100 mt-[30px] text-white rounded"
                    
                  >
                    {" "}
                    Print Report
                  </button>
                )}
                content={() => componentRef.current}
              />

                {
                  user?.account_type === "School Administrator" && (
                      <button
                        onClick={() => setModal(true)}
                        className="border border-secondary-100 rounded text-secondary-100 px-[25px] py-[10px] ml-5"
                        
                      >
                        {" "}
                        {studentResult.result_details.head_teacher_remarks ? (
                          "Edit Remarks"
                        ):("Add Remarks")
                        }
                      </button>
                  )
                }


{
                  user?.account_type === "School Staff" && user?.position === "Teacher" && (
                      <button
                        onClick={() => setTeacherRemarksModal(true)}
                        style={{
                          backgroundColor: "#7A9D54",
                          color: "white",
                          cursor: "pointer",
                          border: "none",
                          padding: "10px 15px",
                          marginTop: "30px",
                          marginLeft: "30px",
                        }}
                      >
                        {" "}
                        {studentResult.result_details.class_teacher_remarks ? (
                          "Edit Remarks"
                        ):("Add Remarks")
                        }
                      </button>
                  )
                }
              
            </div>
            {/* EXAM RESULT  */}
            <div
              ref={componentRef}
              style={{
                
                
              }}
              className="p-5 relative overflow-scroll"
            >
              <div
                style={{
                  textAlign: "right",
                  color: "white",
                  backgroundColor: "#5b3016",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              ></div>
              {/* WATERMARK */}
              <img
                src={schoolInfo.school_logo ? `${img_base_url}${schoolInfo.school_logo}` : Logo}
                alt="logo"
                style={{
                  opacity: 0.1,
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  height: "100%",
                  transform: "translate(-50%,-50%)",
                }}
              />
              <div
                className="flex flex-col items-center justify-center"
                
              >
                <div className="">
                  <img
                    style={{ height: "100px", borderRadius: "50%" }}
                    src={schoolInfo.school_logo ? `${img_base_url}${schoolInfo.school_logo}` : Logo}
                    alt="school logo"
                  />
                </div>
                <div className="text-center" style={{ flex: "1" }}>
                  <h3>{schoolInfo.school_name} </h3>
                  <p>
                    {schoolInfo.school_city} {schoolInfo.school_district} -{" "}
                    {schoolInfo.school_region}
                  </p>
                  <p>{schoolInfo.school_phone_number}</p>
                  <p>{schoolInfo.school_email}</p>
                </div>
              </div>
              <div className="lg:flex items-center justify-center">
                <div className="">
                  <table className="border-collapse">
                    <tr>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        Name:
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        {studentResult.student_name}
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        Student ID:
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        {studentResult.student_id}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        Student Class:
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        {studentResult.student_class}
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        Attendance:
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        {studentResult.student_attendance.days_present} out of {studentResult.student_attendance.overall}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        Students In Class:
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                      {studentResult.result_details.students_in_class}
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        Overall Position:
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        {studentResult.result_details.overall_position}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        Overall Marks:
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                      {studentResult.result_details.total_marks} / {studentResult.overall_total}
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        Next Term Begins
                      </td>
                      <td className="border border-slate-400 px-2 py-2 min-w-[150px] text-[14px] font-[600]">
                        {studentResult.next_term_start_date}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              
              <div
                className="result"
                style={{
                  marginTop: "15px",
                  
                }}
              >
                <h3 className="uppercase font-[700] text-center mb-5">
                  RESULTS FOR {studentResult.term},{" "}
                  {studentResult.academic_year} ACADEMIC YEAR
                </h3>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: "30%",
                          border: "1px solid #ddd",
                          padding: "8px",
                          lineHeight: "1.428",
                          textAlign: "left",
                        }}
                      >
                        Subject
                      </th>
                      <th
                        style={{
                          width: "15%",
                          border: "1px solid #ddd",
                          padding: "8px",
                          lineHeight: "1.428",
                          textAlign: "left",
                          
                        }}
                      >
                        SBA Marks{" "}
                      </th>
                      <th
                        style={{
                          width: "15%",
                          border: "1px solid #ddd",
                          padding: "8px",
                          lineHeight: "1.428",
                          textAlign: "left",
                          
                        }}
                      >
                        Exam Marks{" "}
                      </th>
                      <th
                        style={{
                          width: "15%",
                          border: "1px solid #ddd",
                          padding: "8px",
                          lineHeight: "1.428",
                          textAlign: "left",
                          
                        }}
                      >
                        Total{" "}
                      </th>
                      <th
                        style={{
                          width: "10%",
                          border: "1px solid #ddd",
                          padding: "8px",
                          lineHeight: "1.428",
                          textAlign: "left",
                          
                        }}
                      >
                        Grade
                      </th>
                      <th
                        style={{
                          width: "10%",
                          border: "1px solid #ddd",
                          padding: "8px",
                          lineHeight: "1.428",
                          textAlign: "center",
                        }}
                      >
                        Grade Position
                      </th>
                      <th
                        style={{
                          width: "20%",
                          border: "1px solid #ddd",
                          padding: "8px",
                          lineHeight: "1.428",
                          textAlign: "left",
                          
                        }}
                      >
                        Remarks
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{fontSize:"12px"}}>
                    {studentResult.results.map((result) => {
                      return (
                        <tr key={result.id}>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              lineHeight: "1.428",
                              textAlign: "left",
                              fontSize:"12px"
                            }}
                          >
                            {result.subject}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              lineHeight: "1.428",
                              textAlign: "left",
                              fontSize:"12px"
                            }}
                          >
                            {result.sba_marks}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              lineHeight: "1.428",
                              textAlign: "left",
                              fontSize:"12px"
                            }}
                          >
                            {result.main_exam_marks}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              lineHeight: "1.428",
                              textAlign: "left",
                              fontSize:"12px"
                            }}
                          >
                            {result.total_mark}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              lineHeight: "1.428",
                              textAlign: "left",
                              fontSize:"12px"
                            }}
                          >
                            {result.grade}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              lineHeight: "1.428",
                              textAlign: "left",
                              fontSize:"12px"
                            }}
                          >
                            {result.grade_position}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              lineHeight: "1.428",
                              textAlign: "left",
                              fontSize:"12px"
                            }}
                          >
                            {result.remarks}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="report-details" style={{marginTop:"20px", marginBottom:"20px"}}>
                <div style={{display:"flex"}}>

                  <div style={{ marginBottom: "20px", flex:"1" }} className="detail">
                    <h5 className="font-[600]">Interest:</h5>
                    {studentResult.result_details.interest}
                  </div>
                  <div style={{ marginBottom: "20px", flex:"1" }} className="detail">
                    <h5 className="font-[600]">Attitude:</h5>
                    {studentResult.result_details.attitude}
                  </div>
                </div>
                <div style={{display: "flex"}}>
                  <div style={{ marginBottom: "20px", flex:"1" }} className="detail">
                    <h5 className="font-[600]">Class Teacher's Remarks:</h5>
                    {studentResult.result_details.class_teacher_remarks}
                  </div>
                  <div style={{ marginBottom: "20px", flex:"1" }} className="detail">
                    <h5 className="font-[600]">Head Teacher's Remarks:</h5>
                    {studentResult.result_details.head_teacher_remarks}
                  </div>
                </div>
              </div>
              <div>
                
                <div
                  style={{
                    color: "white",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    background: "#5b3016",
                  }}
                >
                  <p
                    style={{
                      textAlign: "right",
                      width: "100%",
                      paddingRight: "10px",
                    }}
                  >
                    Date Printed: {today}
                  </p>
                </div>
                <p
                  className="info-text"
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    color: "#8a8a8a",
                    marginTop: "0px",
                  }}
                >
                  Generated with Meskuu School Management
                  System
                </p>
              </div>
            </div>
            {modal && (
              <ApproveReportModal
                modal={modal}
                resultDetails = {studentResult.result_details}
                fetchStudentReport={fetchStudentReport}
                setModal={setModal}
                term={academicTerm}
                student={student}
              />
            )}
            {
              teacherRemarksModal && (
                <TeacherRemarksModal 
                modal={teacherRemarksModal}
                resultDetails = {studentResult.result_details}
                fetchStudentReport={fetchStudentReport}
                setModal={setTeacherRemarksModal}
                term={academicTerm}
                student={student}
                />
              )
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentExamResults;
