import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";

const AddSchool = () => {
  const [schoolData, setSchoolData] = useState({})
  const [creatingSchool, setCreatingSchool] = useState(false)
  
  const updateData = (e) => {
    setSchoolData({
      ...schoolData, [e.target.name] : e.target.value
    })
  }

  const createSchool = async (e) => {
    e.preventDefault()
    setCreatingSchool(true)
    const config = {
      headers:{
        "Content-Type": "application/json",
        Authorization : `JWT ${localStorage.getItem("access")}`
      }
    }

    const body =JSON.stringify(schoolData)

    await axios.post(`${API_URL}accounts/register-school/`,body, config).then((response)=>{
      setCreatingSchool(false)
      if(response?.data?.success){
        Swal.fire({
          title:"School Created",
          text:"School Created Successfully",
          icon:"success",
        }).then(()=>{
          window.location.reload();
        })
      }else{
        toast.error(response?.data?.message)
        if(response?.data?.error){
          for (const property in response?.data?.errors) {
            if (property === "0") {
              toast.error(`${response?.data?.errors[property]}`);
            } else {
              toast.error(
                `${property} error: ${response.data?.errors[property]}`
              );
            }
          }
        }
      }
    }).catch((error)=>{
      setCreatingSchool(false)
      toast.error("Something went wrong. Please try again")
    })
  }

  return (
    <div className="main-container">
      <div className="header">
        <h1>ADD SCHOOL</h1>
      </div>
      <div>
        <form onSubmit={(e)=>createSchool(e)}>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                School's Name *
              </label>
              <input
                type="text"
                name="school_name"
                required
                onChange={(e)=>updateData(e)}
                className="border border-slate-400 rounded py-3 px-2"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                School's Initials *
              </label>
              <div className="flex flex-col">
                <input
                  type="text"
                  name="school_initials"
                  required
                  onChange={(e)=>updateData(e)}
                  className="border border-slate-400 rounded py-3 px-2"
                />
                <small>
                  This will be used to generate the staff and student's ID
                </small>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                School's Short Name *
              </label>
              <input
                type="text"
                name="school_short_name"
                required
                onChange={(e)=>updateData(e)}
                className="border border-slate-400 rounded py-3 px-2"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                School Type *
              </label>
              <select
                name="school_type"
                id=""
                onChange={(e)=>updateData(e)}
                className="border border-slate-400 rounded py-3 px-2"
              >
                <option value="">Select School Type</option>
                <option value="Private">Private</option>
                <option value="Government">Government</option>
              </select>
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                Education Level *
              </label>
              <select
                name="school_type"
                id=""
                onChange={(e)=>updateData(e)}
                className="border border-slate-400 rounded py-3 px-2"
              >
                <option value="">Select Education Level</option>
                <option value="Day Care">Day Care</option>
                <option value="Primary">Primary</option>
                <option value="Junior High">Junior High</option>
                <option value="Day Care To Primary">Day Care To Primary</option>
                <option value="Day Care To Junior High">
                  Day Care To Junior High
                </option>
                <option value="Primary To Junior High">
                  Primary To Junior High
                </option>
              </select>
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                School's Email Address
              </label>
              <input
                type="email"
                name="school_email"
                onChange={(e)=>updateData(e)}
                className="border border-slate-400 rounded py-3 px-2"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                School's Phone Number
              </label>
              <input
                type="text"
                name="school_phone_number"
                onChange={(e)=>updateData(e)}
                className="border border-slate-400 rounded py-3 px-2"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                State/Region
              </label>
              <input
                type="text"
                name="school_region"
                onChange={(e)=>updateData(e)}
                className="border border-slate-400 rounded py-3 px-2"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                City
              </label>
              <input
                type="text"
                name="school_city"
                onChange={(e)=>updateData(e)}
                className="border border-slate-400 rounded py-3 px-2"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                SMS ID
              </label>
              <input
                type="text"
                name="sms_id"
                onChange={(e)=>updateData(e)}
                className="border border-slate-400 rounded py-3 px-2"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                SMS Bundle
              </label>
              <input
                type="number"
                name="sms_bundle"
                onChange={(e)=>updateData(e)}
                
                className="border border-slate-400 rounded py-3 px-2"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="" className="font-[500]">
                Uses EYFS
              </label>
              <div className="flex gap-3">
                
                <div className="flex">
                  <input
                    type="radio"
                    name="uses_eyfs"
                    value={true}
                    onChange={(e)=>updateData(e)}
                    className="border border-slate-400 rounded py-3 px-2"
                  /> <span>Yes</span>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    name="uses_eyfs"
                    value={false}
                    onChange={(e)=>updateData(e)}
                    className="border border-slate-400 rounded py-3 px-2"
                  /> <span>No</span>
                </div>
              </div>
            </div>
          </div>

          <div className="py-5 border-t border-gray-400 mt-5">
            <h4 className="mb-2">School Administrator's Information</h4>
            <div className="grid lg:grid-cols-2 gap-3">
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-[500]">
                  First Name*
                </label>
                <input
                required
                  type="text"
                  name="first_name"
                  onChange={(e)=>updateData(e)}
                  className="border border-slate-400 rounded py-3 px-2"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-[500]">
                  Last Name*
                </label>
                <input
                required
                  type="text"
                  name="last_name"
                  onChange={(e)=>updateData(e)}
                  className="border border-slate-400 rounded py-3 px-2"
                />
              </div>
              <div className='flex flex-col gap-1'>
                <label htmlFor="" className='font-[500]'>Email Address*</label>
                <input type="text" onChange={(e)=>updateData(e)} name='user_email' required className='border border-slate-400 rounded py-3 px-2' />
              </div>
              <div className='flex flex-col gap-1'>
                <label htmlFor="" className='font-[500]'>Phone Number*</label>
                <input type="text" required onChange={(e)=>updateData(e)} name='phone_number' className='border border-slate-400 rounded py-3 px-2' />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            {
              creatingSchool ? (
                <div className="flex items-center gap-1 bg-blue-200 cursor-wait px-5 py-3 rounded">
                  <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#055", "#055", "#055", "#055", "#055"]}
                /> Creating School
                </div>
              ):<button type="submit" className="bg-blue-500 text-white px-5 py-3 rounded">Create School</button>
            }
            
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSchool;
