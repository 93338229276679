import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const MarkAttendance = () => {
  const { academicYears } = useAuthenticate();
  const [academicTerms, setAcademicTerms] = useState([]);
  const [teacherClasses, setTeacherClasses] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [selectedAcademicTerm, setSelectedAcademicTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [academicYear, setAcademicYear] = useState("");
  const [savingAttendance, setSavingAttendance] = useState(false);

  const changeAcademicYear = (e) => {
    setAcademicYear(e.target.value);
    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  const getAttendanceData = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    axios
      .get(
        `${API_URL}academics/attendance/${selectedAcademicTerm}/${selectedClass}/${selectedDate}`,
        config
      )
      .then((response) => {
        setAttendanceList(response.data);
        setLoading(false);
        
        setDataFetched(true);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to get attendance data")
      });
  };

  const markStudentAttendance = (status, studentId) => {
    // first check if student already exists in attendance entry list;
    const student_attendance = attendanceList?.find(
      (attendanceItem) => attendanceItem.student.student_id === studentId
    );
    if (student_attendance !== undefined) {
      const updatedAttendanceList = attendanceList.map((attendanceEntry) => {
        if (attendanceEntry.student.student_id === studentId) {
          return {
            ...student_attendance,
            status,
          };
        }
        return attendanceEntry;
      });
      setAttendanceList(updatedAttendanceList);
    } else {
      toast.error("Something went wrong. Please try again later", "error");
    }
  };

  const submitAttendance = (e) => {
    e.preventDefault();
    setSavingAttendance(true);
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
    const data = JSON.stringify({
        attendances: attendanceList?.map((attendanceItem) => {
          return {
            student: attendanceItem.student.student_id,
            status: attendanceItem?.status,
          };
        }),
        date: selectedDate,
        student_class: selectedClass,
        term: selectedAcademicTerm,
      });

      axios.post(`${API_URL}academics/save-attendance/`, data, config).then((response) => {
        if(response?.status === 201){
            Swal.fire({
                title:"Success",
                icon:"success",
                text:"Attendance saved successfully"
            }).then(()=>{
                getAttendanceData()
            })
        }
        setSavingAttendance(false)
      }).catch((error) => {
        toast.error("An error occured. Please try again.")
        setSavingAttendance(false)
      });


    
  }

  // teacher classes
  const getTeacherClasses = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      await axios.get(
        `${API_URL}teacher/assigned-classes`,
        config
      ).then(response => {
        setTeacherClasses(response.data)
      }).catch(error => {
        
      })

  
    }
  };

  useEffect(()=>{
    getTeacherClasses()
  },[])

  return (
    <div className="main-container">
      <div className="header">
        <h1>MARK ATTENDANCE</h1>
      </div>
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => getAttendanceData(e)}>
            {/* ACADEMIC YEAR DROPDOWN */}
            <div className="formInput">
              <label htmlFor="">Academic Year*</label>
              <select
                name="academic_year"
                id="academic_term"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* ACADEMIC TERM DROPDOWN */}
            <div className="formInput">
              <label htmlFor="">Academic Term*</label>
              <select
                name="academic_term"
                id="academic_term"
                onChange={(e) => setSelectedAcademicTerm(e.target.value)}
              >
                {academicYear === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>

            <div className="formInput">
              <label htmlFor="">Class*</label>
              <select
                name="related_class"
                id=""
                required
                onChange={(e) => setSelectedClass(e.target.value)}
              >
                <option>Select Class*</option>
                {teacherClasses.map((schoolClass) => {
                  return (
                    <option value={schoolClass.id} key={schoolClass.id}>
                      {schoolClass.class_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="formInput">
              <label htmlFor="">Date *</label>
              <input
                type="date"
                name="date"
                id=""
                required
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data{" "}
              </button>
            ) : (
              <button type="submit">Get Class Attendance</button>
            )}
          </form>
        </div>
        {attendanceList.length > 0 && (
          <div className="schedule-body">
            <div className="student-list">
              <div className="panel-header">
                <h4>Mark Attendance</h4>
              </div>
              <div className="list-body">
                <table>
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Student ID</th>
                      <th>Gender</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceList?.map((attendance, index) => (
                      <tr key={index}>
                        <td>{attendance?.student?.get_full_name}</td>
                        <td>{attendance?.student?.student_school_id}</td>
                        <td>{attendance?.student?.gender}</td>
                        <td>
                          <input
                            type="radio"
                            name={`status${attendance?.student?.student_id}`}
                            id="status"
                            checked={attendance?.status === "Present"}
                            value="Present"
                            onChange={(e)=>markStudentAttendance(e.target.value, attendance?.student?.student_id)}
                          />{" "}
                          Present &nbsp; &nbsp;
                          <input
                            type="radio"
                            name={`status${attendance?.student?.student_id}`}
                            id="status"
                            checked={attendance?.status === "Absent"}
                            value="Absent"
                            onChange={(e)=>markStudentAttendance(e.target.value, attendance?.student?.student_id)}
                          />{" "}
                          Absent
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {
                    savingAttendance ? <ColorRing
                    visible={true}
                    height="30"
                    width="30"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#aaa", "#aaa", "#aaa", "#aaa", "#aaa"]}
                  /> : <div onClick={(e)=>submitAttendance(e)} className="classic-button" style={{ marginTop: "10px" }}>
                  Save Attendance
                </div>
                }
                
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarkAttendance;
