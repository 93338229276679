import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import NoDataImage from "../../assets/no_data.jpg"
import axios from "axios";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";
import AddClassSubjects from "./AddClassSubjects";
import Header from "../../components/Header";

const ClassSubjects = () => {
  const { schoolClasses } = useAuthenticate();
  const [toggle, setToggle] = useState(1);
  const [dataFetched, setDataFetched] = useState(false);
  const [classSubjects, setClassSubjects] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [loading, setLoading] = useState(false);


  const updateToggle = (tab) => {
    setToggle(tab);
  };

  // REMOVE CLASS SUBJECT
  const removeClassSubject = async (subject_id) =>{
    const body = JSON.stringify({
      "subject":subject_id
    })
    if (localStorage.getItem('access')){
      setLoading(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        }
      }
      await axios.put(`${API_URL}academics/class-term-subjects/${selectedClass}/`,body, config).then(response => {
        
        window.location.reload();
      }).catch(error => {
        if(error.response){
          if(error.response.status ===  401){
            window.location.href = "/"
          }else if(error.response.status === 400){
            for (const property in error.response.data){
              toast.error(
                `${property}: ${error.response.data[property]}`
              )
            }
          }
        }else if (error.message){
          toast.error("Something went wrong. Please try again")
        }
      })
    }
  }

  // GETTING CLASS SUBJECTS
  const getClassSubjects = async (e) => {
    e.preventDefault()
    if(selectedClass === ""){
      toast.error("Class is required")
    }else{
      if (localStorage.getItem('access')){
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          }
        }
        await axios.get(`${API_URL}academics/class-term-subjects/${selectedClass}/`, config).then(response => {
          
          setClassSubjects(response.data)
          setDataFetched(true)
          setLoading(false)
        }).catch(error => {
          if(error.response){
            if(error.response.status ===  401){
              window.location.href = "/"
            }else if(error.response.status === 400){
              for (const property in error.response.data){
                toast.error(
                  `${property}: ${error.response.data[property]}`
                )
              }
            }
          }else if (error.message){
            toast.error("Something went wrong. Please try again")
          }
        })
      }
    }
  }


  

  
  return (
    
      <div className="main-container">
        <Header title="Class Subjects" subtitle="View and assign class subjects" />
        <div className="positions-container">
          <div className="tabs">
            <div
              onClick={() => {
                updateToggle(1);
              }}
              className={toggle === 1 ? "active-tab" : "tab"}
            >
              Class Subjects
            </div>
            <div
              onClick={() => {
                updateToggle(2);
              }}
              className={toggle === 2 ? "active-tab" : "tab"}
            >
              Add Class Subjects
            </div>
          </div>
          <div className={toggle === 1 ? "show-content" : "content"}>
            <div style={{ margin: "25px" }} className="schedule-body">
              <div className="filter-columns">
                <form onSubmit={(e) => getClassSubjects(e)}>
                  <div className="formInput">
                    <select
                      name="school_class"
                      required
                      onChange={(e)=> setSelectedClass(e.target.value)}
                    >
                      <option>Select Class*</option>
                      {schoolClasses.map((schoolClass) => {
                        return (
                          <option value={schoolClass.id} key={schoolClass.id}>
                            {schoolClass.class_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                 
                
                  {loading ? (
                    <button
                      style={{
                        backgroundColor: "gray",
                        cursor: "wait",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "10px",
                        color: "white",
                        border: "none",
                      }}
                    >
                      <ColorRing
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />{" "}
                      Getting Data{" "}
                    </button>
                  ) : (
                    <button
                      type="submit"
                    >
                      Get Class Subjects
                    </button>
                  )}
                </form>
              </div>
              <div className="schedule">
                {!dataFetched ? (
                  <p style={{ textAlign: "center", marginTop: "20px", width: "100%" }}>
                    Please Select Class
                  </p>
                ) : (
                  <>
                  {classSubjects.length > 0 ? (<>
                    <table>
                      <thead style={{ background: "#068DA9", color: "white" }}>
                        <tr>
                          <th>Subject name</th>
                          
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          classSubjects.map((subject, index) => {
                            return(
                              <tr key={index}>
                                <td>{subject.subject_name}</td>
                                
                                <td>
                                  <div onClick={()=>removeClassSubject(subject.id)} className="remove-button">
                                    Remove Subject 
                                  </div>
                                </td>
                              </tr>    
                              )
                          })
                        }
                      </tbody>
                    </table>
                  </>):(
                    <div className="no-data">
                    <img src={NoDataImage} alt="no data" />
                    <p>
                      No Data Found
                    </p>
                  </div>
                  )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={toggle === 2 ? "show-content" : "content"}>
            <AddClassSubjects />
          </div>
        </div>
      </div>
    
  );
};

export default ClassSubjects;
