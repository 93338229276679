import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { API_URL } from '../../url'
import { toast } from 'react-toastify'

const Packages = () => {
    const [packages, setPackages] = useState([])

    const getPackages = async () => {
      const config = {
        headers:{
          "Content-Type":"application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`
        }
      }

      await axios.get(`${API_URL}subscription/packages/list/`,config).then((response)=>{
        if(response?.data?.success){
          setPackages(response?.data?.payload)
        }else{
          toast.error(response?.data?.message)
        }
      }).catch((error)=>{
        toast.error("Something went wrong. Please try again.")
      })
    }

    useEffect(()=>{
      getPackages();
    },[])

  return (
    <div className='main-container'>
        <div className="header">
            <h1>PACKAGES</h1>
        </div>
        <div className="schedule-body">
          <div className="student-list">
            <div className="panel-header">
              <h4>Registered Schools</h4>
            </div>
            <div className="list-body">
              <table>
                <thead>
                  <tr>
                    <th>Package</th>
                    <th>Price</th>
                    <th>Interval</th>
                    <th>Period</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {packages?.map((_package, index) => (
                    <tr key={index}>
                      <td>{_package.name}</td>
                      <td>{_package.price}</td>
                      <td>{_package.interval}</td>
                      <td>{_package.period}</td>
                      
                      <td>
                        <Link>
                          <button className="viewButton">View Details</button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* <div className="pagination-container">
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="pagination-active-page"
                pageLinkClassName="page-link"
              />
              
            </div> */}
          </div>
        </div>
    </div>
  )
}

export default Packages