import React from "react";

const FeeGroupDetails = ({modal, setModal, selectedFeeGroup}) => {
  console.log(selectedFeeGroup)
    return (
    <div className="modal">
      <div className="overlay" onClick={() => setModal(!modal)}>
      <div className="modal-content" onClick={(e)=>e.stopPropagation(e)} style={{ top: "20%" }}>
        <div onClick={() => setModal(!modal)} className="modal-close-btn">
          x
        </div>
        <div className="modal-header">{selectedFeeGroup.name}</div>
        <div
          className="modal-row"
          style={{
            display: "flex",
            marginTop: "30px",
            gap: "30px",
            marginBottom: "20px",
          }}
        >
          <div style={{ flex: "1" }}>
            <b>Academic Year</b>: {selectedFeeGroup.academic_year}
          </div>
          <div style={{ flex: "1" }}>
            <b>Academic Term</b>: {selectedFeeGroup.term}
          </div>
        </div>
        <div
          className="modal-row"
          style={{ display: "flex", gap: "30px", marginBottom: "20px" }}
        >
          <div style={{ flex: "1" }}>
            <b>Total Fees</b>: GHS {selectedFeeGroup.total_fees}
          </div>
        </div>
        <div
          className="modal-row"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "30px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: "1",
              flexWrap: "wrap",
              gap: "5px",
              marginBottom: "20px",
            }}
          >
            <b>Fee Components:</b>
            <ul>
              {
                selectedFeeGroup.components.map(component=>(
                    <li key={component.id}>
                        {component.fee_type} - GHS {component.amount}
                    </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default FeeGroupDetails;
