import React from "react";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import FormInput from "../../components/Forms/FormInput";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../url";
import { ColorRing } from "react-loader-spinner";
import Multiselect from "multiselect-react-dropdown";
import { useEffect } from "react";

const ObjectivesModal = ({ modal, setModal, data, reFetchData }) => {
  const [updating, setUpdating] = useState(false);
  const [eyfsObjectives, setEyfsObjectives] = useState([]);
  const [objectives, setObjectives] = useState([]);

  
  //   FETCH EYFS OBJECTIVES
  const fetchEyfsObjectives = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/eyfs/objectives/`,
          config
        );
        setEyfsObjectives(res.data);
        
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // SUBMIT OBJECTIVES
  const submitObjectives = async (e)=>{
    setUpdating(true)
    e.preventDefault()
    const selectedObjectivesIds = []
    for (let i = 0; i < objectives.length; i++){
      selectedObjectivesIds.push(objectives[i].id);
    }
    const body = JSON.stringify({
        ...data,
        objectives:selectedObjectivesIds
    })
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      try {
        const res = await axios.post(
          `${API_URL}academics/eyfs/subject-area/${data.term}/${data.related_class}/${data.subject}/`,
          body,
          config
        );
        
        setUpdating(false);
        if (res.status === 201) {
          toast.success("Objectives added successfully")
          setModal(!modal)
          reFetchData(e)
        }
      } catch (error) {
        setUpdating(false);
        if (error.response) {
          if(error.response.status === 401){
            window.location.href = "/"
          }else if(error.response.status === 400){
            for (const property in error.response.data) {
              toast.error(`${property} error: ${error.response.data[property]}`);
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  }
  
  useEffect(()=>{
    fetchEyfsObjectives()
  }, [])
  return (
    <div className="">
      
      <div className="modal-content" style={{ top: "60%" }}>
        <div onClick={() => setModal(!modal)} className="modal-close-btn">
          x
        </div>
        <div className="modal-header" style={{ fontWeight: "bold" }}>
          {" "}
          Add Objectives
        </div>
        <form onSubmit={(e)=>submitObjectives(e)}>
          <div className="formSection">
          
                <div className="formRow">
                  <div className="formInput">
                    <label htmlFor="">Objective</label>
                    <Multiselect
                      onSelect={(e) => setObjectives(e)}
                      avoidHighlightFirstOption={true}
                      placeholder="Select Objectives"
                      name="objectives"
                      options={eyfsObjectives} // Options to display in the dropdown
                      displayValue="objective_name" // Property name to display in the dropdown options
                      />
                  </div>    
                </div>
           

            {updating ? (
              <button style={{ cursor: "wait", backgroundColor: "gray" }}>
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
                Adding Objectives...
              </button>
            ) : (
              
              <button type="submit">Submit Objectives</button>
              
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ObjectivesModal;
