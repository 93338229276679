import React from 'react'
import ResetPasswordImage from '../../assets/reset_password.jpg'
import {Link} from 'react-router-dom'
import {IoIosArrowBack} from 'react-icons/io'
import { useState } from 'react'
import axios from 'axios'
import { API_URL } from '../../url'
import { toast } from "react-toastify";
import { ColorRing } from 'react-loader-spinner'

const ResetPassword = () => {
    
    const [email, setEmail] = useState(null)
    const [loading, setLoading] = useState(false)

    const changePassword = async (e) => {
        e.preventDefault()
        if(!loading){
          setLoading(true)
          const config = {
              headers: {
                  "content-type": "application/json",
              }
          }
          const body = {
              "email": email
          }
          try{
              const res = await axios.post(`${API_URL}accounts/reset-password/`, body, config)
              if (res.status === 200){
                  setLoading(false)
                  window.location.href=`/verify-otp/${res.data.id}`
                  
              }
          }catch(error){
              setLoading(false)
              if (error.response) {
                  if(error.response.code !== 500){
                    for (const property in error.response.data) {
                      if (property === "0") {
                        toast.error(`${error.response.data[property]}`);
                      } else {
                        toast.error(
                          `${property} error: ${error.response.data[property]}`
                        );
                      }
                    }
                  }
                } else if (error.message) {
                  toast.error("Something went wrong. Please try again");
                }
          }
        }
    }
    
  return (
    <div id='reset-password'>
        <div className="left-image">
            <img src={ResetPasswordImage} alt="" />
        </div>
        <div className="right-forms">
            <form onSubmit={e=>changePassword(e)}>
                <div className='header'>
                    <h3>Forgot Password</h3>
                    <p>Enter your email and we will send you a link to reset your password</p>
                </div>
                <input onChange={e=>setEmail(e.target.value)} type="email" placeholder='Enter Your Email Address' />
                {
                  loading ? (
                    <button style={{background: "gray"}}>
                      <ColorRing
                      visible={true}
                      height="20"
                      width="20"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                    /> Submitting...
                    </button>
                  ):(
                    <input type="submit" value="Submit" />
                  )
                }
                <div className='back-to-login'>
                    <IoIosArrowBack />
                    <Link to="/">Back To Login</Link>
                </div>
            </form>
        </div>
    </div>
  )
}

export default ResetPassword