import React from "react";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../url";
import FormInput from "../../components/Forms/FormInput";
import { useAuthenticate } from "../../context/AuthContext";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import { AiOutlineUpload } from "react-icons/ai";
import Loading from "../../components/LoadingPage/Loading";
import { toast } from "react-toastify";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import UserImg from "../../assets/avatar.jpg";
import ResetPasswordModal from "../Staffs/ResetPasswordModal";

const AdministratorDetails = () => {
    const { id } = useParams();
  const { user } = useAuthenticate();
  const [administrator, setAdministrator] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [updating, setUpdating] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [updatingStatus, setUpdatingStatus] = useState(false);


    // FETCH ADMINISTRATOR PROFILE
  const fetchSchoolAdmin = async () => {
    if (localStorage.getItem("access")) {
      setLoadingData(true);
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}administrator/administrators/${id}`,
          config
        );
        setAdministrator(res.data);
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
        if (error.response.status === 401) {
          window.location.href = "/";
        } else if (error.response.status === 500) {
          toast.error("Something went wrong");
        } else {
          for (const property in error.response.data) {
            if (property === "0") {
              toast.error(`${error.response.data[property]}`);
            } else {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          }
        }
      }
    }
  };


  // UPDATE ADMINISTRATOR PROFILE
  const update = async (e) => {
    e.preventDefault();

    if (!updating) {
      if (
        updateData.profile_picture &&
        updateData.profile_picture.size > 878576
      ) {
        toast.error(
          "The size of the profile picture should be less than 878KB"
        );
      } else {
        if (localStorage.getItem("access")) {
          setUpdating(true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          const formData = new FormData();
          Object.keys(updateData).forEach((key) => {
            formData.append(key, updateData[key]);
          });
          try {
            await axios
              .put(`${API_URL}administrator/administrators/${id}/`, formData, config)
              .then((response) => {
                setUpdating(false);
                Swal.fire({
                  icon: "success",
                  title: "Updated",
                  text: "Administrator Information Updated Successfully",
                }).then(() => {
                  fetchSchoolAdmin();
                });
              });
          } catch (error) {
            setUpdating(false);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/";
              } else if (error.response.status === 400) {
                for (const property in error.response.data) {
                  if (property === "0") {
                    toast.error(`${error.response.data[property]}`);
                  } else {
                    toast.error(
                      `${property} error: ${error.response.data[property]}`
                    );
                  }
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    }
  };

  // COMPRESS FILE
  const compressFile = (image) => {
    let file_name = image.name;
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      let image_url = event.target.result;
      let image = document.createElement("img");
      image.src = image_url;

      image.onload = (e) => {
        // creating a new canvas for the image
        const canvas = document.createElement("canvas");
        let ratio = 300 / e.target.width;
        canvas.width = 300;
        canvas.height = ratio * e.target.height;

        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        // converting the image quality to 50% and the file type to webp
        let new_image_url = canvas.toDataURL("image/webp", 50);

        // getting the image type and the data
        let arr = new_image_url.split(",");
        let image_type = arr[0].match(/:(.*?);/)[1];
        let data = arr[1];
        let dataStr = atob(data);
        let n = dataStr.length;
        let dataArr = new Uint8Array(n);

        while (n--) {
          dataArr[n] = dataStr.charCodeAt(n);
        }

        const compressed_image = new File([dataArr], file_name, {
          type: image_type,
        });

        setUpdateData({
          ...updateData,
          profile_picture: compressed_image,
        });
      };
    };
  };

  // CHANGE UPDATE DATA
  const handleUpdateDetails = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const deleteAdministrator = (admin_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "All information about this administrator will be lost. You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          axios
            .delete(`${API_URL}administrator/administrators/${admin_id}/`, config)
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Administrator has been deleted.",
                icon: "success",
                timer: 1500,
              }).then(() => {
                window.location.href = "/admin/administrators-list"
              });
            })
            .catch((error) => {
              if (error.response) {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              } else if (error.message) {
                toast.error("Something went wrong. Please try again");
              }
            });
        }
      }
    });
  };

  useEffect(() => {
    fetchSchoolAdmin();
  }, []);


  return (
    <div className="main-container">
      {!loadingData ? (
        <div className="mt-8">
          {modal && (
            <ResetPasswordModal
              setModal={setModal}
              account_id={administrator?.user_account?.id}
            />
          )}
          <div className="border border-slate-300 rounded p-[16px]">
            <div className="flex justify-between lg:items-center lg:flex-row flex-col gap-7">
              <div className="flex gap-5">
                <div className="bg-light-primary h-[90px] w-[90px] flex items-center justify-center">
                  <img
                    src={
                      administrator?.user_account?.profile_picture ? (
                        <img
                          className="school-logo"
                          src={`${API_URL}${administrator?.user_account?.profile_picture}`}
                          alt=""
                        />
                      ) : (
                        <img
                          src={UserImg}
                          alt="profile_image"
                          className="school-logo"
                        />
                      )
                    }
                    alt=""
                  />
                </div>
                <div>
                  <p className="font-[500] text-[18px]">
                    {administrator?.user_account?.first_name}{" "}
                    {administrator?.user_account?.last_name}
                  </p>
                  <div className="flex lg:flex-row flex-col lg:gap-4 text-[14px]">
                    <p>{administrator.admin_id}</p>
                    <p>{administrator?.user_account?.phone_number}</p>
                    <p>{administrator?.user_account?.email}</p>
                  </div>
                  
                </div>
              </div>
              {user?.account_type === "School Administrator" && (
                <div>
                  {updatingStatus ? (
                    <div className="flex items-center ">
                      <RotatingLines
                        visible={true}
                        height="26"
                        width="26"
                        strokeColor="black"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      
                      <button
                        onClick={() => deleteAdministrator(administrator?.admin_id)}
                        className="py-2 px-2  hover:bg-red-50 text-[12px] border border-red-500 text-red-500 rounded"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => setModal(!modal)}
                        className="py-2 px-2  hover:bg-blue-50 text-[12px] border border-blue-500 text-blue-500 rounded"
                      >
                        Reset Password
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          
          <div className="school-profile-body">
            <div className="school-profile-left">
              <form onSubmit={(e) => update(e)}>
                <div className="formSection">
                  <p className="formRowHeader">Administrator Personal Info</p>
                  <div className="formRow">
                    <div className="formInput">
                      <label>Admin Image</label>
                      <input
                        type="file"
                        name="profile_picture"
                        id="administrator_image"
                        onChange={(e) => {
                          compressFile(e.target.files[0]);
                        }}
                      />
                      <label className="upload-button" htmlFor="administrator_image">
                        <AiOutlineUpload style={{ fontSize: "15px" }} />
                        Upload Picture
                      </label>
                      <p style={{ fontWeight: "400", fontSize: "12px" }}>
                        {updateData.profile_picture
                          ? updateData.profile_picture.name
                          : "No file selected"}
                      </p>
                    </div>

                    <FormInput
                      label="First Name"
                      type="text"
                      name="first_name"
                      id="first_name"
                      value={
                        updateData.first_name
                          ? updateData.first_name
                          : administrator?.user_account?.first_name
                      }
                      required={true}
                      onChange={(e) => handleUpdateDetails(e)}
                      // pattern="^[A-Za-z\s]{3,26}$"
                      errorMessage="Should be at least 3 characters long with no number or special
                  characters"
                    />

                    <FormInput
                      label="Last Name"
                      type="text"
                      name="last_name"
                      id="last_name"
                      required={true}
                      value={
                        updateData.last_name
                          ? updateData.last_name
                          : administrator.user_account?.last_name
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                      // pattern="^[A-Za-z ]{3,30}$"
                      errorMessage="Should be at least 3 characters long with no number or special
                  characters"
                    />
                  </div>
                  <div className="formRow">
                    <div className="formInput">
                      <label>Gender</label>
                      {administrator.gender === "Male" ? (
                        <>
                          <div className="radioInline">
                            <input
                              type="radio"
                              name="gender"
                              required
                              checked
                              value="Male"
                              onChange={(e) => handleUpdateDetails(e)}
                            />{" "}
                            Male
                          </div>
                          <div className="radioInline">
                            <input
                              type="radio"
                              name="gender"
                              onChange={(e) => handleUpdateDetails(e)}
                              value="Female"
                            />{" "}
                            Female
                          </div>
                        </>
                      ) : (
                        <>
                          {administrator.gender === "Female" ? (
                            <>
                              <div className="radioInline">
                                <input
                                  type="radio"
                                  name="gender"
                                  required
                                  onChange={(e) => handleUpdateDetails(e)}
                                  value="Male"
                                />{" "}
                                Male
                              </div>
                              <div className="radioInline">
                                <input
                                  type="radio"
                                  name="gender"
                                  checked
                                  value="Female"
                                  onChange={(e) => handleUpdateDetails(e)}
                                />{" "}
                                Female
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="radioInline">
                                <input
                                  type="radio"
                                  name="gender"
                                  required
                                  value="Male"
                                  onChange={(e) => handleUpdateDetails(e)}
                                />{" "}
                                Male
                              </div>
                              <div className="radioInline">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                  onChange={(e) => handleUpdateDetails(e)}
                                />{" "}
                                Female
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    

                    <div className="formInput">
                      <label>Phone Number</label>
                      <PhoneInput
                        defaultCountry="GH"
                        className="phone-input"
                        value={
                          updateData.phone_number
                            ? updateData.phone_number
                            : administrator?.user_account?.phone_number
                        }
                        onChange={(value) =>
                          setUpdateData({
                            ...updateData,
                            phone_number: value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <FormInput
                      label="Email Address"
                      type="email"
                      name="email"
                      id="email"
                      value={
                        updateData.email
                          ? updateData.email
                          : administrator.user_account?.email
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                      errorMessage="Please enter a valid email address"
                    />

                    <FormInput
                      label="Residential Address"
                      type="text"
                      name="residential_address"
                      id="residential_address"
                      required={true}
                      value={
                        updateData.residential_address
                          ? updateData.residential_address
                          : administrator.residential_address
                      }
                      onChange={(e) => handleUpdateDetails(e)}
                      errorMessage="Please enter a valid residential address"
                    />

                    
                  </div>

                 
                </div>


                <div className="formSection">
                  <p className="formRowHeader"> Account Info</p>
                  <div className="formRow">
                    <FormInput
                      label="Username"
                      type="text"
                      name="username"
                      id="username"
                      pattern="^[A-Za-z0-9]{5,16}$"
                      required={true}
                      value={administrator?.user_account?.username}
                      disabled
                      errorMessage="Should be at least 5 characters with no special characters"
                    />

                    <div className="formInput">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        disabled
                        placeholder="**************"
                      />
                    </div>
                  </div>
                </div>
                {user.account_type === "School Administrator" && (
                  <>
                    {updating ? (
                      <button style={{ cursor: "wait" }}>
                        <ColorRing
                          visible={true}
                          height="20"
                          width="20"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                        Updating...
                      </button>
                    ) : (
                      <button type="submit">Update</button>
                    )}
                  </>
                )}
              </form>
            </div>
            
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default AdministratorDetails