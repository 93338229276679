import React, { useEffect, useState } from "react";

import { ColorRing } from "react-loader-spinner";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { API_URL } from "../../url";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useAuthenticate } from "../../context/AuthContext";
import Header from "../../components/Header";

const columns = [
  { field: "title", headerName: "Event Title", width: 230 },
  { field: "date", headerName: "Date", width: 230 },
  { field: "venue", headerName: "Venue", width: 230 },
  {
    field: "action",
    headerName: "Actions",
    width: 200,
    renderCell: ({ id }) => {
      return (
        <div className="cellAction">
          <div className="viewButton">
            <Link to={`/events/${id}`}>View</Link>
          </div>
          <div className="deleteButton">Delete</div>
        </div>
      );
    },
  },
];

const Events = () => {
  const { user } = useAuthenticate();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [toggle, setToggle] = useState(1);
  const [events, setEvents] = useState([]);
  const [data, setData] = useState({
    description: "",
  });

  const updateToggle = (tab) => {
    setToggle(tab);
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const fetchEvents = async () => {
    if (localStorage.getItem("access")) {
      setLoadingData(true);
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(`${API_URL}administrator/events/`, config);
        setLoadingData(false);
        setEvents(res.data);
      } catch (error) {
        setLoadingData(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);
      try {
        const res = await axios.post(
          `${API_URL}administrator/events/`,
          body,
          config
        );

        setLoading(false);
        fetchEvents();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Event Added Successfully",
        }).then(() => {
          setToggle(1);
        });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            for (const property in error.response.data) {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);
  return (
    <div className="main-container">
      <Header title="School Events" subtitle="Keep track of school events"/>
      <div className="positions-container">
        <div className="tabs">
          <div
            className={toggle === 1 ? "active-tab" : "tab"}
            onClick={() => {
              updateToggle(1);
            }}
          >
            School Events
          </div>
          {user.account_type === "School Administrator" && (
            <div
              className={toggle === 2 ? "active-tab" : "tab"}
              onClick={() => {
                updateToggle(2);
              }}
            >
              Add Event
            </div>
          )}
        </div>
        <div
          className={toggle === 1 ? "show-content" : "content"}
          style={{ height: 400 }}
        >
          <DataGrid
            rows={events}
            loading={loadingData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </div>
        <div className={toggle === 2 ? "show-content" : "content"}>
          <div className="add-position-form">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="formInput">
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="formInput">
                <label>Description*</label>

                <CKEditor
                  style={{ marginTop: "10px" }}
                  editor={ClassicEditor}
                  data={data.message}
                  onChange={(event, editor) => {
                    const text = editor.getData();
                    setData({
                      ...data,
                      description: text,
                    });
                  }}
                />
              </div>
              <div className="formInput">
                <label>Date*</label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="formInput">
                <label>Time*</label>
                <input
                  type="time"
                  name="time"
                  id="time"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="formInput">
                <label>Venue</label>
                <input
                  type="text"
                  name="venue"
                  id="venue"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {user.account_type === "School Administrator" && (
                <>
                  {loading ? (
                    <button
                      style={{
                        backgroundColor: "gray",
                        cursor: "wait",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "10px",
                        border: "none",
                        marginTop: "10px",
                        color: "white",
                      }}
                    >
                      <ColorRing
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />{" "}
                      Adding Event{" "}
                    </button>
                  ) : (
                    <button type="submit" className="submitButton">
                      Add Event
                    </button>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
