import {APPS} from './constants'

export const getApp = () => {
    const subdomain = getSubdomain();
    
    const mainApp = APPS.find((app) => app.main)

    if (!mainApp){
        throw new Error ("Must Have Main App")
    }

    if(subdomain === ""){
        return mainApp.app
    }else{
        const app = APPS.find(app => subdomain === app.subdomain);
        if (!app){
            return mainApp.app
        }else{
            return app.app
        }
    }

}

const getSubdomain = () => {
    let locationParts = window.location.hostname.split('.')

    let sliceTill = -2

    const isLocalhost = locationParts.slice(-1)[0] === 'localhost';
    if (isLocalhost) {
        sliceTill = -1
    }
    return locationParts.slice(0, sliceTill).join("")
}