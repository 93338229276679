import React, { useEffect, useState } from "react";
import StatsCard from "../../components/StatsCard/StatsCard";
import { FaSchool } from "react-icons/fa";
import { useAuthenticate } from "../../context/AuthContext";
import { BsPeopleFill } from "react-icons/bs";
import { MdOutlineAttachMoney } from "react-icons/md";
import Barchart from "../../components/Charts/BarChart";
import Loading from "../../components/LoadingPage/Loading";
import axios from "axios";
import { API_URL } from "../../url";

const SuperadminDashboard = () => {
  const [dashboardData, setDashboardData] = useState({})
  const [gettingDashboardData, setGettingDashboardData] = useState(false)
  const [userData, setUserData] = useState(null)

  const getDashboardData = async () => {
    setGettingDashboardData(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    }
    await axios
      .get(`${API_URL}superadmin/dashboard`, config)
      .then((response) => {
        setDashboardData(response.data);
        setUserData({
          labels: dashboardData.registered_students_data.map((data) => data.month),
          datasets: [
            {
              label: "Students Registered",
              data: dashboardData.registered_students_data.map(
                (data) => data.userGain
              ),
            },
          ],
        })
        setGettingDashboardData(false);
      })
      .catch((error) => {
        setGettingDashboardData(false);
      });
  };

  useEffect(()=>{
    getDashboardData()
  },[])


    return (
      <div className="dashboard-container">
        <h1>
          DASHBOARD
        </h1>
        {
          gettingDashboardData ? <Loading /> : (
            <div>
              <div className="body">
                <div className="left">
                  <div className="grid lg:grid-cols-3 mt-4 gap-10">
                    <StatsCard
                      icon={<FaSchool className="text-[70px] text-secondary" />}
                      title="Registered Schools"
                      text={dashboardData?.registered_schools_count}
                    />
                    <StatsCard
                      icon={<BsPeopleFill className="text-[70px] text-secondary" />}
                      title="Registered Students"
                      text={dashboardData?.registered_students_count}
                    />
                    <StatsCard
                      icon={<MdOutlineAttachMoney className="text-[70px] text-secondary" />}
                      title="Overall Income"
                      text={dashboardData?.overall_total_income}
                    />
                  </div>
                  {
                    userData && (
                      <div className="today-attendance">
                        <div className="attendance-top">
                          <p className="title">Registered Students</p>
                        </div>
                        <div className="chart-data">
                          <Barchart className="bar" chartData={userData} />
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="schedule-body">
                <div className="student-list">
                  <div className="panel-header">
                    <h4>Latest Transactions</h4>
                  </div>
                  <div className="list-body">
                    <table>
                      <thead>
                        <tr>
                          <th>Transaction ID</th>
                          <th>School</th>
                          <th>Date</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.latest_transactions?.map(
                          (transaction, index) => (
                            <tr key={index}>
                              <td>{transaction?.invoice_id}</td>
                              <td>{transaction?.school}</td>
                              <td>{transaction?.date}</td>
                              <td>{transaction?.amount_paid}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  

};

export default SuperadminDashboard;
