import React, { useEffect, useState } from 'react'
import { ColorRing } from 'react-loader-spinner';
import { useAuthenticate } from '../../context/AuthContext';
import axios from 'axios';
import { API_URL } from '../../url';
import NoDataImage from "../../assets/no_data.jpg"
import Header from '../../components/Header';

const TeacherSchedules = () => {
    const [academicYears, setAcademicYears] = useState([]);
    const [teachers, setTeachers] = useState([])
    const [academicTerms, setAcademicTerms] = useState([]);
    const [academicYear, setAcademicYear] = useState("");
    const [academicTerm, setAcademicTerm] = useState("");
    const [selectedTeacher, setSelectedTeacher] = useState("");
    const [loading, setLoading] = useState(false);
    const [teacherSchedules, setTeacherSchedules] = useState(null)
    const [gettingAcademicYears, setGettingAcademicYears] = useState(false)

    const changeAcademicYear = (e) => {
        setAcademicYear(e.target.value);

        // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
        if (e.target.value !== "") {
            setAcademicTerms(
                academicYears.find((year) => year.id === parseInt(e.target.value)).terms
            );
        }
    };

    const fetchAcademicYears = async () => {
        if (localStorage.getItem("access")) {
            setGettingAcademicYears(true);
          const config = {
            headers: {
              "content-type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };
          await axios
            .get(`${API_URL}academics/academic-years/`, config)
            .then((response) => {
              setAcademicYears(response.data);
              setGettingAcademicYears(false);
            })
            .catch((error) => {
                setGettingAcademicYears(false)
            });
        }
      };

      const fetchTeachers = async () => {
        
        if (localStorage.getItem("access")) {
        const config = {
            headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            },
        };
    
        await axios.get(
            `${API_URL}administrator/teachers/`,
            config
        ).then(response =>{
            setTeachers(response.data)
        }).catch(error => {
            
        })
    
        
        }
    
      };
    

    const getTeacherSchedules = async (e) => {
        e.preventDefault();
        setLoading(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        }
        await axios.get(`${API_URL}administrator/teacher-schedules/${selectedTeacher}/${academicTerm}/`, config).then((response) => {
            setTeacherSchedules(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    useEffect(()=>{
        fetchAcademicYears();
        fetchTeachers();
    },[])


    return (
        <div className='main-container'>
            <Header title="Teacher Schedules" subtitle={"View Teacher Schedule"} />
            <div className="schedule-body">
                <div className="filter-columns">
                    <form onSubmit={(e) => getTeacherSchedules(e)}>
                        {/* TEACHERS DROPDOWN */}
                        <div className="formInput">
                            <select
                                name="teacher"
                                id=""
                                onChange={(e) => setSelectedTeacher(e.target.value)}
                            >
                                <option value="">Select Teacher</option>
                                {teachers.map((teacher) => (
                                    <option key={teacher.staff_id} value={teacher.staff_id}>
                                        {teacher?.user_account?.first_name} {teacher?.user_account?.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* ACADEMIC YEAR DROPDOWN */}
                        <div className="formInput">
                            <select
                                name="academic_year"
                                id="academic_term"
                                onChange={(e) => changeAcademicYear(e)}
                            >
                                <option value="">Select Academic Year</option>
                                {academicYears.map((academicYear) => {
                                    return (
                                        <option value={academicYear.id} key={academicYear.id}>
                                            {academicYear.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        {/* ACADEMIC TERM DROPDOWN */}
                        <div className="formInput">
                            <select
                                name="academic_term"
                                onChange={(e) => setAcademicTerm(e.target.value)}
                                id="academic_term"
                            >
                                {academicYear === "" ? (
                                    <option value="">Select Academic Year First</option>
                                ) : (
                                    <>
                                        <option value="">Select Term</option>
                                        {academicTerms.map((academicTerm) => {
                                            return (
                                                <option key={academicTerm.id} value={academicTerm.id}>
                                                    {academicTerm.name}
                                                </option>
                                            );
                                        })}
                                    </>
                                )}
                            </select>
                        </div>
                        {/* IF FILTERING DATA SHOW A LOADING BUTTON ELSE SHOW A SUBMIT BUTTON */}
                        {loading ? (
                            <button
                                style={{
                                    backgroundColor: "gray",
                                    cursor: "wait",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    padding: "10px",
                                    color: "white",
                                    border: "none",
                                }}
                            >
                                <ColorRing
                                    visible={true}
                                    height="20"
                                    width="20"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                                />{" "}
                                Getting Data...{" "}
                            </button>
                        ) : (
                            <button type="submit">Get Data</button>
                        )}
                    </form>
                </div>
                {/* IF NO RESULT IS FOUND DISPLAY A NOT FOUND MESSAGE ELSE DISPLAY THE RESULTS */}
                {teacherSchedules && teacherSchedules.length > 0 ? (
                    <div className="schedule">
                        {/* <ReactToPrint
                        trigger={() => (
                          <button className="export-button">
                            Export To PDF
                          </button>
                        )}
                        content={()=> scheduleRef.current}
                        documentTitle = "Class Time Table"
                        /> */}
                        <div className="schedule-table">
                            <div className="day-schedule">
                                <div className="day">Monday</div>
                                {teacherSchedules.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                            {schedule.day === "Monday" && (
                                                <div className="schedule-details">
                                                    <div className="left">

                                                        <p>
                                                            {schedule.start_time} -{" "}
                                                            {schedule.end_time}
                                                        </p>

                                                        <b>{schedule.subject}</b>
                                                        <p>{schedule.related_class}</p>

                                                    </div>
                                                    
                                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="day-schedule">
                                <div className="day">Tuesday</div>
                                {teacherSchedules.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                            {schedule.day === "Tuesday" && (
                                                <div className="schedule-details">
                                                    <div className="left">

                                                        <p>
                                                            {schedule.start_time} -{" "}
                                                            {schedule.end_time}
                                                        </p>

                                                        <b>{schedule.subject}</b>
                                                        <p>{schedule.related_class}</p>

                                                    </div>
                                                    
                                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="day-schedule">
                                <div className="day">Wednesday</div>

                                {teacherSchedules.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                            {schedule.day === "Wednesday" && (
                                                <div className="schedule-details">
                                                    <div className="left">

                                                        <p>
                                                            {schedule.start_time} -{" "}
                                                            {schedule.end_time}
                                                        </p>

                                                        <b>{schedule.subject}</b>
                                                        <p>{schedule.related_class}</p>

                                                    </div>
                                                    
                                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="day-schedule">
                                <div className="day">Thursday</div>
                                {teacherSchedules.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                            {schedule.day === "Thursday" && (
                                                <div className="schedule-details">
                                                    <div className="left">

                                                        <p>
                                                            {schedule.start_time} -{" "}
                                                            {schedule.end_time}
                                                        </p>

                                                        <b>{schedule.subject}</b>
                                                        <p>{schedule.related_class}</p>

                                                    </div>
                                                    
                                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="day-schedule">
                                <div className="day">Friday</div>
                                {teacherSchedules.map((schedule) => {
                                    return (
                                        <div key={schedule.id}>
                                            {schedule.day === "Friday" && (
                                                <div
                                                    key={schedule.id}
                                                    className="schedule-details"
                                                >
                                                    <div className="left">

                                                        <p>
                                                            {schedule.start_time} -{" "}
                                                            {schedule.end_time}
                                                        </p>

                                                        <b>{schedule.subject}</b>
                                                        <p>{schedule.related_class}</p>

                                                    </div>
                                                    
                                                    {/* <button
                                      onClick={() =>
                                        deleteClassSchedule(`${schedule.id}`)
                                      }
                                      className="delete-schedule"
                                    >
                                      Delete
                                    </button> */}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="no-data">
                        <img src={NoDataImage} alt="no data" />
                        <p>
                            No Data Found
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TeacherSchedules
