import React from "react";

import { useAuthenticate } from "../../context/AuthContext";
import { useState } from "react";

const FeesCollection = () => {
  const { academicYears, schoolClasses } = useAuthenticate();
  const [ academicTerms, setAcademicTerms] = useState([])
  const [academicYear, setAcademicYear] = useState("")
  const [academicTerm, setAcademicTerm] = useState("")
  const [loading, setLoading] = useState(false)

  const changeAcademicYear = (e) => {
    setAcademicYear(e.target.value);
    
    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== ""){
      setAcademicTerms(academicYears.find((year) => year.id === parseInt(e.target.value)).terms)
    }
  }

//   const handleSubmit = (e) => {

//   }

  return (
    
      <div className="main-container">
        <div className="header">
          <h1>FEE COLLECTION</h1>
        </div>
        <div className="schedule-body">
          <div className="filter-columns">
            <form action="">
              <div className="formInput">
                <select required name="class">
                  <option value="">Select Class</option>
                  {schoolClasses.map((schoolClass) => {
                    return (
                      <option value={schoolClass.id} key={schoolClass.id}>
                        {schoolClass.class_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="formInput">
                <select name="academic_year" id="academic_term" onChange={e=>changeAcademicYear(e)}>
                  <option value="">Select Academic Year</option>
                  {
                    academicYears.map((academicYear) => {
                      return (
                        <option value={academicYear.id} key={academicYear.id}>
                          {academicYear.name}
                        </option>

                      )
                    })
                  }
                </select>
              </div>
              <div className="formInput">
                <select name="academic_term" onChange={e=>setAcademicTerm(e.target.value)} id="academic_term">
                  {
                    academicYear === "" ? (
                      <option value="">Select Academic Year First</option>
                    ):(
                      <>
                        <option value="">Select Term</option>
                        {
                          academicTerms.map((academicTerm) => {
                            return <option key={academicTerm.id} value={academicTerm.id}>{academicTerm.name}</option>
                          })
                        }
                      </>
                    )
                  }
                  
                </select>
              </div>
              <button type="submit">Filter</button>
            </form>
          </div>
        </div>
      </div>
    
  );
};

export default FeesCollection;
