import React, { useState, useRef } from "react";
import { ColorRing } from "react-loader-spinner";
import Loading from "../../components/LoadingPage/Loading";
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";
import { API_URL, img_base_url } from "../../url";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import Header from "../../components/Header";
import { useAuthenticate } from "../../context/AuthContext";

const IncomeStatement = () => {
  const incomeRef = useRef();
  const { user,schoolInfo } = useAuthenticate();
  const [loading, setLoading] = useState(false);
  const [incomeStatement, setIncomeStatement] = useState(null);
  const [data, setData] = useState({
    start_date: null,
    end_date: null,
  });

  const getIncomeStatement = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}finance/school-income-statement/${data.start_date}/${data.end_date}`,
          config
        );
        setIncomeStatement(res.data);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  const filterIncomeStatement = (e) => {
    e.preventDefault();
    if (data.start_date === null || data.end_date === null) {
      toast.error("Please specify the date range");
    } else {
      setIncomeStatement(null);
      setLoading(true);
      getIncomeStatement();
    }
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: incomeRef.current,
    filename: `Income Statement`,
    sheet: "Income Statement",
  });

  useEffect(() => {
    getIncomeStatement();
  }, []);

  return (
    <div className="main-container">
      <Header title="Income Statement" />

      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => filterIncomeStatement(e)}>
            <div className="formInput">
              <label htmlFor="">From:</label>
              <input
                type="date"
                name="start_date"
                id=""
                onChange={(e) =>
                  setData({ ...data, start_date: e.target.value })
                }
              />
            </div>
            <div className="formInput">
              <label htmlFor="">To:</label>
              <input
                type="date"
                onChange={(e) => setData({ ...data, end_date: e.target.value })}
                name="end_date"
                id=""
              />
            </div>
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  color: "white",
                  padding: "15px",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data...{" "}
              </button>
            ) : (
              <button>Filter</button>
            )}
          </form>
        </div>
        {incomeStatement ? (
          <div className="income-statement">
            
            <ReactToPrint
              trigger={() => (
                <button className="px-3 py-2 border border-blue-400 rounded mr-3 text-[12px] text-blue-400 hover:bg-blue-50">
                  
                  Export to PDF
                </button>
              )}
              content={() => incomeRef.current}
              documentTitle={
                data.start_date
                  ? `Income Statement for ${data.start_date} to ${data.end_date}`
                  : "Income Statement"
              }
            />
            <div>
              <h1 style={{textAlign: 'center'}}>Income Statement {data.start_date && `${data.start_date} to ${data.end_date}`}</h1>
              <div>
                <table
                  style={{
                    marginTop: "30px",
                    borderCollapse: "collapse",
                    width: "100%",
                  }}
                  className="income-statement-table"
                >
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Item
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        GHS
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        GHS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Income
                      </td>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                    </tr>
                    {incomeStatement.income_list.map((income) => {
                      return (
                        <tr>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {income.revenue_type}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "right",
                            }}
                          >
                            {income.amount}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                        </tr>
                      );
                    })}

                    <tr style={{ fontWeight: "bold" }}>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Total Income
                      </td>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "right",
                        }}
                      >
                        {incomeStatement.total_income}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                    </tr>

                    <tr>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Expenses
                      </td>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                    </tr>
                    {incomeStatement.expense_list.map((expense) => {
                      return (
                        <tr>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {expense.expense_type}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "right",
                            }}
                          >
                            {expense.amount}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                        </tr>
                      );
                    })}

                    <tr style={{ fontWeight: "bold" }}>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Total Expenses
                      </td>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "right",
                        }}
                      >
                        ({incomeStatement.total_expense})
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: "bold" }}>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Net Income
                      </td>
                      <td
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      ></td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "right",
                        }}
                      >
                        {incomeStatement.net_income}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
              <div className="hidden">
                <div ref={incomeRef} className="p-3">
                    <div className="flex flex-col items-center justify-center py-5 border-b-2 border-slate-500">
                      <div className="flex items-center">
                          <img src={`${img_base_url}${schoolInfo?.school_logo}`} alt="" className="h-20"  />
                          <h3 className="text-[30px] font-bold">

                            {
                              schoolInfo?.school_name
                            }
                          </h3>
                      </div>
                      <p>{schoolInfo?.school_city} - {schoolInfo?.school_region}</p>
                      <p>
                        {schoolInfo?.school_phone_number} - {schoolInfo?.school_email}
                      </p>

                      
                    </div>
                  <h1 style={{textAlign: 'center'}} className="mt-5">Income Statement {data.start_date && `${data.start_date} to ${data.end_date}`}</h1>
                  <div>
                    <table
                      style={{
                        marginTop: "30px",
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                      className="income-statement-table"
                    >
                      <thead>
                        <tr>
                          <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                            Item
                          </th>
                          <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                            GHS
                          </th>
                          <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                            GHS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            Income
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                        </tr>
                        {incomeStatement.income_list.map((income) => {
                          return (
                            <tr>
                              <td
                                style={{ border: "1px solid #ddd", padding: "8px" }}
                              >
                                {income.revenue_type}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "right",
                                }}
                              >
                                {income.amount}
                              </td>
                              <td
                                style={{ border: "1px solid #ddd", padding: "8px" }}
                              ></td>
                            </tr>
                          );
                        })}

                        <tr style={{ fontWeight: "bold" }}>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            Total Income
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "right",
                            }}
                          >
                            {incomeStatement.total_income}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                        </tr>

                        <tr>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            Expenses
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                        </tr>
                        {incomeStatement.expense_list.map((expense) => {
                          return (
                            <tr>
                              <td
                                style={{ border: "1px solid #ddd", padding: "8px" }}
                              >
                                {expense.expense_type}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "right",
                                }}
                              >
                                {expense.amount}
                              </td>
                              <td
                                style={{ border: "1px solid #ddd", padding: "8px" }}
                              ></td>
                            </tr>
                          );
                        })}

                        <tr style={{ fontWeight: "bold" }}>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            Total Expenses
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "right",
                            }}
                          >
                            ({incomeStatement.total_expense})
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr style={{ fontWeight: "bold" }}>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            Net Income
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          ></td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "right",
                            }}
                          >
                            {incomeStatement.net_income}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default IncomeStatement;
