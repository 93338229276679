import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_FAIL,
    AUTHENTICATED_SUCCESS,
    LOGOUT,
    SIGNUP_FAIL,
    SIGNUP_SUCCESS,
    ACTIVATION_FAIL,
    ACTIVATION_SUCCESS,
    LOGIN_REQUEST
} from '../actions/types'


// Setting inital states
const initialState= {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    loginFail:null,
    user: null,
    loading: false,
    error: false
}


export const authReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case AUTHENTICATED_SUCCESS:
            return{
                ...state,
                isAuthenticated: true,
                loading: false
            }
        case AUTHENTICATED_FAIL:
            return{
                ...state,
                isAuthenticated: false,
                loading:false
            }
        case LOGIN_REQUEST:
            return{
                ...state,
                loading: true,
                isAuthenticated: false
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('access',payload.access)
            localStorage.setItem('refresh',payload.refresh)    
            return{
                    ...state,
                    access: payload.access,
                    refresh: payload.refresh,
                    loading: false,
                    error: false
                }
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload,
                isAuthenticated: true
            }
        case USER_LOADED_FAIL:
            return{
                ...state,
                user: null
            }
        case SIGNUP_SUCCESS:
            return{
                ...state,
                isAuthenticated: false,
                isCreated: true
            }
        case LOGIN_FAIL:
            return{
                user: null,
                access: null,
                refresh: null,
                isAuthenticated: false,
                loginFail: true,
                loading: false,
                error: payload
            }
        case LOGOUT:
        case SIGNUP_FAIL:
        localStorage.removeItem('access')
        localStorage.removeItem('refresh')    
        return{
                user: null,
                access: null,
                refresh: null,
                isAuthenticated: false,
            }
        case ACTIVATION_FAIL:
        case ACTIVATION_SUCCESS:
            return{
                ...state
            }
        
        default:
            return state
    }
}