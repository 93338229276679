import React, { useState } from "react";
import { useAuthenticate } from "../../context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../url";
import { ColorRing } from "react-loader-spinner";
import Header from "../../components/Header";

const StudentTermMarks = () => {
  const { academicYears, schoolInfo, schoolClasses } = useAuthenticate();
  const [academicTerms, setAcademicTerms] = useState([]);
  const [academicYear, setAcademicYear] = useState("");
  const [academicTerm, setAcademicTerm] = useState("");
  const [student, setStudent] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentResult, setStudentResult] = useState([]);

  const changeAcademicYear = (e) => {
    setAcademicYear(e.target.value);

    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  // GET STUDENT REPORT
  const fetchStudentResults = async (e) => {
    e.preventDefault();
    if (student === "" || academicYear === "" || academicTerm === "") {
      toast.error("Please select all fields");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/student-term-marks/${academicTerm}/${student}`,
            config
          );
          
          setStudentResult(res.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  // FETCH CLASS STUDENTS
  const fetchClassStudents = async (e) => {
    if (e.target.value !== "") {
      if (localStorage.getItem("access")) {
        setLoadingStudents(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-students/${e.target.value}`,
            config
          );
          setStudents(res.data);
          setLoadingStudents(false);
        } catch (error) {
          setLoadingStudents(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    } else {
      setStudents([]);
    }
  };

  return (
    <div className="main-container">
      <Header title="Student Exam Marks" subtitle="Get the raw exam marks of students" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => fetchStudentResults(e)}>
            {/* CLASSES DROPDOWN */}
            <div className="formInput">
              <select
                name="school_class"
                id=""
                onChange={(e) => fetchClassStudents(e)}
              >
                <option value="">Select Class</option>
                {schoolClasses.map((schoolClass) => (
                  <option key={schoolClass.id} value={schoolClass.id}>
                    {schoolClass.class_name}
                  </option>
                ))}
              </select>
            </div>
            {/* STUDENTS LIST DROPDOWN */}
            <div className="formInput">
              <select
                name="student"
                id="student"
                required
                onChange={(e) => setStudent(e.target.value)}
              >
                {loadingStudents ? (
                  <option>Loading Students...</option>
                ) : (
                  <>
                    {students.length < 1 ? (
                      <option>Select Class First</option>
                    ) : (
                      <>
                        <option value="">Select Student</option>
                        {students.map((student) => {
                          return (
                            <option
                              key={student.student_id}
                              value={student.student_id}
                            >
                              {student.get_full_name} 
                            </option>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </select>
            </div>
            {/* ACADEMIC YEAR DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_year"
                id="academic_term"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* ACADEMIC TERM DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_term"
                onChange={(e) => setAcademicTerm(e.target.value)}
                id="academic_term"
              >
                {academicYear === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            {/* IF FILTERING DATA SHOW A LOADING BUTTON ELSE SHOW A SUBMIT BUTTON */}
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data...{" "}
              </button>
            ) : (
              <button type="submit">Filter</button>
            )}
          </form>
        </div>
        {studentResult.map((result, index) => {
          return (
            <div key={index} className="income-statement">
              <div>
                <h3>{result.subject}</h3>
                <table>
                  <thead>
                    <tr>
                      <th style={{width:"50%"}}>Assessment Type</th>
                      <th>Marks</th>
                    </tr>
                  </thead>

                  <tbody>
                    {result.marks.map((mark, index) => {
                      return (
                        <tr key={index}>
                            <td>{mark.assessment_type}</td>
                            <td style={{textAlign:"right"}}>{mark.mark}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StudentTermMarks;
