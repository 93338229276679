import React, { useState } from "react";
import { TbMoneybag } from "react-icons/tb";
import { GiReceiveMoney, GiPayMoney } from "react-icons/gi";
import "./finance.scss";
import BarChart from "../../components/Charts/BarChart";
import PieC from "../../components/Charts/Pie";
import Loading from "../../components/LoadingPage/Loading";
import { API_URL } from "../../url";
import axios from "axios";
import { useEffect } from "react";
import Header from "../../components/Header";

const Overview = () => {
  const [schoolFinance, setSchoolFinance] = useState(null);
  const [balanceData, setBalanceData] = useState({});
  const [expenseData, setExpenseData] = useState({});
  const [incomeData, setIncomeData] = useState({});

  const getSchoolFinance = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}finance/school-finances/`,
          config
        );
        setSchoolFinance(res.data);

        setBalanceData({
          labels: res.data.balance_stats.map((data) => data.type),
          datasets: [
            {
              label: "Total",
              data: res.data.balance_stats.map((data) => data.amount),
              backgroundColor: ["#CD1818", "#85A389"],
            },
          ],
        });

        setExpenseData({
          labels: res.data.expense_stats.map((data) => data.month),
          datasets: [
            {
              label: "Expense",
              data: res.data.expense_stats.map((data) => data.amount),
            },
          ],
        });

        setIncomeData({
          labels: res.data.income_stats.map((data) => data.month),
          datasets: [
            {
              label: "Income",
              data: res.data.income_stats.map((data) => data.amount),
            },
          ],
        });

      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  useEffect(() => {
    getSchoolFinance();
  }, []);

  return (
    <div className="main-container">
      <Header title="Finance Overview" subtitle="School Finance Overview"/>
      {schoolFinance ? (
        <div className="finance">
          <div className="left-finance">
            <div className="cards">
              <div
                
                className="finance-card bg-blue-200"
              >
                
                <h3>Total Balance</h3>
                <p>{schoolFinance.total_balance}</p>
              </div>
              <div
                
                className="finance-card bg-green-200"
              >
                
                <h3>Total Income</h3>
                <p>{schoolFinance.total_income}</p>
              </div>
              <div
                
                className="finance-card bg-red-200"
              >
                
                <h3>Total Expense</h3>
                <p>{schoolFinance.total_expense}</p>
              </div>
            </div>

            <div className="expenses-chart">
              <p className="title">Expenses Stats</p>
              <p className="amount">{schoolFinance.total_expense}</p>
              <div className="chart">
                <BarChart className="bar" chartData={expenseData} />
                {/* <LineChart className="bar" chartData={userData} /> */}
              </div>
            </div>

            <div className="expenses-chart">
              <p className="title">Income Stats</p>
              <p className="amount">{schoolFinance.total_income}</p>
              <div className="chart">
                <BarChart className="bar" chartData={incomeData} />
                
              </div>
            </div>
          </div>
          <div className="right-finance">
            <div className="overview-pie">
              <h3>Total Balance</h3>
              <div className="chart">
                <PieC className="bar" chartData={balanceData} />
              </div>
            </div>

            
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Overview;
