import React, { useState } from "react";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Header from "../../components/Header";

const EyfsMarks = () => {
  const { academicYears, schoolClasses } = useAuthenticate();
  const [subjects, setSubjects] = useState([]);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [students, setStudents] = useState([]);
  const [academicTerms, setAcademicTerms] = useState([]);
  const [data, setData] = useState({});
  const [isFiltering, setIsFiltering] = useState(false);
  const [assessmentList, setAssessmentList] = useState([]);
  const [isSubmittingAssessments, setIsSubmittingAssessments] = useState(false);

  // CHANGE DATA
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // FETCH CLASS SUBJECTS
  const fetchClassTermSubjects = async (e) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/class-term-subjects/${e.target.value}/`,
          config
        );
        setSubjects(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // FETCH CLASS STUDENTS
  const fetchClassStudents = async (e) => {
    if (e.target.value !== "") {
      if (localStorage.getItem("access")) {
        setLoadingStudents(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-students/${e.target.value}`,
            config
          );
          setStudents(res.data);
          setLoadingStudents(false);
        } catch (error) {
          setLoadingStudents(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else if (error.response.status === 500) {
              toast.error("Something unexpected happened.");
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    } else {
      setStudents([]);
    }
  };

  // CHANGE ACADEMIC YEAR WITH TERM
  const changeAcademicYear = (e) => {
    handleChange(e);

    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  const handleFilterRecords = async (e) => {
    e.preventDefault();
    setIsFiltering(true);
    const config = {
      headers: {
        "Content-Type": `application/json`,
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(
        `${API_URL}academics/eyfs/objective-marks/${data["term"]}/${data["related_class"]}/${data["student"]}/${data["subject"]}`,
        config
      )
      .then((response) => {
        
        setAssessmentList(response?.data);
        // setAssessmentSnapShot(response?.data);
        setIsFiltering(false);
      })
      .catch((error) => {
        setIsFiltering(false);
      });
  };

  const updateStudentAssessmentMark = (newScore, assessmentId) => {
    if (assessmentList?.length > 0) {
      //get student id
      if (newScore > 4) {
        return;
      } else {
        const updatedAssessmentList = assessmentList?.map((assessment) => {
          if (assessment?.id === assessmentId) {
            return {
              ...assessment,
              marks: Number(newScore),
            };
          }
          return assessment;
        });
        setAssessmentList(updatedAssessmentList);
      }
    }
  };

  //save assessment record
  const saveAssessment = async () => {
    // first map assessmentList to get desired data for submission
    setIsSubmittingAssessments(true);

    const assessmentMarks = assessmentList?.map((assessment) => {
      return {
        marks: Number(assessment?.marks),
        eyfs_objective: assessment?.id,
      };
    });

    const formData = {
      student_class: data["related_class"],
      term: data["term"],
      student: data["student"],
      subject: data["subject"],
      student_marks: assessmentMarks,
    };

    const config = {
      headers: {
        "Content-Type": `application/json`,
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    await axios
      .post(
        `${API_URL}academics/eyfs/objective-marks/${data["term"]}/${data["related_class"]}/${data["student"]}/${data["subject"]}/`,
        formData,config
      )
      .then((response) => {
        if (response.data?.length > 0) {
          toast.success("Assessment entries sucessfully submitted!");

          setIsSubmittingAssessments(false);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
        setIsSubmittingAssessments(false);
      });
  };

  return (
    <div className="main-container">
      <Header title="EYFS Assessment" subtitle="Manage Assessment Marks for EYFS" />
      <form
        onSubmit={(e) => handleFilterRecords(e)}
        className="flex flex-wrap items-end gap-5 mt-4"
      >
        <div className="flex flex-col">
          <label htmlFor="" className="font-[500]">
            Academic Year
          </label>
          <select
            name="academic_year"
            id="academic_year"
            className="border border-primary p-2"
            onChange={(e) => changeAcademicYear(e)}
          >
            <option value="">Select Academic Year</option>
            {academicYears.map((academicYear) => {
              return (
                <option value={academicYear.id} key={academicYear.id}>
                  {academicYear.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="" className="font-[500]">
            Academic Term
          </label>
          <select
            name="term"
            onChange={(e) => handleChange(e)}
            required
            className="border border-primary p-2"
            id="academic_term"
          >
            {data.academic_year === "" ? (
              <option value="">Select Academic Year First</option>
            ) : (
              <>
                <option value="">Select Term</option>
                {academicTerms.map((academicTerm) => {
                  return (
                    <option key={academicTerm.id} value={academicTerm.id}>
                      {academicTerm.name}
                    </option>
                  );
                })}
              </>
            )}
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="" className="font-[500]">
            Class
          </label>
          <select
            name="related_class"
            id=""
            required
            className="border border-primary p-2"
            onChange={(e) => {
              handleChange(e);
              fetchClassStudents(e);
              fetchClassTermSubjects(e);
            }}
          >
            <option value="">Select Class</option>
            {schoolClasses.map((schoolClass) => (
              <React.Fragment key={schoolClass.id}>
                {schoolClass.class_level === "Preschool" && (
                  <option key={schoolClass.id} value={schoolClass.id}>
                    {schoolClass.class_name}
                  </option>
                )}
              </React.Fragment>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="" className="font-[500]">
            Student
          </label>
          <select
            name="student"
            id="student"
            className="border border-primary p-2"
            required
            onChange={(e) => handleChange(e)}
          >
            {loadingStudents ? (
              <option>Loading Students...</option>
            ) : (
              <>
                {students.length < 1 ? (
                  <option>Select Class First</option>
                ) : (
                  <>
                    <option value="">Select Student</option>
                    {students.map((student) => {
                      return (
                        <option
                          key={student.student_id}
                          value={student.student_id}
                        >
                          {student.get_full_name}
                        </option>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="" className="font-[500]">
            Subject
          </label>
          <select
            className="border border-primary p-2"
            name="subject"
            id=""
            required
            onChange={(e) => handleChange(e)}
          >
            <option value="">Select Subject</option>
            {subjects.map((subject, index) => {
              return (
                <option key={index} value={subject.id}>
                  {subject.subject_name}
                </option>
              );
            })}
          </select>
        </div>

        <button
          type="submit"
          className="px-3 py-2 rounded bg-secondary text-white"
        >
          Get Data
        </button>
      </form>

      {assessmentList?.length > 0 && (
        <div>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 my-5">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-left text-sm font-light text-surface border border-gray-300">
                  <thead className=" w-screen  font-medium">
                    <tr className="bg-gray-200 text-black">
                      <th scope="col" className="px-6 py-2 font-[500]">
                        #
                      </th>

                      <th scope="col" className="px-6 py-2 font-[500]">
                        Objective
                      </th>

                      <th scope="col" className="px-6 py-2 font-[500]">
                        Score
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assessmentList?.map((assessment, index) => (
                      <tr key={index} className="border-b border-gray-300">
                        <td className="px-6 py-2 font-[300]">{index + 1}</td>
                        <td className="px-6 py-2 font-[300]">
                          {assessment?.objective_name}
                        </td>
                        <td className="px-6 py-2 font-[300] flex items-center gap-3">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="radio"
                              name={`marks${assessment.id}`}
                              value="1"
                              checked={assessment?.marks === 1}
                              onChange={(e) =>
                                updateStudentAssessmentMark(
                                  e.target?.value,
                                  assessment?.id
                                )
                              }
                            />{" "}
                            <span className="px-[2px]">Beginning </span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="radio"
                              name={`marks${assessment.id}`}
                              value="2"
                              checked={assessment?.marks === 2}
                              onChange={(e) =>
                                updateStudentAssessmentMark(
                                  e.target?.value,
                                  assessment?.id
                                )
                              }
                            />{" "}
                            <span className="px-[2px]">Progressing</span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="radio"
                              name={`marks${assessment.id}`}
                              value="3"
                              checked={assessment?.marks === 3}
                              onChange={(e) =>
                                updateStudentAssessmentMark(
                                  e.target?.value,
                                  assessment?.id
                                )
                              }
                            />{" "}
                            <span className="px-[2px]">Satisfactory</span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="radio"
                              name={`marks${assessment.id}`}
                              value="4"
                              checked={assessment?.marks === 4}
                              onChange={(e) =>
                                updateStudentAssessmentMark(
                                  e.target?.value,
                                  assessment?.id
                                )
                              }
                            />{" "}
                            <span className="px-[2px]">Exceeding</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <button
            onClick={() => saveAssessment()}
            className="px-3 py-2 bg-light-primary text-white border border-light-primary hover:bg-transparent hover:text-light-primary"
          >
            Submit Marks
          </button>
        </div>
      )}
    </div>
  );
};

export default EyfsMarks;
