import React from "react";
import './StatsCard.scss'


const StatsCard = ({icon, title, text}) => {
  return (
    <div className="border border-primary rounded-lg px-6 py-5 relative">
      <div className="absolute top-5 left-5">
        {icon}
      </div>
      <div className="">
        <p className="text-right">{title}</p>
        <h3 className="text-right font-[800] text-[30px]">{text}</h3>
      </div>
    </div>
  );
};

export default StatsCard;
