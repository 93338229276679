import { combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { configureStore } from "@reduxjs/toolkit"
import { authReducer } from './reducers/authReducers'

const reducer = combineReducers({
    auth: authReducer
})

const initialState = {

};

const middleware = [thunk];

const store = configureStore(
    {reducer},
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;
