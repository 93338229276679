import React, { useEffect, useState } from "react";
import "./Dashboard.scss";

import { HiAcademicCap } from "react-icons/hi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdPeopleAlt } from "react-icons/md";

import StatsCard from "../../components/StatsCard/StatsCard";
import PieChart from "../../components/Charts/PieChart";

import { useAuthenticate } from "../../context/AuthContext";
import GreetingCard from "../../components/GreetingCard/GreetingCard";
import List from "../../components/List/List";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Barchart from "../../components/Charts/BarChart";
import { API_URL } from "../../url";
import axios from "axios";
import {toast} from 'react-toastify'
import SuperadminDashboard from "./SuperadminDashboard";

const Dashboard = () => {
  const { dashboardData, user } = useAuthenticate();
  const [attendanceData, setAttendanceData] = useState({})
  const [fetchingAttendanceData, setFetchingAttendanceData] = useState(true)
  console.log(dashboardData)

  const studentData = [
    {
      id: "Male",
      label: "Male",
      value: `${dashboardData.male_students_count}`,
      color: "hsl(227, 70%, 50%)",
    },
    {
      id: "Female",
      label: "Female",
      value: `${dashboardData.female_students_count}`,
      color: "hsl(3, 70%, 50%)",
    },
  ];

  // FETCH ATTENDANCE DATA
  const fetchAttendanceData = async () => {
    if (localStorage.getItem("access")){
      setFetchingAttendanceData(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      }

      if(user?.account_type === "School Administrator"){
        try {
          const response = await axios.get(
            `${API_URL}academics/today-attendance/`, config
          )
          setAttendanceData({
            labels: response.data.map((data)=>data.class_name),
            datasets: [
              {
                label: "Total Present",
                data: response.data.map((data)=>data.students_present),
                backgroundColor:"#549F93",
                barThickness:20
              },
              {
                label: "Total Absent",
                data: response.data.map((data)=>data?.students_absent),
                backgroundColor:"#E85F5C",
                barThickness:20
              },
            ],
          })
          setFetchingAttendanceData(false)
        } catch (error) {
            toast.error("An error occurred")        
        }
      }
    }
  }

  useEffect(() => {
    fetchAttendanceData()
    if(user?.account_type === "Master"){
      window.location.href = "/superadmin/dashboard"
    }else if(user?.account_type === "School Administrator"){
      window.location.href = "/admin/dashboard"
    }else if(user?.account_type === "School Staff"){
      if(user?.position === "Accountant"){
        window.location.href = "/accountant/dashbaord"
      }else{
        window.location.href = "/staff/dashboard"
      }
    }else if(user?.account_type === "Student"){
      window.location.href = "/std/dashboard"
    }else{
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      window.location.href = "/"
    }
  }, [])
  
  if(user?.account_type === "Master"){
    window.location.href = "/superadmin/dashboard"
  }else{
    return (
      <div className="dashboard-container">
        <div className="body">
          <div className="left">
            <GreetingCard user={dashboardData} />
            {
              user?.is_student ? (<div className="cards">
                <StatsCard
                    icon={<HiAcademicCap />}
                    title="Current Class"
                    text={dashboardData?.student_class?.class_name}
                  />
                  <StatsCard
                    icon={<MdPeopleAlt />}
                    title="Students in class"
                    text={dashboardData?.class_students}
                  />
                  <StatsCard
                    icon={<FaChalkboardTeacher />}
                    title="Total Subjects"
                    text={dashboardData?.subjects_count}
                  />
              </div>):(
                <div className="cards">
                  <StatsCard
                    icon={<HiAcademicCap />}
                    title="Total Students"
                    text={dashboardData?.total_students}
                  />
                  <StatsCard
                    icon={<MdPeopleAlt />}
                    title="Total Staff"
                    text={dashboardData?.total_staffs}
                  />
                  <StatsCard
                    icon={<FaChalkboardTeacher />}
                    title="Teachers"
                    text={dashboardData?.total_teachers}
                  />
                </div>
              )
            }
  
            {
              user.account_type === "School Administrator" && (
  
                <div className="today-attendance">
                  <div className="attendance-top">
                    <p className="title">
                      Today's Attendance
                    </p>
                    <Link to="/attendance/class-weekly-attendance" className="attendance-link">
                      Go to Attendance
                    </Link>
                  </div>
                  <div className="chart-data">
                    {
                      fetchingAttendanceData ? "Fetching Data..." : 
  
                      <Barchart chartData={attendanceData}/>
                    }
                  </div>
                </div>
              )
            }
  
          </div>
          {/* <div className="right">
            <div className="events-card">
              <div className="header">
                <h3 className="title">Upcoming Events</h3>
                {user.account_type === "School Administrator" && (
                  <div className="add-events-btn">
                    <Link to="/events">See All</Link>
                  </div>
                )}
              </div>
              <div className="events">
                {dashboardData.events && dashboardData.events.length > 0 ? (
                  <>
                    {dashboardData.events.map((event) => {
                      return (
                        <div className="event" key={event.id}>
                          <div className="event-details">
                            <h4 className="event-title">
                              <Link to={`/events/${event.id}`}>
                                {event.title}
                              </Link>
                            </h4>
                            <span className="event-description">
                              {parse(event.description.slice(0, 100))}...
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="no-event">
                    <h2>No Event Added</h2>
                    {user.account_type === "School Administrator" && (
                      <button>
                        <Link to="/events">Add Event</Link>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="chart-card">
              <div className="title">
                <h3>Student Population</h3>
              </div>
              <PieChart data={studentData} />
            </div>
          </div> */}
        </div>
        {
          user?.account_type === "School Administrator" && (
        <div className="list-container">
          <div className="list-title">Latest Admissions</div>
          <List rows={dashboardData.latest_admissions} />
        </div>
          )
        }
        {
          user?.is_employee && user?.position == "Teacher" && (
            <div className="schedule-body">
                <div className="student-list">
                  <div className="panel-header">
                    <h4>Upcoming Classes</h4>
                  </div>
                  <div className="list-body">
                    <table>
                      <thead>
                        <tr>
                          <th>Subject</th>
                          <th>Class</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dashboardData?.upcoming_classes?.map((upcoming_class, index)=>(
                            <tr>
                              <td>{upcoming_class?.subject}</td>
                              <td>{upcoming_class?.class}</td>
                              <td>{upcoming_class?.start_time}</td>
                              <td>{upcoming_class?.end_time}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          )
        }
  
        {
          user?.is_student &&(
            <div className="schedule-body">
                <div className="student-list">
                  <div className="panel-header">
                    <h4>Today's Schedule</h4>
                  </div>
                  <div className="list-body">
                    <table>
                      <thead>
                        <tr>
                          <th>Subject</th>
                          <th>Teacher</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dashboardData?.today_schedule?.map((upcoming_class, index)=>(
                            <tr>
                              <td>
                                {
                                  upcoming_class?.is_break ? "Break Time" : (
                                    <>
                                      {
                                  upcoming_class?.activity ? (
                                    <>
                                      {upcoming_class?.activity}
                                    </>
                                  ):(
                                    <>
                                      {upcoming_class?.subject}
                                    </>
                                  )
                                }
                                    </>
                                  )
                                }
                                
                                </td>
                              <td>{upcoming_class?.teacher}</td>
                              <td>{upcoming_class?.start_time}</td>
                              <td>{upcoming_class?.end_time}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          )
        }
      </div>
    );
  }
};

export default Dashboard;
