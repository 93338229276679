import axios from "axios";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { MdDelete } from "react-icons/md";
import { useAuthenticate } from "../../context/AuthContext";

const NewCollectible = ({ setModal }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
  });
  

  // Handle Form Change
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);

      try {
        await axios.post(`${API_URL}finance/collectibles/`, body, config);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Collectible Added Successfully",
        }).then(() => {
          window.location.reload();
        });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if(error.response.status === 400){
              for (const property in error.response.data) {
                toast.error(`${property} error: ${error.response.data[property]}`);
              }
          }else{
              toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  return (
    <div
      id="modal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal"
      onClick={() => setModal(false)}
    >
      <div
        class="bg-white rounded-lg shadow-lg lg:w-1/3 w-4/5 max-h-screen overflow-auto"
        onClick={(e) => e.stopPropagation(e)}
      >
        <div class="px-6 py-4 border-b text-black items-center flex justify-between">
          <h3 class="text-lg  font-semibold">New Collectible</h3>
          <IoCloseCircleOutline
            onClick={() => setModal(false)}
            className="text-[20px] cursor-pointer"
          />
        </div>
        <div class="px-4 max-h-[500px] overflow-auto">
          <div className="shadow mt-5 lg:mt-0 overflow-hidden sm:rounded-md bg-white">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="px-4 py-2 bg-white sm:p-6"
            >
              <div className="grid grid-cols-6 gap-2">
                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Collectible Name
                  </label>

                  <input
                    type="text"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    id=""
                    required
                    placeholder="e.g Canteen Fee"
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  />
                </div>

                
                
              </div>
              <div className="flex items-center justify-end">

                {loading ? (
                    <RotatingLines
                    visible={true}
                    height="26"
                    width="26"
                    strokeColor="black"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                ) : (
                    <button
                    type="submit"
                    value="Save"
                    id="payouts-account-submit"
                    class="mt-2 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-600"
                    >
                    Add Collectible
                    </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCollectible;
