import React from "react";
import VerifyOTP from "../../assets/VerifyOTP.jpg";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";

const VerifyOtp = () => {
  const { id } = useParams();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const resendOtp = async () => {
    if (!loading) {
      setLoading(true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      try {
        const res = await axios.post(
          `${API_URL}accounts/resend-otp/${id}/`,
          config
        );
        if (res.status === 200) {
          setLoading(false)
          toast.success("OTP code sent successfully");
        }
      } catch (error) {
        setLoading(false)
        if (error.response) {
          if (error.response.code !== 500) {
            for (const property in error.response.data) {
              if (property === "0") {
                toast.error(`${error.response.data[property]}`);
              } else {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const body = {
      otp: otp,
    };

    try {
      const res = await axios.post(
        `${API_URL}accounts/verify-otp/${id}/`,
        body,
        config
      );
      if (res.status === 200) {
        toast.success("OTP Verified Successfully");
        window.location.href = `/change-password/${id}`;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.code !== 500) {
          for (const property in error.response.data) {
            if (property === "0") {
              toast.error(`${error.response.data[property]}`);
            } else {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          }
        }
      }
    }
  };

  return (
    <div id="reset-password">
      <div className="left-image">
        <img src={VerifyOTP} alt="" />
      </div>
      <div className="right-forms">
        <form onSubmit={(e) => verifyOtp(e)}>
          <div className="header">
            <h3>Verify OTP</h3>
            <p>A Verification Code Has Been Sent To Your Phone Number</p>
          </div>
          <input
            onChange={(e) => setOtp(e.target.value)}
            type="number"
            placeholder="OTP Code"
          />
          <p className="resend-code">
            Didn't receive code? &nbsp;
            <i onClick={() => resendOtp()}>Resend Code</i>
          </p>
          {loading ? (
            <button style={{ background: "gray" }}>
              <ColorRing
                visible={true}
                height="20"
                width="20"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />{" "}
              Submitting...
            </button>
          ) : (
            <input type="submit" value="Submit" />
          )}
          <div className="back-to-login">
            <IoIosArrowBack />
            <Link to="/">Back To Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;
