import axios from "axios";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { MdDelete } from "react-icons/md";
import { useAuthenticate } from "../../context/AuthContext";

const NewFeeGroup = ({ setModal }) => {
    const { academicYears } = useAuthenticate();
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState([]);
  const [data, setData] = useState({
    is_school_fees: true,
  });
  const [formFields, setFormFields] = useState([
    {
      fee_type: "",
      amount: "",
    },
  ]);
  const changeAcademicYear = (e) => {
    handleChange(e);
    if (e.target.value !== "") {
      setTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  // Handle Form Change
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // Handle Fee type change
  const handleFeeTypeChange = (e, index) => {
    const formData = [...formFields];
    formData[index][e.target.name] = e.target.value;
    setFormFields(formData);
  };

  //  Adding fee types section
  const addFields = () => {
    const object = {
      fee_type: "",
      amount: "",
    };
    setFormFields([...formFields, object]);
  };

  // Removing fee type section
  const removeFee = (index) => {
    const formFieldData = [...formFields];
    formFieldData.splice(index, 1);
    setFormFields(formFieldData);
  };

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify({
        ...data,
        fees_components: formFields,
      });

      try {
        await axios.post(`${API_URL}finance/fees-group/`, body, config);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Fee Category Added Successfully",
        }).then(() => {
          window.location.reload();
        });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  return (
    <div
      id="modal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal"
      onClick={() => setModal(false)}
    >
      <div
        class="bg-white rounded-lg shadow-lg lg:w-1/3 w-4/5 max-h-screen overflow-auto"
        onClick={(e) => e.stopPropagation(e)}
      >
        <div class="px-6 py-4 border-b text-black items-center flex justify-between">
          <h3 class="text-lg  font-semibold">New Fee Category</h3>
          <IoCloseCircleOutline
            onClick={() => setModal(false)}
            className="text-[20px] cursor-pointer"
          />
        </div>
        <div class="px-4 max-h-[500px] overflow-auto">
          <div className="shadow mt-5 lg:mt-0 overflow-hidden sm:rounded-md bg-white">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="px-4 py-2 bg-white sm:p-6"
            >
              <div className="grid grid-cols-6 gap-2">
                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Category Name
                  </label>

                  <input
                    type="text"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    id=""
                    required
                    placeholder="e.g Class One Fees"
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  />
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Academic Year
                  </label>
                  <select
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                    name="academic_year"
                    id="academic_year"
                    onChange={(e) => changeAcademicYear(e)}
                  >
                    <option value="">Select Academic Year</option>
                    {academicYears.map((academicYear) => {
                      return (
                        <option value={academicYear.id} key={academicYear.id}>
                          {academicYear.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Term
                  </label>
                  <select
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                    name="term"
                    id="term"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Academic Year</option>
                    {terms.map((term) => {
                      return (
                        <option value={term.id} key={term.id}>
                          {term.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-span-6">
                  {formFields.map((formField, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <div>
                          <label>Fees Type</label>
                          <input
                            style={{ width: "80%" }}
                            type="text"
                            name="fee_type"
                            id="fee_type"
                            className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                            onChange={(e) => handleFeeTypeChange(e, index)}
                          />
                        </div>
                        <div>
                          <label>Amount</label>
                          <input
                            style={{ width: "80%" }}
                            type="text"
                            name="amount"
                            id="amount"
                            className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                            onChange={(e) => handleFeeTypeChange(e, index)}
                          />
                        </div>
                        {index > 0 && (
                          <MdDelete
                            onClick={(e) => removeFee(index)}
                            style={{
                              fontSize: "25px",
                              
                              cursor: "pointer",
                            }}
                            className="text-red-500"
                          />
                        )}
                      </div>
                    );
                  })}
                    <small>
                        Add more fees from the one with the highest priority
                    </small>
                    <div className="w-max border border-blue-600 text-blue-600 cursor-pointer px-3 text-[13px] py-2 rounded" onClick={addFields}>
                        Add More Fees
                    </div>
                </div>
              </div>
              <div className="flex items-center justify-end">

                {loading ? (
                    <RotatingLines
                    visible={true}
                    height="26"
                    width="26"
                    strokeColor="black"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                ) : (
                    <button
                    type="submit"
                    value="Save"
                    id="payouts-account-submit"
                    class="mt-2 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-600"
                    >
                    Add Category
                    </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFeeGroup;
