import React from 'react'
import { ProgressBar, RotatingSquare } from "react-loader-spinner";
import Logo from "../../assets/logos/default-logo.png"

const Loading = () => {
  return (
    <div className=" min-h-[85vh] flex flex-col items-center justify-center">
        <img src={Logo} className='h-20' />
        <RotatingSquare
          visible={true}
          height="50"
          width="50"
          color="#ad5b2a"
          ariaLabel="rotating-square-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
    </div>
  )
}

export default Loading