import React, { useState, useEffect } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { API_URL } from "../../url";
import { ColorRing } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const columns = [
  { field: "academic_year", headerName: "Academic Year", width: 150 },
  { field: "name", headerName: "Term Name", width: 150 },
  { field: "start_date", headerName: "Start Date", width: 150 },
  { field: "end_date", headerName: "End Date", width: 150 },
  { field: "is_current_term", headerName: "Current Term", width: 150 },
];

const AcademicTerms = () => {
  const { academicTerms, academicYears} = useAuthenticate()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    academic_year: "",
    name: "",
    start_date: "",
    end_date: "",
    is_current_term: true,
  });

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (localStorage.getItem("access")) {
      setLoading(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);
      try {
        await axios.post(
          `${API_URL}academics/academic-terms/`,
          body,
          config
        );
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Academic Term Added Successfully",
        }).then(()=>{
          window.location.reload();
        });
      } catch (error) {
        if (error.response){
          if(error.response.status === 401){
            window.location.href="/"
          }else{
            for (const property in error.response.data){
              toast.error(`${property} error: ${error.response.data[property]}`)
            }
          }
        }else if(error.message){
          toast.error("Something went wrong. Please try again")
        }
        setLoading(false);
      }
    }
  };

  
  return (
    
      <div className="main-container">
        <div className="header">
          <h1>ACADEMIC TERMS</h1>
        </div>
        <div className="class-body">
          <div className="left">
            <p>Add Academic Term</p>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="classFormGroup">
                <label>Academic Year</label>
                <select name="academic_year" onChange={(e) => onChange(e)}>
                  {
                    academicYears.length > 0 ? (
                      <>
                        <option>Select Academic Year</option>
                        {academicYears.map((academicYear) => {
                          return (
                            <option key={academicYear.id} value={academicYear.id}>
                              {academicYear.name}
                            </option>
                          );
                        })}
                      </>

                    ):(
                      <option>
                        No Academic Year has been added yet
                      </option>
                    )
                  }
                  
                </select>
              </div>
              <div className="classFormGroup">
                <label>Academic Term Name</label>
                <input
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="name"
                  id="term_name"
                  placeholder="Term 1"
                />
              </div>
              <div className="classFormGroup">
                <label>Academic Term Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  onChange={(e) => onChange(e)}
                  id="start_date"
                  placeholder=""
                />
              </div>
              <div className="classFormGroup">
                <label>Academic Term End Date</label>
                <input
                  type="date"
                  name="end_date"
                  onChange={(e) => onChange(e)}
                  id="end_date"
                  placeholder=""
                />
              </div>
              <div className="classFormGroup">
                <label>Next Term Start Date</label>
                <input
                  type="date"
                  name="next_term_start_date"
                  onChange={(e) => onChange(e)}
                  id="next_term_start_date"
                  placeholder=""
                />
              </div>
              <div className="classFormGroup">
                <div className="radioInline">
                  <input
                    type="checkbox"
                    name="is_current_term"
                    defaultChecked={data.is_current_term}
                    onChange={(e) =>
                      setData({
                        ...data,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  />{" "}
                  Set As Current Academic Term
                </div>
                <small style={{ marginTop: "10px", color: "gray" }}>
                  Setting checking this checkbox will make this new academic
                  year the current academic term.
                </small>
              </div>
              {loading ? (
                <button className="submitButton"
                  style={{
                    backgroundColor: "gray",
                    cursor: "wait",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />{" "}
                  Adding Academic Term
                </button>
              ) : (
                <input disabled={data.academic_year === "" || data.name === "" || data.start_date === "" || data.end_date === "" } className="submitButton" type="submit" value="Add Academic Term" />
              )}
            </form>
          </div>
          <div className="right" style={{ height: 400 }}>
            <DataGrid
              rows={academicTerms}
              columns={columns}
              paginationModel={{ page: 0, pageSize: 5 }}
              pageSizeOptions={[5,10,15,20]}
              // checkboxSelection
            />
          </div>
        </div>
      </div>
    
  );
};

export default AcademicTerms;
