import axios from 'axios'
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_FAIL,
    AUTHENTICATED_SUCCESS,
    LOGOUT,
    SIGNUP_FAIL,
    SIGNUP_SUCCESS,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL
} from './types'
import { API_URL } from '../url'

// Checking Authentication
export const checkAuthenticated = () => async dispatch =>{

    if(localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-Type':'application/json'
            }
        }
        const body = JSON.stringify({token:localStorage.getItem('access')})
        try{
            const res = await axios.post(`${API_URL}authentication/token/verify/`,body,config)    
            if(res.data.code !== "token_not_valid"){
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                })
            }else{
                
                dispatch({
                    type: AUTHENTICATED_FAIL
                })
            }

        }catch(error){
            if(error.response){
                if(error.response.data.code === "token_not_valid"){
                    if(localStorage.getItem("refresh")){
                        const config = {
                            headers:{
                                'Content-Type': 'application/json'
                            }
                        }
                        const body = JSON.stringify({"refresh": localStorage.getItem('refresh')})
                        try{
                            const res = await axios.post(`${API_URL}authentication/token/refresh/`,body, config)
                            
                        }catch(error){
                            
                        }
                    }
                    dispatch({
                        type: AUTHENTICATED_FAIL
                    })
                }
            }

            if(error.code === "ERR_NETWORK"){
                dispatch({
                    type: AUTHENTICATED_FAIL
                })
            }
            
        }
        
    }
    
}
   

// loading user
export const load_user = () => async (dispatch) =>{
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,

            }
        };
        try{
            const res = await axios.get(`${API_URL}accounts/users/me/`, config)
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            })
        }catch(err){
            dispatch({
                type: USER_LOADED_FAIL
            })
        }
    }
    
}


// loging in user
export const login = (username, password) => async (dispatch) =>{
    try{
        dispatch({
            type: LOGIN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type':'application/json'
            }
        }

        const body = JSON.stringify({username, password})
        

        await axios.post(`${API_URL}authentication/login/`,body,config).then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            })
            dispatch(load_user())
        })


    }catch(error){
        if (error.code === "ERR_NETWORK"){
            dispatch({
                type: LOGIN_FAIL,
                payload: "You seem not to be connected to the internet. Please try again."
            })
        }
        if (error.response){
            dispatch({
                type: LOGIN_FAIL,
                payload: error.response.data.detail
            })
        }

    }
}

// Verify user
export const verify = (uid, token) => async dispatch =>{
    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    };

    const body = JSON.stringify({uid, token});

    
    try{
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, body, config)
        dispatch({
            type: ACTIVATION_SUCCESS,
        })
    }catch(err){
        dispatch({
            type: ACTIVATION_FAIL
        })
    }
}

// logging out user
export const logout = () => dispatch =>{
    dispatch({
        type: LOGOUT
    })
}