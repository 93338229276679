import React from "react";

import { useState } from "react";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuthenticate } from "../../context/AuthContext";
import { ColorRing } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { AiOutlineUpload } from "react-icons/ai";

const AddMultipleStudents = () => {
  const { schoolClasses } = useAuthenticate();
  const [studentImages, setStudentImages] = useState([]);
  const [schoolClass, setSchoolClass] = useState("");
  const [loading, setLoading] = useState(false);
  const [studentList, setStudentList] = useState(null);
  const [error, setError] = useState(false);

  // SUBMIT DATA
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append("school_class", schoolClass);
    formData.append("student_list", studentList);


    for (let i = 0; i < studentImages.length; i++) {
      if (studentImages[i].size > 878576) {
        toast.error(`${studentImages[i].name} is larger than 878KB`);
        setError(true);
      }
      formData.append("student_images", studentImages[i]);
    }

    if (localStorage.getItem("access") && !error) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        const res = await axios.post(
          `${API_URL}accounts/add-multiple-students/`,
          formData,
          config
        );

        setLoading(false);
        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Students added successfully",
          }).then(() => {
            window.location.reload();
          });
        }
      } catch (error) {
        if (error.response) {
          if (error.response.data.rows_created > 0) {
            toast.success(
              `Successfully created the first ${
                error.response.data.rows_created + 1
              } row(s)`
            );
          }
          for (const property in error.response.data.response_data) {
            if (property === "0") {
              toast.error(
                `For row ${error.response.data.row + 1} ${
                  error.response.data.response_data[property]
                }`
              );
            } else {
              toast.error(
                `For row ${error.response.data.row + 1}, ${property} error: ${
                  error.response.data.response_data[property]
                }`
              );
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
        setLoading(false);
      }
    }
  };

  // COMPRESS FILE
  const compressFiles = (images) => {
    var imagesList = []
    for(let i = 0; i < images.length; i++) {
      let file_name = images[i].name;
      let reader = new FileReader();
      reader.readAsDataURL(images[i]);
      reader.onload = (event) => {
        let image_url = event.target.result;
        let image = document.createElement("img");
        image.src = image_url;
  
        image.onload = (e) => {
          // creating a new canvas for the image
          const canvas = document.createElement("canvas");
          let ratio = 300 / e.target.width;
          canvas.width = 300;
          canvas.height = ratio * e.target.height;
  
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
  
          // converting the image quality to 50% and the file type to webp
          let new_image_url = canvas.toDataURL("image/webp", 50);
  
          // getting the image type and the data
          let arr = new_image_url.split(",");
          let image_type = arr[0].match(/:(.*?);/)[1];
          let data = arr[1];
          let dataStr = atob(data);
          let n = dataStr.length;
          let dataArr = new Uint8Array(n);
  
          while (n--) {
            dataArr[n] = dataStr.charCodeAt(n);
          }
  
          const compressed_image = new File([dataArr], file_name, {
            type: image_type,
          });
          imagesList.push(compressed_image);
          setStudentImages(imagesList)
        };
      };
    }
  };
  
  return (
    <div className="main-container">
      <h3 className="font-[400] text-[40px] my-5">Bulk Upload Student</h3>
      <div className="disclaimer disclaimer  py-[15px] px-[20px] rounded-[5px] mb-[20px] ">
        <h4>Instructions</h4>
        <p>
          You are to follow the instructions below to successfully add multiple
          students
        </p>
        <ul>
          <li>
          Click the button below to download the student bulk create excel file
          </li>
          <Link to="https://api.meskuu.com/media/Student-Upload-Template.xlsx" className="bg-blue-600 text-white px-3 text-[13px] py-2 rounded">
            Download Excel File
          </Link>
          <li>
            You are not to change the fields in the first row of the excel file
          </li>
          <li>The username field is required and should not contain spaces</li>
          <li>
            All phone number fields (phone_number, mother_phone_number,
            father_phone_number, guardian_phone_number) should start with the
            country code. For example +233541245785 for a number in Ghana
          </li>
          <li>
            The gender of the student is to be selected from the dropdown which is either Male or Female.
          </li>
          <li>
            The date of birth and admission date are a required fields and must not be left blank
          </li>
          <li>
            Roll number, index Number, blood group, religion and email are optional field. But must be valid once entered.
          </li>
          <li>
            For all dates (date_of_birth, admission_date) should be in the
            mm/dd/yyyy format
          </li>
          <li>
          Click on the 'Browse File' button to select the filled template saved on your computer
          </li>
          <li>
            For the images of the students, you are to save the image of the
            student with a name that corresponds to the Student Image Name field in the
            excel file
          </li>
          <li>
            Upon uploading the file, select the class and then upload the
            completed excel file with the images of the students before
            uploading
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div className="right">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="formSection">
              <div className="formRowHeader">
                <div className="formRow">
                  <div className="formInput">
                    <label>Class</label>
                    <select
                      onChange={(e) => setSchoolClass(e.target.value)}
                      name="school_class"
                      id="school_class"
                      className="text-[13px]"
                      required
                    >
                      <option value="">Select Class</option>
                      {schoolClasses.map((schoolClass) => {
                        return (
                          <option value={schoolClass.id} key={schoolClass.id}>
                            {schoolClass.class_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="formInput">
                    <label htmlFor="">Student List</label>
                    <input
                      type="file"
                      accept=".xlsx"
                      required
                      onChange={(e) => setStudentList(e.target.files[0])}
                      name="student_list"
                      id="student_list"
                    />
                    <label id="upload-button" htmlFor="student_list">
                      <AiOutlineUpload style={{ fontSize: "15px" }} />
                      Browse File
                    </label>
                    <p style={{ fontWeight: "400", fontSize: "12px" }}>
                      {studentList ? studentList.name : "No file selected"}
                    </p>
                  </div>

                  <div className="formInput">
                    <label htmlFor="">Student Images</label>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      name="student_images"
                      onChange={(e) => compressFiles(e.target.files)}
                      id="student_images"
                    />
                    <label id="upload-button" htmlFor="student_images">
                      <AiOutlineUpload style={{ fontSize: "15px" }} />
                      Browse Files
                    </label>
                    <p style={{ fontWeight: "400", fontSize: "12px" }}>
                      {studentImages
                        ? `${studentImages.length} files selected`
                        : "No image selected"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <button
                className="submitButton"
                style={{ width: "max-content", background: "gray" }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
                Adding Students...
              </button>
            ) : (
              <input
                type="submit"
                className="submitButton"
                value="Upload Data"
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMultipleStudents;
