export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS'
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL'

export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS'
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL'

export const LOGOUT = 'LOGOUT'

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL' 