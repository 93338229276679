import React, { useState, useRef } from "react";
import { useAuthenticate } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../../url";
import { ColorRing } from "react-loader-spinner";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { TbMoneybag } from "react-icons/tb";
import ReactToPrint from "react-to-print";
import Logo from "../../../assets/logos/default-logo.png";
import NoDataImage from "../../../assets/no_data.jpg"
import Transaction from "../Transaction";
import Header from "../../../components/Header";


const YourFees = () => {
  const { academicYears,schoolInfo } = useAuthenticate();
  const [academicTerms, setAcademicTerms] = useState([]);
  const [academicYear, setAcademicYear] = useState("");
  const [academicTerm, setAcademicTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const today = new Date().toISOString().slice(0, 10);
  const [toggle, setToggle] = useState(1);
  const [studentFees, setStudentFees] = useState(null)
  const [feeTransactions, setFeeTransactions] = useState(null);
  const mainFeeRef = useRef();


  const changeAcademicYear = (e) => {
    setAcademicYear(e.target.value);

    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  // TOGGLE PAGES
  const updateToggle = (tab) => {
    setToggle(tab);
  };

  //   GET STUDENT FEES
  const getStudentFees = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        const res = await axios.get(
          `${API_URL}student/academic-fees/${academicTerm}/`,
          config
        );

        setStudentFees(res.data);
        getFeeTransactions();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

    //   GET STUDENT FEES
  const getFeeTransactions = async () => {
    if (localStorage.getItem("access")) {
      
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        const res = await axios.get(
          `${API_URL}student/transactions/${academicYear}/${academicTerm}/`,
          config
        );

        setFeeTransactions(res.data);
        
      } catch (error) {
        console.log(error);
        
      }
    }
  };
  

  return (
    <div className="main-container">
      <Header title="My Fees" subtitle="Get to know your fees and transactions" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => getStudentFees(e)}>
            {/* ACADEMIC YEAR DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_year"
                id="academic_term"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* ACADEMIC TERM DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_term"
                onChange={(e) => setAcademicTerm(e.target.value)}
                id="academic_term"
              >
                {academicYear === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            {/* IF FILTERING DATA SHOW A LOADING BUTTON ELSE SHOW A SUBMIT BUTTON */}
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data...{" "}
              </button>
            ) : (
              <button type="submit">Filter</button>
            )}
          </form>
        </div>

        <div className="positions-container" style={{ marginTop: "25px" }}>
            {/* NAVIGATION TABS */}
            <div className="tabs">
              <div
                className={toggle === 1 ? "active-tab" : "tab"}
                onClick={() => {
                  updateToggle(1);
                }}
              >
                Term School Fees
              </div>
              {/* <div
                className={toggle === 2 ? "active-tab" : "tab"}
                onClick={() => {
                  updateToggle(2);
                }}
              >
                Term Extra Fees
              </div> */}
              <div
                className={toggle === 3 ? "active-tab" : "tab"}
                onClick={() => {
                  updateToggle(3);
                }}
              >
                Transaction History
              </div>
            </div>

            {/* SCHOOL FEES */}
            <div className={toggle === 1 ? "show-content" : "content"}>
              {/* IF THERE ARE FEES FOR THE STUDENT, DISPLAY THE FEES ELSE DISPLAY NO DATA FOUND MESSAGE */}
              {studentFees ? (
                
                  
                    <div className="schedule-body">
                      <div className="tab-cards">
                        <div className="tab-card">
                          <GiPayMoney className="icon" />
                          <h3>GHS {studentFees?.total_fees_amount}</h3>
                          <p>Amount To Be Paid</p>
                        </div>
                        <div className="tab-card">
                          <TbMoneybag className="icon" />
                          <h3>GHS {studentFees?.amount_paid}</h3>
                          <p>Amount Paid</p>
                        </div>
                        <div className="tab-card">
                          <GiReceiveMoney className="icon" />
                          <h3>GHS {studentFees?.balance}</h3>
                          <p>Outstanding Balance</p>
                        </div>
                      </div>
                      <ReactToPrint
                        trigger={() => (
                          <button
                            style={{
                              backgroundColor: "#304D7B",
                              marginTop: "20px",
                              marginLeft: "20px",
                              color: "white",
                              cursor: "pointer",
                              border: "none",
                              padding: "10px 15px",
                              width: "max-content",
                            }}
                          >
                            {" "}
                            Print / Download
                          </button>
                        )}
                        content={() => mainFeeRef.current}
                      />
                      {/* STUDENT FEES BILL */}
                      <div
                        ref={mainFeeRef}
                        style={{
                          padding: "25px 55px 50px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        className="student-fee-body"
                      >
                        <div
                          style={{
                            color: "white",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            background: "#5b3016",
                          }}
                        ></div>
                        {/* WATERMARK */}
                        <img
                          src={
                            schoolInfo?.school_logo
                              ? schoolInfo?.school_logo
                              : Logo
                          }
                          alt="logo"
                          style={{
                            opacity: 0.1,
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            height: "100%",
                            transform: "translate(-50%,-50%)",
                          }}
                        />
                        <div
                          className="school-info"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="logo" style={{ flex: "2" }}>
                            {schoolInfo?.school_logo ? (
                              <img
                                style={{ height: "100px", borderRadius: "50%" }}
                                src={schoolInfo?.school_logo}
                                alt="school_logo"
                              />
                            ) : (
                              <img
                                style={{ height: "50px", marginBottom: "30px" }}
                                src={Logo}
                                alt="school_logo"
                              />
                            )}
                          </div>
                          <div style={{ flex: "1" }} className="school-details">
                            <h3>{schoolInfo.school_name}</h3>
                            <p>
                              {schoolInfo?.school_city}
                              {","}
                              {schoolInfo?.school_district} -{" "}
                              {schoolInfo?.school_region}
                            </p>
                            <p>{schoolInfo?.school_phone_number}</p>
                            <p>{schoolInfo?.school_email}</p>
                          </div>
                        </div>
                        <div
                          className="student-term-info"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="student-info">
                            <p>
                              {" "}
                              <b>Student Name:</b> {studentFees?.student}
                            </p>
                            <p>
                              <b>Student ID: </b>{" "}
                              {studentFees?.student_school_id}
                            </p>
                            <p>
                              <b>Student Class: </b>
                              {studentFees?.student_class}
                            </p>
                          </div>
                        </div>
                        <div
                          className="bill"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <h3
                            style={{
                              textDecoration: "underline",
                              fontWeight: "600",
                              marginTop: "30px",
                            }}
                          >
                            Student Fees For {studentFees?.academic_year}{" "}
                            {studentFees?.term}
                          </h3>
                          <table
                            style={{
                              border: "1px solid #eee",
                              borderCollapse: "collapse",
                              width: "70%",
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    color: "#176B87",
                                    border: "1px solid #ddd",
                                    textAlign: "left",
                                    padding: "8px",
                                    width: "50%",
                                  }}
                                >
                                  Fee Type
                                </th>
                                <th
                                  style={{
                                    color: "#176B87",
                                    border: "1px solid #ddd",
                                    textAlign: "right",
                                    padding: "8px",
                                  }}
                                >
                                  Amount (GHS)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentFees?.fees?.map((fee, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {fee?.components.map((component, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid #ddd",
                                                textAlign: "left",
                                                padding: "8px",
                                              }}
                                            >
                                              {component?.fee_type}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid #ddd",
                                                textAlign: "right",
                                                padding: "8px",
                                              }}
                                            >
                                              {component?.amount}
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    })}
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    textAlign: "left",
                                    padding: "8px",
                                    color: "#FF6666",
                                  }}
                                >
                                  Total
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    textAlign: "right",
                                    padding: "8px",
                                    color: "#FF6666",
                                  }}
                                >
                                  {studentFees?.total_fees_amount}
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>

                        <p
                          className="info-text"
                          style={{
                            textAlign: "center",
                            fontSize: "15px",
                            color: "#8a8a8a",
                            marginTop: "100px",
                          }}
                        >
                          This bill was generated using Meskuu School
                          Management System
                        </p>
                        <div
                          style={{
                            color: "white",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            background: "#5b3016",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "right",
                              width: "100%",
                              paddingRight: "10px",
                            }}
                          >
                            Date Printed: {today}
                          </p>
                        </div>
                      </div>
                    </div>
                  
                
              ) : (
                <div className="no-data">
                    <img src={NoDataImage} alt="no data" />
                    <p>
                      No Data Found
                    </p>
                  </div>
              )}
            </div>

            {/* EXTRA FEES */}
            {/* <div className={toggle === 2 ? "show-content" : "content"}>
              {studentExtraFees.fees ? (
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <button
                        style={{
                          backgroundColor: "#304D7B",
                          marginTop: "20px",
                          marginLeft: "20px",
                          color: "white",
                          cursor: "pointer",
                          border: "none",
                          padding: "10px 15px",
                        }}
                      >
                        {" "}
                        Print / Download
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  <div
                    ref={componentRef}
                    style={{
                      padding: "25px 55px 50px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    className="student-fee-body"
                  >
                    <div
                      style={{
                        color: "white",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        background: "#5b3016",
                      }}
                    ></div>
                    
                    <img
                      src={
                        schoolInfo.school_logo ? schoolInfo.school_logo : Logo
                      }
                      alt="logo"
                      style={{
                        opacity: 0.1,
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        height: "100%",
                        transform: "translate(-50%,-50%)",
                      }}
                    />
                    <div
                      className="school-info"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="logo" style={{ flex: "2" }}>
                        {schoolInfo.school_logo ? (
                          <img
                            style={{ height: "100px", borderRadius: "50%" }}
                            src={schoolInfo.school_logo}
                            alt="school_logo"
                          />
                        ) : (
                          <img
                            style={{ height: "50px", marginBottom: "30px" }}
                            src={Logo}
                            alt="school_logo"
                          />
                        )}
                      </div>
                      <div style={{ flex: "1" }} className="school-details">
                        <h3>{schoolInfo.school_name}</h3>
                        <p>
                          {schoolInfo.school_city}, {schoolInfo.school_district}{" "}
                          - {schoolInfo.school_region}
                        </p>
                        <p>Phone: {schoolInfo.school_phone_number}</p>
                        <p>Email: {schoolInfo.school_email}</p>
                      </div>
                    </div>
                    <div
                      className="student-term-info"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="student-info">
                        <p>
                          {" "}
                          <b>Student Name:</b> {studentFees.student}
                        </p>
                        <p>
                          <b>Student ID: </b> {studentFees.student_school_id}
                        </p>
                        <p>
                          <b>Student Class: </b>
                          {studentFees.student_class}
                        </p>
                      </div>
                    </div>
                    <div
                      className="bill"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <h3
                        style={{
                          textDecoration: "underline",
                          fontWeight: "600",
                          marginTop: "30px",
                        }}
                      >
                        Student Extra Fees For {studentExtraFees.academic_year}{" "}
                        {studentExtraFees.term}
                      </h3>
                      <table
                        style={{
                          border: "1px solid #eee",
                          borderCollapse: "collapse",
                          width: "70%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#176B87",
                                color: "white",
                                border: "1px solid #ddd",
                                textAlign: "left",
                                padding: "8px",
                                width: "50%",
                              }}
                            >
                              Fee Type
                            </th>
                            <th
                              style={{
                                backgroundColor: "#176B87",
                                color: "white",
                                border: "1px solid #ddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentExtraFees.fees.map((fee, index) => {
                            return (
                              <React.Fragment key={index}>
                                {fee.components.map((component, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr key={index}>
                                        <td
                                          style={{
                                            border: "1px solid #ddd",
                                            textAlign: "left",
                                            padding: "8px",
                                          }}
                                        >
                                          {component.fee_type}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid #ddd",
                                            textAlign: "left",
                                            padding: "8px",
                                          }}
                                        >
                                          {component.amount}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                textAlign: "left",
                                padding: "8px",
                                color: "#FF6666",
                              }}
                            >
                              Total
                            </th>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                textAlign: "left",
                                padding: "8px",
                                color: "#FF6666",
                              }}
                            >
                              {studentExtraFees.total_fees_amount}
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <p
                      className="info-text"
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        color: "#8a8a8a",
                        marginTop: "50px",
                      }}
                    >
                      This bill was generated using Meskuu School Management
                      System
                    </p>
                    <div
                      style={{
                        color: "white",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        background: "#5b3016",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "right",
                          width: "100%",
                          paddingRight: "10px",
                        }}
                      >
                        Date Printed: {today}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: "center", padding: "50px" }}>
                  NO EXTRA FEES FOR THIS STUDENT
                </div>
              )}
            </div> */}

            
            <div
              className={
                toggle === 3 ? "show-content transaction-history" : "content"
              }
            >
                {
                    feeTransactions && (
                        <div className="transactions-list">
                            {feeTransactions.map((transaction, index) => {
                            return (
                                <Transaction
                                key={transaction.id}
                                transaction={transaction}
                                schoolInfo={schoolInfo}
                                />
                            );
                            })}
                        </div>
                    )
                }
            </div>

            
          </div>
      </div>
    </div>
  );
};

export default YourFees;
