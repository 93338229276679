import React from 'react'
import './Table.scss'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const List = ({rows}) => {
    const UserImage = "https://epasmart.s3.eu-north-1.amazonaws.com/assets/user_image.svg"
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="table-cell">Name</TableCell>
            <TableCell className='table-cell'>Student ID</TableCell>
            <TableCell className='table-cell'>Class</TableCell>
            <TableCell className='table-cell'>Gender</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows &&
            (
              <>
              {rows.map((row, index) => (
              <TableRow
                key={index}
                
              >
                <TableCell className="table-cell">
                  <div className="cell-wrapper">
                    {
                      row.profile_picture ? (
                        <img src={row.profile_picture} alt="" className='cell-image' />
                      ) : (
                        <img src={UserImage} alt="" className='cell-image' />
                      )
                    }
                    {row.get_full_name}
                  </div>
                </TableCell>
                <TableCell className="table-cell">{row.student_school_id}</TableCell>
                <TableCell className="table-cell">{row.student_class}</TableCell>
                <TableCell className="table-cell">{row.gender}</TableCell>
              </TableRow>
            ))}
            </>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default List