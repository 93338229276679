import React, { useState } from "react";
import { useAuthenticate } from "../../context/AuthContext";
import { API_URL } from "../../url";
import axios from "axios";
import ObjectivesModal from "./ObjectivesModal";
import { toast } from "react-toastify";
import Header from "../../components/Header";

const PreschoolReport = () => {
  const { academicYears, schoolClasses } = useAuthenticate();
  const [data, setData] = useState({});
  const [academicTerms, setAcademicTerms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [addObjectivesModal, setAddObjectivesModal] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [eyfsReport, setEyfsReport] = useState(null);

  // REMOVE OBJECTIVE
  const removeObjective = async (e, objective_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.put(
          `${API_URL}academics/eyfs/subject-area/${data.term}/${data.related_class}/${data.subject}/`,
          {"objective": objective_id},
          config
        );
        toast.success('Objective Removed Successfully')
        handleSubmit(e)
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  }

  // SUBMIT DATA
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/eyfs/subject-area/${data.term}/${data.related_class}/${data.subject}/`,
          config
        );
        setEyfsReport(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        
      }
    }
    setDataFetched(true);
  };

  // FETCH CLASS SUBJECTS
  const fetchClassTermSubjects = async (e) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/class-term-subjects/${e.target.value}/`,
          config
        );
        setSubjects(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // CHANGE DATA
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // CHANGE ACADEMIC YEAR WITH TERM
  const changeAcademicYear = (e) => {
    handleChange(e);

    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };
  
  return (
    <div className="main-container">
      <Header title="EYFS Areas" subtitle="Pre-school (EYFS) study areas" />
      <p>
        The early years foundation stage ( EYFS ) sets standards for the
        learning, development and care of your child from birth to 5 years old.
      </p>
      <div style={{ marginTop: "20px" }} className="schedule-body">
        <div className="filter-columns">
          <form style={{ gap: "12px" }} onSubmit={(e) => handleSubmit(e)}>
            {/* ACADEMIC YEARS DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_year"
                id="academic_year"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* ACADEMIC TERMS DROPDOWN */}
            <div className="formInput">
              <select
                name="term"
                onChange={(e) => handleChange(e)}
                id="academic_term"
              >
                {data.academic_year === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            {/* CLASSES DROPDOWN */}
            <div className="formInput">
              <select
                name="related_class"
                id=""
                onChange={(e) => {
                  handleChange(e);
                  fetchClassTermSubjects(e);
                }}
              >
                <option value="">Select Class</option>
                {schoolClasses.map((schoolClass) => (
                  <>
                    {schoolClass.class_level === "Preschool" && (
                      <option key={schoolClass.id} value={schoolClass.id}>
                        {schoolClass.class_name}
                      </option>
                    )}
                  </>
                ))}
              </select>
            </div>
            {/* AREA TYPE DROPDOWN */}
            <div className="formInput">
              <select name="area_type" onChange={(e) => handleChange(e)} id="">
                <option value="">Select Area Type</option>
                <option value="Prime Area">Prime Area</option>
                <option value="Specific Area">Specific Area</option>
              </select>
            </div>
            {/* SUBJECT DROPDOWN */}
            <div className="formInput">
              <select name="subject" id="" onChange={(e) => handleChange(e)}>
                <option value="">Select Subject</option>
                {subjects.map((subject, index) => {
                  return (
                    <option key={index} value={subject.id}>
                      {subject.subject_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <button>Get Data</button>
          </form>
        </div>
        {dataFetched && (
          <div className="schedule">
            <div
              onClick={() => setAddObjectivesModal(!addObjectivesModal)}
              className="classic-button-outline"
            >
              Add More Objectives
            </div>
            {addObjectivesModal && (
              <ObjectivesModal
                modal={addObjectivesModal}
                setModal={setAddObjectivesModal}
                data={data}
                reFetchData={handleSubmit}
              />
            )}

            {eyfsReport.objectives ? (
              <table style={{ marginTop: "20px" }}>
                <thead style={{ background: "#068DA9", color: "white" }}>
                  <tr>
                    <th>Objective</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {eyfsReport.objectives.map((objective) => (
                    <tr key={objective.id}>
                      <td style={{width: "80%"}}>
                        {objective.objective_name}
                      </td>
                      <td style={{width: "20%"}}>
                        <div onClick={(e)=>removeObjective(e,objective.id)} className="remove-button">Remove Objective</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ):(
              <div style={{marginTop: "20px", display: "flex", alignItems:"center", justifyContent:"center"}}>No Objectives For This Area</div>
            )
          }
          </div>
        )}
      </div>
    </div>
  );
};

export default PreschoolReport;
