import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../context/AuthContext";
import Header from "../../components/Header";


const EyfsObjectives = () => {
  const {user} = useAuthenticate()
  const [eyfsObjectives, setEyfsObjectives] = useState([]);
  const [data, setData] = useState({
    objective_name: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  // HANDLE CHANGE IN DATA
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  //   ADD OBJECTIVE
  const addObjective = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);
      try {
        const res = await axios.post(
          `${API_URL}academics/eyfs/objectives/`,
          body,
          config
        );

        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Objective Added Successfully",
        }).then(() => {
            fetchEyfsObjectives();
        });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          } else if(error.response.status === 400) {
            for (const property in error.response.data) {
              toast.error(`${error.response.data[property]}`);
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  //   FETCH EYFS OBJECTIVES
  const fetchEyfsObjectives = async () => {
    setLoadingData(true);
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/eyfs/objectives/`,
          config
        );
        setEyfsObjectives(res.data);
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  const columns = [
    { field: "objective_name", headerName: "Objective Name", width: 700 },
  ];

  useEffect(() => {
    fetchEyfsObjectives();
  }, []);
  return (
    <div className="main-container">
      <Header title="EYFS Objectives" subtitle="Early Years Foundation Stage Objectives Management" />
      <div className="class-body">
        {
          user?.account_type === "School Administrator" && (
            <div className="left">
              <p>Add EYFS Objective</p>
              <form onSubmit={(e) => addObjective(e)}>
                <div className="classFormGroup">
                  <label>Objective Name*</label>
                  <textarea
                    name="objective_name"
                    onChange={(e) => handleChange(e)}
                    id="objective_name"
                    cols="30"
                    rows="8"
                  ></textarea>
                  {/* <input
                    type="text"
                    name="objective_name"
                    id="objective_name"
                    onChange={(e) => handleChange(e)}
                  /> */}
                </div>
                {loading ? (
                  <button className="submitButton">
                    <ColorRing
                      visible={true}
                      height="20"
                      width="20"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                    />{" "}
                    Adding Objective{" "}
                  </button>
                ) : (
                  <input
                    disabled={data.objective_name === ""}
                    className="submitButton"
                    type="submit"
                    value="Add Objective"
                  />
                )}
              </form>
            </div>
          )
        }
        <div className="right">
          <DataGrid
            rows={eyfsObjectives}
            columns={columns}
            loading={loadingData}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      </div>
    </div>
  );
};

export default EyfsObjectives;
