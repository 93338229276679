import React, { useState, useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../context/AuthContext";
import NoDataImage from "../../assets/no_data.jpg";

const AssignStudentVehicle = () => {
  const { schoolClasses } = useAuthenticate();
  const [selectedClass, setSelectedClass] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [classStudents, setClassStudents] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [assigningVehicle, setAssigningVehicle] = useState(false);

  // HANDLE CHECK ALL CHECK BOXES
  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === "selectAll") {
      let tempUser = classStudents.map((student) => {
        return { ...student, assigned: checked };
      });
      setClassStudents(tempUser);
    } else {
      let tempUser = classStudents.map((student) => {
        return student.student_id === value
          ? { ...student, assigned: checked }
          : student;
      });
      setClassStudents(tempUser);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedClass === "") {
      toast.error("Please Check And Select all required fields");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-students/${selectedClass}/`,
            config
          );
          setClassStudents([]);
          setClassStudents(res.data);
          setDataFetched(true);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    }
  };

  const handleAssignVehicle = async (e) => {
    e.preventDefault();
    if(selectedVehicle === ""){
      toast.error("Please select a vehicle")
    }else{

      let tempSelectedStudents = [];
      for (var i = 0; i < classStudents.length; i++) {
        if (classStudents[i].assigned) {
          tempSelectedStudents.push(classStudents[i].student_id);
        }
      }

      

      if (localStorage.getItem("access")) {
        setAssigningVehicle(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
  
        const body = JSON.stringify({
          student_list: tempSelectedStudents,
          vehicle: selectedVehicle
        })

        try {
          await axios.post(
            `${API_URL}transport/student-assigned-vehicle/`,
            body,
            config
          );
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Students assigned to vehicle successfully",
          });
          setAssigningVehicle(false)
        } catch (error) {
          setAssigningVehicle(false)
          console.log(error);
          toast.error("Something went wrong. Please check and try again")
        }
      }
    }
    
    
  };

  useEffect(() => {
    const fetchVehicles = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        try {
          const res = await axios.get(`${API_URL}transport/vehicles/`, config);

          setVehicles(res.data);
        } catch (error) {
          if (error.response.status === 401) {
            window.location.href = "/";
          }
        }
      }
    };
    fetchVehicles();
  }, []);

  return (
    <div className="main-container">
      <div className="header">
        <h1>ASSIGN VEHICLE TO STUDENT</h1>
      </div>
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="formInput">
              <select
                name="student_class"
                id=""
                onChange={(e) => setSelectedClass(e.target.value)}
              >
                <option value="">Select Class</option>
                {schoolClasses.map((schoolClass) => {
                  return (
                    <option value={schoolClass.id} key={schoolClass.id}>
                      {schoolClass.class_name}
                    </option>
                  );
                })}
              </select>
            </div>
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  color: "white",
                  padding: "15px",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Filtering...{" "}
              </button>
            ) : (
              <button type="submit">Get Class Students</button>
            )}
          </form>
        </div>

        {dataFetched && (
          <>
            {classStudents.length > 0 ? (
              <div className="student-list">
                <header className="panel-header">
                  <h4>Student List</h4>
                </header>

                <div className="list-body">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "80px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            id=""
                            checked={
                              !classStudents.some(
                                (student) => student?.assigned !== true
                              )
                            }
                            onChange={(e) => handleCheckboxChange(e)}
                          />
                        </th>
                        <th>Student Name</th>
                        <th>Student ID</th>
                        <th>Class</th>
                        <th>Gender</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classStudents.map((student, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: "80px" }}>
                              <input
                                type="checkbox"
                                name="student"
                                value={student.student_id}
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={student?.assigned}
                              />
                            </td>
                            <td>
                              {student.first_name} {student.last_name}
                            </td>
                            <td>{student.student_school_id}</td>
                            <td>{student.student_class}</td>
                            <td>{student.gender}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <form onSubmit={(e) => handleAssignVehicle(e)} className="graduating-badge-form">
                    <select
                      name="vehicle"
                      id=""
                      onChange={(e) => setSelectedVehicle(e.target.value)}
                    >
                      <option value="">Select Vehicle</option>
                      {vehicles.map((vehicle) => (
                        <option key={vehicle.id} value={vehicle.id}>
                          {vehicle.vehicle_number}
                        </option>
                      ))}
                    </select>
                    {assigningVehicle ? (
                      <button
                        className="save-button"
                        style={{
                          backgroundColor: "gray",
                          cursor: "wait",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          color: "white",
                          padding: "10px",
                          marginTop: "15px",
                          border: "none",
                        }}
                      >
                        <ColorRing
                          visible={true}
                          height="20"
                          width="20"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />{" "}
                        Assigning Vehicle...{" "}
                      </button>
                    ) : (
                      <button
                        className="save-button"
                      >
                        Assign Vehicle
                      </button>
                    )}
                  </form>
                </div>
              </div>
            ) : (
              <div className="no-data">
                <img src={NoDataImage} alt="no data" />
                <p>No Student In This Class.</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AssignStudentVehicle;
