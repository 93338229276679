import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { API_URL } from "../../url";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import "./Class.scss";
import { useAuthenticate } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import NewClassModal from "./NewClassModal";
import Header from "../../components/Header";

const ClassList = () => {
  const { schoolClasses, user } = useAuthenticate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    class_name: "",
    class_level: "",
  });
  const [update, setUpdate] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [updateDataFields, setUpdateDataFields] = useState({});
  const [newClassModal, setNewClassModal] = useState(false);

  // Field change handler
  const handleChange = (e) => {
    if (!update) {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    } else {
      setUpdateData({
        ...updateData,
        [e.target.name]: e.target.value,
      });
      setUpdateDataFields({
        ...updateDataFields,
        [e.target.name]: e.target.value,
      });
    }
  };

  // form Submittion handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (data.class_name === "" || data.class_level === "") {
        toast.error("Please check fill all required fields");
      } else {
        if (localStorage.getItem("access")) {
          setLoading(true);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          if (!update) {
            const body = JSON.stringify(data);
            try {
              await axios.post(`${API_URL}academics/classes/`, body, config);

              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Class Added Successfully",
              }).then(() => {
                window.location.reload();
              });
            } catch (error) {
              setLoading(false);
              if (error.response) {
                if (error.response.status === 401) {
                  window.location.href = "/";
                } else {
                  for (const property in error.response.data) {
                    toast.error(
                      `${property} error: ${error.response.data[property]}`
                    );
                  }
                }
              } else if (error.message) {
                toast.error("Something went wrong. Please try again");
              }
            }
          } else {
            const updateBody = JSON.stringify(updateDataFields);
            try {
              await axios.put(
                `${API_URL}academics/class/${updateData.id}/`,
                updateBody,
                config
              );
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Updated",
                text: "Class Updated Successfully",
              }).then(() => {
                window.location.reload();
              });
            } catch (error) {
              setLoading(false);
              if (error.response) {
                if (error.response.status === 401) {
                  window.location.href = "/";
                } else {
                  for (const property in error.response.data) {
                    toast.error(
                      `${property} error: ${error.response.data[property]}`
                    );
                  }
                }
              } else if (error.message) {
                toast.error("Something went wrong. Please try again");
              }
            }
          }
        }
      }
    }
  };

  // table columns
  const columns = [
    { field: "class_name", headerName: "Class Name", width: 170 },
    { field: "students_in_class", headerName: "Students In Class", width: 150 },
    { field: "class_level", headerName: "Class Level", width: 150 },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {user.account_type === "School Administrator" && (
              <div className="cellAction">
                <div
                  // onClick={() => {
                  //   setUpdateData(params.row);
                  //   setUpdate(true);
                  // }}
                  onClick={() => {
                    window.location.href = `/class/${params.id}/`;
                  }}
                  className="viewButton"
                >
                  View Details
                </div>
                {/* <div className="deleteButton">Delete</div> */}
              </div>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {}, []);
  return (
    <div className="main-container">
      <Header title="Classes" subtitle="List of classes " />

      {newClassModal && <NewClassModal setNewClassModal={setNewClassModal} />}

      {
        user?.account_type === "School Administrator" && (
        <div>
          <button
            className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-400 rounded"
            onClick={() => setNewClassModal(true)}
          >
            Add New Class
          </button>
        </div>
        )
      }


      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light text-surface border border-gray-300">
              <thead className=" w-screen  font-medium">
                <tr className="bg-gray-200 text-black">
                  <th scope="col" className="px-6 py-2 font-[500]">
                    Class Name
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Students In Class
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Class Level
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {schoolClasses?.map((school_class, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="px-6 py-2 font-[300]">
                      {school_class?.class_name}
                    </td>

                    <td className="px-6 py-2 font-[300]">
                      {school_class?.students_in_class}
                    </td>

                    <td className="px-6 py-2 font-[300]">
                      {school_class?.class_level}
                    </td>

                    <td className="flex items-center gap-2 py-2">
                      <Link
                        to={`/class/${school_class.id}/`}
                        className=" w-max px-3 py-2 font-[500] border border-blue-400 text-blue-400 rounded hover:bg-blue-400 hover:text-white cursor-pointer"
                      >
                        View Details
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default ClassList;
