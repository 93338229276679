import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.scss";

import { HiAcademicCap } from "react-icons/hi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdPeopleAlt } from "react-icons/md";

import StatsCard from "../../components/StatsCard/StatsCard";
import PieChart from "../../components/Charts/PieChart";

import { useAuthenticate } from "../../context/AuthContext";
import GreetingCard from "../../components/GreetingCard/GreetingCard";
import List from "../../components/List/List";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Barchart from "../../components/Charts/BarChart";
import { API_URL } from "../../url";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../components/LoadingPage/Loading";

const StudentDashboard = () => {
  const { user } = useAuthenticate();
  const [gettingDashboardData, setGettingDashboardData] = useState(true);
  const [dashboardData, setDashboardData] = useState({});

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };

  const getDashboardData = async () => {
    setGettingDashboardData(true)
    await axios.get(`${API_URL}student/dashboard`, config).then((response)=>{
      setDashboardData(response.data);
      setGettingDashboardData(false)
    }).catch((error)=>{
      setGettingDashboardData(false)
    })
  }

  useEffect(()=>{
    getDashboardData()
  },[])

  return (
    <div className="dashboard-container">
      {gettingDashboardData ? (
        <Loading />
      ) : (
        <div>
          <div className="body">
            <div className="left">
              <GreetingCard user={dashboardData} />
            </div>
          </div>

          <div className="schedule-body">
            <div className="student-list">
              <div className="panel-header">
                <h4>Today's Schedule</h4>
              </div>
              <div className="list-body">
                <table>
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Teacher</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData?.today_schedule?.map(
                      (upcoming_class, index) => (
                        <tr>
                          <td>
                            {upcoming_class?.is_break ? (
                              "Break Time"
                            ) : (
                              <>
                                {upcoming_class?.activity ? (
                                  <>{upcoming_class?.activity}</>
                                ) : (
                                  <>{upcoming_class?.subject}</>
                                )}
                              </>
                            )}
                          </td>
                          <td>{upcoming_class?.teacher}</td>
                          <td>{upcoming_class?.start_time}</td>
                          <td>{upcoming_class?.end_time}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentDashboard;
