import React, { useState, useEffect } from "react";

import { API_URL } from "../../url";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import Multiselect from "multiselect-react-dropdown";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const FeeAllocation = () => {
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [terms, setTerms] = useState([]);
  const [feesGroup, setFeesGroup] = useState([]);
  const [classFees, setClassFees] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const deleteClassFee = async (id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        await axios.delete(`${API_URL}finance/class-fee/${id}`, config);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Class Fee Deleted Successfully",
        });
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            for (const property in error.response.data) {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedClassesIds = [];
    for (let i = 0; i < selectedClasses.length; i++) {
      selectedClassesIds.push(selectedClasses[i].id);
    }

    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify({
        ...data,
        classes: selectedClassesIds,
      });
      try {
        await axios.post(`${API_URL}finance/class-fees/`, body, config);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Fees Allocated Successfully",
        });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            for (const property in error.response.data) {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };
  useEffect(() => {
    const fetchFeeGroups = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        try {
          const res = await axios.get(`${API_URL}finance/fees-group/`, config);
          // setSchoolClasses(res.data)
          setFeesGroup(res.data);
        } catch (error) {}
      }
    };

    const fetchSchoolClasses = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        try {
          const res = await axios.get(`${API_URL}academics/classes/`, config);
          // setSchoolClasses(res.data)
          setSchoolClasses(res.data);
        } catch (error) {}
      }
    };

    const fetchAcademicTerms = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        try {
          const res = await axios.get(
            `${API_URL}academics/academic-terms/`,
            config
          );
          setTerms(res.data);
        } catch (error) {}
      }
    };

    const fetchClassFees = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        try {
          const res = await axios.get(`${API_URL}finance/class-fees/`, config);
          setClassFees(res.data);
        } catch (error) {}
      }
    };

    fetchAcademicTerms();
    fetchFeeGroups();
    fetchSchoolClasses();
    fetchClassFees();
  }, []);

  const columns = [
    { field: "fee_group", headerName: "Fees Group", width: 230 },
    { field: "term", headerName: "Term", width: 230 },
    { field: "classes", headerName: "Classes", width: 230 },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      renderCell: ({ id }) => {
        return (
          <div className="cellAction">
            <div className="viewButton">View</div>
            <div onClick={(e) => deleteClassFee(id)} className="deleteButton">
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="main-container">
      <div className="header">
        <h1>FEES ALLOCATION</h1>
      </div>
      <div className="class-body">
        <div className="left">
          <p>Allocate Fees</p>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="classFormGroup">
              <label>Fees Group</label>
              <select
                name="fee_group"
                onChange={(e) => handleChange(e)}
                id="fees_group"
              >
                <option>Select Fee Group</option>
                {feesGroup.map((feeGroup) => {
                  return (
                    <option value={feeGroup.id} key={feeGroup.id}>
                      {" "}
                      {feeGroup.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="classFormGroup">
              <label>Term</label>
              <select name="term" id="term" onChange={(e) => handleChange(e)}>
                <option>Select Term</option>
                {terms.map((term) => {
                  return (
                    <option value={term.id} key={term.id}>
                      {" "}
                      {term.term_full_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="classFormGroup">
              <label>Classes</label>
              <Multiselect
                onSelect={(e) => setSelectedClasses(e)}
                avoidHighlightFirstOption={true}
                placeholder="Select Classes"
                name="classes"
                options={schoolClasses} // Options to display in the dropdown
                displayValue="class_name" // Property name to display in the dropdown options
              />
              <small>
                This will assign this fee type to all students in this class
              </small>
            </div>
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Adding Fee Type...{" "}
              </button>
            ) : (
              <button type="submit">Add Fee Type</button>
            )}
          </form>
        </div>
        <div style={{ height: "max-content" }} className="right">
          <DataGrid
            rows={classFees}
            columns={columns}
            paginationModel={{ page: 0, pageSize: 5 }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
};

export default FeeAllocation;
