import React, { useState, useRef } from "react";
import NoDataImage from "../../assets/no_data.jpg"
import axios from "axios";
import { API_URL, img_base_url } from "../../url";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";
import ReactToPrint from "react-to-print";
import Logo from "../../assets/logos/default-logo.png";
import Swal from "sweetalert2";
import { ColorRing } from "react-loader-spinner";
import Transaction from "./Transaction";
import { TbMoneybag } from "react-icons/tb";
import { GiCheckMark, GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { FaExclamation } from "react-icons/fa";
import Header from "../../components/Header";
import { MdOutlinePendingActions } from "react-icons/md";
import NoData from "../../components/NoData";

const StudentFees = () => {
  const { academicYears, schoolClasses, schoolInfo } = useAuthenticate();
  const [students, setStudents] = useState([]);
  const [academicTerms, setAcademicTerms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [studentFees, setStudentFees] = useState({});
  const [feeTypes, setFeeTypes] = useState([]);
  const [selectedBillType, setSelectedBillType] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [selectedFeeType, setSelectedFeeType] = useState("");
  const [studentExtraFees, setStudentExtraFees] = useState({});
  const [toggle, setToggle] = useState(1);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [makingPayment, setMakingPayment] = useState(false);
  const today = new Date().toISOString().slice(0, 10);
  const [outstandingBalanceData, setOutstandingBalanceData] = useState([]);
  const [fullPaymentAmount, setFullPaymentAmount] = useState("");
  const [data, setData] = useState({
    student: "",
    term: "",
    academic_year: "",
    amount_paid: "",
    date_paid: "",
    mode_of_transfer: "",
  });
  const mainFeeRef = useRef();
  const componentRef = useRef();

  // CHANGE DATA
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // CHANGE ACADEMIC YEAR WITH TERM
  const changeAcademicYear = (e) => {
    handleChange(e);

    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  // CHANGING FEE TYPE WITH BILL TYPES
  const changeFeeType = (e) => {
    if (e.target.value !== "") {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
      const feeType = feeTypes.find(
        (feeType) => feeType.id === parseInt(e.target.value)
      );

      if (feeType.is_school_fees) {
        setFullPaymentAmount(studentFees.balance);
      }
      setSelectedFeeType(feeType);
    }
  };

  // CHANGE BILL TYPES
  const changeBillType = (e) => {
    
    setSelectedBillType(e.target.value);
    setMaxAmount(
      outstandingBalanceData.find(
        (balanceData) => balanceData.id === parseInt(e.target.value)
      ).amount
    );
  };

  // TOGGLE PAGES
  const updateToggle = (tab) => {
    setToggle(tab);
  };

  // GET TRANSACTION HISTORY FOR STUDENT PAYMENT
  const getTransactionHistory = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        const res = await axios.get(
          `${API_URL}finance/student-fees-transactions/${data.student}/${data.academic_year}/${data.term}/`,
          config
        );

        setTransactionHistory(res.data);
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // GET BILL TYPES FOR STUDENT
  const getStudentBillTypes = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        const res = await axios.get(
          `${API_URL}finance/student-bill-types/${data.student}/${data.term}/`,
          config
        );
        setFeeTypes(res.data);
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // GET STUDENT'S EXTRA FEES
  const getExtraFees = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        const res = await axios.get(
          `${API_URL}finance/student-extra-fees/${data.student}/${data.term}/`,
          config
        );

        setStudentExtraFees(res.data);
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // SUBMIT DATA
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.student === "" || data.term === "") {
      toast.error("Please Check And Select all required fields");
    } else {
      setLoading(true);
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}finance/student-fees/${data.student}/${data.term}/`,
            config
          );
          setStudentFees(res.data);
          getStudentOutstandingBalanceDetails();
          getTransactionHistory();
          getExtraFees();
          getStudentBillTypes();
          setLoading(false);
          setDataFetched(true);
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else if (error.response.status === 500) {
              toast.error("Something went wrong");
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  // COLLECT FEES
  const handleMakePayment = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setMakingPayment(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      let body = {};
      if (data["bill_type"] === "full_payment") {
        body = JSON.stringify({
          ...data,
          amount_paid: fullPaymentAmount,
        });
      } else {
        body = JSON.stringify(data);
      }
      try {
        const res = await axios.post(
          `${API_URL}finance/collect-fees/`,
          body,
          config
        );

        if (res.status === 201) {
          setMakingPayment(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Fee collected Successfully",
          }).then(() => {
            getTransactionHistory();
            handleSubmit(e);
            updateToggle(3);
          });
        } else if (res.status === 200) {
          setMakingPayment(false);
          for (const property in res.data) {
            if (property === "0") {
              toast.success(`${res.data[property]}`);
            } else {
              toast.success(`${property} success: ${res.data[property]}`);
            }
          }
        }
      } catch (error) {
        setMakingPayment(false);
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          } else if(error.response.status === 400) {
            for (const property in error.response.data) {
              if (property === "0") {
                toast.error(`${error.response.data[property]}`);
              } else {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // FETCH CLASS STUDENTS
  const fetchClassStudents = async (e) => {
    if (e.target.value !== "") {
      if (localStorage.getItem("access")) {
        setLoadingStudents(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-students/${e.target.value}`,
            config
          );
          setStudents(res.data);
          setLoadingStudents(false);
        } catch (error) {
          setLoadingStudents(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    } else {
      setStudents([]);
    }
  };

  // GET STUDENT OUTSTANDING BALANCE DETAILS
  const getStudentOutstandingBalanceDetails = async (e) => {
    if (localStorage.getItem("access")) {
      try {
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        await axios
          .get(
            `${API_URL}finance/student-outstanding-balance/${data.student}/${data.term}/`,
            config
          )
          .then((response) => {
            console.log(response.data);
            setOutstandingBalanceData(response.data);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="main-container">
      <Header title="Student Fees" subtitle="Manage the fees of students" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => handleSubmit(e)}>
            {/* CLASSES DROPDOWN */}
            <div className="formInput">
              <select
                name="school_class"
                id=""
                onChange={(e) => fetchClassStudents(e)}
              >
                <option value="">Select Class</option>
                {schoolClasses.map((schoolClass) => (
                  <option key={schoolClass.id} value={schoolClass.id}>
                    {schoolClass.class_name}
                  </option>
                ))}
              </select>
            </div>
            {/* STUDENTS DROPDOWN */}
            <div className="formInput">
              <select
                name="student"
                id="student"
                required
                onChange={(e) => handleChange(e)}
              >
                {loadingStudents ? (
                  <option>Loading Students...</option>
                ) : (
                  <>
                    {students.length < 1 ? (
                      <option>Select Class First</option>
                    ) : (
                      <>
                        <option value="">Select Student</option>
                        {students.map((student) => {
                          return (
                            <option
                              key={student.student_id}
                              value={student.student_id}
                            >
                              {student.get_full_name} 
                            </option>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </select>
            </div>
            {/* ACADEMIC YEARS DROPDOWN */}
            <div className="formInput">
              <select
                name="academic_year"
                id="academic_year"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* ACADEMIC TERMS DROPDOWN */}
            <div className="formInput">
              <select
                name="term"
                onChange={(e) => handleChange(e)}
                id="academic_term"
              >
                {data.academic_year === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            {/* IF GETTING DATA, SHOW A LOADING BUTTON ELSE SHOW A SUBMIT BUTTON */}
            {loading ? (
              <div className="loading-btn">
                <button
                  style={{
                    backgroundColor: "gray",
                    cursor: "wait",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    color: "white",
                    padding: "15px",
                    border: "none",
                  }}
                >
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />{" "}
                  Getting Data...{" "}
                </button>
              </div>
            ) : (
              <button type="submit">Get Data</button>
            )}
          </form>
        </div>
        {/* IF THE DATA IS FETCHED DISPLAY THIS SECTION */}
        {dataFetched && (
          <div className="positions-container" style={{ marginTop: "25px" }}>
            {/* NAVIGATION TABS */}
            <div className="tabs">
              <div
                className={toggle === 1 ? "active-tab" : "tab"}
                onClick={() => {
                  updateToggle(1);
                }}
              >
                Term School Fees
              </div>
              {/* <div
                className={toggle === 2 ? "active-tab" : "tab"}
                onClick={() => {
                  updateToggle(2);
                }}
              >
                Term Extra Fees
              </div> */}
              <div
                className={toggle === 3 ? "active-tab" : "tab"}
                onClick={() => {
                  updateToggle(3);
                }}
              >
                Transaction History
              </div>
              <div
                className={toggle === 4 ? "active-tab" : "tab"}
                onClick={() => {
                  updateToggle(4);
                }}
              >
                Collect Fees
              </div>
            </div>

            {/* SCHOOL FEES */}
            <div className={toggle === 1 ? "show-content" : "content"}>
              {/* IF THERE ARE FEES FOR THE STUDENT, DISPLAY THE FEES ELSE DISPLAY NO DATA FOUND MESSAGE */}
              {studentFees.fees ? (
                <>
                  {studentFees.fees.length > 0 ? (
                    <div className="schedule-body">
                      <div className="grid grid-cols-3 my-10 mx-10 gap-10">
                        <div className="custom-drop-shadow p-[20px] rounded-[5px] flex items-center">
                          <FaExclamation className="text-yellow-500 mr-4 text-[25px]" />
                          <div>
                            <h3 className="font-[700] text-[20px]">GHS {studentFees.total_fees_amount}</h3>
                            <p className="text-[14px] text-gray-500">Amount To Be Paid</p>
                          </div>
                        </div>
                        <div className="custom-drop-shadow p-[20px] rounded-[5px] flex items-center">
                          <GiCheckMark className="text-green-500 mr-4 text-[25px]" />
                          <div>
                            <h3  className="font-[700] text-[20px]">GHS {studentFees.amount_paid}</h3>
                            <p className="text-[14px] text-gray-500">Amount Paid</p>
                          </div>
                        </div>
                        <div className="custom-drop-shadow p-[20px] rounded-[5px] flex items-center">
                          <MdOutlinePendingActions className="text-red-500 mr-4 text-[25px]" />
                          <div>
                            <h3  className="font-[700] text-[20px]">GHS {studentFees.balance}</h3>
                            <p className="text-[14px] text-gray-500">Outstanding Balance</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-end">
                        <ReactToPrint
                          trigger={() => (
                            <button
                              className="w-max border border-secondary text-secondary px-4 py-2 rounded"
                            >
                              {" "}
                              Download PDF
                            </button>
                          )}
                          content={() => mainFeeRef.current}
                        />
                      </div>
                      {/* STUDENT FEES BILL */}
                      <div
                        ref={mainFeeRef}
                        style={{
                          padding: "25px 55px 50px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        className="student-fee-body"
                      >
                        <div
                          className="text-white h-[2px] border border-primary"
                          
                        ></div>
                        {/* WATERMARK */}
                        <img
                          src={
                            schoolInfo.school_logo
                              ? `${img_base_url}${schoolInfo.school_logo}`
                              : Logo
                          }
                          alt="logo"
                          style={{
                            opacity: 0.1,
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            height: "100%",
                            transform: "translate(-50%,-50%)",
                          }}
                        />
                        <div
                          className=" flex flex-col items-center justify-center"
                        >
                          <div className="logo">
                            {schoolInfo.school_logo ? (
                              <img
                                style={{ height: "100px", borderRadius: "50%" }}
                                src={`${img_base_url}${schoolInfo.school_logo}`}
                                alt="school_logo"
                              />
                            ) : (
                              <img
                                style={{ height: "50px", marginBottom: "30px" }}
                                src={Logo}
                                alt="school_logo"
                              />
                            )}
                          </div>
                          <div className="school-details text-center">
                            <h3 className="text-3xl font-bold">{schoolInfo.school_name}</h3>
                            <p>
                              {schoolInfo.school_city}
                              {","}
                              {schoolInfo.school_district} -{" "}
                              {schoolInfo.school_region}
                            </p>
                            <p>{schoolInfo.school_phone_number}</p>
                            <p>{schoolInfo.school_email}</p>
                          </div>
                        </div>
                        <div
                          className="student-term-info"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="student-info">
                            <h3 className="font-[600]">Student Details</h3>
                            <p>
                              {" "}
                              {studentFees.student}
                            </p>
                            <p>
                              
                              {studentFees.student_school_id}
                            </p>
                            <p>
                              
                              {studentFees.student_class}
                            </p>
                          </div>
                        </div>
                        <div
                          className="bill"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <h3
                            className="font-[700] text-[25px]"
                          >
                            {studentFees.term}, {studentFees.academic_year}{" "}
                            
                          </h3>
                          <table
                            style={{
                              border: "1px solid #eee",
                              borderCollapse: "collapse",
                              width: "70%",
                            }}
                          >
                            <thead>
                              <tr className="bg-slate-100">
                                <th
                                  style={{
                                    color: "black",
                                    border: "1px solid #ddd",
                                    textAlign: "left",
                                    padding: "8px",
                                    width: "50%",
                                  }}
                                >
                                  Fee Item
                                </th>
                                <th
                                  style={{
                                    color: "black",
                                    border: "1px solid #ddd",
                                    textAlign: "right",
                                    padding: "8px",
                                  }}
                                >
                                  Amount (GHS)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentFees.fees.map((fee, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {fee.components.map((component, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid #ddd",
                                                textAlign: "left",
                                                padding: "8px",
                                              }}
                                            >
                                              {component.fee_type}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid #ddd",
                                                textAlign: "right",
                                                padding: "8px",
                                              }}
                                            >
                                              {component.amount}
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    })}
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    textAlign: "left",
                                    padding: "8px",
                                    
                                  }}
                                >
                                  Total
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    textAlign: "right",
                                    padding: "8px",
                                    
                                  }}
                                >
                                  {studentFees.total_fees_amount}
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>

                        
                        <div
                          style={{
                            color: "white",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            background: "#844620",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "right",
                              width: "100%",
                              paddingRight: "10px",
                            }}
                          >
                            Date Printed: {today}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <NoData />
              )}
            </div>

            {/* EXTRA FEES */}
            <div className={toggle === 2 ? "show-content" : "content"}>
              {studentExtraFees.fees ? (
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <button
                        className="border border-secondary text-secondary px-4 py-2 rounded"
                      >
                        {" "}
                        Print / Download
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  <div
                    ref={componentRef}
                    style={{
                      padding: "25px 55px 50px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    className="student-fee-body"
                  >
                    <div
                      style={{
                        color: "white",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        background: "#844620",
                      }}
                    ></div>
                    {/* WATERMARK */}
                    <img
                      src={
                        schoolInfo.school_logo ? schoolInfo.school_logo : Logo
                      }
                      alt="logo"
                      style={{
                        opacity: 0.1,
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        height: "100%",
                        transform: "translate(-50%,-50%)",
                      }}
                    />
                    <div
                      className="school-info"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="logo" style={{ flex: "2" }}>
                        {schoolInfo.school_logo ? (
                          <img
                            style={{ height: "100px", borderRadius: "50%" }}
                            src={schoolInfo.school_logo}
                            alt="school_logo"
                          />
                        ) : (
                          <img
                            style={{ height: "50px", marginBottom: "30px" }}
                            src={Logo}
                            alt="school_logo"
                          />
                        )}
                      </div>
                      <div style={{ flex: "1" }} className="school-details">
                        <h3>{schoolInfo.school_name}</h3>
                        <p>
                          {schoolInfo.school_city}, {schoolInfo.school_district}{" "}
                          - {schoolInfo.school_region}
                        </p>
                        <p>Phone: {schoolInfo.school_phone_number}</p>
                        <p>Email: {schoolInfo.school_email}</p>
                      </div>
                    </div>
                    <div
                      className="student-term-info"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="student-info">
                        <p>
                          {" "}
                          {studentFees.student}
                        </p>
                        <p>
                           {studentFees.student_school_id}
                        </p>
                        <p>
                          
                          {studentFees.student_class}
                        </p>
                      </div>
                    </div>
                    <div
                      className="bill"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <h3
                        style={{
                          textDecoration: "underline",
                          fontWeight: "600",
                          marginTop: "30px",
                        }}
                      >
                        Student Extra Fees For {studentExtraFees.academic_year}{" "}
                        {studentExtraFees.term}
                      </h3>
                      <table
                        style={{
                          border: "1px solid #eee",
                          borderCollapse: "collapse",
                          width: "70%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#176B87",
                                color: "white",
                                border: "1px solid #ddd",
                                textAlign: "left",
                                padding: "8px",
                                width: "50%",
                              }}
                            >
                              Fee Type
                            </th>
                            <th
                              style={{
                                backgroundColor: "#176B87",
                                color: "white",
                                border: "1px solid #ddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentExtraFees.fees.map((fee, index) => {
                            return (
                              <React.Fragment key={index}>
                                {fee.components.map((component, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr key={index}>
                                        <td
                                          style={{
                                            border: "1px solid #ddd",
                                            textAlign: "left",
                                            padding: "8px",
                                          }}
                                        >
                                          {component.fee_type}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid #ddd",
                                            textAlign: "left",
                                            padding: "8px",
                                          }}
                                        >
                                          {component.amount}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Total
                            </th>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {studentExtraFees.total_fees_amount}
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <p
                      className="info-text"
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        color: "#8a8a8a",
                        marginTop: "50px",
                      }}
                    >
                      This bill was generated using Meskuu School Management
                      System
                    </p>
                    <div
                      style={{
                        color: "white",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        background: "#844620",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "right",
                          width: "100%",
                          paddingRight: "10px",
                        }}
                      >
                        Date Printed: {today}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: "center", padding: "50px" }}>
                  NO EXTRA FEES FOR THIS STUDENT
                </div>
              )}
            </div>

            {/* TRANSACTION HISTORY */}
            <div
              className={
                toggle === 3 ? "show-content transaction-history" : "content"
              }
            >
              <div className="transactions-list">
                {transactionHistory.map((transaction, index) => {
                  return (
                    <Transaction
                      key={transaction.id}
                      transaction={transaction}
                      schoolInfo={schoolInfo}
                    />
                  );
                })}
              </div>
            </div>

            {/* COLLECT FEES */}
            <div className={toggle === 4 ? "show-content" : "content"}>
              <div className="add-position-form">
                <form onSubmit={(e) => handleMakePayment(e)}>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div className="formInput">
                      <label htmlFor="">Date*</label>
                      <input
                        type="date"
                        required
                        name="date_paid"
                        onChange={(e) => handleChange(e)}
                        id="date_paid"
                      />
                    </div>

                    <div className="formInput">
                      <label htmlFor="">Fee Type*</label>
                      <select
                        required
                        onChange={(e) => changeFeeType(e)}
                        name="fee_type"
                        id=""
                      >
                        <option value="">Select Fee Type</option>
                        {feeTypes.map((feeType) => {
                          return (
                            <option value={feeType.id} key={feeType.id}>
                              {" "}
                              {feeType.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {/* BILL TYPE */}
                  <div style={{ display: "flex", gap: "20px" }}>
                    

                    <div className="formInput">
                      <label htmlFor="">Amount Paid*</label>
                      <input
                        type="number"
                        required
                        min="0"
                        step=".01"
                        max={
                          selectedBillType === "full_payment"
                            ? fullPaymentAmount.toString()
                            : maxAmount.toString()
                        }
                        name="amount_paid"
                        onChange={(e) => handleChange(e)}
                        id="amount_paid"
                        disabled={selectedBillType === "full_payment"}
                        value={
                          selectedBillType === "full_payment"
                            ? fullPaymentAmount
                            : data.amount_paid
                        }
                      />
                    </div>
                    <div className="formInput">
                      <label htmlFor="">Payment Method*</label>
                      <select
                        required
                        onChange={(e) => handleChange(e)}
                        name="mode_of_transfer"
                        id=""
                      >
                        <option value="">Select Transaction Mode</option>
                        <option value="Cash">Cash</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="Mobile Money">Mobile Money</option>
                      </select>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "20px" }}>
                    
                  </div>

                  {makingPayment ? (
                    <button
                      style={{
                        backgroundColor: "gray",
                        cursor: "wait",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        color: "white",
                        padding: "10px",
                        border: "none",
                        marginTop: "20px",
                      }}
                    >
                      <ColorRing
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />{" "}
                      Making Payment...{" "}
                    </button>
                  ) : (
                    <button className="submitButton" type="submit">
                      Accept Payment
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentFees;
