import React from 'react'
import {Pie} from "react-chartjs-2"
import {Chart as ChartJS} from "chart.js/auto"

const PieC = ({chartData}) => {
  return (
    <Pie data={chartData} width={200} height={200} />
  )
}

export default PieC