import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../url";
import { ColorRing } from "react-loader-spinner";

const ExpenseDetailsModal = ({ modal, setModal, expense, expenseTypes }) => {    
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const changeUpdateData = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

//   UPDATE EXPENSE
  const updateExpense = async (e) => {
    e.preventDefault()
    if (!updating) {
        if (localStorage.getItem("access")) {
          setUpdating(true);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };
  
          const body = JSON.stringify(updateData);
  
          try {
            const res = await axios.put(
              `${API_URL}finance/school-expense/${expense.id}/`,
              body,
              config
            );
            setUpdating(false);
            if (res.status === 200) {
              toast.success("Expense Updated Successfully")
              setModal(!modal)
            }
          } catch (error) {
            setUpdating(false);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/";
              } else if(error.response.status === 404) {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        } else {
          window.location.href = "/";
        }
      }
  }

  return (
    <div className="modal">
      <div className="overlay" onClick={() => setModal(!modal)}></div>
      <div className="modal-content" style={{ top: "60%" }}>
        <div onClick={() => setModal(!modal)} className="modal-close-btn">
          x
        </div>
        <div className="modal-header" style={{ fontWeight: "bold" }}>
          {" "}
          Expense Details
        </div>
        <form onSubmit={e=>updateExpense(e)}>
          <div className="formSection">
            <div className="formRow">
              <div className="formInput">
                <label htmlFor="">Date(mm/dd/yyyy)</label>
                <input
                  type="date"
                  name="date"
                  value={updateData.date ? updateData.date : expense.date}
                  onChange={(e) => changeUpdateData(e)}
                />
              </div>
              <div className="formInput">
                <label htmlFor="">Expense Type</label>
                <select name="expense_type" id="expense_type" onChange={(e) => changeUpdateData(e)}>
                    {
                        expenseTypes.map((expenseType) => (
                            <option value={expenseType.id} selected={expense.expense_type == expenseType.name} key={expenseType.id}>{expenseType.name}</option>
                        ))
                    }
                </select>
              </div>
              <div className="formInput">
                <label htmlFor="">Amount</label>
                <input type="number"
                value={updateData.amount ? updateData.amount : expense.amount}
                name="amount"
                min="0"
                step=".01"
                onChange={(e) => changeUpdateData(e)}
                />
              </div>
              <div className="formInput">
              <label htmlFor="">Expense Description</label>
              <textarea
                name="expense_description"
                rows="4"
                value={updateData.expense_description ? updateData.expense_description : expense.expense_description}
                onChange={(e) => changeUpdateData(e)}
              >
                
              </textarea>
              
            </div>
            </div>
            {updating ? (
              <button style={{ cursor: "wait", backgroundColor: "gray" }}>
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
                Updating...
              </button>
            ) : (
              <button type="submit">Update</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExpenseDetailsModal;
