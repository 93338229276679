import { AdminRouter, AppRouter } from "../routers/AppRouter"

export const APPS = [
    // {
    //     subdomain: "www",
    //     app: AppRouter,
    //     // app: AdminRouter,
    //     main: true
    // },
    {
        subdomain: "www",
        app: AdminRouter,
        main: true
    },
    // {
    //     subdomain: "super-admin",
    //     app: SuperAdminRouter,
    //     main: false
    // },
]