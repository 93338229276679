import React from "react";
import { useState } from "react";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useAuthenticate } from "../../context/AuthContext";
import { AiOutlineUpload } from "react-icons/ai";
import Header from "../../components/Header";

const AddMultipleStaff = () => {
  const { staffPositions } = useAuthenticate();
  const [loading, setLoading] = useState(false);
  const [staffImages, setStaffImages] = useState(null);
  const [staffList, setStaffList] = useState(null);
  const [staffPosition, setStaffPosition] = useState("");
  const [error, setError] = useState(false);

  // SUBMIT DATA
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submitting");
    if(!loading){
      if (staffPosition === "" || staffList === null){
        toast.error("Please provide the necessary information")
      }else{
        const formData = new FormData();
        formData.append("staff_position", staffPosition);
        formData.append("staff_list", staffList);
    
        for (let i = 0; i < staffImages.length; i++) {
          if (staffImages[i].size > 878576) {
            toast.error(`${staffImages[i].name} is larger than 878KB`);
            setError(true);
          }
          formData.append("staff_images", staffImages[i]);
        }
    
        if (localStorage.getItem("access") && !error) {
          setLoading(true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };
    
          try {
            await axios.post(
              `${API_URL}accounts/add-multiple-staffs/`,
              formData,
              config
            );
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Staffs added successfully",
            }).then(() => {
              window.location.reload();
            });
          
          } catch (error) {
            setLoading(false);
            if (error.response) {
              if(error.response.status === 401){
                window.location.href = "/"
              }else if(error.response.status === 500){
                return toast.error("Something went wrong. Please try again later")
              }
              if(error.response.data.rows_created > 0){
                toast.success(`Successfully created the first ${error.response.data.rows_created + 1} row(s)`)
              }
              for (const property in error.response.data.response_data) {
                if (property === "0") {
                  toast.error(`For row ${error.response.data.row + 1} ${error.response.data.response_data[property]}`);
                } else {
                  toast.error(
                    `For row ${error.response.data.row + 1}, ${property} error: ${error.response.data.response_data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
            
          }
        }
      }
    }
  };

  // COMPRESS FILE
  const compressFiles = (images) => {
    var imagesList = []
    for(let i = 0; i < images.length; i++) {
      let file_name = images[i].name;
      let reader = new FileReader();
      reader.readAsDataURL(images[i]);
      reader.onload = (event) => {
        let image_url = event.target.result;
        let image = document.createElement("img");
        image.src = image_url;
  
        image.onload = (e) => {
          // creating a new canvas for the image
          const canvas = document.createElement("canvas");
          let ratio = 300 / e.target.width;
          canvas.width = 300;
          canvas.height = ratio * e.target.height;
  
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
  
          // converting the image quality to 50% and the file type to webp
          let new_image_url = canvas.toDataURL("image/webp", 50);
  
          // getting the image type and the data
          let arr = new_image_url.split(",");
          let image_type = arr[0].match(/:(.*?);/)[1];
          let data = arr[1];
          let dataStr = atob(data);
          let n = dataStr.length;
          let dataArr = new Uint8Array(n);
  
          while (n--) {
            dataArr[n] = dataStr.charCodeAt(n);
          }
  
          const compressed_image = new File([dataArr], file_name, {
            type: image_type,
          });
          imagesList.push(compressed_image);
          setStaffImages(imagesList)
        };
      };
    }
  };

  return (
    <div className="main-container">
      <Header title="Bulk Upload Staff" />
      <div className="disclaimer py-[15px] px-[20px] rounded-[5px] mb-[20px] ">
        <h4>Instructions</h4>
        <p>
          You are to follow the instructions below to successfully add multiple
          staff
        </p>
        <ul>
          <li>
            Click the button below to download the staff bulk create excel file
          </li>
          <Link to="https://api.meskuu.com/media/Staff-Upload-Template.xlsx" className="bg-blue-600 text-white px-3 text-[13px] py-2 rounded">
            Download Excel File
          </Link>
          <li>
            Open the file on your computer
          </li>
          <li>
            Skip the first row and start filling from the next line
          </li>
          <li>
            Fill all sections provided in the specified format using the sample provided as a guide, then save
          </li>
          <li>The username field is required and should not contain spaces</li>
          <li>
            Staff type must be selected from the drop down which is either, teaching staff or non-teaching staff.
          </li>
          <li>
            Date of birth is a required field and must not be left empty
          </li>
          <li>
            Marital status must be selected from the drop down which is either Single or Married
          </li>
          <li>
            The phone number field should start with the
            country code. For example +233243645625 for a Ghanaian phone number
          </li>
          <li>
            All dates should be in the 
            mm/dd/yyyy format
          </li>
          <li>
            For the images of the school, you are to save the image of the
            student with a name that corresponds to the Profile Picture Name field in the
            excel file.
          </li>
          <li>
          Click on the 'Browse File' button to select the filled template saved on your computer
          </li>
          <li>
          Click on the 'Upload Data' button to upload
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div className="right">
          <form onSubmit={e=>handleSubmit(e)}>
            <div className="formSection">
              <div className="formRowHeader">
                <div className="formRow">
                  <div className="formInput">
                    <label htmlFor="">Staff Position</label>
                    <select
                      name="staff_position"
                      id=""
                      onChange={(e) => setStaffPosition(e.target.value)}
                      className="text-[15px]"
                    >
                      <option value="">Select Staff Position</option>
                      {staffPositions.map((staffPosition) => {
                        return (
                          <option
                            value={staffPosition.id}
                            key={staffPosition.id}
                          >
                            {staffPosition.position}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="formInput">
                    <label htmlFor="">Staff List</label>
                    <input
                      type="file"
                      name="staff_list"
                      id="staff_list"
                      required
                      accept=".xlsx"
                      onChange={(e) => setStaffList(e.target.files[0])}
                    />
                    <label id="upload-button" htmlFor="staff_list">
                      <AiOutlineUpload style={{ fontSize: "15px" }} />
                      Browse File
                    </label>
                    <p style={{fontWeight: "400", fontSize: "12px"}}>
                      {staffList ? staffList.name : "No file selected"}
                    </p>
                  </div>
                  <div className="formInput">
                    <label htmlFor="">Staff Images</label>
                    <input
                      type="file"
                      name="staff_images"
                      id="staff_images"
                      multiple
                      accept="image/*"
                      onChange={(e) => {compressFiles(e.target.files); }}
                    />
                    <label id="upload-button" htmlFor="staff_images">
                      <AiOutlineUpload style={{ fontSize: "15px" }} />
                      Browse Files
                    </label>
                    <p style={{fontWeight: "400", fontSize: "12px"}}>
                      {staffImages ? `${staffImages.length} files selected` : "No image selected"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <button style={{width:"max-content"}} className="submitButton">
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                  ]}
                />
                Adding Staffs...
              </button>
            ) : (
              <input
                type="submit"
                className="submitButton"
                value="Upload Data"
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMultipleStaff;
