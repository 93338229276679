import { createContext, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkAuthenticated, load_user } from "../actions/auth";
import axios from "axios";
import { API_URL } from "../url";
import { ProgressBar } from "react-loader-spinner";
import Logo from "../assets/logos/default-logo.png";
import Loading from "../components/LoadingPage/Loading";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticating, setAuthenticating] = useState(true);
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState()
  const [dashboardData, setDashboardData] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [academicTerms, setAcademicTerms] = useState([]);
  const [students, setStudents] = useState([]);
  const [schoolDetails, setSchoolDetails] = useState([]);
  const [schoolClasses, setSchoolClasses] = useState([])
  const [subjects, setSubjects] = useState([])
  const [staffPositions, setStaffPositions] = useState([])
  const [staffs, setStaffs] = useState([])
  const [teachers, setTeachers] = useState([])
  const [teacherSubjects, setTeacherSubjects] = useState([])
  const [teacherClasses, setTeacherClasses] = useState([])
  const [assessmentTypes, setAssessmentTypes] = useState([])

  useEffect(() => {
    let updateToken = async () => {
      let response = await fetch(`${API_URL}authentication/token/refresh/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {
            "refresh": localStorage.getItem('refresh')
          }
        )
      })
  
      let data = await response.json();
      if (response.status === 200) {
        localStorage.setItem('refresh',data.refresh);
        localStorage.setItem('access', data.access);
  
      }else{
        window.location.href = "/"
      }
  
    }

    const getUser = () => {
      // Check if there is access token available
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        // Get the user details
        axios
          .get(`${API_URL}accounts/users/me/`, config)
          .then((response) => {
            setUser(response.data);
            
            if(response.data?.account_type !== "Master"){
              fetchSchoolDetails()
              fetchSchoolPositions()
              fetchAcademicYears()
              fetchClasses()
            }
            setAuthenticating(false)
            
          })
          .catch(async (error) => {
            setUser(null)
            setAuthenticating(false);
          });
      } else {

        setAuthenticating(false);
      }
    };


    

   

    const fetchStudents = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        await axios.get(
          `${API_URL}administrator/students/`,
          config
        ).then(response => {
          setStudents(response.data)
        }).catch((error)=>{
          
        })
        
      }
    };

    const fetchSchoolDetails = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        await axios.get(
          `${API_URL}accounts/school-details/`,
          config
        ).then(response => {
          setSchoolDetails(response.data);
        }).catch((error) => {
          
        })

      }
    };

    const fetchClasses = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        await axios.get(
          `${API_URL}academics/classes/`,
          config
        ).then(response => {
          setSchoolClasses(response.data)
        }).catch(error => {
          
        })

    
      }
    };

    const getTeacherSubjects = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        await axios.get(
          `${API_URL}teacher/subjects`,
          config
        ).then(response => {
          setTeacherSubjects(response.data)
        }).catch(error => {
          
        })

    
      }
    };

    const getTeacherClasses = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        await axios.get(
          `${API_URL}teacher/assigned-classes`,
          config
        ).then(response => {
          setTeacherClasses(response.data)
          console.log(response.data)
        }).catch(error => {
          
        })

    
      }
    };

    const getSubjects = async () =>{
      if(!user?.is_staff){
        if (localStorage.getItem('access')){
            const config = {
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                }
            }
  
            await axios.get(`${API_URL}academics/subjects/`,config).then(response => {
              setSubjects(response.data)
            }).catch(error => {
              
            })
  
        }
      }
  }

  const getAssessmentTypes = async () =>{
    if(!user?.is_staff){
      if (localStorage.getItem('access')){
          const config = {
              headers:{
                  'Content-Type': 'application/json',
                  'Authorization': `JWT ${localStorage.getItem('access')}`,
              }
          }
  
          await axios.get(`${API_URL}academics/assessment-types/`,config).then(response => {
            setAssessmentTypes(response.data)
          }).catch(error => {
            
          })
  
      }
    }
}

  const fetchSchoolPositions = async () => {
    if(!user?.is_staff){
      if(localStorage.getItem('access')){
        const config={
          headers:{
            'Content-Type': 'application/json',
            'Authorization':`JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        };
        
          await axios.get(`${API_URL}administrator/staff-position/`,config).then(response => {
            setStaffPositions(response.data)
          }).catch(error => {
            
          })
            
        }
    }

  }

  const fetchStaffs = async () =>{
    if(!user?.is_staff){
      if(localStorage.getItem('access')){
        const config={
          headers:{
            'content-type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
          }
        };
        await axios.get(`${API_URL}administrator/staffs/`, config).then(response => {
          setStaffs(response.data);

        }).catch(error => {
          
        })
        
      }
    }
  }

  const fetchTeachers = async () => {
    if(!user?.is_staff){
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
  
        await axios.get(
          `${API_URL}administrator/teachers/`,
          config
        ).then(response =>{
          setTeachers(response.data)
        }).catch(error => {
          
        })
  
       
      }
    }
  };

  const fetchAcademicYears = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      await axios
        .get(`${API_URL}academics/academic-years/`, config)
        .then((response) => {
          setAcademicYears(response.data);
        })
        .catch((error) => {});
    }
  };

    // fetchTeachers()
    // fetchStaffs();
    // fetchSchoolPositions()
    // getSubjects()
    // fetchClasses();
    // fetchSchoolDetails();
    
    getUser();
    

    let interval = setInterval(()=>{
      if(localStorage.getItem('refresh')){
        updateToken()
      }
    }, 1500000)
    return () => clearInterval(interval)
  }, []);


    
  const contextData = {
    authenticating,
    user,
    dashboardData,
    academicTerms,
    academicYears,
    students,
    schoolInfo: schoolDetails,
    schoolClasses,
    subjects,
    staffPositions,
    staffs,
    teachers,
    errorMessage,
    teacherSubjects,
    teacherClasses,
    assessmentTypes
  };



  if (authenticating) {
    return (
      <Loading />
    );
  } else {
    return (
      <AuthContext.Provider value={contextData}>
        {children}
      </AuthContext.Provider>
    );
  }
};

export const useAuthenticate = () => useContext(AuthContext);
