import React, {useState} from "react";

const FormInput = (props) => {
    const [focused, setFocused] = useState(false);
    const handleFocused = (e) => {
        setFocused(true);
      }
  return (
    <div className="formInput">
      <label>{props.label}</label>
      <input
        type={props.type}
        id={props.id}
        onChange={props.onChange}
        name={props.name}
        required={props.required}
        pattern={props.pattern}
        onBlur={handleFocused}
        focused={focused.toString()}
        value={props.value}
        disabled={props.disabled}
      />
      <small>
        {props.infoMessage}
    </small>
      <small className="error-message">
        {props.errorMessage}
      </small>
    </div>
  );
};

export default FormInput;
