import React, {useRef} from "react";
import Logo from "../../assets/logos/default-logo.png";
import ReactToPrint from "react-to-print";

const Transaction = ({ schoolInfo, transaction }) => {
  const today = new Date().toISOString().slice(0, 10);
  const receiptRef = useRef();
  return (
    <>
      <div className="transaction" key={transaction.id}>
        <div className="title">
          <p>
            Academic Year: {transaction.academic_year} | {transaction.term} |{" "}
            {transaction.current_class}{" "}
          </p>
        </div>
        <div className="details">
          <div className="detail">
            <p>
              <span className="detail-type">Transaction ID: &nbsp;</span>
              {transaction.transaction_id}
            </p>
          </div>
          <div className="detail">
            <p>
              <span className="detail-type">Mode Of Payment: &nbsp;</span>
              {transaction.mode_of_transfer}
            </p>
          </div>
          <div className="detail">
            <p>
              <span className="detail-type">Bill Type: &nbsp;</span>
              {transaction.bill_type}
            </p>
          </div>
          <div className="detail">
            <p>
              <span className="detail-type">Amount Paid: &nbsp;</span>
              GHS {transaction.amount_paid}
            </p>
          </div>
          <ReactToPrint
            trigger={() => (
              <div
                className="classic-button"
              >
                {" "}
                Print Receipt
              </div>
            )}
            content={() => receiptRef.current}
          />
        </div>
      </div>
      <div className="main-receipt">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px 55px 50px",
            position: "relative",
            overflow: "hidden",
          }}
          className="receipt"
          ref={receiptRef}
        >
          <div
            style={{
              textAlign: "right",
              color: "white",
              backgroundColor: "#468B97",
              padding: "10px",
              marginBottom: "20px",
            }}
          ></div>
          <img
            src={schoolInfo.school_logo ? schoolInfo.school_logo : Logo}
            alt="logo"
            style={{
              opacity: 0.1,
              position: "absolute",
              left: "50%",
              top: "50%",
              height: "auto",
              transform: "translate(-50%,-50%)",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "30px",
              marginBottom: "20px",
            }}
            className="header"
          >
            <img
              src={schoolInfo.school_logo ? schoolInfo.school_logo : Logo}
              alt=""
              className="logo"
              style={{ height: "70px", borderRadius: "50%" }}
            />

            <div>Official Receipt</div>
          </div>
          <div className="school-details" style={{ marginBottom: "40px" }}>
            <h4 style={{ fontSize: "20px" }}>{schoolInfo.school_name}</h4>
            <p>
              {schoolInfo.school_city}, {schoolInfo.school_region}
            </p>
            {schoolInfo.school_phone_number && (
              <p>
                {" "}
                <b>Phone:</b> {schoolInfo.school_phone_number}
              </p>
            )}
            {schoolInfo.school_email && (
              <p>
                {" "}
                <b>Email:</b> {schoolInfo.school_email}{" "}
              </p>
            )}

            <p>Finance Department</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <h3 style={{ color: "#F24C3D" }}>Receipt For</h3>
            <div className="flex gap-6">
              <div>
                  <h5>Academic Year</h5>
                  <p>{transaction.academic_year}</p>
                </div>
                <div>
                  <h5>Academic Term</h5>
                  <p>{transaction.term}</p>
                </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "10px",
                borderBottom: "3px solid #1D5B79",
              }}
            >
              <div>
                <h5>Student ID</h5>
                <p>{transaction.student.student_school_id}</p>
              </div>
              <div>
                <h5>Student Name</h5>
                <p>
                  {transaction.student.get_full_name}
                  
                </p>
              </div>
              <div>
                <h5>Class</h5>
                <p>{transaction.current_class}</p>
              </div>
              <div>
                <h5>Transaction ID</h5>
                <p>{transaction.transaction_id}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "space-between",
                paddingBottom: "10px",
                borderBottom: "3px solid #1D5B79",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h5>Amount Paid</h5>
                  <p>GHS {transaction.amount_paid}</p>
                </div>
                <div>
                  <h5>Date Paid</h5>
                  <p>{transaction.date_paid}</p>
                </div>
                <div>
                  <h5>Payment Method</h5>
                  <p>{transaction.mode_of_transfer}</p>
                </div>
              </div>
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ flex: "2" }}>
                  <h5>Amount Paid (words)</h5>
                  <p>{transaction.amount_words}</p>
                </div>
                
                <div style={{ flex: "1" }}>
                  <h5>Printed Date</h5>
                  <p>{today}</p>
                </div>
                
              </div>
              
            </div>
            <div
              style={{
                textAlign: "right",
                color: "white",
                backgroundColor: "#468B97",
                padding: "10px",
              }}
            >
              Payment Received By: {transaction.received_by}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transaction;
