import React, { useState, useEffect } from "react";
import { useAuthenticate } from "../../context/AuthContext";
import { API_URL } from "../../url";
import axios from "axios";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";
import Header from "../../components/Header";

const GraduateStudents = () => {
  const { schoolClasses } = useAuthenticate();
  const [classStudents, setClassStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graduateBadges, setGraduateBadges] = useState([]);
  const [data, setData] = useState({
    current_class: "",
    graduating_badge: "",
    graduating_badge_name: "",
  });

  // HANDLE CHECK ALL CHECK BOXES
  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === "selectAll") {
      let students = [];
      for (let i = 0; i < classStudents.length; i++) {
        students.push(classStudents[i].student_id);
      }
      
      setSelectedStudents(students);
    } else {
      setSelectedStudents([]);
    }
  };

  const fetchClassStudents = async (e) => {
    e.preventDefault();
    if (data.current_class === "") {
      toast.error("Please select a class");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        try {
          const res = await axios.get(
            `${API_URL}academics/class-students/${data.current_class}/`,
            config
          );
          setLoading(false);
          setClassStudents(res.data);
          setDataFetched(true);
        } catch (error) {
          setLoading(false);
        }
      }
    }
  };

  const graduateStudentRequest = async () => {
    const body = JSON.stringify({
      graduating_badge: data.graduating_badge,
      graduating_badge_name: data.graduating_badge_name,
      selected_students: selectedStudents
    })
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        await axios
          .post(`${API_URL}academics/graduate-students/`, body, config)
          .then((response) => {
            
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Students Graduated",
                text: "Students are dully graduated",
              }).then(() => window.location.reload());
            }
          });
      } catch (error) {
        if(error.response){

          toast.error(error.response.data)
        }else{
          toast.error("An unidentified error occurred. Please try again.")
        }
      }
    }
  }

  const handleGraduateStudents = async (e) => {
    e.preventDefault();
    if (selectedStudents.length < 1) {
      toast.error("Please select at least one student to graduate");
    } else if(data.graduating_badge === ""){
        toast.error("Please select the graduating badge")
    } else if(data.graduating_badge === "Other" && data.graduating_badge_name === ""){
        toast.error("Please enter the new badge name")
    } else {
      Swal.fire({
        title: `Are you sure?`,
        text: `You are about to graduate ${selectedStudents.length} students. Once graduated, they are no longer your students but you will still have their data.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, proceed!",
      }).then((result) => {
        if (result.isConfirmed) {
          graduateStudentRequest();
        }
      });
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getGraduateBadges = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/graduate-badge/`,
          config
        );
        setGraduateBadges(res.data);
      } catch (error) {}
    }
  };

  useEffect(() => {
    getGraduateBadges();
  }, []);

  return (
    <div className="main-container">
      <Header title="Student Graduation" subtitle="Graduate Students" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => fetchClassStudents(e)}>
            <div className="formInput">
              <select
                name="current_class"
                id="current_class"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Current Class</option>
                {schoolClasses.map((schoolClass) => {
                  return (
                    <option value={schoolClass.id} key={schoolClass.id}>
                      {schoolClass.class_name}
                    </option>
                  );
                })}
              </select>
            </div>
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  color: "white",
                  padding: "15px",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Filtering...{" "}
              </button>
            ) : (
              <button type="submit">Filter</button>
            )}
          </form>
        </div>
        {dataFetched && (
          <>
            {classStudents.length > 0 ? (
              <div className="student-list">
                <div className="panel-header">
                  <h4>Student List</h4>
                </div>

                <div className="list-body">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {/* <input
                            type="checkbox"
                            name="selectAll"
                            id=""
                            // checked={
                            //   !studentFees.some(
                            //     (studentFee) => studentFee?.assigned !== true
                            //   )
                            // }
                            onChange={(e) => handleCheckboxChange(e)}
                          /> */}
                        </th>
                        <th>Student Name</th>
                        <th>Student ID</th>
                        <th>Class</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classStudents.map((classStudent, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: "80px" }}>
                              <input
                                type="checkbox"
                                value={classStudent.student_id}
                                name=""
                                id=""
                                // onChange={(e) => handleCheckboxChange(e)}
                                onChange={(e) =>
                                  e.target.checked
                                    ? setSelectedStudents([
                                        ...selectedStudents,
                                        e.target.value,
                                      ])
                                    : selectedStudents.splice(
                                        selectedStudents.indexOf(
                                          e.target.value
                                        ),
                                        1
                                      )
                                }

                                // checked={selectedStudents.includes(classStudent.student_id)}
                              />
                            </td>
                            <td>
                              {classStudent.get_full_name}
                            </td>
                            <td>{classStudent.student_school_id}</td>
                            <td>{classStudent.student_class}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <form action="" className="graduating-badge-form">
                    <select
                      name="graduating_badge"
                      id="graduating_badge"
                      required
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select Graduating Badge</option>
                      {
                        graduateBadges.map((badge) => (
                            <option key={badge.id} value={badge.id}>{badge.name}</option>
                        ))
                      }
                      <option value="Other">Other</option>
                    </select>
                    {data.graduating_badge === "Other" && (
                      <input
                        type="text"
                        name="graduating_badge_name"
                        onChange={(e) => handleChange(e)}
                        id=""
                        placeholder="Enter new graduating badge name here"
                      />
                    )}
                    <button
                      className="save-button"
                      onClick={(e) => handleGraduateStudents(e)}
                    >
                      Graduate Students
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div className="no-data-msg">
                THERE ARE NO STUDENTS IN THIS CLASS
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GraduateStudents;
