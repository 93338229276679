import React, { useState } from 'react'
import { ColorRing } from 'react-loader-spinner'
import { API_URL } from '../../url'
import axios from 'axios'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import FormInput from '../../components/Forms/FormInput'

const TeacherRemarksModal = ({
  modal,
  setModal,
  student,
  term,
  resultDetails,
  fetchStudentReport,
}) => {
  const [teacherRemarks, setTeacherRemarks] = useState(resultDetails?.class_teacher_remarks)
  const [attitude, setAttitude] = useState(resultDetails?.attitude)
  const [interest, setInterest] = useState(resultDetails?.interest)
  const [addingRemark, setAddingRemark] = useState(false)

  const approveReport = async (e) => {
    e.preventDefault();
   
      const body = JSON.stringify({
        attitude: attitude,
        interest: interest,
        class_teacher_remarks: teacherRemarks
      });
      const config = {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      if (localStorage.getItem("access")) {
        setAddingRemark(true);
        try {
          let response = await axios.put(
            `${API_URL}academics/exam-result-remarks/${term}/${student}/`,
            body,
            config
          );
          setAddingRemark(false);

          if (response?.data?.success){
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Remarks Added",
            }).then(() => {
              // window.location.reload()
              fetchStudentReport(e);
              setModal(!modal);
            });

          }else{
            toast.error(response?.data?.message)
          }
        } catch (error) {
          setAddingRemark(false);
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            toast.error("An error occurred, try again later");
          }
        }
      }
    
  };
  return (
    <div className="modal">
      <div className="overlay" onClick={() => setModal(!modal)}>
        <div className="modal-content" style={{ top: "20%" }} onClick={(e)=>e.stopPropagation()}>
          <div onClick={() => setModal(!modal)} className="modal-close-btn">
            x
          </div>
          <div className="modal-header">Add Student Remarks</div>

          <form onSubmit={(e) => approveReport(e)}>
            <div className="formSection">
              <div className="formRow">
                <FormInput
                  label="Student Interests"
                  type="text"
                  id="interests"
                  name="interests"
                  required={true}
                  value={interest}
                  errorMessage="This field is required"
                  onChange={(e) => {
                    setInterest(e.target.value);
                  }}
                />
              </div>
              <div className="formRow">
                <FormInput
                  label="Student Attitude"
                  type="text"
                  id="attitude"
                  name="attitude"
                  required={true}
                  value={attitude}
                  errorMessage="This field is required"
                  onChange={(e) => {
                    setAttitude(e.target.value);
                  }}
                />
              </div>
              <div className="formRow">
                <FormInput
                  label="Your Remarks"
                  type="text"
                  id="teacher_remarks"
                  name="teacher_remarks"
                  required={true}
                  value={teacherRemarks}
                  errorMessage="This field is required"
                  onChange={(e) => {
                    setTeacherRemarks(e.target.value);
                  }}
                />
              </div>

              <button type="submit" style={{ backgroundColor: "#47A992" }}>
                {addingRemark ? (
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                ) : (
                  "Add Remarks"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default TeacherRemarksModal