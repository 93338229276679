import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import { API_URL } from "../../url";
import axios from "axios";
import { toast } from "react-toastify";
import { MdOutlineCancel } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { MdQuestionMark } from "react-icons/md";

const StudentAttendance = () => {
  const { academicYears } = useAuthenticate();
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [months, setMonths] = useState([]);
  const [attendance, setAttendance] = useState(null);
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [notMarkedCount, setNotMarkedCount] = useState(0);

  const [gettingYears, setGettingYears] = useState(false);

  const getYearMonths = (academic_year) => {
    setGettingYears(true);
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    axios
      .get(`${API_URL}academics/academic-year-months/${academic_year}/`, config)
      .then((response) => {
        if (response.data) {
          setYears(response.data);
          setGettingYears(false);
        }
      })
      .catch((error) => {
        setGettingYears(false);
      });
  };

  const changeYear = (e) => {
    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setMonths(
        years.find((year) => year.name === parseInt(e.target.value)).months
      );
    }
  };

  const getAttendanceData = (e) => {
    e.preventDefault();
    setLoading(true);

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    axios
      .get(`${API_URL}student/attendance-report/${month}/${year}`, config)
      .then((response) => {
        if (response.data) {
          console.log("attendance", response.data);
          const present_count =
            response.data?.attendance_data?.filter(
              (item) => item.status === "Present"
            ).length || 0;

          const absent_count =
            response.data?.attendance_data?.filter(
              (item) => item.status === "Absent"
            ).length || 0;

          const not_marked_count =
            response.data?.attendance_data?.filter(
              (item) => item.status === null
            ).length || 0;
          setNotMarkedCount(not_marked_count);
          setPresentCount(present_count);
          setAbsentCount(absent_count);

          setAttendance(response.data);

          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error fetching attendance", error);

        toast.error(
          "Failed to fetch your attendace records. Please try again later or select a different month and year to filter"
        );

        setLoading(false);
      });
  };
  return (
    <div className="main-container">
      <div className="header">
        <h1>STUDENT ATTENDANCE</h1>
      </div>
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => getAttendanceData(e)}>
            <div className="formInput">
              <label htmlFor="">Academic Year*</label>
              <select
                onChange={(e) => getYearMonths(e.target.value)}
                name="related_class"
                id=""
                required
              >
                <option>Select Academic Year*</option>

                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="formInput">
              <label htmlFor="">Year*</label>
              <select
                onChange={(e) => {
                  setYear(e.target.value);
                  changeYear(e);
                }}
                name="related_class"
                id=""
                required
              >
                {gettingYears ? (
                  <option>Getting Years</option>
                ) : (
                  <>
                    <option value="">Select Year</option>
                    {years.map((year, index) => (
                      <option key={index} value={year.name}>
                        {year.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>

            <div className="formInput">
              <label htmlFor="">Month*</label>
              <select
                onChange={(e) => setMonth(e.target.value)}
                name="related_class"
                id=""
                required
              >
                <option>Select Month*</option>

                {months.map((month) => (
                  <option key={month.id} value={month.id}>
                    {month.month_name}
                  </option>
                ))}
              </select>
            </div>

            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data{" "}
              </button>
            ) : (
              <button type="submit">Get Class Attendance</button>
            )}
          </form>
        </div>
        {attendance && (
          <div className="schedule" style={{ overflow: "auto" }}>
            <table className="attendance-report-table">
              <thead>
                <tr>
                  <th
                    colSpan={3}
                    className=""
                    style={{ textAlign: "center", width: "600px" }}
                  >
                    <div className="">
                      <span>Summary</span>
                    </div>
                  </th>
                  <th colSpan={31} className="" style={{ textAlign: "center" }}>
                    <span>Dates</span>
                  </th>
                </tr>
                <tr>
                  <th>
                    <span>
                      <div className="" style={{ color: "#0066F5" }}>
                        <span className="indicator">
                          <FaCheck style={{ color: "green" }} />
                        </span>
                      </div>
                    </span>
                  </th>
                  <th>
                    <span>
                      <div className="" style={{ color: "#0066F5" }}>
                        <span className="indicator">
                          <MdOutlineCancel style={{ color: "red" }} />
                        </span>
                      </div>
                    </span>
                  </th>
                  <th>
                    <span>
                      <div className="">
                        <span className="indicator">
                          <MdQuestionMark />
                        </span>
                      </div>
                    </span>
                  </th>

                  {attendance?.dates?.map((date, index) => (
                    <th key={index}>
                      <small>{date}</small>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }} className="">
                    {presentCount}
                  </td>
                  <td style={{ textAlign: "center" }} className="">
                    {absentCount}
                  </td>
                  <td style={{ textAlign: "center" }} className="">
                    {notMarkedCount}
                  </td>
                  {attendance?.attendance_data?.map((attendance, number) => (
                    <td
                      key={number}
                      style={{ textAlign: "center", padding: "10px" }}
                      className=""
                    >
                      {attendance.status === "Present" ? (
                        <FaCheck style={{ color: "green" }} />
                      ) : (
                        <>
                          {attendance.status === "Absent" ? (
                            <MdOutlineCancel style={{ color: "red" }} />
                          ) : (
                            <>
                              <MdQuestionMark />
                            </>
                          )}
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentAttendance;
