import React, { useState } from "react";
import { PaystackButton } from "react-paystack";

const MakeSubscriptionPayment = () => {
  const publicKey = "pk_test_b280bd842436239d7059d65481bd50c960db4302";
  const amount = 1000000;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    currency: "GHS",
    publicKey,
    text: "Pay Now",
    onSuccess: () =>
      alert("Thanks for doing business with us! Come back soon!!"),
    onClose: () => alert("Wait! Don't leave :("),
  };

  const packages = [
    {
      id: 1,
      name: "Basic Package",
      price: 500,
      period:"monthly",
      features:[
        "Academic Management",
        "Bill payment management",
        "Main school settings",
        "Student Information Management",
        "Bill settings",
        "200 free sms",
        "Examination & Reports",
        "Attendance Management",
        "Communications"
      ]
    }
  ]

  return (
    <div>
      <div className="text-center my-10">
        <h6 className="uppercase font-[600] text-green-700">Pricing</h6>
        <h3 className="text-[25px] font-[700]">Affordable Pricing suitable for your needs</h3>
        <p>Select a plan that serves the needs of your school and lets go.</p>
      </div>
      <div>
        {
          packages?.map((package_item, index)=>(
            <div key={index}>
              <div className="p-[25px] border border-gray-300">
                <p className="">
                  {
                    package_item?.name
                  }
                </p>
                <h4>
                  GHS {package_item?.price} <small>{package_item?.period}</small>
                </h4>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default MakeSubscriptionPayment;
