
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../components/LoadingPage/Loading";
import { useAuthenticate } from "../../context/AuthContext";
import AcademicTermModal from "./AcademicTermModal";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import NewAcademicTermModal from "./NewAcademicTermModal";

const AcademicYearDetails = () => {
  const { id } = useParams();
  const [academicYear, setAcademicYear] = useState(null);
  const [gettingAcademicYear, setGettingAcademicYear] = useState(false)
  const [newAcademicTerm, setNewAcademicTerm] = useState(false)
  const [selectedTerm, setSelectedTerm] = useState("");
  const [modal, setModal] = useState(false);
  const [updating, setUpdating] = useState(false);

  // UPDATE TERM MODAL
  const updateTermModal = (term) => {
    setSelectedTerm(term);
    setModal(!modal);
  };

  // SET CURRENT TERM
  const setCurrentTerm = async (term) => {
    if (!updating) {
      if (localStorage.getItem("access")) {
        setUpdating(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        const body = JSON.stringify({
          is_current_term: true,
        });
        try {
          await axios
            .put(`${API_URL}academics/academic-term/${term}/`, body, config)
            .then((response) => {
              setUpdating(false);
              window.location.reload();
            });
        } catch (error) {
          setUpdating(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else if(error.response.status ===400){
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  // FETCH ACADEMIC YEAR DETAILS
  

  const fetchAcademicYear = async () => {
    if (localStorage.getItem("access")) {
      setGettingAcademicYear(true)
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      await axios
        .get(`${API_URL}academics/academic-year/${id}/`, config)
        .then((response) => {
          if(response?.data?.success){
            setAcademicYear(response.data?.payload);
          }else{
            toast.error(response?.data?.message)
          }
          setGettingAcademicYear(false)
        })
        .catch((error) => {
          setGettingAcademicYear(false)
        });
    }
  };

  useEffect(() => {
    fetchAcademicYear();
  }, []);

  return (
    <>
      
      <div className="main-container">
        {
          newAcademicTerm && <NewAcademicTermModal setAcademicTermModal = {setNewAcademicTerm} academic_year = {academicYear} />
        }
        
        {
          gettingAcademicYear ? <Loading /> : (
            <div>
              <div className="header">
                <h1>{academicYear?.name} Academic Year</h1>
              </div>
              <div className="academic-year-details">
                <p>
                    <b>Start Date:</b> {academicYear?.start_date}
                </p>
                <p>
                    <b>End Date:</b> {academicYear?.end_date}
                </p>
              </div>
              <div className="terms">
                {modal && (
                  <AcademicTermModal
                    modal={modal}
                    setModal={setModal}
                    term={selectedTerm}
                  />
                )}
                <p className="font-[600] ">Academic Terms</p>
                <button onClick={()=>setNewAcademicTerm(true)} className="px-3 py-2 bg-blue-600 rounded text-white " >
                  Add Academic Term
                </button>
                <div className="terms-list">
                  <table>
                    <thead>
                      <tr className="bg-blue-100">
                        <th>Term</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {academicYear?.terms?.map((term) => {
                        return (
                          <tr key={term?.id}>
                            <td>
                              <b>{term?.name}</b>
                            </td>
                            <td>{term?.start_date}</td>
                            <td>{term?.end_date}</td>
                            <td>
                              {term?.is_current_term ? (
                                <div
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  <div className="indicator"></div>

                                  <div className="label">Current Term</div>
                                </div>
                              ) : (
                                <>
                                  {updating ? (
                                    <div
                                      className="set-current-term-btn"
                                      style={{
                                        backgroundColor: "#EEEEEE",
                                        color: "black",
                                        cursor: "wait",
                                      }}
                                    >
                                      Updating...
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => setCurrentTerm(term.id)}
                                      className="set-current-term-btn"
                                      style={{ backgroundColor: "#47A992" }}
                                    >
                                      Set As Current Term
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                            <td>
                              <div
                                onClick={() => updateTermModal(term)}
                                className="update-button"
                              >
                                Update
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {
                    academicYear?.terms?.length === 0 && (
                      <div className="py-5 flex flex-col gap-3 items-center justify-center">
                        <p>
                          No Academic Terms For this academic year
                        </p>
                        <button onClick={()=>setNewAcademicTerm(true)} className="px-3 py-2 bg-blue-600 rounded text-white " >
                          Add Academic Term
                        </button>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
      </div>
     
    </>
  );
};

export default AcademicYearDetails;
