import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../url";
import { ColorRing } from "react-loader-spinner";

const AcademicTermModal = ({ modal, setModal, term }) => {
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({
    start_date: term.start_date,
    end_date: term.end_date,
    next_term_start_date:term.next_term_start_date,
    name: term.name,
  });

  const changeUpdateData = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const updateTerm = async (e) => {
    e.preventDefault();
    if (!updating) {
      if (updateData.start_date === "") {
        updateData.start_date = term.start_date;
      } else if (updateData.end_date === "") {
        updateData.end_date = term.end_date;
      }

      if (localStorage.getItem("access")) {
        setUpdating(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        const body = JSON.stringify(updateData);
        try {
          await axios
            .put(`${API_URL}academics/academic-term/${term.id}/`, body, config)
            .then((response) => {
              setUpdating(false);
              toast.success("Term Details updated successfully");
              window.location.reload();
            });
        } catch (error) {
          setUpdating(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else if (error.response.status === 400) {
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={() => setModal(!modal)}>
      <div className="modal-content" onClick={(e)=>e.stopPropagation(e)} style={{ top: "5%", }}>
        <div onClick={() => setModal(!modal)} className="modal-close-btn">
          x
        </div>
        <div className="modal-header" style={{ fontWeight: "bold" }}>
          {" "}
          {term.name} Details
        </div>
        <form onSubmit={(e) => updateTerm(e)}>
          <div className="formSection">
            <div className="formRow">
              <div className="formInput">
                <label htmlFor="">Term Name</label>
                <input
                  type="text"
                  name="name"
                  value={updateData.name === "" ? term.name : updateData.name}
                  onChange={(e) => changeUpdateData(e)}
                />
              </div>
            </div>
            <div className="formRow">
              <div className="formInput">
                <label htmlFor="">Start Date(mm/dd/yyyy)</label>
                <input
                  type="date"
                  name="start_date"
                  value={
                    updateData.start_date === ""
                      ? term.start_date
                      : updateData.start_date
                  }
                  onChange={(e) => changeUpdateData(e)}
                />
              </div>
              <div className="formInput">
                <label htmlFor="">End Date(mm/dd/yyyy)</label>
                <input
                  type="date"
                  name="end_date"
                  value={
                    updateData.end_date === ""
                      ? term.end_date
                      : updateData.end_date
                  }
                  onChange={(e) => changeUpdateData(e)}
                />
              </div>
            </div>
            
            <div className="formRow">
              <div className="formInput">
                <label htmlFor="">Next Term Begins On(mm/dd/yyyy):</label>
                <input
                  type="date"
                  name="next_term_start_date"
                  value={
                    updateData.next_term_start_date === ""
                      ? term.next_term_start_date
                      : updateData.next_term_start_date
                  }
                  onChange={(e) => changeUpdateData(e)}
                />
              </div>
            </div>
            {updating ? (
              <button style={{ cursor: "wait", backgroundColor: "gray" }}>
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
                Updating...
              </button>
            ) : (
              <button type="submit">Update</button>
            )}
          </div>
        </form>
      </div>
      </div>
      
    </div>
  );
};

export default AcademicTermModal;
