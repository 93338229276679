import React, { useEffect, useState } from "react";

import axios from "axios";
import { API_URL } from "../../url";
import { DataGrid } from "@mui/x-data-grid";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../context/AuthContext";
import NewAssessmentType from "./NewAssessmentType";
import Header from "../../components/Header";


const AssessmentTypes = () => {
  const {user} = useAuthenticate()
  const [assessmentTypes, setAssessmentTypes] = useState([]);
  const [assessmentTypeModal, setAssessmentTypeModal] = useState(false);
  const [data, setData] = useState({
    name:"",
    total_marks:""
  });
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false)

  const fetchAssessmentTypes = async () => {
    setLoadingData(true)
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/assessment-types/`,
          config
        );
        setAssessmentTypes(res.data);
        setLoadingData(false)
      } catch (error) {
        setLoadingData(false)
      }
    }
  };


  
  const deleteAssessmentType = (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        if(localStorage.getItem('access')){
          const config={
            headers:{
              'Content-Type': `application/json`,
              'Authorization':`JWT ${localStorage.getItem('access')}`,
              'Accept':'application/json'
            }
          }
          
    
          try{
            axios.delete(`${API_URL}academics/assessment-type/${id}`, config)
            Swal.fire(
              'Deleted!',
              'Assessment Type has been deleted.',
              'success'
            ).then(()=>{
              fetchAssessmentTypes()
            }
            )
            
          }catch(error){
            
            if (error.response){
              if(error.response.status === 401){
                window.location.href="/"
              }else{
                for (const property in error.response.data){
                  toast.error(`${property} error: ${error.response.data[property]}`)
                }
              }
            }else if(error.message){
              toast.error("Something went wrong. Please try again")
            }
          }

        }
        
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        const body = JSON.stringify(data)
        try {
          const res = await axios.post(
            `${API_URL}academics/assessment-types/`,
            body,
            config
          );
          
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Assessment Type Added Successfully",
          }).then(()=>{
            fetchAssessmentTypes()
          })
        } catch (error) {
          setLoading(false);
          if (error.response){
            if(error.response.status === 401){
              window.location.href="/"
            }else{
              for (const property in error.response.data){
                toast.error(`${error.response.data[property]}`)
              }
            }
          }else if(error.message){
            toast.error("Something went wrong. Please try again")
          }
        }
      }

  }



  useEffect(() => {
    

    

    fetchAssessmentTypes();
    
  }, []);
  return (
    
      <div className="main-container">
        <Header title="Assessment Types" subtitle="Manage the assessment types of the school" />
        {
          assessmentTypeModal && <NewAssessmentType setAssessmentTypeModal={setAssessmentTypeModal} />
        }
        {
          user?.account_type === "School Administrator" && (
          <div>
            <button className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-400 rounded" onClick={()=>setAssessmentTypeModal(true)}>
              Add Assessment Type
            </button>
          </div>
          )
        }

        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light text-surface border border-gray-300">
              <thead className=" w-screen  font-medium">
                <tr className="bg-gray-200 text-black">
                  <th scope="col" className="px-6 py-2 font-[500]">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Total Marks
                  </th>

                </tr>
              </thead>
              <tbody>
                {assessmentTypes?.map((assessment_type, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="px-6 py-2 font-[300]">
                      {assessment_type?.name}
                    </td>
                    <td className="px-6 py-2 font-[300]">
                      {assessment_type?.total_marks}
                    </td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
            {
              loadingData && (
                <div className="flex items-center justify-center py-5">
                  <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
                </div>
              )
            }
          </div>
        </div>
      </div>

        
      </div>
    
  );
};

export default AssessmentTypes;
