import React, { useEffect, useState } from "react";

import { ColorRing } from "react-loader-spinner";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { MdKeyboardArrowRight } from "react-icons/md";
import Header from "../../components/Header";

const Grading = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [schoolGrading, setSchoolGrading] = useState([]);
  const [gradingModal, setGradingModal] = useState(false)

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const fetchSchoolGrading = async () => {
    setLoadingData(true);
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/school-grading/`,
          config
        );
        setSchoolGrading(res.data);
        setLoadingData(false);
      } catch (error) {
        if(error.response.status === 401){
          window.location.href = "/"
        }
        setLoadingData(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setLoading(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);

      try {
        await axios.post(`${API_URL}academics/school-grading/`, body, config);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "School Grading Added Successfully",
        }).then(() => {
          fetchSchoolGrading();
        });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  const deleteExamGradings = (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          try {
            axios.delete(`${API_URL}academics/school-grading/${id}`, config);
            Swal.fire(
              "Deleted!",
              "School Grading has been deleted.",
              "success"
            ).then(() => {
              fetchSchoolGrading();
            });
          } catch (error) {
            if (error.response) {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    });
  };

  const columns = [
    { field: "grade_name", headerName: "Grade Name", width: 140 },
    { field: "min_value", headerName: "Minimum Mark", width: 150 },
    { field: "max_value", headerName: "Maximum Mark", width: 150 },
    { field: "remarks", headerName: "Remarks", width: 150 },

    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: ({id}) => {
        return (
          <div className="cellAction">
            <div onClick={()=>deleteExamGradings(id)} className="deleteButton">Delete</div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchSchoolGrading();
  }, []);
  return (
    
      <div className="main-container">
        <Header title="Grading System" subtitle={"Manage Academic Grading"} />

        

        <div className="class-body">
          <div className="left">
            <p>Add Grading</p>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="classFormGroup">
                <label htmlFor="">Grade Name</label>
                <input
                  type="text"
                  max_length="2"
                  name="grade_name"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="classFormGroup">
                <label htmlFor="">Minimum Mark</label>
                <input
                  type="number"
                  onChange={(e) => handleChange(e)}
                  min="0"
                  max="100"
                  step="0.01"
                  name="min_value"
                />
              </div>
              <div className="classFormGroup">
                <label htmlFor="">Maximum Mark</label>
                <input
                  type="number"
                  name="max_value"
                  min="0"
                  max="100"
                  step="0.01"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="classFormGroup">
                <label htmlFor="">Remarks</label>
                <input
                  type="text"
                  name="remarks"
                  
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {loading ? (
                <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border:'none'
                }}
                >
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />{" "}
                  Adding School Grade...{" "}
                </button>
              ) : (
                <input
                  className="submitButton"
                  value="Add Grading"
                  type="submit"
                />
              )}
            </form>
          </div>
          <div style={{height: 500}} className="right">
            <DataGrid
              rows={schoolGrading}
              loading={loadingData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 15, 20]}
            />
          </div>
        </div>
      </div>
    
  );
};

export default Grading;
