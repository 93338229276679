import React, { useEffect } from "react";
import { useState } from "react";
import FormInput from "../../components/Forms/FormInput";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../url";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import { IoCloseCircleOutline } from "react-icons/io5";

const NewAcademicYearModal = ({ modal, setModal, classDetails }) => {
    const [teachers, setTeachers] = useState([])
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({
    school_class:classDetails.id,
    teacher:""
  });

  const changeUpdateData = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const addClassTeacher = async (e) => {
    e.preventDefault();
    if (!updating) {
        
      if (localStorage.getItem("access")) {
        setUpdating(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        const body = JSON.stringify(updateData);
        try {
          await axios
            .post(`${API_URL}academics/class-teachers/`, body, config)
            .then((response) => {
              setUpdating(false);
              toast.success("Class Teacher Added Successfully");
              window.location.reload();
            });
        } catch (error) {
          setUpdating(false);
          
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else if (error.response.status === 400) {
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };


  const fetchTeachers = async () => {
    
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
  
        await axios.get(
          `${API_URL}administrator/teachers/`,
          config
        ).then(response =>{
          setTeachers(response.data)
        }).catch(error => {
          
        })
  
       
      }
    
  };

  useEffect(()=>{
    fetchTeachers();
  },[])

  return (
    <div
      id="modal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal"
    >
      <div class="bg-white rounded-lg shadow-lg lg:w-1/3 w-4/5 max-h-screen overflow-auto">
        <div class="px-6 py-4 border-b text-black items-center flex justify-between">
          <h3 class="text-lg  font-semibold">Add Class Teacher To {classDetails.class_name}</h3>
          <IoCloseCircleOutline
            onClick={() => setModal(false)}
            className="text-[20px] cursor-pointer"
          />
        </div>
        <div class="px-4">
          <div className="shadow mt-5 lg:mt-0 overflow-hidden sm:rounded-md bg-white">
            <form
              onSubmit={(e) => addClassTeacher(e)}
              className="px-4 py-2 bg-white sm:p-6"
            >
              <div className="grid grid-cols-6 gap-2">
                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Class
                  </label>

                  <input
                    type="text"
                    name="name"
                    disabled
                    value={classDetails.class_name} 
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  />
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Class Teacher
                  </label>
                  <select className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out" name="teacher" onChange={(e)=>changeUpdateData(e)} id="">
                    <option value="">Select Teacher</option>
                    {
                        teachers.map((teacher, index) => {
                            return (
                                <option key={index} value={teacher.staff_id}>{teacher?.user_account?.first_name} {teacher?.user_account?.last_name} </option>
                            )
                        })
                    }
                </select>
                  
                </div>

                
              </div>

              {updating ? (
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                <button
                  type="submit"
                  value="Save"
                  id="payouts-account-submit"
                  class="mt-2 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-600"
                >
                  Add Class Teacher
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAcademicYearModal;
