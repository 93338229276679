import axios from "axios";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const NewClassModal = ({ setNewClassModal }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);
      try {
        await axios.post(`${API_URL}academics/classes/`, body, config);

        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Class created successfully",
        }).then(() => {
          window.location.reload();
        });
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            for (const property in error.response.data) {
              if (property === "0") {
                toast.error(`${error.response.data[property]}`);
              } else {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
        setLoading(false);
      }
    }
  };

  return (
    <div
      id="modal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal"
    >
      <div class="bg-white rounded-lg shadow-lg lg:w-1/3 w-4/5 max-h-screen overflow-auto">
        <div class="px-6 py-4 border-b text-black items-center flex justify-between">
          <h3 class="text-lg  font-semibold">New Class</h3>
          <IoCloseCircleOutline
            onClick={() => setNewClassModal(false)}
            className="text-[20px] cursor-pointer"
          />
        </div>
        <div class="px-4">
          <div className="shadow mt-5 lg:mt-0 overflow-hidden sm:rounded-md bg-white">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="px-4 py-2 bg-white sm:p-6"
            >
              <div className="grid grid-cols-6 gap-2">
                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Class Name*
                  </label>

                  <input
                    type="text"
                    name="class_name"
                    onChange={(e) => onChange(e)}
                    id="className"
                    placeholder="E.g: Class 1"
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  />
                  <p className="text-[12px] text-gray-500 leading-[15px]">If the class is divided into subclasses, identify the subclass with the class name. E.g Class 1A</p>
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Class Level*
                  </label>
                  <select
                    name="class_level"
                    onChange={(e) => onChange(e)}
                    id=""
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  >
                    <option value="">Select Class Level</option>
                    <option value="Preschool">Preschool</option>
                    <option value="Grade School">Grade School</option>
                  </select>
                </div>
              </div>

              {loading ? (
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                <button
                  type="submit"
                  value="Save"
                  id="payouts-account-submit"
                  class="mt-2 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-600"
                >
                  Add Class
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewClassModal;
