import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.scss";

import { HiAcademicCap } from "react-icons/hi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdPeopleAlt } from "react-icons/md";

import StatsCard from "../../components/StatsCard/StatsCard";
import PieChart from "../../components/Charts/PieChart";

import { useAuthenticate } from "../../context/AuthContext";
import GreetingCard from "../../components/GreetingCard/GreetingCard";
import List from "../../components/List/List";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Barchart from "../../components/Charts/BarChart";
import { API_URL } from "../../url";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../components/LoadingPage/Loading";

const SchoolAdminDashboard = () => {
  const { user } = useAuthenticate();
  const [dashboardData, setDashboardData] = useState({});
  const [attendanceData, setAttendanceData] = useState({});
  const [gettingDashboardData, setGettingDashboardData] = useState(false);
  const [fetchingAttendanceData, setFetchingAttendanceData] = useState(true);
  

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };

  // FETCH ATTENDANCE DATA
  const fetchAttendanceData = async () => {
    if (localStorage.getItem("access")) {
      setFetchingAttendanceData(true);

      if (user?.account_type === "School Administrator") {
        try {
          const response = await axios.get(
            `${API_URL}academics/today-attendance/`,
            config
          );
          setAttendanceData({
            labels: response.data.map((data) => data.class_name),
            datasets: [
              {
                label: "Total Present",
                data: response.data.map((data) => data.students_present),
                backgroundColor: "#549F93",
                barThickness: 20,
              },
              {
                label: "Total Absent",
                data: response.data.map((data) => data?.students_absent),
                backgroundColor: "#E85F5C",
                barThickness: 20,
              },
            ],
          });
          setFetchingAttendanceData(false);
        } catch (error) {
          toast.error("An error occurred");
        }
      }
    }
  };

  const getDashboardData = async () => {
    setGettingDashboardData(true);
    await axios
      .get(`${API_URL}administrator/dashboard`, config)
      .then((response) => {
        setDashboardData(response.data);
        setGettingDashboardData(false);
      })
      .catch((error) => {
        setGettingDashboardData(false);
      });
  };

  useEffect(() => {
    fetchAttendanceData();
    getDashboardData();
  }, []);

  return (
    <div className="dashboard-container">
      {gettingDashboardData ? (
        <Loading />
      ) : (
        <div>
          <div className="body">
            <div className="">
              <GreetingCard user={dashboardData} />

              <div className="grid lg:grid-cols-3 my-3 gap-4">
                <StatsCard
                  icon={<HiAcademicCap className="text-[70px] text-secondary" />}
                  title="Total Students"
                  text={dashboardData?.total_students}
                />
                <StatsCard
                  icon={<MdPeopleAlt className="text-[70px] text-secondary" />}
                  title="Total Staff"
                  text={dashboardData?.total_staffs}
                />
                <StatsCard
                  icon={<FaChalkboardTeacher className="text-[70px] text-secondary" />}
                  title="Teachers"
                  text={dashboardData?.total_teachers}
                />
              </div>
              <div className="grid lg:grid-cols-3 gap-4 mt-5">
                <div className="h-[397px] pb-[25px] border border-gray-500 rounded">
                  <div className="title px-[17px] py-[10px] bg-primary text-white">
                    <h3>Student Population</h3>
                  </div>
                  
                  <PieChart data={dashboardData?.class_data} />
                </div>
                <div className="rounded border border-gray-500 ">
                  <div className="title px-[17px] py-[10px] bg-primary text-white">
                    <h3>Upcoming Events</h3>
                  </div>
                  <div className="events  pb-[25px]">
                    {dashboardData.events && dashboardData.events.length > 0 ? (
                      <>
                        {dashboardData.events.map((event) => {
                          return (
                            <div className="pb-[12px] px-[17px] border-b border-primary flex gap-[10px] items-center mt-5" key={event.id}>
                              <div className="">
                                <h4 className="text-[18px] font-[600] hover:text-[#f19c74] cursor-pointer">
                                  <Link to={`/events/${event.id}`}>
                                    {event.title}
                                  </Link>
                                </h4>
                                <p className="text-[12px] text-gray-500">
                                  {event?.date}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="h-full flex flex-col items-center justify-center gap-4">
                        <h2>No Event Added</h2>
                        {user.account_type === "School Administrator" && (
                          <button className="bg-[#F7B697] text-black hover:bg-[#f19c74] text-[12px] font-[600] px-3 py-2 rounded-[30px]">
                            <Link to="/events">Add Event</Link>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-[20px] mt-[20px] border border-#e6e6e6">
              <div className="flex justify-between items-center">
                <p className="title">Today's Attendance</p>
                <Link
                  to="/attendance/class-weekly-attendance"
                  className="border border-border-[#e6e6e6] rounded p-[10px] capitalize hover:bg-[#c2e3ff]"
                >
                  Go to Attendance
                </Link>
              </div>
              <div className="chart-data">
                {fetchingAttendanceData ? (
                  "Fetching Data..."
                ) : (
                  <Barchart chartData={attendanceData} />
                )}
              </div>
            </div>
          </div>
          
          {user?.is_employee && user?.position == "Teacher" && (
            <div className="schedule-body">
              <div className="student-list">
                <div className="panel-header">
                  <h4>Upcoming Classes</h4>
                </div>
                <div className="list-body">
                  <table>
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Class</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.upcoming_classes?.map(
                        (upcoming_class, index) => (
                          <tr>
                            <td>{upcoming_class?.subject}</td>
                            <td>{upcoming_class?.class}</td>
                            <td>{upcoming_class?.start_time}</td>
                            <td>{upcoming_class?.end_time}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {user?.is_student && (
            <div className="schedule-body">
              <div className="student-list">
                <div className="panel-header">
                  <h4>Today's Schedule</h4>
                </div>
                <div className="list-body">
                  <table>
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Teacher</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.today_schedule?.map(
                        (upcoming_class, index) => (
                          <tr>
                            <td>
                              {upcoming_class?.is_break ? (
                                "Break Time"
                              ) : (
                                <>
                                  {upcoming_class?.activity ? (
                                    <>{upcoming_class?.activity}</>
                                  ) : (
                                    <>{upcoming_class?.subject}</>
                                  )}
                                </>
                              )}
                            </td>
                            <td>{upcoming_class?.teacher}</td>
                            <td>{upcoming_class?.start_time}</td>
                            <td>{upcoming_class?.end_time}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SchoolAdminDashboard;
