import React, { useEffect, useState,useRef } from 'react'
import { API_URL } from '../../../url';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactToPrint from 'react-to-print';
import NoDataImage from "../../../assets/no_data.jpg"
import Loading from '../../../components/LoadingPage/Loading';

const StudentExamSchedule = () => {
    const examScheduleRef = useRef();
    const [gettingSchedule, setGettingSchedule] = useState(true);
    const [examSchedules, setExamSchedules] = useState([]);

    const getExamSchedule = async () => {
        if (localStorage.getItem("access")) {
            setGettingSchedule(true);
            const config = {
              headers: {
                "Content-Type": `application/json`,
                Authorization: `JWT ${localStorage.getItem("access")}`,
                Accept: "application/json",
              },
            };
    
            try {
              const res = await axios.get(
                `${API_URL}student/exam-schedule/`,
                config
              );
              setExamSchedules(res.data);
              setGettingSchedule(false);
            } catch (error) {
              setGettingSchedule(false);
              if (error.response) {
                if (error.response.status === 401) {
                  window.location.href = "/";
                } else{
                    toast.error("Something went wrong. Please try again");
                }
              } 
            }
          }
    }

    useEffect(() => {
        getExamSchedule();
    },[])

  return (
    <div className='main-container'>
        <div className="header">
            <h1>EXAM SCHEDULE</h1>
        </div>
        <div className="schedule-body">
            {/* EXAM SCHEDULE TO DISPLAY */}
            {
                gettingSchedule ? <Loading /> : (
                    <div className="schedule">
                        {examSchedules.length > 0 ? (
                        <>
                            <div className="buttons">
                            <ReactToPrint
                                trigger={() => (
                                <button
                                    style={{
                                    backgroundColor: "#304D7B",
                                    color: "white",
                                    cursor: "pointer",
                                    border: "none",
                                    padding: "10px 15px",
                                    marginTop: "30px",
                                    }}
                                >
                                    Print / Download
                                </button>
                                )}
                                content={() => examScheduleRef.current}
                            />
                            </div>
                            <table>
                            <thead
                                style={{ background: "#47A992", color: "white" }}
                            >
                                <tr>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Subject</th>
                                <th>Exam Hall</th>
                                </tr>
                            </thead>
                            <tbody>
                                {examSchedules.map((schedule) => {
                                return (
                                    <tr key={schedule.id}>
                                    <td>{schedule.date}</td>
                                    <td>
                                        {schedule.starting_time} -{" "}
                                        {schedule.ending_time}
                                    </td>
                                    <td>{schedule.subject}</td>
                                    <td>{schedule.exams_venue}</td>
                                    </tr>
                                );
                                })}
                            </tbody>
                            </table>
                        </>
                        ) : (
                        <div className="no-data">
                        <img src={NoDataImage} alt="no data" />
                        <p>
                            No Data Found
                        </p>
                        </div>
                        )}
                    </div>
                )
            }

            {/* EXAM SCHEDULE TO PRINT */}
            <div className="schedule hidden">
                {examSchedules.length > 0 && (
                <div ref={examScheduleRef} style={{width:"100% !important"}}>
                    <h1 style={{textAlign: "center", margin: "10px auto"}}>Examination Timetable for {examSchedules[0].school_class}</h1>
                    <table style={{borderCollapse: "collapse", border:"1px solid #d0d5d9"}}>
                    <thead
                        style={{ background: "#47A992", color: "white" }}
                    >
                        <tr>
                        <th style={{border: "1px solid #d0d5d9", fontWeight:"bold", padding: "13px 20px"}}>Date</th>
                        <th style={{border: "1px solid #d0d5d9", fontWeight:"bold", padding: "13px 20px"}}>Time</th>
                        <th style={{border: "1px solid #d0d5d9", fontWeight:"bold", padding: "13px 20px"}}>Subject</th>
                        <th style={{border: "1px solid #d0d5d9", fontWeight:"bold", padding: "13px 20px"}}>Exam Hall</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        {examSchedules.map((schedule) => {
                        return (
                            <tr key={schedule.id}>
                            <td style={{border: "1px solid #d0d5d9", padding: "13px 20px"}}>{schedule.date}</td>
                            <td style={{border: "1px solid #d0d5d9", padding: "13px 20px"}}>
                                {schedule.starting_time} -{" "}
                                {schedule.ending_time}
                            </td>
                            <td style={{border: "1px solid #d0d5d9", padding: "13px 20px"}}>{schedule.subject}</td>
                            <td style={{border: "1px solid #d0d5d9", padding: "13px 20px"}}>{schedule.exams_venue}</td>
                            </tr>
                        );
                        })}
                    </tbody>
                    </table>
                </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default StudentExamSchedule