import React, { useEffect, useState } from "react";

import "./Staffs.scss";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";
import Header from "../../components/Header";


const StaffPosition = () => {
  const [toggle, setToggle] = useState(1);
  const [loading, setLoading] = useState(false)
  const [loadingStaffPositions, setLoadingStaffPositions] = useState(false);
  const [data, setData] = useState({});

  const [staffPositions, setStaffPositions] = useState([]);

  const updateToggle = (tab) => {
    setToggle(tab);
  };

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const deleteStaffPosition =  (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        if(localStorage.getItem('access')){
          const config={
            headers:{
              'Content-Type': `application/json`,
              'Authorization':`JWT ${localStorage.getItem('access')}`,
            }
          }
          
    
          try{
            axios.delete(`${API_URL}administrator/staff-position/${id}`, config)
            Swal.fire(
              'Deleted!',
              'Staff Position has been deleted.',
              'success'
            ).then(()=>{
              window.location.reload()
            }
            )
            
          }catch(error){
            
            if (error.response){
              for (const property in error.response.data){
                toast.error(`${property} error: ${error.response.data[property]}`)
              }
            }else if(error.message){
              toast.error("Something went wrong. Please try again")
            }
          }

        }
        
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setLoading(true);
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);
      try {
        const res = await axios.post(
          `${API_URL}administrator/staff-position/`,
          body, config
        );
        if (res.status === 201){
          Swal.fire({
            icon:"success",
            title:"Success",
            text:"Staff Position Added Successfully"
          }).then(()=>{
            window.location.reload();
          })
        }
        setLoading(false);
      } catch (error) {
        setLoading(false)
        if (error.response) {
          if(error.response.code !== 500){
            for (const property in error.response.data) {
              if (property === "0") {
                toast.error(`${error.response.data[property]}`);
              } else {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  const columns = [
    { field: "position", headerName: "Position Name", width: 600 },
    // { field: "description", headerName: "Description", width: 430 },
    // { field: "permissions", headerName: "Permissions", width: 430 },
  
    // {
    //   field: "action",
    //   headerName: "Actions",
    //   width: 200,
    //   renderCell: ({id}) => {
    //     return (
    //       <div className="cellAction">
    //         <div className="viewButton">View</div>
    //         <div className="deleteButton" onClick={()=>deleteStaffPosition(`${id}`)}>Delete</div>
    //       </div>
    //     );
    //   },
    // },
  ];
  

  useEffect(() => {
    const fetchStaffPositions = async () => {
      setLoadingStaffPositions(true);
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        try {
          const res = await axios.get(`${API_URL}administrator/staff-position/`, config);
          setLoadingStaffPositions(false)
          setStaffPositions(res.data);
          
        } catch (error) {
          
        }
      }
    }
    fetchStaffPositions()
  },[])

  return (
    
      <div className="main-container">
        <Header title="Staff Positions" subtitle="Manage staff positions" />
        <div className="positions-container">
          <div className="tabs">
            <div
              className={toggle === 1 ? "active-tab" : "tab"}
              onClick={() => {
                updateToggle(1);
              }}
            >
              Staff Positions
            </div>
            <div
              className={toggle === 2 ? "active-tab" : "tab"}
              onClick={() => {
                updateToggle(2);
              }}
            >
              Add Staff Position
            </div>
          </div>
          <div  className={toggle === 1 ? "show-content" : "content"}>
            <div style={{ height: "max-content", width: '100%' }}>
              <DataGrid
                rows={staffPositions}
                columns={columns}
                loading={loadingStaffPositions}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20]}
              />
            </div>
          </div>
          <div className={toggle === 2 ? "show-content" : "content"}>
            <div className="add-position-form">
              <form onSubmit={e=>handleSubmit(e)}>
                <div className="formInput">
                  <label>Position Name</label>
                  <input
                    type="text"
                    name="position"
                    onChange={(e) => onChange(e)}
                  />
                </div>
                {/* <div className="formInput">
                  <label>Permissions</label>
                  <div className="formRow">
                    <div>
                      <input type="checkbox" name="" id="" /> Manage Inquiries
                    </div>
                    <div>
                      <input type="checkbox" name="" id="" /> Manage Admissions
                    </div>
                    <div>
                      <input type="checkbox" name="" id="" /> Manage Students
                    </div>
                    <div>
                      <input type="checkbox" name="" id="" /> Delete Students
                    </div>
                    
                  </div>
                  <div className="formRow">
                  <div>
                      <input type="checkbox" name="" id="" /> Add Staff
                    </div>
                    <div>
                      <input type="checkbox" name="" id="" /> Manage Subjects
                    </div>
                    <div>
                      <input type="checkbox" name="" id="" /> Manage Exams
                    </div>
                    <div>
                      <input type="checkbox" name="" id="" /> Manage Events
                    </div>
                  </div>
                </div> */}
                {loading ? 
                <button className="submitButton">
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                  ]}
                />
                Adding Staff Position...
              </button>:  
                <button className="submitButton" type="submit">Add Staff Position</button>
              }
              </form>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default StaffPosition;
