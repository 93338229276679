import React from 'react'
import NoDataImage from "../assets/no_data.jpg"

const NoData = () => {
  return (
    <div className="no-data">
        <img src={NoDataImage} alt="no data" />
        <p>
            No Data Found
        </p>
    </div>
  )
}

export default NoData