import React, { useEffect, useState } from 'react'
import { RotatingLines } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import axios from 'axios';
import { API_URL } from '../../url';

const Teachers = () => {
    const [teachers, setTeachers] = useState([]);
    const [gettingTeachers, setGettingTeachers] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');  // Track the search query
    const [totalPages, setTotalPages] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);


    const filterTeachersList = (searchValue) => {
        setSearch(searchValue);
        setCurrentPage(1);  // Reset to page 1 whenever search query changes
      };

      const fetchTeachers = async () => {
        if (localStorage.getItem("access")) {
          setGettingTeachers(true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };
          await axios
            .get(`${API_URL}student/teachers/`, config)
            .then((response) => {
              setTeachers(response.data);
              
              setGettingTeachers(false);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                window.location.href = "/";
              }
            });
        }
      };

      useEffect(()=>{
        fetchTeachers()
      },[])

  return (
    <div className="main-container">
      <Header title="Teachers" subtitle="My class teachers" />

      

      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            {!gettingTeachers && (
              <table className="min-w-full text-left text-sm font-light text-surface ">
                <thead className=" w-screen  font-medium">
                  <tr className="border-b border-t border-slate-200 text-black">
                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      
                      Staff ID
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Email Address
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Phone Number
                    </th>

                    
                  </tr>
                </thead>
                <tbody>
                  {teachers?.map((teacher, index) => (
                    <tr
                      key={index}
                      className="text-[12px] border-l-2 border-transparent py-3 hover:border-primary hover:bg-slate-50 "
                    >
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        
                        {teacher?.get_full_name}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                      {teacher?.staff_school_id}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {teacher?.user_account?.email}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {teacher?.user_account?.phone_number}
                      </td>

                      
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {gettingTeachers && (
              <div className="flex items-center justify-center py-5">
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
            {!gettingTeachers && teachers.length == 0 && (
              <p className="text-surface text-gray-400 py-10 text-center">
                No teacher found.
              </p>
            )}
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Teachers