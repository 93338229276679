import React from "react";
import { Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import Guest from "../utils/Guest";
import Login from "../pages/Login/Login";
import PrivateRoutes from "../utils/PrivateRoutes";
import Layout from "../components/Layout/Layout";
import Landing from "../Domains/Landing";
// import SuperAdmin from "../Domains/SuperAdmin";
import ResetPassword from "../pages/Settings/ResetPassword";
import VerifyOtp from "../pages/Settings/VerifyOtp";
import ChangePassword from "../pages/Settings/ChangePassword";
import { Provider } from "react-redux";
import store from "../store";
import { AuthProvider } from "../context/AuthContext";

// Landing Page
export const AppRouter = () => {
  return (
    <Landing />
  );
};


// Administrator Page
export const AdminRouter = () => {
  return (

    <>
    <Provider store={store}>
      <AuthProvider>
        <Routes>
          <Route exact path="" element={<Guest component={Login} />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route path="verify-otp/:id/" element={<VerifyOtp />} />
          <Route path="change-password/:id" element={<ChangePassword />} />
          <Route path="/*" element={<PrivateRoutes component={Layout} />} />
        </Routes>
        <ToastContainer
        position="top-right"
        autoClose={6608}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
      </AuthProvider>
    </Provider>
    </>
    
  );
};

// Super Administrator
// export const SuperAdminRouter = () => {
//   return (
//     <SuperAdmin />
//   );
// };
