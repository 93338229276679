import React from 'react'
import './GreetingCard.scss'
import WelcomeImage from "../../assets/welcomeImage.png";
import { useState } from 'react';
import { useEffect } from 'react';
import {BsCalendar3} from 'react-icons/bs'
import { useAuthenticate } from '../../context/AuthContext';


const GreetingCard = () => {
  const {schoolInfo,user} = useAuthenticate()
  const [greeting, setGreeting] = useState("")


  const today = new Date()
  const curHr = today.getHours()

  useEffect(()=>{
    if (curHr < 12) {
      setGreeting("Good Morning")
    } else if (curHr < 18) {
      setGreeting("Good Afternoon")
    } else {
      setGreeting("Good Evening")
    }
    
  }, [curHr])
  
  return (
    <div className="welcome-card header-background">

        <div className="welcome-image">
            <img src={WelcomeImage} alt="welcome" />
        </div>
        <div className="text">
            <h3>{greeting} {user?.first_name}</h3>
            {
              schoolInfo?.current_term && schoolInfo?.academic_year ? (
                <div style={{display: "flex", gap: "5px", marginTop:"10px"}}>
                  <p style={{fontWeight: "bold"}}>Current Term: </p>
                  <p>{schoolInfo?.current_term?.name}, {schoolInfo?.academic_year} </p>
                </div>
              ):(
                <p>Current Term Not Set</p>
              )
            }
            {
              schoolInfo?.current_term && schoolInfo?.academic_year && (
                <div className="term-progress">
                  <p className="title">Current Term Progress</p>
                  <div className="term-progress-bar">
                      <div className="filled" style={{width: `${schoolInfo?.current_term?.days_spent_percentage}%`}}></div>
                  </div>
                  <div className="term-definitions">
                    <p className="term-start">
                      Term Start: {schoolInfo?.current_term?.start_date}
                    </p>
                    <p className="term-end">
                      Term End: {schoolInfo?.current_term?.end_date}
                    </p>
                  </div>
                </div>
              )
            }
        </div>
    </div>
  )
}

export default GreetingCard