import React, { useState, useEffect } from "react";

import "./Mailbox.scss";
import { BsFillPencilFill } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import {
  AiOutlineDelete,
  AiOutlineMail,
  AiOutlineSend,
  AiOutlineStar,
  AiFillStar,
} from "react-icons/ai";
import { BiEnvelopeOpen } from "react-icons/bi";
import { MdRefresh } from "react-icons/md";
import { DataGrid } from "@mui/x-data-grid";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { useAuthenticate } from "../../context/AuthContext";
import Loading from "../../components/LoadingPage/Loading";

const Mailbox = () => {
  const {user} = useAuthenticate();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingInbox, setLoadingInbox] = useState(false);
  const [page, setPage] = useState("inbox");
  const [inbox, setInbox] = useState([]);
  const [sentBox, setSentBox] = useState([]);
  const [message, setMessage] = useState({});
  const [loadingMessage, setLoadingMessage] = useState(false);
  const { students, schoolClasses, staffs } = useAuthenticate();

  // SET MESSAGE DATA
  const [data, setData] = useState({
    receipient_type: "",
    message: "",
  });

  // UPDATE MESSAGE DATA
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // GET SENT MESSAGES
  const getSentMessages = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}communication/send-message/`,
          config
        );
        setSentBox(res.data);
      } catch (error) {}
    }
  };

  // GET RECEIVED MESSAGES
  const getReceivedMessages = async () => {
    if (localStorage.getItem("access")) {
      setLoadingInbox(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}communication/received-messages/`,
          config
        );
        setLoadingInbox(false);

        setInbox(res.data);
      } catch (error) {
        setLoadingInbox(false);
      }
    }
  };

  // REFRESH MESSAGES
  const handleRefresh = () => {
    setLoadingData(true);
    getSentMessages();
    getReceivedMessages();
    setLoadingData(false);
  };

  // DELETE MESSAGE
  const handleDeleteMail = async (id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        await axios
          .put(
            `${API_URL}communication/sent-message/${id}/`,
            { is_deleted: true },
            config
          )
          .then((response) => {
            handleRefresh();
          });
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // MARKING A MESSAGE AS READ
  const handleMarkAsRead = async (id, is_read) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        await axios
          .put(
            `${API_URL}communication/received-message/${id}/`,
            { is_read: !is_read },
            config
          )
          .then((response) => {
            handleRefresh();
          });
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // STARRING A MESSAGE
  const handleStarMail = async (id, is_starred) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        await axios
          .put(
            `${API_URL}communication/sent-message/${id}/`,
            { is_starred: !is_starred },
            config
          )
          .then((response) => {
            handleRefresh();
          });
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // SENDING A MESSAGE
  const handleSendMessage = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);
      try {
        await axios
          .post(`${API_URL}communication/send-message/`, body, config)
          .then((response) => {
            setLoading(false);
            handleRefresh();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Message Sent",
            }).then(() => {
              setPage("sent");
            });
          });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // GET RECEIVED MESSAGE
  const getReceivedMessage = async (id) => {
    if (localStorage.getItem("access")) {
      setLoadingMessage(true);
      setPage("message");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        await axios
          .get(`${API_URL}communication/received-message/${id}`, config)
          .then((response) => {
            setMessage(response.data);
            setLoadingMessage(false);
          });
      } catch (error) {
        setLoadingMessage(false);
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // GET SENT MESSAGE
  const getSentMessage = async (id) => {
    if (localStorage.getItem("access")) {
      setLoadingMessage(true);
      setPage("sent-message");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        await axios
          .get(`${API_URL}communication/sent-message/${id}`, config)
          .then((response) => {
            setMessage(response.data);
            setLoadingMessage(false);
          });
      } catch (error) {
        setLoadingMessage(false);
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // SENT MESSAGE DATA COLUMNS
  const SentColumns = [
    { field: "receipients", headerName: "Receipient", width: 200 },
    {
      field: "message",
      headerName: "Message",
      width: 330,
      renderCell: (params) => {
        return (
          <>
            {
              <div style={{ display: "flex" }}>
                {params.row.subject} &nbsp; - &nbsp;{" "}
                {parse(params.value.substring(0, 80))}
              </div>
            }
          </>
        );
      },
    },
    { field: "date", headerName: "Date", width: 200 },
    {
      fields: "is_starred",
      headerName: "",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <AiOutlineDelete
              onClick={() => handleDeleteMail(params.id)}
              className="mail-icon"
            />
            {params.row.is_starred.toString() === "true" ? (
              <AiFillStar
                className="mail-icon"
                onClick={() => handleStarMail(params.id, params.row.is_starred)}
              />
            ) : (
              <AiOutlineStar
                className="mail-icon"
                onClick={() => handleStarMail(params.id, params.row.is_starred)}
              />
            )}
          </>
        );
      },
    },
  ];

  // DELETE MESSAGE
  const handleDeleteReceivedMessage = async (id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        await axios
          .put(
            `${API_URL}communication/received-message/${id}/`,
            { is_deleted: true },
            config
          )
          .then((response) => {
            handleRefresh();
          });
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // STAR MESSAGE
  const handleStarReceivedMessage = async (id, is_starred) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      try {
        await axios
          .put(
            `${API_URL}communication/received-message/${id}/`,
            { is_starred: !is_starred },
            config
          )
          .then((response) => {
            handleRefresh();
          });
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  // INBOX MESSAGE COLUMNS
  const InboxColumns = [
    { field: "sender", headerName: "Sender", width: 200 },
    {
      field: "message",
      headerName: "Message",
      width: 330,
      renderCell: (params) => {
        return (
          <>
            {
              <div style={{ display: "flex" }}>
                {params.row.is_read ? (
                  <div style={{ display: "flex" }}>
                    {params.row.subject ? (
                      <>
                        {params.row.subject} - &nbsp;{" "}
                        {parse(params.value.substring(0, 80))}
                      </>
                    ) : (
                      <>
                        (No Subject) - &nbsp;{" "}
                        {parse(params.value.substring(0, 80))}
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    {params.row.subject ? (
                      <>
                        <strong> {params.row.subject} </strong> - &nbsp;{" "}
                        {parse(params.value.substring(0, 80))}
                      </>
                    ) : (
                      <>
                        <strong> (No Subject) </strong> - &nbsp;{" "}
                        {parse(params.value.substring(0, 80))}
                      </>
                    )}
                  </>
                )}
              </div>
            }
          </>
        );
      },
    },
    { field: "date", headerName: "Date", width: 200 },
    {
      fields: "is_starred",
      headerName: "",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <AiOutlineDelete
              onClick={() => handleDeleteReceivedMessage(params.id)}
              className="mail-icon"
            />
            {params.row.is_starred.toString() === "true" ? (
              <AiFillStar
                className="mail-icon"
                onClick={() =>
                  handleStarReceivedMessage(params.id, params.row.is_starred)
                }
              />
            ) : (
              <AiOutlineStar
                className="mail-icon"
                onClick={() =>
                  handleStarReceivedMessage(params.id, params.row.is_starred)
                }
              />
            )}
            {params.row.is_read.toString() === "true" ? (
              <AiOutlineMail
                className="mail-icon"
                title="Mark As Unread"
                onClick={() => handleMarkAsRead(params.id, params.row.is_read)}
              />
            ) : (
              <BiEnvelopeOpen
                className="mail-icon"
                title="Mark As Read"
                onClick={() => handleMarkAsRead(params.id, params.row.is_read)}
              />
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoadingData(true);
    getSentMessages();
    getReceivedMessages();
    setLoadingData(false);
  }, []);

  return (
    <div className="main-container">
      <div className="header">
        <h1>MAILBOX</h1>
      </div>
      <div className="mailbox-body">
        <div className="mail-left">
          <h2>Mailbox</h2>
          {
            !user?.is_student && (
              <div className="compose" onClick={() => setPage("compose")}>
                <BsFillPencilFill /> compose
              </div>
            )
          }
          <div className="mailsections">
            <div
              className={`section ${page === "inbox" && "mail-active"}`}
              onClick={() => setPage("inbox")}
            >
              <div className="text">
                <AiOutlineMail /> INBOX
              </div>
              <div className="number">{inbox.length}</div>
            </div>
            {
              !user?.is_student && (
                <div
                  className={`section ${page === "sent" && "mail-active"}`}
                  onClick={() => setPage("sent")}
                >
                  <div className="text">
                    <AiOutlineSend /> SENT
                  </div>
                  <div className="number">{sentBox.length}</div>
                </div>
              )
            }
          </div>
        </div>
        <div className="mail-right">
          {page === "inbox" && (
            <>
              <div className="mail-right-top">
                {loadingData ? (
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#aaa", "#aaa", "#aaa", "#aaa", "#aaa"]}
                  />
                ) : (
                  <MdRefresh
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRefresh()}
                  />
                )}
              </div>
              <div className="mails">
                <div className="mail">
                  <DataGrid
                    rows={inbox}
                    loading={loadingInbox}
                    columns={InboxColumns}
                    autoHeight="true"
                    onCellDoubleClick={(message) => {
                      getReceivedMessage(message.id);
                    }}
                    paginationModel={{ page: 0, pageSize: 5 }}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                  />
                </div>
              </div>
            </>
          )}
          {page === "compose" && (
            <>
              <div className="mail-right-top">
                <h5>New Message</h5>
              </div>
              <div className="mails">
                <form onSubmit={(e) => handleSendMessage(e)}>
                  <div className="receipients">
                    <div className="formInput">
                      <label>Send Message To</label>
                      <select
                        name="receipient_type"
                        id="receipient_type"
                        onChange={(e) => handleChange(e)}
                      >
                        <option>Select Receipient</option>
                        <option value="All Teachers">All Teachers</option>
                        <option value="All Students">All Students</option>
                        <option value="Specific Class">Specific Class</option>
                        <option value="Specific Student">
                          Specific Student
                        </option>
                        <option value="Specific Teacher">Specific Staff</option>
                      </select>
                    </div>
                    {data.receipient_type === "Specific Class" && (
                      <div className="formInput">
                        <label>Select Class</label>
                        <select
                          name="class"
                          id=""
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">Select Class</option>
                          {schoolClasses.map((schoolClass) => {
                            return (
                              <option
                                value={schoolClass.id}
                                key={schoolClass.id}
                              >
                                {schoolClass.class_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {data.receipient_type === "Specific Student" && (
                      <div className="formInput">
                        <label>Student</label>
                        <select
                          name="student_id"
                          id="student"
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">Select Student</option>
                          {students.map((student) => {
                            return (
                              <option
                                key={student.student_id}
                                value={student.student_id}
                              >
                                {student.first_name} {student.last_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {data.receipient_type === "Specific Teacher" && (
                      <div className="formInput">
                        <label>Staff</label>
                        <select
                          name="staff_id"
                          id="staff"
                          onChange={(e) => handleChange(e)}
                        >
                          <option>Select Staff</option>
                          {staffs.map((staff) => {
                            return (
                              <option
                                value={staff.staff_id}
                                key={staff.staff_id}
                              >
                                {staff.first_name} {staff.last_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="formInput">
                    <label>Subject</label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {/* <div className="formInput">
                      <label>Message</label>
                      <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        onChange={e=>handleChange(e)}
                      ></textarea>
                    </div> */}
                  <div className="formInput">
                    <label htmlFor="">Message</label>
                    <CKEditor
                      style={{ marginTop: "10px" }}
                      editor={ClassicEditor}
                      data={data.message}
                      onChange={(event, editor) => {
                        const text = editor.getData();
                        setData({
                          ...data,
                          message: text,
                        });
                      }}
                    />
                  </div>
                  {loading ? (
                    <button
                      style={{
                        backgroundColor: "gray",
                        cursor: "wait",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "10px 20px",
                        border: "none",
                        marginTop: "10px",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      <ColorRing
                        visible={true}
                        height="30"
                        width="30"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />{" "}
                      Sending Message{" "}
                    </button>
                  ) : (
                    <button className="sendBtn" type="submit">
                      Send Message
                    </button>
                  )}
                </form>
              </div>
            </>
          )}
          {page === "sent" && (
            <>
              <div className="mail-right-top">
                {loadingData ? (
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#aaa", "#aaa", "#aaa", "#aaa", "#aaa"]}
                  />
                ) : (
                  <MdRefresh
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRefresh()}
                  />
                )}
              </div>
              <div className="mails" style={{ height: 400 }}>
                <DataGrid
                  rows={sentBox}
                  loading={loadingData}
                  onCellDoubleClick={(message) => {
                    getSentMessage(message.id);
                  }}
                  columns={SentColumns}
                  paginationModel={{ page: 0, pageSize: 5 }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  // checkboxSelection
                />
              </div>
            </>
          )}
          {page === "message" && (
            <>
              <div className="mail-right-top">
                <BiArrowBack
                  onClick={() => {
                    setPage("inbox");
                  }}
                  className="backButton"
                />
              </div>
              <div className="mails">
                <div className="mail">
                  {loadingMessage ? (
                    <Loading />
                  ) : (
                    <>
                      <p className="subject">
                        {message.subject ? (
                          <b>{message.subject}</b>
                        ) : (
                          <>(no subject)</>
                        )}
                      </p>
                      <div className="sender">
                        {message.sender.profile_picture ? (
                          <img
                            style={{ height: "40px", borderRadius: "50%" }}
                            src={message.sender.profile_picture}
                            alt=""
                          />
                        ) : (
                          <img
                            style={{ height: "40px", borderRadius: "50%" }}
                            src="https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg"
                            alt=""
                          />
                        )}
                        <div className="message-details">
                          <div className="sender-details">
                            <span>
                              <b>
                                {message.sender.first_name} {message.sender.last_name}
                              </b>
                            </span>
                            <br />
                            <small>To me</small>
                          </div>
                          <div className="time-details">
                            <p>{message.date}</p>
                          </div>
                        </div>
                      </div>
                      <div className="message-body">
                        {parse(message.message)}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {page === "sent-message" && (
            <>
              <div className="mail-right-top">
                <BiArrowBack
                  onClick={() => {
                    setPage("inbox");
                  }}
                  className="backButton"
                />
              </div>
              <div className="mails">
                <div className="mail">
                  {loadingMessage ? (
                    <Loading />
                  ) : (
                    <>
                      <p className="subject">
                        {message.subject ? (
                          <>{message.subject}</>
                        ) : (
                          <>(no subject)</>
                        )}
                      </p>
                      <div className="sender">
                        {/* {
                        message.sender.profile_picture ? (
                          <img style={{height: "40px", borderRadius:"50%"}} src={message.sender.profile_picture} alt="" />  
                          ):(

                            <img style={{height: "40px", borderRadius:"50%"}} src="https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg" alt="" />
                          )
                        } */}
                        <img
                          style={{ height: "40px", borderRadius: "50%" }}
                          src="https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg"
                          alt=""
                        />
                        <div className="message-details">
                          <div className="sender-details">
                            <span>
                              {/* <b>{message.sender.first_name} {message.sender.last_name}</b>  */}
                              <b>From Me</b>
                            </span>
                            <br />
                            <small>
                              To: &nbsp;
                              {message.receipients.map((receipient, index) => (
                                <span key={index}> {receipient}, </span>
                              ))}
                            </small>
                          </div>
                          <div className="time-details">
                            <p>{message.date}</p>
                          </div>
                        </div>
                      </div>
                      <div className="message-body">
                        {parse(message.message)}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Mailbox;
