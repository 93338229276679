import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.scss";

import { HiAcademicCap } from "react-icons/hi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdPeopleAlt } from "react-icons/md";

import StatsCard from "../../components/StatsCard/StatsCard";
import PieChart from "../../components/Charts/PieChart";

import { useAuthenticate } from "../../context/AuthContext";
import GreetingCard from "../../components/GreetingCard/GreetingCard";
import List from "../../components/List/List";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Barchart from "../../components/Charts/BarChart";
import { API_URL } from "../../url";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../components/LoadingPage/Loading";

const TeacherDashboard = () => {
  const { user } = useAuthenticate();
  const [dashboardData, setDashboardData] = useState({});
  const [attendanceData, setAttendanceData] = useState({});
  const [gettingDashboardData, setGettingDashboardData] = useState(false);
  const [fetchingAttendanceData, setFetchingAttendanceData] = useState(true);

  const studentData = [
    {
      id: "Male",
      label: "Male",
      value: `${dashboardData.male_students_count}`,
      color: "hsl(227, 70%, 50%)",
    },
    {
      id: "Female",
      label: "Female",
      value: `${dashboardData.female_students_count}`,
      color: "hsl(3, 70%, 50%)",
    },
  ];

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };

  const getDashboardData = async () => {
    setGettingDashboardData(true);
    await axios
      .get(`${API_URL}teacher/dashboard`, config)
      .then((response) => {
        setDashboardData(response.data);
        setGettingDashboardData(false);
      })
      .catch((error) => {
        setGettingDashboardData(false);
      });
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className="dashboard-container">
      {gettingDashboardData ? (
        <Loading />
      ) : (
        <div>
          <div className="body">
            <div className="left">
              <GreetingCard user={dashboardData} />

              {user.account_type === "School Administrator" && (
                <div className="today-attendance">
                  <div className="attendance-top">
                    <p className="title">Today's Attendance</p>
                    <Link
                      to="/attendance/class-weekly-attendance"
                      className="attendance-link"
                    >
                      Go to Attendance
                    </Link>
                  </div>
                  <div className="chart-data">
                    {fetchingAttendanceData ? (
                      "Fetching Data..."
                    ) : (
                      <Barchart chartData={attendanceData} />
                    )}
                  </div>
                </div>
              )}

              <div className="grid grid-cols-3 gap-4 mt-5">
              <div className="h-[397px] pb-[25px] border border-gray-500 rounded">
                  <div className="title px-[17px] py-[10px] bg-primary text-white">
                    <h3>Student Population</h3>
                  </div>
                  
                  <PieChart data={dashboardData?.class_data} />
                </div>
                <div className="rounded border border-gray-500 ">
                  <div className="title px-[17px] py-[10px] bg-primary text-white">
                    <h3>Upcoming Events</h3>
                  </div>
                  <div className="events  pb-[25px]">
                    {dashboardData.events && dashboardData.events.length > 0 ? (
                      <>
                        {dashboardData.events.map((event) => {
                          return (
                            <div
                              className="pb-[12px] px-[17px] border-b border-primary flex gap-[10px] items-center mt-5"
                              key={event.id}
                            >
                              <div className="">
                                <h4 className="text-[18px] font-[600] hover:text-[#f19c74] cursor-pointer">
                                  <Link to={`/events/${event.id}`}>
                                    {event.title}
                                  </Link>
                                </h4>
                                <p className="text-[12px] text-gray-500">
                                  {event?.date}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="h-full flex flex-col items-center justify-center gap-4">
                        <h2>No Event Added</h2>
                        {user.account_type === "School Administrator" && (
                          <button className="bg-[#F7B697] text-black hover:bg-[#f19c74] text-[12px] font-[600] px-3 py-2 rounded-[30px]">
                            <Link to="/events">Add Event</Link>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="right">
                <div className="events-card">
                    <div className="header">
                    <h3 className="title">Upcoming Events</h3>
                    {user.account_type === "School Administrator" && (
                        <div className="add-events-btn">
                        <Link to="/events">See All</Link>
                        </div>
                    )}
                    </div>
                    <div className="events">
                    {dashboardData.events && dashboardData.events.length > 0 ? (
                        <>
                        {dashboardData.events.map((event) => {
                            return (
                            <div className="event" key={event.id}>
                                <div className="event-details">
                                <h4 className="event-title">
                                    <Link to={`/events/${event.id}`}>
                                    {event.title}
                                    </Link>
                                </h4>
                                <span className="event-description">
                                    {parse(event.description.slice(0, 100))}...
                                </span>
                                </div>
                            </div>
                            );
                        })}
                        </>
                    ) : (
                        <div className="no-event">
                        <h2>No Event Added</h2>
                        {user.account_type === "School Administrator" && (
                            <button>
                            <Link to="/events">Add Event</Link>
                            </button>
                        )}
                        </div>
                    )}
                    </div>
                </div>
                <div className="chart-card">
                    <div className="title">
                    <h3>Student Population</h3>
                    </div>
                    <PieChart data={studentData} />
                </div>
                </div> */}
          </div>
          {user?.account_type === "School Administrator" && (
            <div className="list-container">
              <div className="list-title">Latest Admissions</div>
              <List rows={dashboardData.latest_admissions} />
            </div>
          )}
          {user?.is_employee && user?.position == "Teacher" && (
            <div className="schedule-body">
              <div className="student-list">
                <div className="panel-header">
                  <h4>Upcoming Classes</h4>
                </div>
                <div className="list-body">
                  <table>
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Class</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.upcoming_classes?.map(
                        (upcoming_class, index) => (
                          <tr>
                            <td>{upcoming_class?.subject}</td>
                            <td>{upcoming_class?.class}</td>
                            <td>{upcoming_class?.start_time}</td>
                            <td>{upcoming_class?.end_time}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {user?.is_student && (
            <div className="schedule-body">
              <div className="student-list">
                <div className="panel-header">
                  <h4>Today's Schedule</h4>
                </div>
                <div className="list-body">
                  <table>
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Teacher</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.today_schedule?.map(
                        (upcoming_class, index) => (
                          <tr>
                            <td>
                              {upcoming_class?.is_break ? (
                                "Break Time"
                              ) : (
                                <>
                                  {upcoming_class?.activity ? (
                                    <>{upcoming_class?.activity}</>
                                  ) : (
                                    <>{upcoming_class?.subject}</>
                                  )}
                                </>
                              )}
                            </td>
                            <td>{upcoming_class?.teacher}</td>
                            <td>{upcoming_class?.start_time}</td>
                            <td>{upcoming_class?.end_time}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TeacherDashboard;
