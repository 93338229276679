import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.scss";

import { HiAcademicCap } from "react-icons/hi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdPeopleAlt } from "react-icons/md";

import StatsCard from "../../components/StatsCard/StatsCard";


import { useAuthenticate } from "../../context/AuthContext";
import GreetingCard from "../../components/GreetingCard/GreetingCard";
import { API_URL } from "../../url";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../components/LoadingPage/Loading";
import PieChart from "../../components/Charts/PieChart";
import { Link } from "react-router-dom";

const AccountantDashboard = () => {
  const { user } = useAuthenticate();
  const [dashboardData, setDashboardData] = useState({});
  const [gettingDashboardData, setGettingDashboardData] = useState(false)
  const incomeExpenseData = [
    {
      id: "Income",
      label: "Income",
      value: `${dashboardData?.this_month_revenue}`,
      color: "#CC2B52",
    },
    {
      id: "Expense",
      label: "Expense",
      value: `${dashboardData?.this_month_expense}`,
      color: "#133E87",
    },
  ];
 

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };



  const getDashboardData = async () => {
    setGettingDashboardData(true)
    await axios.get(`${API_URL}accounts/dashboard/accountant/`, config).then((response)=>{
      setDashboardData(response.data);
      setGettingDashboardData(false)
    }).catch((error)=>{
      setGettingDashboardData(false)
    })
  }

  useEffect(() => {
    getDashboardData();
  }, []);

 
    return (
      <div className="dashboard-container">
        {
          gettingDashboardData ? (
            <Loading />
          ):(
            <div>
              <div className="body">
                <div className="">
                  <GreetingCard user={dashboardData} />
                  
                  <div className="grid lg:grid-cols-3 my-3 gap-4">
                    <StatsCard
                      icon={<HiAcademicCap className="text-[70px] text-secondary" />}
                      title="Total Revenue"
                      text={`GHS ${dashboardData?.total_revenue}`}
                    />
                    <StatsCard
                      icon={<MdPeopleAlt className="text-[70px] text-secondary" />}
                      title="Today's Revenue"
                      text={`GHS ${dashboardData?.today_revenue}`}
                    />
                    <StatsCard
                      icon={<FaChalkboardTeacher className="text-[70px] text-secondary" />}
                      title="School Fees Collected Today"
                      text={`GHS ${dashboardData?.fees_collected_today}`}
                    />
                    <StatsCard
                      icon={<GiTakeMyMoney className="text-[70px] text-secondary" />}
                      title="Collectibles Collected Today"
                      text={`GHS ${dashboardData?.collectibles_collected_today}`}
                    />
                </div>
        
                 
                </div>
                <div className="grid lg:grid-cols-3 my-3 gap-4">
                  <div className="h-[397px] pb-[25px] border border-gray-500 rounded">
                    <div className="title px-[17px] py-[10px] bg-primary text-white">
                      <h3>Income and Expense for this month</h3>
                    </div>
                    
                    <PieChart data={incomeExpenseData} />
                  </div>

                  <div className="rounded border border-gray-500 ">
                  <div className="title px-[17px] py-[10px] bg-primary text-white">
                    <h3>Upcoming Events</h3>
                  </div>
                  <div className="events  pb-[25px]">
                    {dashboardData.events && dashboardData.events.length > 0 ? (
                      <>
                        {dashboardData.events.map((event) => {
                          return (
                            <div className="pb-[12px] px-[17px] border-b border-primary flex gap-[10px] items-center mt-5" key={event.id}>
                              <div className="">
                                <h4 className="text-[18px] font-[600] hover:text-[#f19c74] cursor-pointer">
                                  <Link to={`/events/${event.id}`}>
                                    {event.title}
                                  </Link>
                                </h4>
                                <p className="text-[12px] text-gray-500">
                                  {event?.date}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="h-full flex flex-col items-center justify-center gap-4">
                        <h2>No Event Added</h2>
                        {user.account_type === "School Administrator" && (
                          <button className="bg-[#F7B697] text-black hover:bg-[#f19c74] text-[12px] font-[600] px-3 py-2 rounded-[30px]">
                            <Link to="/events">Add Event</Link>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                </div>


              </div>
              
            </div>
          )
        }
      </div>
    );
  

};

export default AccountantDashboard;
