import React, { useState, useEffect } from 'react'
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import NoDataImage from "../../assets/no_data.jpg"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const StudentVehicle = () => {
    const UserImage = "https://epasmart.s3.eu-north-1.amazonaws.com/assets/user_image.svg"
    const [studentVehicles, setStudentVehicles] = useState([]);
    const [loadingStudentVehicles, setLoadingStudentVehicles] = useState(true);

    const columns = [
        {
          field: "profile_picture",
          headerName: "",
          width: 60,
          renderCell: (params) => {
            return (
              <>
                {params.row.student.profile_picture ? (
                  <img
                    style={{ height: "40px", borderRadius: "50%", width: "100%" }}
                    src={params.row.student.profile_picture}
                    alt=""
                  />
                ) : (
                  <img
                    src={UserImage}
                    style={{ height: "40px", borderRadius: "50%" }}
                    alt="user"
                  />
                )}
              </>
            );
          },
        },
        { field: "student_school_id", headerName: "Student ID", width: 200, renderCell:(params) => {
          return (
            <p>
              {params.row.student.student_school_id}
            </p>
          )
        } },
        { field: "first_name", headerName: "First Name", width: 200, renderCell:(params) =>{
          return (
            <p>
              {params.row.student.first_name}
            </p>
          )
        }},
        { field: "last_name", headerName: "Last Name", width: 150, renderCell:(params)=>{
          return (
            <p>
              {params.row.student.last_name}
            </p>
          )
        } },
        { field: "vehicle", headerName: "Vehicle", width: 150 },
        { field: "student_class", headerName: "Class", width: 140, renderCell:(params)=>{
          return (
            <p>
              {params.row.student.class}
            </p>
          )
        } },
        {
          field: "action",
          headerName: "Actions",
          width: 170,
          renderCell: (params) => {
            return (
              <div className="cellAction">
                <div className="deleteButton" onClick={()=>unassignStudentVehicle(params.row.id)}>
                  Unassign
                </div>
              </div>
            );
          },
        },
      ];
    

      const unassignStudentVehicle = (id) => {
        Swal.fire({
          title: `Are you sure?`,
          text: "This action is not reversible",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, unassign!'
        }).then((result) => {
          if (result.isConfirmed) {
    
            if(localStorage.getItem('access')){
              const config={
                headers:{
                  'Content-Type': `application/json`,
                  'Authorization':`JWT ${localStorage.getItem('access')}`,
                  'Accept':'application/json'
                }
              }
              
        
              try{
                axios.delete(`${API_URL}transport/student-assigned-vehicle/${id}`, config)
                Swal.fire(
                  'Unassigned!',
                  'Student has been unassigned from the vehicle.',
                  'success'
                ).then(()=>{
                  window.location.reload()
                }
                )
                
              }catch(error){
                
                toast.error("Something went wrong, please try again.")
              }
    
            }
            
          }
        })
        
      }


    useEffect(() => {
        const fetchStudentVehicles = async () =>{
            if(localStorage.getItem('access')){
                setLoadingStudentVehicles(true);
              const config={
                headers:{
                  'content-type': 'application/json',
                  'Authorization': `JWT ${localStorage.getItem('access')}`,
                }
              };
              try{
                const res = await axios.get(`${API_URL}transport/student-assigned-vehicle/`, config);
                setLoadingStudentVehicles(false)
                setStudentVehicles(res.data);
              }catch(error){
                if(error.response.status === 401){
                  window.location.href = "/"
                }
              }
            }
          }
          fetchStudentVehicles();
    },[])

    return (
    <div className='main-container'>
        <div className="header">
            <h1>STUDENT ASSIGNED VEHICLES</h1>
        </div>
        {
            studentVehicles.length > 0 ? (
                <div>
                    <div className="classic-button" style={{marginBottom:"10px"}}>
                        Assign Student Vehicle
                    </div>
                    <DataGrid
                        rows = {studentVehicles}
                        loading = {loadingStudentVehicles}
                        columns = {columns}
                        initialState={{
                            pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        getRowId={(row) => row.id}
                        components={{ Toolbar: GridToolbar }}
                    />
                </div>
            ):(
                <React.Fragment>
                {
                    loadingStudentVehicles ? (
                        "Loading..."
                    ):(
                    <div className="no-data">
                            <img src={NoDataImage} alt="no data" />
                            <p>
                               No Student Has Been Assigned To A Vehicle Yet.
                            </p>
                            <Link to="/transport/assign-student-vehicle" className="classic-button" style={{marginTop:"10px"}}>
                                Assign Student Vehicle
                            </Link>
                    </div>
                    )
                }
                </React.Fragment>
            )
        }
    </div>
  )
}

export default StudentVehicle