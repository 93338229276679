import React from "react";


const SchemeDetails = ({modal, setModal, schemeDetails}) => {
    
  return (
    <div className="modal">
      <div className="overlay" onClick={()=>setModal(!modal)}>
      <div className="modal-content" onClick={(e)=>e.stopPropagation(e)}  style={{top:"30%"}}>
        <div onClick={()=>setModal(!modal)} className="modal-close-btn">
          x
        </div>
        <div className="modal-header"> {schemeDetails.name} Details</div>
        <div className="modal-row" style={{display: "flex",marginTop:"30px", gap:"30px",marginBottom:"20px"}}>
            <div style={{flex: "1"}}>
                <b>Academic Year</b>: {schemeDetails.academic_year}
            </div>
            <div style={{flex: "1"}}>
                <b>Academic Term</b>: {schemeDetails.term}
            </div>
        </div>
        <div className="modal-row" style={{display: "flex", gap:"30px",marginBottom:"20px"}}>
            <div style={{flex: "1"}}>
                <b>Exam Scaling</b>: {schemeDetails.exam_scaling}
            </div>
            <div style={{flex: "1"}}>
                <b>SBA Scaling</b>: {schemeDetails.sba_scaling}
            </div>
        </div>
        <div className="modal-row" style={{display: "flex",flexWrap:"wrap", gap:"30px",marginBottom:"20px"}}>
            <div style={{flex: "1"}}>
                <b>Main Exam</b>: {schemeDetails.main_exam}
            </div>
            <div style={{display: "flex",flex:"1", flexWrap:"wrap", gap:'5px',marginBottom:"20px"}}>
                <b>SBA Components:</b>
                <ul >
                    {
                        schemeDetails.sba_components.map((component, index)=>{
                            return(
                                <li key={index}>{component}</li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default SchemeDetails;
