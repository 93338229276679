import React, { useEffect } from "react";
import { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../url";

const ReceiveIncome = () => {
  const [loading, setLoading] = useState(false);
  const [incomeTypes, setIncomeTypes] = useState([])

  const [data, setData] = useState({
    mode_of_payment: "",
    revenue_type: "",
    new_revenue_type: "",
  });

  const getIncomeTypes = async (e) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}finance/school-income-type/`,
          config
        );
        setIncomeTypes(res.data);


      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  }

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        const body = JSON.stringify(data);

        try {
          const res = await axios.post(
            `${API_URL}finance/school-revenue/`,
            body,
            config
          );
          setLoading(false);
          if (res.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Income Recorded Successfully",
            }).then(()=>{
              window.location.href("/finance/income")
            });
          }
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            }else if(error.response.status === 500){
              toast.error("Something unexpected happened. Please try again later")
            }
             
            else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      } else {
        window.location.href = "/";
      }
    }
  };

  useEffect(()=>{
    getIncomeTypes()
  },[])

  return (
    <div className="main-container">
      <div className="header">
        <h1>RECEIVE INCOME</h1>
      </div>
      <div className="add-form">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="formRow">
            <div className="formInput">
              <label htmlFor="">Date*</label>
              <input
                type="date"
                name="date"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="formInput">
              <label htmlFor="">Income Type*</label>
              <select onChange={(e) => handleChange(e)} name="revenue_type" id="">
                <option value="">Select Income Type</option>
                {
                  incomeTypes.map((incomeType) => (
                    <option key={incomeType.id} value={incomeType.id}>{incomeType.name}</option>
                  ))
                }
                <option value="other">Other</option>
              </select>
              
            </div>
            {
              data.revenue_type === "other" && (
              <div className="formInput">
                <label htmlFor="">New Income Type*</label>
                <input
                  type="text"
                  name="new_revenue_type"
                  onChange={(e) => handleChange(e)}
                />
                
              </div>
              )
            }
          </div>

          <div className="formRow">
            <div className="formInput">
              <label htmlFor="">Amount Paid*</label>
              <input
                type="number"
                name="amount"
                min="0" step=".01"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="formInput">
              <label htmlFor="">Paid By*</label>
              <input
                type="text"
                name="payee"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="formInput">
              <label htmlFor="">Payee Phone Number</label>
              <input
                type="text"
                name="payee_phone_number"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          <div className="formRow">
            <div className="formInput">
              <label htmlFor="">Payment Method*</label>
              <select
                required
                name="mode_of_payment"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Payment Method</option>
                <option value="Cash">Cash</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Mobile Money">Mobile Money</option>
              </select>
            </div>
            {data.mode_of_payment === "Bank Transfer" && (
              <div className="formInput">
                <label htmlFor="">GHIPPS ID*</label>
                <input type="text" name="payment_id" onChange={e=>handleChange(e)} />
              </div>
            )}
            {
              data.mode_of_payment === "Mobile Money" && (
                <div className="formInput">
                <label htmlFor="">MoMo Transaction ID*</label>
                <input type="text" name="payment_id" onChange={e=>handleChange(e)} />
              </div>
              )
            }
            <div className="formInput">
              <label htmlFor="">Description*</label>
              <textarea name="description" onChange={e=>handleChange(e)} id="description" cols="30" rows="5"></textarea>
              
            </div>
          </div>
          {loading ? (
            <div className="submitButton" style={{ cursor: "wait" }}>
              <ColorRing
                visible={true}
                height="20"
                width="20"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
              Recording Income...
            </div>
          ) : (
            <button className="submitButton" type="submit">
              Record Income
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ReceiveIncome;
