import React from "react";
import { useState } from "react";
import FormInput from "../../components/Forms/FormInput";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../url";
import { ColorRing } from "react-loader-spinner";

const ApproveReportModal = ({
  modal,
  setModal,
  student,
  term,
  resultDetails,
  fetchStudentReport,
}) => {
  console.log(resultDetails)
  const [remarks, setRemarks] = useState(resultDetails.head_teacher_remarks);
  const [addingRemark, setAddingRemark] = useState(false);

  const approveReport = async (e) => {
    e.preventDefault();
    if (!addingRemark && remarks === "") {
      toast.error("Please enter some remarks");
    } else {
      const body = JSON.stringify({
        head_teacher_remarks: remarks,
      });
      const config = {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      if (localStorage.getItem("access")) {
        setAddingRemark(true);
        try {
          await axios.put(
            `${API_URL}academics/exam-result-remarks/${term}/${student}/`,
            body,
            config
          );
          setAddingRemark(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Remarks Added",
          }).then(() => {
            // window.location.reload()
            fetchStudentReport(e);
            setModal(!modal);
          });
        } catch (error) {
          setAddingRemark(false);
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            toast.error("An error occurred, try again later");
          }
        }
      }
    }
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={() => setModal(!modal)}>
        <div className="modal-content" style={{ top: "30%" }} onClick={(e)=>e.stopPropagation()}>
          <div onClick={() => setModal(!modal)} className="modal-close-btn">
            x
          </div>
          <div className="modal-header">Add Student Remarks</div>

          <form onSubmit={(e) => approveReport(e)}>
            <div className="formSection">
              <div className="formRow">
                <FormInput
                  label="Remarks"
                  type="text"
                  id="remarks"
                  name="remarks"
                  required={true}
                  value={remarks}
                  errorMessage="This field is required"
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>

              <button type="submit" style={{ backgroundColor: "#47A992" }}>
                {addingRemark ? (
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                ) : (
                  "Add Remarks"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApproveReportModal;
