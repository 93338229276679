import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";
import FeeGroupDetails from "./FeeGroupDetails";
import Header from "../../components/Header";
import NewFeeGroup from "./NewFeeGroup";
import NoData from "../../components/NoData";



const FeesGroups = () => {
  const [feeGroups, setFeeGroups] = useState([]); 
  const [deletingFeeGroup, setDeletingFeeGroup] = useState(false); 
  const [gettingFeeGroups, setGettingFeeGroups] = useState(false)
  const [selectedFeeGroup, setSelectedFeeGroup] = useState("");
  const [modal, setModal] = useState(false)
  const [feeCategoryModal, setFeeCategoryModal] = useState(false)
  
  

  // TOGGLE MODAL TO VIEW FEE GROUP
  const toggleModal = (details) => {
    setSelectedFeeGroup(details)
    setModal(!modal)
  }

  // DELETE FEE GROUP
  const deleteFeeGroup = (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Please note that deleting this fee group, will delete all allocations and payments received in relation to this fee group. Do you still want to delete?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed){
        deleteFee(id)
      }
    })
  }

  const deleteFee = async(id) => {
    if(localStorage.getItem("access")){
      setDeletingFeeGroup(true)
      const config = {
        headers:{
          'Content-Type': `application/json`,
          'Authorization':`JWT ${localStorage.getItem('access')}`,
          'Accept':'application/json'
        }
      }
      try {
        const res = await axios.delete(`${API_URL}finance/fees-group/${id}/`, config)
        if(res.status === 204){
          Swal.fire(
            'Deleted!',
            'Fee group has been deleted.',
            'success'
          ).then(()=>{
            fetchFeeGroups();
          })
          setDeletingFeeGroup(false)
        }
      } catch (error) {
        toast.error("Unfortunately you cannot delete this fee group.")
        setDeletingFeeGroup(false)
      }
    }
  }
  
  // Handling Academic Year Change
  

  // Getting Fee Groups
  const fetchFeeGroups = async () => {
    if (localStorage.getItem("access")) {
      setGettingFeeGroups(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(`${API_URL}finance/fees-group/`, config);

        setFeeGroups(res.data);
        setGettingFeeGroups(false)
      } catch (error) {
        setGettingFeeGroups(false)
        toast.error("Could not get fee groups")
      }
    }
  };


  useEffect(() => {
    fetchFeeGroups();
  }, []);



  return (
    <div className="main-container">
      <Header title="Fee Categories" subtitle="Manage Fee Categories" />
      {
        modal && (
          <FeeGroupDetails selectedFeeGroup = {selectedFeeGroup} modal={modal} setModal={setModal} />
        )
      }

      {
        feeCategoryModal && (
          <NewFeeGroup setModal={setFeeCategoryModal} />
        )
      }

      <div>
        <button className="px-3 py-2 bg-secondary text-secondary-white hover:bg-secondary-100 rounded" onClick={()=>setFeeCategoryModal(true)}>
          Add Fee Category
        </button>
      </div>

      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light text-surface border border-gray-300">
              <thead className=" w-screen  font-medium">
                <tr className="bg-gray-200 text-black">
                  <th scope="col" className="px-6 py-2 font-[500]">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Academic Year
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Term
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {feeGroups?.map((fee_group, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="px-6 py-2 font-[300]">
                      {fee_group?.name}
                    </td>
                    <td className="px-6 py-2 font-[300]">
                      {fee_group?.academic_year}
                    </td>
                    <td className="px-6 py-2 font-[300]">
                      {fee_group?.term}
                    </td>
                    
                    <td className="py-2 flex gap-2">
                      <button onClick={()=>toggleModal(fee_group)} className=" w-max px-3 py-2 font-[500] border border-blue-400 text-blue-400 rounded hover:bg-blue-400 hover:text-white cursor-pointer">
                         Details
                      </button>
                      {
                        deletingFeeGroup ? (
                          <RotatingLines type="Grid" strokeColor="blue" height="26" width="26" strokeWidth="5" />
                        ):(
                          <button onClick={()=>deleteFeeGroup(fee_group?.id)} className=" w-max px-3 py-2 font-[500] border border-red-400 text-red-400 rounded hover:bg-red-400 hover:text-white cursor-pointer">
                            Delete
                          </button>
                        )
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {
              gettingFeeGroups && (
                <div className="flex items-center justify-center">
                  <RotatingLines type="Grid" strokeColor="blue" height="26" width="26" strokeWidth="5" />
                </div>
              )
            }
            {
              feeGroups.length === 0 && !gettingFeeGroups && (
                <NoData />
              )
            }
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default FeesGroups;
