import axios from "axios";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { MdDelete } from "react-icons/md";
import { useAuthenticate } from "../../context/AuthContext";

const SingleCollectiblePayment = ({ setModal, collectibles }) => {
    const { schoolClasses } = useAuthenticate();
    const [loadingStudents, setLoadingStudents] = useState(false);
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [data, setData] = useState({
  });
  
// FETCH CLASS STUDENTS
const fetchClassStudents = async (e) => {
    if (e.target.value !== "") {
        setData({...data, ['current_class']:e.target.value})
      if (localStorage.getItem("access")) {
        setLoadingStudents(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-students/${e.target.value}`,
            config
          );
          setStudents(res.data);
          setLoadingStudents(false);
        } catch (error) {
          setLoadingStudents(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    } else {
      setStudents([]);
    }
  };
  // Handle Form Change
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  
  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);

      try {
        await axios.post(`${API_URL}finance/daily-collectibles/`, body, config);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Payment Recorded Successfully",
        }).then(() => {
          window.location.reload();
        });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  return (
    <div
      id="modal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal"
      onClick={() => setModal(false)}
    >
      <div
        class="bg-white rounded-lg shadow-lg lg:w-1/3 w-4/5 max-h-screen overflow-auto"
        onClick={(e) => e.stopPropagation(e)}
      >
        <div class="px-6 py-4 border-b text-black items-center flex justify-between">
          <h3 class="text-lg  font-semibold">Record Payment</h3>
          <IoCloseCircleOutline
            onClick={() => setModal(false)}
            className="text-[20px] cursor-pointer"
          />
        </div>
        <div class="px-4 max-h-[500px] overflow-auto">
          <div className="shadow mt-5 lg:mt-0 overflow-hidden sm:rounded-md bg-white">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="px-4 py-2 bg-white sm:p-6"
            >
              <div className="grid grid-cols-6 gap-2">
                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Collectible
                  </label>

                  <select
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                    name="collectible"
                    id="collectible"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Collectible</option>
                    {collectibles.map((collectible) => (
                        <option key={collectible.id} value={collectible.id}>
                          {collectible.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Student Class
                  </label>
                  <select
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                    name="academic_year"
                    id="academic_year"
                    onChange={(e) => fetchClassStudents(e)}
                  >
                    <option value="">Select Class</option>
                    {schoolClasses.map((schoolClass) => (
                        <option key={schoolClass.id} value={schoolClass.id}>
                          {schoolClass.class_name}
                        </option>
                      ))}
                  </select>
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Student
                  </label>
                  <select
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                    name="student"
                    id="student"
                    onChange={(e) => handleChange(e)}
                  >
                    
                    {loadingStudents ? (
                  <option>Loading Students...</option>
                ) : (
                  <>
                    {students.length < 1 ? (
                      <option>Select Class First</option>
                    ) : (
                      <>
                        <option value="">Select Student</option>
                        {students.map((student) => {
                          return (
                            <option
                              key={student.student_id}
                              value={student.student_id}
                            >
                              {student?.get_full_name}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
                  </select>
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Amount
                  </label>
                  <input onChange={(e)=>handleChange(e)} required type="number" name="amount" id="amount" step="0.01" className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out" />
                  
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Date
                  </label>
                  <input onChange={(e)=>handleChange(e)} required type="date" name="date" id="date" className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out" />
                  
                </div>
                
              </div>
              <div className="flex items-center justify-end">

                {loading ? (
                    <RotatingLines
                    visible={true}
                    height="26"
                    width="26"
                    strokeColor="black"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                ) : (
                    <button
                    type="submit"
                    value="Save"
                    id="payouts-account-submit"
                    class="mt-2 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-600"
                    >
                        Record Payment
                    </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCollectiblePayment;
