import React from "react";
import ChangePasswordImage from "../../assets/changePassword.jpg";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";

const ChangePassword = () => {
  const { id } = useParams();
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);

  const changePassword = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (newPassword !== confirmPassword) {
        toast.error("Passwords do not match");
      } else {
        setLoading(true);
        const body = {
          new_password: newPassword,
        };
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        try {
          const res = await axios.post(
            `${API_URL}accounts/change-password/${id}/`,
            body,
            config
          );
          if (res.status === 200) {
            setLoading(false);
            toast.success("Password Changed Successfully");
            window.location.href = `/`;
          }
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.code !== 500) {
              for (const property in error.response.data) {
                if (property === "0") {
                  toast.error(`${error.response.data[property]}`);
                } else {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <div id="reset-password">
      <div className="left-image">
        <img src={ChangePasswordImage} alt="" />
      </div>
      <div className="right-forms">
        <form onSubmit={(e) => changePassword(e)}>
          <div className="header">
            <h3>Set New Password</h3>
            <p>Enter The New Password You Want To Use</p>
          </div>
          <input
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            placeholder="New Password"
          />
          <input
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            placeholder="Confirm Password"
          />
          {loading ? (
            <button style={{ background: "gray" }}>
              <ColorRing
                visible={true}
                height="20"
                width="20"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />{" "}
              Submitting...
            </button>
          ) : (
            <input type="submit" value="Change Password" />
          )}
          <div className="back-to-login">
            <IoIosArrowBack />
            <Link to="/">Back To Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
