import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loading from "../../components/LoadingPage/Loading";
import NewSubjectModal from "./NewSubjectModal";
import UpdateSubjectModal from "./UpdateSubjectModal";
import Header from "../../components/Header";

const SubjectList = () => {
  const [subjectList, setSubjectList] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [newSubjectModal, setNewSubjectModal] = useState(false)
  const [updateSubjectModal, setUpdateSubjectModal] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState(null)

  

  const getSubjects = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      setLoadingData(true);
      try {
        const res = await axios.get(`${API_URL}academics/subjects/`, config);
        setLoadingData(false);
        setSubjectList(res.data);
      } catch (error) {}
    }
  };

  

  const deleteSubject = (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "All information relating to this subject will be lost. You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          try {
            axios
              .delete(`${API_URL}academics/subject/${id}/`, config)
              .then((response) => {
                if (response.status === 204) {
                  Swal.fire(
                    "Deleted!",
                    "Subject has been deleted.",
                    "success"
                  ).then(() => {
                    getSubjects();
                  });
                } else {
                  toast.error("Something went wrong");
                }
              });
          } catch (error) {
            if (error.response) {
              if (error.response.status === 403) {
                toast.error("Something went wrong");
              }else if(error.response?.status === 500){
                toast.error("Something went wrong. Please try again")
              }
               else {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    });
  };


  useEffect(() => {
    getSubjects();
  }, []);
  return (
    <div className="main-container">
      <Header title="Subjects List" subtitle="List of all Subjects" />
      {
        newSubjectModal && <NewSubjectModal setNewSubjectModal={setNewSubjectModal} />
      }
      {
        updateSubjectModal && <UpdateSubjectModal setUpdateSubjectModal={setUpdateSubjectModal} subject={selectedSubject} />
      }
      <div>
        <button className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-400 rounded" onClick={()=>setNewSubjectModal(true)}>
          Add New Subject
        </button>
      </div>
      {loadingData ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-left text-sm font-light text-surface border border-gray-300">
                <thead className=" w-screen  font-medium">
                  <tr className="bg-gray-200 text-black">
                    <th scope="col" className="px-6 py-2 font-[500]">
                      Subject Name
                    </th>

                    <th scope="col" className="px-6 py-2 font-[500]">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {subjectList?.map((subject, index) => (
                    <tr key={index} className="border-b border-gray-300">
                      <td className="px-6 py-2 font-[300]">{subject?.subject_name}</td>

                      <td className="flex items-center gap-2 py-2">
                        <button
                          onClick={()=>{
                            setSelectedSubject(subject);
                            setUpdateSubjectModal(true);
                          }}
                          className=" w-max px-3 py-2 font-[500] border border-blue-400 text-blue-400 rounded hover:bg-blue-400 hover:text-white cursor-pointer"
                        >
                          Update
                        </button>
                        <button
                          onClick={() => deleteSubject(subject?.id)}
                          className=" w-max px-3 py-2 font-[500] border border-red-400 text-red-400 rounded hover:bg-red-400 hover:text-white cursor-pointer"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      
    </div>
  );
};

export default SubjectList;
