import React from 'react'

const AssignReport = () => {
  return (
    <div className='main-container'>
        <div className="header">
            ASSIGN EYFS REPORT
        </div>
    </div>
  )
}

export default AssignReport