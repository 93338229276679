import React, { useEffect } from "react";
import { useState,useRef } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import ReactToPrint from "react-to-print";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../url";
import Header from "../../components/Header";

const WeeklyAttendance = () => {
  const attendanceRef = useRef();
  const { schoolClasses, user } = useAuthenticate();
  const [maxDate, setMaxDate] = useState("");
  const [teacherClasses, setTeacherClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [attendanceData, setAttendanceData] = useState("");
  const [data, setData] = useState({
    start_date: "",
    end_date: "",
  });

  // SET END DATE
  const setEndDate = (e) => {
    const startDate = new Date(e.target.value);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    setMaxDate(formatDate(endDate));

    setData({
      ...data,
      start_date: e.target.value,
      end_date: formatDate(endDate),
    });
  };

  // FORMAT DATE
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  //   SUBMIT DATA
  const submitData = async (e) => {
    e.preventDefault();
    if (selectedClass === "") {
      toast.error("Please specify a class");
    } else {
      setLoading(true);
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/week-attendance-report/${selectedClass}/${data.start_date}/${data.end_date}/`,
            config
          );
          if (res.status === 200) {
            setDataFetched(true);
            setAttendanceData(res.data);
            
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/";
              } else {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
        }
      }
    }
  };

  // teacher classes
  const getTeacherClasses = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      await axios.get(
        `${API_URL}teacher/assigned-classes`,
        config
      ).then(response => {
        setTeacherClasses(response.data)
      }).catch(error => {
        
      })

  
    }
  };

  useEffect(()=>{
    if (user?.account_type === "School Staff") {
      getTeacherClasses();
    }
  },[])

  return (
    <div className="main-container">
      <Header title="Class Attendance" subtitle="Weekly Report" />
      <div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => submitData(e)}>
            <div className="formInput">
              <label htmlFor="">Class*</label>
              <select
                name="related_class"
                id=""
                onChange={(e) => setSelectedClass(e.target.value)}
                required
              >
                <option>Select Class*</option>
                {
                  user?.account_type === "School Administrator" && (
                    <>
                      {schoolClasses.map((schoolClass) => {
                        return (
                          <option value={schoolClass.id} key={schoolClass.id}>
                            {schoolClass.class_name}
                          </option>
                        );
                      })}
                    </>
                  )
                }

                {
                  user?.account_type === "School Staff" && user?.position === "Teacher" && (
                    <>
                      {teacherClasses.map((schoolClass) => {
                        return (
                          <option value={schoolClass.id} key={schoolClass.id}>
                            {schoolClass.class_name}
                          </option>
                        );
                      })}
                    </>
                  )
                }
              </select>
            </div>
            <div className="formInput">
              <label htmlFor="">From*</label>
              <input
                type="date"
                name="start_date"
                onChange={(e) => setEndDate(e)}
                id=""
                required
              />
            </div>
            <div className="formInput">
              <label htmlFor="">To*</label>
              <input
                type="date"
                max={maxDate}
                value={data.end_date}
                required
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.value })
                }
                name="end_date"
                id=""
              />
            </div>
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Getting Data{" "}
              </button>
            ) : (
              <button type="submit">Get Class Attendance</button>
            )}
          </form>
        </div>
        {dataFetched && (
          <div className="income-statement" style={{width:"90%"}}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <ReactToPrint
                  trigger={() => (
                    <button className="export-button">
                      <AiOutlineFilePdf />
                      Export to PDF
                    </button>
                  )}
                  content={() => attendanceRef.current}
                  documentTitle="Attendance List"
                />
              </div>
            </div>
            <div style={{overflow:"auto"}}>

              <table ref={attendanceRef} style={{
                    marginTop: "30px",
                    borderCollapse: "collapse",
                    width: "100%",
                  }} >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Student</th>
                    {attendanceData.dates.map((date, index) => (
                      <th style={{ border: "1px solid #ddd", padding: "8px" }} key={index}>{date}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {attendanceData.attendance_data.map(
                    (student_attendance, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            {" "}
                            <b>{student_attendance.student.get_full_name}</b>{" "}
                            <br />{" "}
                            <small>
                              {student_attendance.student.student_school_id}
                            </small>
                          </td>
                          {student_attendance.attendance.map(
                            (attendance, index) => {
                              return (
                                <td style={{ border: "1px solid #ddd", padding: "8px" }} key={index}>
                                  {attendance.status === "Present" ? (
                                    <div className="green-badge">Present</div>
                                  ) : (
                                    <>
                                      {attendance.status === "Absent" ? (
                                        <div className="red-badge">Absent</div>
                                      ) : (
                                        <>
                                          <div className="gray-badge">
                                            Not Marked
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                              );
                            }
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeeklyAttendance;
