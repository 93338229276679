import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import NoDataImage from "../../assets/no_data.jpg"
import axios from 'axios';
import { API_URL } from '../../url';
import { Link } from 'react-router-dom';
import { useAuthenticate } from '../../context/AuthContext';

const Drivers = () => {
    const UserImage = "https://epasmart.s3.eu-north-1.amazonaws.com/assets/user_image.svg"
    const [drivers, setDrivers] = useState([]);
    const [loadingDrivers, setLoadingDrivers] = useState([]);
    const {user} = useAuthenticate()

    const columns = [
        { field: 'profile_picture', headerName: "", width: 60, renderCell: (params) => {
          return (
            <>
              {
                params.row.profile_picture ? (
                  <img style={{height:"40px", borderRadius: "50%"}} src={params.row.profile_picture} alt="" />
    
                ):(
                  <img src={UserImage} style={{height:"40px", borderRadius: "50%"}} alt='user' />
                )
              }
            </>
          )
        }},
        { field: 'staff_school_id', headerName: 'STAFF ID', width: 200 },
        { field: 'first_name', headerName: 'First Name', width: 200 },
        { field: 'last_name', headerName: 'Last Name', width: 200 },
        { field: 'position', headerName: 'Position', width: 140 },
        { field: 'staff_type', headerName: 'Staff Type', width: 140 },
        { 
          field: 'action', headerName: 'Actions', width: 170,
          renderCell: ({id}) => {
            return (
              <>
                {user.account_type === "School Administrator" && (
                  <div className='cellAction'>
                    <div className='viewButton'>
                    
                      <Link to={`/staff/${id}`}>
                        View
                      </Link>
                    </div>
                    
                  </div>
                )}
              </>
            )
          }
        },
        
    ];

    useEffect(() => {
        const fetchDrivers = async () =>{
          if(localStorage.getItem('access')){
            setLoadingDrivers(true);
            const config={
              headers:{
                'content-type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
              }
            };
            try{
              const res = await axios.get(`${API_URL}accounts/drivers/`, config);
              setLoadingDrivers(false)
              setDrivers(res.data);
            }catch(error){
              if(error.response.status === 401){
                window.location.href = "/"
              }
            }
          }
        }
        fetchDrivers();
      },[])
  return (
    <div className="main-container">
        <div className="header">
            <h1>DRIVERS</h1>
        </div>
        <div>
            {
                drivers.length <= 0 ? (
                    <div className="no-data">
                        <img src={NoDataImage} alt="no data" />
                        <p>
                        No Drivers Found.
                        </p>
                  </div>
                ) : <DataGrid
                rows = {drivers}
                loading = {loadingDrivers}
                columns = {columns}
                initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20]}
                getRowId={(row) => row.staff_id}
                components={{ Toolbar: GridToolbar }}
            />
            }
            
        </div>
    </div>
  )
}

export default Drivers