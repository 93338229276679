import React from 'react'
import { Route, Routes } from 'react-router-dom'
import StudentDashboard from '../pages/Dashboard/StudentDashboard'
import Teachers from '../pages/StudentPages/Teachers'
import StudentClass from '../pages/StudentPages/StudentClass'

const StudentRoutes = () => {
  return (
    <Routes>
        <Route path="/dashboard" element={<StudentDashboard />} />
        <Route path="/teachers" element={<Teachers />} />
        <Route path="/student-class" element={<StudentClass />} />
    </Routes>
  )
}

export default StudentRoutes