import React, { useEffect, useState } from "react";

import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";


const ClassFees = () => {
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [academicTerms, setAcademicTerms] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [classFees, setClassFees] = useState([]);
    const [data, setData] = useState({
      school_class: "",
      term: "",
    });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        if(data.school_class === "" || data.term === ""){
          toast.error("Please Check And Select all required fields")
        }else{
          if (localStorage.getItem("access")) {
              const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${localStorage.getItem("access")}`,
                },
              };
        
              try {
                const res = await axios.get(
                  `${API_URL}finance/class-term-fees/${data.term}/${data.school_class}/`,
                  config
                );
                setClassFees(res.data);
                setDataFetched(true)
              } catch (error) {
                
                if (error.response) {
                  if(error.response.status === 401){
                    window.location.href="/"
                  }else{
                    for (const property in error.response.data) {
                      toast.error(`${property} error: ${error.response.data[property]}`);
                    }
                  }
                } else if (error.message) {
                  toast.error("Something went wrong. Please try again");
                }
              }
            }

        }
        
    }

  useEffect(() => {
    const fetchClasses = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        try {
          const res = await axios.get(`${API_URL}academics/classes/`, config);
          setSchoolClasses(res.data);
        } catch (error) {
          
        }
      }
    };

    const fetchAcademicTerms = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        try {
          const res = await axios.get(
            `${API_URL}academics/academic-terms/`,
            config
          );
          setAcademicTerms(res.data);
        } catch (error) {
          
        }
      }
    };

    fetchAcademicTerms();
    fetchClasses();
  },[]);

  return (
    
      <div className="main-container">
        <div className="header">
          <h1>CLASS FEES</h1>
        </div>
        <div className="schedule-body">
          <div className="filter-columns">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="formInput">
                <select required name="school_class" onChange={(e) => handleChange(e)}>
                  <option>Select Class</option>
                  {schoolClasses.map((schoolClass) => {
                    return (
                      <option value={schoolClass.id} key={schoolClass.id}>
                        {schoolClass.class_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="formInput">
                <select name="term" required onChange={(e) => handleChange(e)}>
                  <option>Academic Term</option>
                  {academicTerms.map((academicTerm) => {
                    return (
                      <option value={academicTerm.id} key={academicTerm.id}>
                        {academicTerm.term_full_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <button
                
                type="submit"
              >
                Get Class Fees 
              </button>
              
            </form>
          </div>
          <div className="schedule">
            {
              dataFetched && (
                <>
                  {
                    classFees.length > 0 ? (
                      <table>
                        <thead>
                          <tr>
                            <td>Fee Type</td>
                            <td>Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                              {
                                classFees.map((classFee, index) =>{
                                  return (
                                    <>
                                      {
                                        classFee.components.map((component) => {
                                          return (
                                            <tr>
                                              <td>
            
                                                {component.fee_type}
                                              </td>
                                              <td>
            
                                                {component.amount}
                                              </td>
            
                                            </tr>
            
                                          )
                                        })
                                      }
                                    </>
            
                                  )
                                })
                              }    
                          
                        </tbody>
                      </table>
                      
                    ) : (
                      <div className="no-data-msg">
                         THE SELECTED CLASS HAS NO FEES FOR THIS TERM
                      </div>
                    )
                  }
                </>
              ) 
            }
            </div>
        </div>
      </div>
    
  );
};

export default ClassFees;
