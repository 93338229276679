import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthenticate } from "../../context/AuthContext";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import Loading from "../../components/LoadingPage/Loading";
import Header from "../../components/Header";

const GenerateStudentReport = () => {
  const [loading, setLoading] = useState(false);
  const { academicYears, schoolClasses } = useAuthenticate();
  const [academicTerms, setAcademicTerms] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [approving, setApproving] = useState(false);
  const [data, setData] = useState({
    academic_year: "",
    term: "",
    current_class: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // CHANGE ACADEMIC YEAR
  const changeAcademicYear = (e) => {
    handleChange(e);
    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  // GENERATE REPORT
  const generateReport = async (e) => {
    e.preventDefault();

    if (
      data.current_class === "" ||
      data.academic_year === "" ||
      data.term === ""
    ) {
      toast.error("Please select all fields");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        const body = JSON.stringify(data);
        try {
          const res = await axios.post(
            `${API_URL}academics/generate-exam-result/`,
            body,
            config
          );

          if (res.status === 201) {
            setStudentList(res.data);
            toast.success( `Reports generated for ${res.data.length} students`)

          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            }else if(error.response.status === 500){
              return toast.error("Something went wrong. Please try again");
            } else {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  const confirmApproveReport = async () => {
    if (localStorage.getItem("access")) {
      setApproving(true);
      const config = {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      const body = JSON.stringify(data);
      try {
        const res = await axios.post(
          `${API_URL}academics/approve-exam-result/`,
          body,
          config
        );

        if (res.status === 200) {
          
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Reports approved for ${res.data} students`,
          });
        }
        setApproving(false);
      } catch (error) {
        setApproving(false);
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            for (const property in error.response.data) {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  }

  // APPROVE REPORT
  const approveReport = () => {

    if (
      data.current_class === "" ||
      data.academic_year === "" ||
      data.term === ""
    ) {
      toast.error("Please select all fields");
    } else {
      Swal.fire({
        title: `Are you sure?`,
        text: "Once the report is approved, you can not regenerate the student's report with new details",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approve reports!'
      }).then((result) => {
        if(result.isConfirmed){
          confirmApproveReport()
        }
      })
     
    }
  };

  // CONFIRM DISAPPROVE REPORT
  const confirmDisapproveReport = async () => {
    if (localStorage.getItem("access")) {
      setApproving(true);
      const config = {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      const body = JSON.stringify(data);
      try {
        const res = await axios.post(
          `${API_URL}academics/disapprove-exam-result/`,
          body,
          config
        );

        if (res.status === 200) {
          
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Reports disapproved for ${res.data} students`,
          });
        }
        setApproving(false);
      } catch (error) {
        setApproving(false);
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          } else {
            for (const property in error.response.data) {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  }
  // DISAPPROVE REPORT
  const disapproveReport = () => {
    if (
      data.current_class === "" ||
      data.academic_year === "" ||
      data.term === ""
    ){
      toast.error("Please select all fields")
    }else{
      Swal.fire({
        title: `Are you sure?`,
        text: "Once the report is disapproved, the student position will be reverted unless the reports are approved again",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approve reports!'
      }).then((result)=>{
        if(result.isConfirmed){
          confirmDisapproveReport()
        }
      })
    }
  }

  

  return (
    <div className="main-container">
      <Header title="Generate Student Report" subtitle="Graduate academic reports for students" />
      {
        !approving ? (<div className="schedule-body">
        <div className="filter-columns">
          <form onSubmit={(e) => generateReport(e)}>
            <div className="formInput">
              <select
                name="current_class"
                id=""
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Class</option>
                {schoolClasses.map((schoolClass) => {
                  return (
                    <option value={schoolClass.id} key={schoolClass.id}>
                      {schoolClass.class_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="formInput">
              <select
                name="academic_year"
                id="academic_term"
                onChange={(e) => changeAcademicYear(e)}
              >
                <option value="">Select Academic Year</option>
                {academicYears.map((academicYear) => {
                  return (
                    <option value={academicYear.id} key={academicYear.id}>
                      {academicYear.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="formInput">
              <select
                name="term"
                onChange={(e) => handleChange(e)}
                id="academic_term"
              >
                {data.academic_year === "" ? (
                  <option value="">Select Academic Year First</option>
                ) : (
                  <>
                    <option value="">Select Term</option>
                    {academicTerms.map((academicTerm) => {
                      return (
                        <option key={academicTerm.id} value={academicTerm.id}>
                          {academicTerm.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            {loading ? (
              <button
                style={{
                  backgroundColor: "gray",
                  cursor: "wait",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  color: "white",
                  border: "none",
                }}
              >
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />{" "}
                Generating Reports{" "}
              </button>
            ) : (
              <button type="submit">Generate Report</button>
            )}
          </form>
        </div>
        {
          studentList.length > 0 && (
            <>
              {/* <p style={{marginTop: "30px"}}>
                Exam report for these students have been generated. <span style={{color: "blue", textDecoration: "underline", cursor:"pointer"}} onClick={()=>approveReport()}>Click Here</span>  to approve the reports
              </p> */}
              <div className="student-list">
                
                <div className="panel-header">
                  <h4>Student List</h4>
                </div>
                
                <div className="list-body">
                  <table>
                    <thead>
                      <tr>
                        <th>Student Name</th>
                        <th>Student ID</th>
                        <th>Class</th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentList.map((student) => {
                        return (
                          <tr>
                            <td>
                              {student.get_full_name}{" "}
                              
                            </td>
                            <td>{student.student_school_id}</td>
                            <td>{student.student_class}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )
        }
      </div>
      ):(
        <Loading />
      )
      }
      
    </div>
  );
};

export default GenerateStudentReport;
