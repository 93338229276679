import React, { useRef, useState } from "react";
import NoDataImage from "../../assets/no_data.jpg"
import ReactToPrint from "react-to-print";
import axios from "axios";
import { API_URL } from "../../url";
import { useAuthenticate } from "../../context/AuthContext";
import ExamScheduleModal from "./ExamScheduleModal";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";
import Header from "../../components/Header";

const ExamSchedules = () => {
  const { academicYears, schoolClasses, user } = useAuthenticate();
  const [toggle, setToggle] = useState(1);
  const [academicTerms, setAcademicTerms] = useState([]);
  const [examSchedules, setExamSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const examScheduleRef = useRef();
  const [data, setData] = useState({
    class: "",
    term: "",
    academic_year: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const changeAcademicYear = (e) => {
    setData({
      ...data,
      academic_year: e.target.value,
    });

    // setAcademicTerms(academicYears.find((year) => year.id === e.target.value).terms)
    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  const updateToggle = (tab) => {
    setToggle(tab);
  };

  const fetchExamSchedule = async () => {
    if (data.class === "" || data.term === "") {
      toast.error("Please check and select all required fields");
    } else {
      if (localStorage.getItem("access")) {
        setLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };

        try {
          const res = await axios.get(
            `${API_URL}academics/class-exam-schedule/${data.class}/${data.term}/`,
            config
          );
          setExamSchedules(res.data);
          setLoading(false);
          setDataFetched(true);
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/";
            } else {
              for (const property in error.response.data) {
                toast.error(`${error.response.data[property]}`);
              }
            }
          } else if (error.message) {
            toast.error("Something went wrong. Please try again");
          }
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchExamSchedule();
  };

  const deleteExamSchedule = (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };

          try {
            axios
              .delete(`${API_URL}academics/exam-schedule-detail/${id}/`, config)
              .then((response) => {
                Swal.fire(
                  "Deleted!",
                  "Exam Schedule deleted successfully.",
                  "success"
                ).then(() => {
                  fetchExamSchedule();
                });
              });
          } catch (error) {
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/";
              } else {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    });
  };

  return (
    <div className="main-container">
      <Header title="Exam Time table" subtitle="Manage Exam Time table" />
      <div className="positions-container">
        <div className="tabs">
          <div
            onClick={() => {
              updateToggle(1);
            }}
            className={toggle === 1 ? "active-tab" : "tab"}
          >
            Exam Schedules
          </div>
          {
            user?.account_type === "School Administrator" && (
              <div
            onClick={() => {
              updateToggle(2);
            }}
            className={toggle === 2 ? "active-tab" : "tab"}
          >
            Add Exam Schedules
          </div>
            )
          }
          
        </div>
        <div className={toggle === 1 ? "show-content" : "content"}>
          <div style={{ margin: "25px" }} className="schedule-body">
            <div className="filter-columns">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="formInput">
                  <select onChange={(e) => handleChange(e)} name="class">
                    <option>Select Class</option>
                    {schoolClasses.map((schoolClass) => {
                      return (
                        <option value={schoolClass.id} key={schoolClass.id}>
                          {schoolClass.class_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="formInput">
                  <select
                    required
                    name="academic_year"
                    id="academic_term"
                    onChange={(e) => changeAcademicYear(e)}
                  >
                    <option value="">Select Academic Year*</option>
                    {academicYears.map((academicYear) => {
                      return (
                        <option value={academicYear.id} key={academicYear.id}>
                          {academicYear.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="formInput">
                  <select onChange={(e) => handleChange(e)} name="term">
                    {data.academic_year === "" ? (
                      <option>Select Academic Year First</option>
                    ) : (
                      <>
                        <option>Academic Term</option>
                        {academicTerms.map((term) => {
                          return (
                            <option value={term.id} key={term.id}>
                              {term.name}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </select>
                </div>
                {loading ? (
                  <button
                    style={{
                      backgroundColor: "gray",
                      cursor: "wait",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      padding: "10px",
                      color: "white",
                      border: "none",
                    }}
                  >
                    <ColorRing
                      visible={true}
                      height="20"
                      width="20"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                    />{" "}
                    Getting Data{" "}
                  </button>
                ) : (
                  <button type="submit">Get Exam Schedule</button>
                )}
              </form>
            </div>

            {/* EXAM SCHEDULE TO DISPLAY */}
            <div className="schedule">
              {!dataFetched ? (
                <>
                  <p style={{ textAlign: "center" }}>
                    Please Select The Class And Term
                  </p>
                </>
              ) : (
                <>
                  {examSchedules.length > 0 ? (
                    <>
                      <div className="buttons">
                        <ReactToPrint
                          trigger={() => (
                            <button
                              style={{
                                backgroundColor: "#304D7B",
                                color: "white",
                                cursor: "pointer",
                                border: "none",
                                padding: "10px 15px",
                                marginTop: "30px",
                              }}
                            >
                              Print / Download
                            </button>
                          )}
                          content={() => examScheduleRef.current}
                        />
                      </div>
                      <table>
                        <thead
                          style={{ background: "#47A992", color: "white" }}
                        >
                          <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Subject</th>
                            <th>Exam Hall</th>
                            {
                              user?.account_type === "School Administrator" && (
                                <th>Actions</th>
                              )
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {examSchedules.map((schedule) => {
                            return (
                              <tr key={schedule.id}>
                                <td>{schedule.date}</td>
                                <td>
                                  {schedule.starting_time} -{" "}
                                  {schedule.ending_time}
                                </td>
                                <td>{schedule.subject}</td>
                                <td>{schedule.exams_venue}</td>
                                {
                                  user?.account_type === "School Administrator" && (
                                    <td>
                                      <AiFillDelete
                                        onClick={() =>
                                          deleteExamSchedule(schedule.id)
                                        }
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </td>
                                  )
                                }
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <div className="no-data">
                    <img src={NoDataImage} alt="no data" />
                    <p>
                      No Data Found
                    </p>
                  </div>
                  )}
                </>
              )}
            </div>

            {/* EXAM SCHEDULE TO PRINT */}
            <div className="schedule hidden">
              {dataFetched && (
                <>
                  {examSchedules.length > 0 && (
                    <div ref={examScheduleRef} style={{width:"100% !important"}}>
                      <h1 style={{textAlign: "center", margin: "10px auto"}}>Examination Timetable for {examSchedules[0].school_class}</h1>
                      <table style={{borderCollapse: "collapse", border:"1px solid #d0d5d9"}}>
                        <thead
                          style={{ background: "#47A992", color: "white" }}
                        >
                          <tr>
                            <th style={{border: "1px solid #d0d5d9", fontWeight:"bold", padding: "13px 20px"}}>Date</th>
                            <th style={{border: "1px solid #d0d5d9", fontWeight:"bold", padding: "13px 20px"}}>Time</th>
                            <th style={{border: "1px solid #d0d5d9", fontWeight:"bold", padding: "13px 20px"}}>Subject</th>
                            <th style={{border: "1px solid #d0d5d9", fontWeight:"bold", padding: "13px 20px"}}>Exam Hall</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {examSchedules.map((schedule) => {
                            return (
                              <tr key={schedule.id}>
                                <td style={{border: "1px solid #d0d5d9", padding: "13px 20px"}}>{schedule.date}</td>
                                <td style={{border: "1px solid #d0d5d9", padding: "13px 20px"}}>
                                  {schedule.starting_time} -{" "}
                                  {schedule.ending_time}
                                </td>
                                <td style={{border: "1px solid #d0d5d9", padding: "13px 20px"}}>{schedule.subject}</td>
                                <td style={{border: "1px solid #d0d5d9", padding: "13px 20px"}}>{schedule.exams_venue}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={toggle === 2 ? "show-content" : "content"}>
          <ExamScheduleModal />
        </div>
      </div>
    </div>
  );
};

export default ExamSchedules;
